<template>
  <div class="navbar-container mt-6">
    <nav
      class="navbar h-fullwidth mt-3"
      role="navigation"
      aria-label="main navigation"
      style="height: 3rem;"
    >
      <div class="navbar-brand">
        <a
          class="is-hidden-touch"
          href="/"
          style="position:absolute; top:-25px;"
        >
          <Logo :width="100">
            <!-- inPage.into is TRUE when it is inside of any of the following
              sections artist/*, content/*, event/*, tablero/, capsula/
              :-->
            <h2 
              v-if="!inPage.into"
              class="is-uppercase title is-2 center ml-5 has-text-black"
            >Habeatat
            </h2>
          </Logo>
        </a>
        <a
          class="is-hidden-desktop"
          href="/"
          style="position:absolute; top:-5px; right: 5%;"
        >
          <Logo :width="65" />
        </a>

        <div
          class="navbar-item is-hidden-touch"
          v-if="inPage.into"
          style="width: 120px; z-index: -1;"
        ></div>
        <!-- <transition name="fadeLeft"> -->
        <div class="navbar-item is-hidden-desktop" v-if="inPage.into">
          <div class="is-size-6">
            <router-link :to="{ name: inPage.pageName }">
              <span class="icon h-vertical"
                ><i class="icon-retry is-size-4"></i
              ></span>
              <span>{{ text.goBack }}</span>
            </router-link>
          </div>
        </div>
        <!-- </transition>	  		 -->
        <!-- <transition name="fadeLeft"> -->
        <router-link
          :to="{ name: inPage.pageName }"
          class="navbar-item is-size-4 is-hidden-touch is-uppercase"
          v-if="inPage.into"
        >
          {{ $store.state.internalPage.page }}
        </router-link>
        <!-- </transition> -->
        <!-- <transition name="fadeRight"> -->
        <a
          role="button"
          v-if="!inPage.into"
          :class="{
            'navbar-burger': true,
            burger: true,
            'is-active': mobileMenuShow,
            'is-size-4 h-textcolor-darkblue': true,
          }"
          @click="mobileMenuShow = !mobileMenuShow"
          aria-label="menu"
        >
          <i class="fas fa-bars" v-if="!mobileMenuShow"></i>
          <i class="fas fa-times" v-else></i>
        </a>
        <!-- </transition> -->
      </div>

      <!-- MENU DESKTOP -->
      <!-- <transition name="h-in"> -->
      <div v-if="!inPage.into" class="navbar-menu is-hidden-touch">
        <div
          class="
								navbar-end 
								is-uppercase 
								has-text-black
								is-size-5-fullhd 
								is-size-6-widescreen 
								is-size-7-desktop 
								is-size-7-touch"
        >
          <router-link :to="{ name: 'artist' }" class="navbar-item is-size-5-widescreen">
            artistas
          </router-link>
          <router-link :to="{ name: 'event' }" class="navbar-item is-size-5-widescreen">
            eventos
          </router-link>
          <router-link :to="{ name: 'tickets' }" class="navbar-item is-size-5-widescreen">
            Tickets
          </router-link>
          <router-link :to="{ name: 'content' }" class="navbar-item is-size-5-widescreen">
            contenido
          </router-link>
          <!--router-link 
								:to="{ name: 'therepro'}"
								class="navbar-item bdleft">
								the repro
								</router-link-->
          <router-link :to="{ name: 'capsula' }" class="navbar-item is-size-5-widescreen">
            la cápsula
          </router-link>
          <router-link :to="{ name: 'contact' }" class="navbar-item is-size-5-widescreen">
            contacto
          </router-link>
        </div>
      </div>
      <!-- </transition> -->

      <!-- MENU MOBILE -->
      <transition name="fade">
        <div
          v-if="mobileMenuShow"
          :class="{
            'navbar-menu is-hidden-desktop': true,
            'is-active': mobileMenuShow,
          }"
        >
          <div
            class="
								navbar-start 
								is-uppercase 
								has-text-white 
								is-size-6"
          >
            <a
              @click="goTo('artist')"
              :class="{
                'navbar-item': true,
                'router-link-exact-active': inPage.pageName == 'artist',
              }"
            >
              artistas
            </a>

            <a
              @click="goTo('event')"
              :class="{
                'navbar-item': true,
                'router-link-exact-active': inPage.pageName == 'event',
              }"
            >
              eventos
            </a>


            <a
              @click="goTo('tickets')"
              :class="{
                'navbar-item': true,
                'router-link-exact-active': inPage.pageName == 'tickets',
              }"
            >
              tickets
            </a>            

            <a
              @click="goTo('content')"
              :class="{
                'navbar-item': true,
                'router-link-exact-active': inPage.pageName == 'content',
              }"
            >
              contenido
            </a>

            <!--a 
								:class="{'navbar-item':true, 'router-link-exact-active':inPage.pageName == -a }">
								the repro
								</a-->

            <a
              @click="goTo('capsula')"
              :class="{
                'navbar-item': true,
                'router-link-exact-active': inPage.pageName == 'capsula',
              }"
            >
              la cápsula
            </a>

            <a
              @click="goTo('contact')"
              :class="{
                'navbar-item': true,
                'router-link-exact-active': inPage.pageName == 'contacto',
              }"
            >
              contacto
            </a>
          </div>
        </div>
      </transition>

      <!-- <transition name="fadeRight"> -->
      <div class="navbar-end" v-if="inPage.into">
        <div class="navbar-item is-hidden-touch">
          <div class="is-size-5">
            <router-link
              :to="{
                name: inPage.pageName,
                params: inPage.params != undefined ? inPage.params : {},
              }"
            >
              <span class="icon h-vertical"
                ><i class="icon-retry is-size-3"></i>
              </span>
              <span>{{ text.goBack }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <!-- </transition> -->
    </nav>
  </div>
</template>

<script>
import Logo from "@/components/logo";
export default {
  name: "H-Menu",
  data() {
    return {
      mobileMenuShow: false,
      text: {
        goBack: "Regresar",
      },
    };
  },
  components: {
    Logo,
  },
  computed: {
    inPage() {
      return this.$store.state.internalPage;
    },
  },
  watch: {
    inPage: function() {
      this.mobileMenuShow = false;
    },
  },
  methods: {
    goTo(page) {
      this.mobileMenuShow = false;
      this.$router.push({ name: page });
    },
  },
};
</script>

<style scoped>
.center{
  margin:auto auto
}
a:hover {
  opacity: 1 !important;
}
.navbar {
  background-color: inherit !important;
  transition: width 1s;
  z-index: 20 !important;
}
a.navbar-item {
  font-size: 13px;
  padding: 0.5rem .75rem !important;
  transition: color 0.6s;
}
.navbar-item img {
  max-height: inherit !important;
}
.navbar-burger {
  height: inherit !important;
  margin-right: auto;
  margin-left: 10%;
  padding-left: auto;
  padding-right: auto;
  padding-top: 2.5%;
  text-decoration: none !important;
}
.navbar-start {
  margin-left: auto;
}
.navbar-item:hover {
  background: inherit !important;
}
.router-link-exact-active {
  color: #e68433 !important;
}
.icon-retry,
.burger {
  vertical-align: middle;
}
@media screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: #21292f;
    border-radius: 8px;
  }
  .navbar-menu.is-active {
    width: 45%;
  }
}
</style>
