<template>
  <section v-if="slidePosition === positionIndexArray" class="main-container">
    <div class="carousel" id="carousel">

      <div
        :key="capsula.id"
        class="carousel-item visible">
        <img
          :class="['imgBackground',
            capsula.background === '' ? 'has-background-black' : null]"
          loading="lazy"
          :src="capsula.background != '' ? capsula.background : '/img/black-bg.png'"
          :alt="capsula.name"
        />
        <div class="carousel-artist p-6">
          <div class="carousel-artist_info is-flex is-flex-direction-column is-justify-content-start">
            <h1 class="title is-size-1 is-size-3-mobile is-uppercase has-text-white has-text-left px-5 py-4 opensans">{{capsula.name}}</h1>
            <div
              class="
                    has-text-white
                    artist_bio
                    px-5
                  "
            >
              <p class="mb-3 is-size-5-widescreen has-text-white subtitle is-6 has-text-left opensans p-description">
                {{capsula.description.substring(0,314) + '...'}}
              </p>
            </div>
            <router-link 
                :to="{name: 'capsula'}"
                class="button 
                m-5
                is-size-6
                has-text-white-bis
                has-background-grey-darker
                button-width"
              >Ver más
            </router-link>
<!--             <div class="artist_social mt-6 px-4">
              <Social class="is-flex is-justify-content-start" :social="capsula.social"/>
            </div> -->
          </div>
          <div class="carousel-artist_videos">
            
          </div>
        </div>
      </div>
    </div>
    <div class="artist_social_desktop">
      <Social
        v-for="(cap,index) in capsula"
        :key="cap.id"
        :class="[index === slide ? null : 'is-hidden',
          'is-flex is-justify-content-start social-width-desktop is-hidden-touch']" 
        :social="cap.social"
        :color="color" 
        />
    </div>
  </section>
</template>

<script>
  import Social from '@/components/general/social'
export default {
  name: "CarouselLaCapsula",
  props: ['slidePosition'],
  data(){
    return {
      slide: 0,
      positionIndexArray: 3
    }
  },
  created(){
    this.content.length ? this.positionIndexArray = 3 : this.positionIndexArray = 2
  },
  methods:{
  },
  components: {Social},
  computed:{
    //Gets artists information
    capsula(){
      return this.$store.state.capsula
    },
    content(){
      return this.$store.state.content
    }
  }
};
</script>

<style scoped>

.carousel {
  overflow: hidden;
  position: relative;
}
.carousel-item {
  position:relative;

}
.carousel-item,
.carousel-item_hidden {
  display: none;
  animation: fadeVisibility 2s;
}

.carousel-item .imgBackground {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;

  /*For some reason, I had to use translateX 
  otherwise the background image would only
  be shown at the container's center left side*/

  transform: translateX(-50%);
}

.carousel-item.visible {
  display: block;
  background-size: cover;
}

/* Carousel Information */
.carousel-artist {
  position: relative;
  display: grid;
  grid-template: repeat(auto-fit, minmax(430px, 1fr)) / repeat(
      auto-fit,
      minmax(375px, 1fr)
    );
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.carousel-artist_videos{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

/*Carousel Youtube Video*/
.main_video iframe {
  width: 400px;
  height: 220px;
}

/* Carousel dots */
.carousel-dots {
  list-style: none;
  position: absolute;
  right: 50%;
  bottom: 5%;
  transform: translateX(50%);
}
.carousel-dots_list > li {
  display: inline-block;
  content: "";
  border: 4px solid gray;
  border-radius: 100%;
}
.carousel-dots_list >li:hover {
  cursor: pointer;
}

.carousel-dots_list > .active {
  border-color: white;
}

.button-width{
  width: 100px;
}
/*TExt modifiers*/
  p.has-text-left{
    line-height: 1.5;
  }
  h1.title.is-1{
    font-size: 5rem;
  }
@keyframes fadeVisibility {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>