<template>
<div class="content-detail columns is-vcentered">
    <div class="column is-2-fullhd is-1-tablet is-hidden-mobile"
        @mouseover="animate.left = true"
        @mouseout="animate.left = false">
        <a 
        v-show="'id' in prev"
        @click="toPrev()"
        v-tooltip.bottom="prev.title">
            <span class="icon fa-3x"><i :class="{'icon-play-prev':true, 'h-animate-text-left':animate.left}"></i></span>
        </a>
    </div>
    <!-- INTERNAL PAGINATION MOBILE -->
    <div class="columns is-mobile is-hidden-tablet is-centered is-vcentered">
        <div class="column is-3">
            <a 
            v-show="'id' in prev"
            @click="toPrev()"
            v-tooltip.bottom="prev.title">
                <span class="icon fa-2x"><i class="icon-play-prev"></i></span>
            </a>
        </div>
        <div class="column">
            <p class="is-size-4">
                {{detail.title}}
            </p>
        </div>
        <div class="column is-3 is-text-left">
            <a 
            v-show="'id' in next"
            @click="toNext()"
            v-tooltip.bottom="next.title">
                <span class="icon fa-2x"><i class="icon-play-next"></i></span>
            </a>	
        </div>
    </div>

    <div class="column px-0">
        <div class="columns">
            <div class="column pl-0">
                <HVideoBlock 
                    :ownerkey="key"
                    :channel="detail.playlist.channel"
                    :galery="detail.album"
                    @data-ready="loading=false"
                    :name="detail.title" />                
            </div>
            <div class="column pr-0">
                <HDetails 
                :hname="detail.title"
                :hdescription="detail.description"
                :hweb="event.web"
                :hemail="event.email"
                :hasPlayer="detail.playlist.spotify != ''"
                @show-more-description="openDesModal()" />
                <div class="h-separator20"></div>
                <HSong v-if="detail.playlist.spotify != ''" :playList="detail.playlist.spotify" />
            </div>
        </div>

    </div>
    <div class="column is-2-fullhd is-1-tablet is-hidden-mobile"
        @mouseover="animate.right = true"
        @mouseout="animate.right = false">
        <a 
        v-show="'id' in next"
        @click="toNext()"
        v-tooltip.bottom="next.title">
            <span class="icon fa-3x"><i :class="{'icon-play-next':true, 'h-animate-text-right':animate.right}"></i></span>
        </a>
    </div>

</div>
</template>

<script>
    import HVideoBlock 		from '@/components/general/videoBlock'
    import HDetails 		from '@/components/general/details'
    import HSong 			from '@/components/general/song'
    
export default {
    name:'EventLibrary',
    components: {
        HVideoBlock,
        HDetails,
        HSong
    },
    data: () => ({
        page: 'eventos',
        pageName: 'event',
        container: false,
        detail: {},
        next: {},
        prev: {},
        animate: {
            left: false,
            right: false
        }
    }),
    computed: {
        event() {
            return this.$store.getters.GetEvent.filter(x => x.name_id == this.$route.params.id)[0]
        },
        key() {
            return this.$store.state.googleAPIKey
        }
    },
    created() {
        this.setInternalPage()
        this.event.details.length > 0 ? this.setUpPage(this.event.details[0].id):this.$router.push(`/${this.page}/${this.$route.params.id}/portada`)
    },
    methods: {
        setInternalPage() {
            this.$store.commit('inPage', {title: this.page, name: this.pageName, container: this.container})
        },
        setUpPage(detail_id) {
            this.$store.commit('loader', 'y')
            this.next = {}, this.prev = {}
            const tempArray = this.event.details
            
            var cond = false
            for (var i in tempArray) {
                var item = tempArray[i]
                
                if (tempArray[i].id === detail_id) {
                    cond = true
                    this.detail = item
                } else {
                    if (cond) {
                        this.next = item
                        break
                    } else {
                        this.prev = item
                    }
                }                
            }

            this.$store.commit('loader', 'n')
        },
        toNext() {
            this.setUpPage(this.next.id)
        },
        toPrev() {
            this.setUpPage(this.prev.id)
        },
        openDesModal() {
            this.$store.commit('changeModalStatus', {
                    modal:'des', 
                    status:true, 
                    info: {
                        title: this.detail.title,
                        description: this.detail.description
                    }
                })
        }
    }
}
</script>

<style>

</style>