<template>
	<div class="tile is-vertical">
				<!-- 'is-uppercase h-letspace-2 is-hidden-mobile h-title-box':true,
				'is-size-1-fullhd is-size-2-widescreen is-size-3-desktop is-size-4-touch': (hname.length <= 12),
				'is-size-2-fullhd is-size-3-widescreen is-size-4-desktop is-size-5-touch': (hname.length > 12 && hname.length <= 19),
				'is-size-3-fullhd is-size-4-widescreen is-size-5-desktop is-size-6-touch': (hname.length > 19)		 -->
		<h2 :class="{
			'is-uppercase h-letspace-1 is-hidden-mobile h-title-box is-size-3-fullhd is-size-4-widescreen is-size-5-desktop is-size-6-tablet':true,
			'title-box-large': ((hemail == '') && (hweb == undefined || !('text' in hweb) || hweb.text == '')) && !(hasPlayer)
			}">
			{{ hname }}
		</h2>
		<p :style="cssTextField"
		class="
			is-size-5-fullhd 
			is-size-6-widescreen 
			is-size-7-desktop 
			is-size-7-touch 
			h-line-1-5 
			has-text-justified 
			text-field 
			h-padding-rl
			is-clipped">
			{{ (hdescription != '' || hdescription.length > 200) ? hdescription.substring(0, 
				screen > 1800 ? 410 : 
				(screen > 1590 ? 285 : 
				(screen > 1380 ? 190 : 
				(screen > 1180 ? 375 : 
				(screen > 980 ? 650 : 255 ) ) ))) 
				: hdescription }}<span v-show="hdescription.length > 285">...</span> <span class="is-clickable" @click="$emit('show-more-description')" v-show="hdescription.length > 285"><b class="h-text-warning">Leer más.</b></span>
		</p>
		<p
		class="link-box is-size-5-fullhd is-size-6-widescreen is-size-7-desktop is-size-7-touch h-separator-t2">
			<a :href="hweb.url" target="_blank" v-if="hweb != undefined" class="h-textcolor-gold">{{ hweb.text }}</a>&nbsp;<span v-if='hemail != "" && (hweb != undefined && hweb.text != "")'>/</span> <a :href="`https://mail.google.com/mail/?view=cm&source=mailto&to=${hemail}`" target="_blank">{{ hemail }}</a>
		</p>
	</div>
</template>

<script>
	export default {
		props: {
			'hname':String, 'hdescription':[Text,String], 'hweb':[Object,String], 'hemail':String,
			'hasPlayer': {
				type: Boolean,
				default: false
			}
		},
		computed: {
			screen() {
				return window.screen.width
			},
			cssTextField() {
				var text1800 = '12.8rem'
				var text1600 = '8.75rem'
				var text1200 = '7.15rem'
				var text1000 = '9.8rem'
				if (typeof this.hcondition != 'undefined') {
					text1800 = '12.5rem'
					text1600 = '8.25rem'
					text1200 = '6.8rem'
					text1000 = '9rem'
					if(this.hemail === "" && this.hweb.text === "") {
						text1800 = '15.2rem'
						text1600 = '10.75rem'
						text1200 = '8.85rem'
						text1000 = '10.7rem'
					}
				}
				return {
					'--text-field-1800':text1800,
					'--text-field-1600':text1600,
					// '--text-field-1400':text1400,
					'--text-field-1200':text1200,
					'--text-field-1000':text1000
				}
			}
		}
	}
</script>

<style scoped>
	.text-field {
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.link-box {
		height: 1rem;
	}
	@media only screen and (min-width: 1000px) {
		.text-field {
			height: var(--text-field-1000);
		}
		.h-title-box {
			height: 2.3rem;
		}
		.h-title-box.title-box-large {
			height: 3.3rem;
		}
	}
	@media only screen and (min-width: 1200px) {
		.text-field {
			height: var(--text-field-1200);
		}
		.h-title-box {
			height: 3.7rem;
		}
		.h-title-box.title-box-large {
			height: 5.3rem;
		}
		.link-box {
			height: 1.6rem;
		}
	}
	@media only screen and (min-width: 1600px) {
		.text-field {
			height: var(--text-field-1600);
		}
		.h-title-box {
			height: 4.4rem;
		}
		.h-title-box.title-box-large {
			height: 6.1rem;
		}
		.link-box {
			height: 1.7rem;
		}
	}
	@media only screen and (min-width: 1800px) {
		.text-field {
			height: var(--text-field-1800);
		}
		.h-title-box {
			height: 4.4rem;
		}
		.h-title-box.title-box-large {
			height: 6.2rem;
		}
		.link-box {
			height: 1.8rem;
		}
	}
	@media screen and (max-width: 999px) {
		.text-field {
			padding-left: 3em;
			padding-right: 3em;
		}
	}
</style>