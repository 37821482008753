<template>
	<div :class="{'tile':true, 'h-separator-t5':!isMobile}">
			<transition name="fade">
				<div class="tile is-parent is-9 is-vertical h-margin-center" v-if="!codeVerifying || isAlreadyIn || !key.status">
					<ReproPlayer
					v-if="selected_song.id != undefined"
					:song="selected_song"
					:random="random"
					@set-random="setRandom"
					@ended="getNextPlay()"
					@get-prev="getPrevPlay()"
					@get-next="getNextPlay()" />

					<ReproPlaylist 
					:playItems="songs"
					:selectedRow="selected_song"
					@select-row="setSelectedSong" />

					<div class="tile repro-logo-box is-hidden-tablet has-background-grey-darker is-marginless">
						<figure>
							<img src="/img/therepro/repro_logo_0.png" width="100px">
						</figure>
					</div>
				</div>
			</transition>
			<!--
			<div class="tile is-parent is-5">
				<ReproProducer />
			</div>
			-->
			<VerifyCode v-if="!isAlreadyIn && key.status"
			:show="codeVerifying"
			:message="'Ingrese la llave secreta para acceder a esta pagina'"
			:loading="codeModalLoading"
			@submit-code="verifyCode" />

			<!-- Charging Modal -->
			<ModalLoading :show="modalLoading" />
	</div>
</template>

<script>
	import ReproPlayer from '@/components/therepro/player'
	import ReproPlaylist from '@/components/therepro/playlist'
	//import ReproProducer from '@/components/therepro/producer'
	import VerifyCode from '@/components/modal/verifyCodeModal'
	import ModalLoading from '@/components/modal/pic'
	export default {
		name:'theRepro',
		components: {
			ReproPlayer,
			ReproPlaylist,
			//ReproProducer,
			VerifyCode,
			ModalLoading
		},
		data() {
			return {
				codeVerifying: true,
				codeModalLoading: false,
				random: false,
				selected_song: {},
				modalLoading: false,
				playlist: {
					prev: [],
					next: []
				}
			}
		},
		beforeCreate() {
			this.$emit('hideMenu')
		},
		created() {
			this.modalLoading = true
			this.$store.commit('resetMusic')
			const formData = new FormData();
			formData.append('h_key', this.HKey);
			this.$http
				.post(this.api+'/h/music-list/', formData)
				.then(response => {
					var songs = response.data.songs
					if (songs.length > 0) {
						this.$store.commit('makeMusic', songs)
						this.selected_song = songs[0]
						songs.shift()
						var playlist = []
						songs.forEach(function (value) {
							playlist.push(value)
						});
						this.playlist.next = playlist
					}
					this.modalLoading = false
				})
		},
		computed: {
			songs() {
				return this.$store.getters.GetSongs
			},
			isMobile() {
				return this.$store.getters.GetMobileBrowser
			},
			isAlreadyIn() {
				return this.$store.getters.GetIfReproKey
			},
			key() {
				return this.$store.getters.GetPageskeys.repro
			},
			HKey() {
				return this.$store.getters.GetHabeatatKey
			},
			api() {
				return this.$store.getters.GetApiPath
			}
		},
		methods: {
			verifyCode(code) {
				this.codeModalLoading = true
				if (code === this.key.key) {
					this.$swal({
						type: "success",
						title: "Correcto!",
						text: 'Se ha verificado correctamente su llave axeso.',
						confirmButtonClass: "button",
						buttonsStyling: false,
						background: "white"
					}).then(() => {
						this.$store.dispatch('reproKey')
						this.codeVerifying = false 
					}); 
				} else {
					this.$swal({
						type: "error",
						title: "Clave incorrecta!",
						text: 'Porfavor ingrese una llave valida.',
						confirmButtonClass: "button",
						buttonsStyling: false,
						background: "white"
					})
				}
				this.codeModalLoading = false
			},
			resetLists() {
				this.playlist.prev = []
				this.playlist.next = []
			},
			setRandom() {
				this.random = !this.random
				if (!this.random) {
					this.setSelectedSong(this.selected_song)
				} else {
					this.playlist.next.sort(() => Math.random() - 0.5);
				}
			},
			setSelectedSong(song) {
				this.resetLists()
				var songs = this.$store.getters.GetSongs
				if ( !this.random ) {
					var corner = false
					for ( var ss in songs ) {
						if ( !corner ) {
							if ( songs[ss].id === song.id ) {
								corner = true
							} else {
								this.playlist.prev.push(songs[ss])
							}
						} else {
							this.playlist.next.push(songs[ss])
						}
					}
				} else {
					for (var i in songs) {
						if ( songs[i].id != song.id ) {
							this.playlist.next.push(songs[i]) 
						}
					}
					this.playlist.next.sort(() => Math.random() - 0.5);
				}
				this.selected_song = song
			},
			getNextPlay() {
				if( this.playlist.next.length > 0 ) {
					this.playlist.prev.push(this.selected_song)
					this.selected_song = this.playlist.next[0]
					this.playlist.next.shift()
				}
			},
			getPrevPlay() {
				if( this.playlist.prev.length > 0 ) {
					this.playlist.next.unshift(this.selected_song)
					this.selected_song = this.playlist.prev[this.playlist.prev.length-1]
					this.playlist.prev.pop()
				}
			}
		}
	}
</script>

<style>
	.repro-logo-box {
		padding-top: 5px;
		border-bottom-width: 1px;
		border-bottom-style: ridge; 
		border-bottom-color: white;
	}
</style>