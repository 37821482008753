<template>
	<div class="tile is-vertical">
			<button class="button h-margin-center" type="button" @click="formModal.detail=true">
				<span class="icon"><i class="far fa-plus-square"></i></span> <span>Nuevo Segmento</span>
			</button>
		<table class="table h-fullwidth">
			<thead>
				<tr>
					<th>
						<abbr title="Título para el segmento del Contenido">Título</abbr>
					</th>
					<th v-if="item.condition.includes(1)">
						<abbr title="Año que se efectua el Evento">Año</abbr>
					</th>
					<th v-if="item.condition.includes(2)">
						<abbr title="País en donde se organiza el Evento">País</abbr>
					</th>
					<th v-if="item.condition.includes(3)">
						<abbr title="Volumen o version del Evento">Vol.</abbr>
					</th>
					<th>
						<abbr title="Acciones" v-if="!tableLoading"><i class="fas fa-exclamation"></i> Detalle</abbr>
						<span v-else><i class="fas fa-compact-disc fa-spin"></i> Cargando tabla...</span>
					</th>
				</tr>
			</thead>
			<tfoot>
				<tr>
					<th>
						<abbr title="Título para el segmento del Contenido">Título</abbr>
					</th>
					<th v-if="item.condition.includes(1)">
						<abbr title="Año que se efectua el Evento">Año</abbr>
					</th>
					<th v-if="item.condition.includes(2)">
						<abbr title="País en donde se organiza el Evento">País</abbr>
					</th>
					<th v-if="item.condition.includes(3)">
						<abbr title="Volumen o version del Evento">Vol.</abbr>
					</th>
					<th>
						<div class="buttons has-addons is-centered">
							<button 
							:class="{'button is-small':true, 'is-loading':page.loadPrev}" 
							:disabled="page.prev == null || page.loadNext" 
							@click="pagination(1)">
								<i class="icon-play-prev"></i>
							</button>
							<button 
							:class="{'button is-small':true, 'is-loading':page.loadNext}" 
							:disabled="page.next == null || page.loadPrev" 
							@click="pagination(2)">
								<i class="icon-play-next"></i>
							</button>
						</div>
					</th>
				</tr>
			</tfoot>
			<transition-group name="list" tag="tbody">
				<tr v-for="i in items" :key="i.id" :class="{'has-background-warning-light':i.is_active}">
					<td>
						{{i.title}}
					</td>
					<td v-if="item.condition.includes(1)">
						{{i.year}}
					</td>
					<td v-if="item.condition.includes(2)">
						{{i.country}}
					</td>
					<td v-if="item.condition.includes(3)">
						{{i.vol}}
					</td>
					<td>
						<a title="Información" 
						v-tooltip.top="'Información básica del segmento'"
							@click="formData = {
								'id':i.id,
								'description':i.description,
								'title':i.title,
								'condition':i.condition,
								'year':i.year,
								'country':i.country,
								'vol':i.vol,
								'condition':item.condition
							}, formModal.detailChange=true">
							<i class="fas fa-info-circle"></i>
						</a> | 
						<a title="Listas de reproducción"
						v-tooltip.top="'Listas de reproducción del segmento'"
						@click="setEventPlaylist(i.id)">
							<i class="fas fa-film"></i>
						</a> | 
						<a :title="i.is_active ? 'Desactivar':'Activar'"
						v-tooltip.top="(i.is_active ? 'Desactivar':'Activar')+' el segmento'"
						@click="changeStatus({id:i.id, 'is_active':i.is_active})">
							<i :class="['fas', i.is_active ? 'fa-stop-circle':'fa-play-circle']"></i>
						</a> | 
						<a title="Album" 
						v-tooltip.top="'Album con imagenes del segmento'"
						@click="setEventBanners({id:i.id, name: item.name+' '+ (item.condition.includes(2) ? i.country : '') + ' ' + (item.condition.includes(1) ? i.year : '') + ' ' + (item.condition.includes(3) ? 'Vol'+ i.vol : '')})">
							<i :class="['fas fa-images h-animate-text-top']"></i>
						</a> |
						<a title="Eliminar"
						v-tooltip.top="'Eliminar segmento'"
						@click="confirmDestroy(i.id)">
							<i class="fas fa-trash"></i>
						</a>
					</td>
				</tr>
			</transition-group>
		</table>

		<!-- Event Form -->
		<FormModal 
			:show="formModal.detail"
			:formType="9"
			:data="item"
			@modal-close="formModal.detail=false"
			@save-form="createDetail"
			:charging="modalLoading"
			v-if="formModal.detail" />
		<!-- Event Form Change -->
		<FormModal 
			:show="formModal.detailChange"
			:formType="9"
			:data="formData"
			@modal-close="formModal.detailChange=false"
			@save-form="changeDetail"
			:charging="modalLoading"
			v-if="formModal.detailChange" />
		<!-- Event Playlist Form -->
		<FormModal 
			:show="formModal.playlist"
			:formType="4"
			:data="selected_playlist"
			@modal-close="formModal.playlist = false"
			@save-form="changePlaylist"
			:charging="modalLoading"
			v-if="formModal.playlist" />
		<!-- Event Pictures -->
		<bannerModal 
			:show="bannerModal" 
			:item="selected_detail"
			:type="'content'"
			@modal-close="selected_detail={}, bannerModal=false"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder"
			v-if="'id' in selected_detail" />
		<!-- Confirmation Modal -->
		<ConfirmModal 
			:show="confirmModal"
			v-if="confirmModal"
			:message="confirmText"
			@modal-close="confirmModal = false"
			@modal-submit="destroyEventDetail()" />
		<!-- Loading Screen -->
		<PicModal :show="loadingScreen" />
	</div>
</template>

<script>
	import FormModal from '@/components/modal/form'
	import bannerModal from '@/components/modal/banner'
	import PicModal from '@/components/modal/pic'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: "AdminContentTwo",
		props: ['token','api','priorityOrder','item'],
		data() {
			return {
				items: [],
				detail_id: 0,
				page: {
					next: '',
					prev: '',
					loadNext: false,
					loadPrev: false,
				},
				bannerModal: false,
				confirmModal: false,
				formModal: {
					detail: false,
					detailChange: false,
					playlist: false
				},
				modalLoading: false,
				tableLoading: true,
				formData: {},
				selected_playlist: {},
				selected_detail: {},
				loadingScreen: false,
				basePath: '/content/'
			}
		},
		created() {
			this.setTable()
		},
		components: {
			FormModal,
			bannerModal,
			ConfirmModal,
			PicModal
		},
		watch: {
			item() {
				this.setTable()
			}
		},
		methods: {
			setTable() {
				this.$http
					.get(this.api+this.basePath+'detail/?content='+this.item.id, this.token)
					.then(response => {
						var info = response.data
						this.items = info.results
						this.page.next = info.next
						this.page.prev = info.previous
						this.tableLoading = false
					})
			},
			setEventPlaylist(content_id) {
				this.loadingScreen = true
				this.$http
					.get(this.api+this.basePath+'playlist/'+content_id+'/', this.token)
					.then(response => {
						var result = response.data

						this.selected_playlist = result
						this.loadingScreen = false
						this.formModal.playlist = true
						
					})
					.catch(error => {
						if (error.response.status === 404) {
							const formData = new FormData();
							formData.append('content', content_id);
							this.$http
								.post(this.api+this.basePath+'playlist/',formData,this.token)
								.then(response => {
									var result = response.data

									this.selected_playlist = result
									this.loadingScreen = false
									this.formModal.playlist = true
									
								})
						}
					})
			},
			setEventBanners(data) {
				this.selected_detail = data
				this.bannerModal = true
			},
			setFormData(data) {
				const formData = new FormData();
				if("title" in data) {
					formData.append('title', data.title);
				}
				if("description" in data) {
					formData.append('description', data.description);
				}
				if("year" in data && data.year) {
					formData.append('year', data.year);
				}
				if("country" in data) {
					formData.append('country', data.country);
				}
				if("vol" in data && data.vol) {
					formData.append('vol', data.vol);
				}
				if("is_active" in data) {
					formData.append('is_active', !data.is_active);
				}
				if("spotify" in data) {
					formData.append('spotify', data.spotify);
				}
				if("youtube" in data) {
					formData.append('youtube', data.youtube);
				}
				return formData
			},
			createDetail(content) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('content', this.item.id);
				formData.append('description', content.description);
				formData.append('title', content.title);
				if (content.year > 0) { formData.append('year', content.year); }
				if (content.vol > 0) { formData.append('vol', content.vol); }
				if (content.country != '') { formData.append('country', content.country); }

				this.$http
					.post(this.api+this.basePath+"detail/", formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.formModal.detail = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha creado un nuevo Segmento de Contenido.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.items.unshift(response.data)
						}); 
					})
					.catch(() => {
						this.modalLoading = false
						this.formModal.detail = false
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido guardar completamente el Segmento.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})		
					})
			
			},
			changeDetail(event) {
				this.modalLoading = true
				const formData = this.setFormData(event)
				this.$http
					.patch(this.api+this.basePath+"detail/"+event.id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.formModal.detailChange = false
						var item = response.data
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado la información correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
						for (var i in this.items) {
							if (this.items[i].id === item.id) {
								this.items[i].description = item.description
								this.items[i].year = item.year
								this.items[i].vol = item.vol
								this.items[i].country = item.country
								break
							}
						}

						}); 
					})
					.catch(() => {
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido actualizar la información del segmento.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			},
			changePlaylist(data) {
				this.modalLoading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+"playlist/"+data.id+'/', formData, this.token)
					.then(() => {
						this.modalLoading = false
						this.formModal.playlist = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se han actualizado las listas de reproduccion correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
			},
			changeStatus(data) {
				this.loadingScreen = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+'detail/'+data.id+'/', formData, this.token)
					.then(response => {
						this.loadingScreen = false
						var item = response.data
						for (var i in this.items) {
							if (this.items[i].id === item.id) {
								this.items[i].is_active = item.is_active
								break
							}
						}
					})	
			},
			pagination(n) {
				var page
				if (n === 2) { 
					page = this.page.next 
					this.loadNext = true
				}
				if (n === 1) { 
					page = this.page.prev
					this.loadPrev = true
				}
				this.$http
					.get(page, this.token)
					.then(response => {
						var info = response.data
						this.items = info.results
						this.page.next = info.next
						this.page.prev = info.previous	
					})
			},
			confirmDestroy(content_id) {
				this.detail_id = content_id
				this.confirmText = "Esta seguro de eliminar por completo a este Segmento junto a todo su contenido multimedia?"
				this.confirmModal = true
				
			},
			destroyEventDetail() {
				this.confirmModal = false
				this.loadingScreen = true
				this.$http
					.delete(this.api+this.basePath+'detail/'+this.detail_id+'/', this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha eliminado el Segmento correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.loadingScreen = false
							var item = parseInt(response.data.item.id)
							for (var i in this.items) {
								if (this.items[i].id === item) {
									this.items.splice(i, 1);
									break;
								}
							}
						})
					})
					.catch(() => {
						this.loadingScreen = false
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido eliminar el contenido e imagenes del Segmento.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			}
		}
	}
</script>