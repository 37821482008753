<template>
	<div class="container h-separator-t2" v-if="content.status != undefined">
		<div class="tile is-parent custom-parent is-vertical" v-if="content.status == 202">
			<h1 class="title is-4 is-marginless has-text-justified" >
				Letra de la canción {{content.item.lyric.title}} de {{content.item.artist.name}} <span v-if="content.item.lyric.featuring != ''">+ {{content.item.lyric.featuring}}</span>
			</h1>
			<div class="tile">
				<div class="tile is-parent custom-parent is-3 is-vertical ">
					<div class="tile is-child is-hidden-desktop-only is-hidden-widescreen-only">
						<div class="box custom-other-link">
							<ProfileDescription 
							:name="content.item.artist.name"
							:img="content.item.artist.picture"
							:description="content.item.artist.description"
							:link="'/artistas/'+content.item.artist.name_id"
							:max_length="165" />
						</div>
					</div>
					<div class="tile is-child">
						<div class="box custom-info-box">
							<h2 class="title is-6">
								<i class="fas fa-info-circle" @click="scrollTo(initContentName)"> Información</i>
							</h2>
							<div class="content has-text-justified">
								<ul>
									<li>
										<b>Título</b>: <br>{{content.item.lyric.title}}
									</li>
									<li>
										<b>Artista</b>:<br> {{content.item.artist.name}}
									</li>
									<li>
										<b>Colaborador</b>:<br> {{content.item.lyric.featuring}}
									</li>
									<li>
										<b>Album</b>: <br>{{content.item.lyric.album}}
									</li>
									<li>
										<b>Año</b>:<br> {{content.item.lyric.year}}
									</li>
									<li>
										<b>Comentarios</b>:<br> {{content.item.lyric.comments}}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="tile is-parent custom-parent is-5 h-zindex-10">
					<div class="box has-text-justified h-fullwidth lyric-custom-box lyric-box" v-html="lyric"></div>
				</div>	
				<div class="tile is-parent custom-parent is-4 is-vertical">
					<div class="tile is-child" v-if="content.item.lyric.media.youtube != ''">
						<div class="box is-paddingless is-clipped video-box">
							<SimpleVideo 
							:title="'Canción '+content.item.lyric.title+' de '+content.item.artist.name" 
							:videoId="content.item.lyric.media.youtube"/>
						</div>
					</div>
					<div class="tile is-child">
						
							<h2 class="title is-6 has-text-left" v-if="content.item.more.length > 0">
							Más letras de {{content.item.artist.name}}
							</h2>
							<div class="columns	 is-centered is-mobile" v-if="content.item.more.length > 0">
								<div class="column" v-for="more in content.item.more" :key="more.id">
									<router-link 
									:to="{ name: 'lyrics', params: { artist:more.artist_id, song: more.title_id}}">
										<div class="box custom-other-link">
											<h3 class="title is-7">
												<i class="fas fa-music"></i> <br>
												{{more.title}}
											</h3>
										</div>
									</router-link>
								</div>
							</div>
										
						
							<h2 class="title is-6 has-text-left is-hidden-desktop-only is-hidden-widescreen-only" v-if="content.item.other.length > 0">
								Letras de otros artistas
							</h2>
							<div class="columns is-centered is-mobile is-hidden-desktop-only is-hidden-widescreen-only" v-if="content.item.other.length > 0">
								<div class="column" v-for="other in content.item.other" :key="other.id">
									<router-link 
									:to="{ name: 'lyrics', params: { artist:other.artist_id, song: other.title_id}}">
										<div class="box custom-other-link">
											<h3 class="title is-7">
												<i class="fas fa-music"></i> <br>
												{{other.title}} de <br>
												<span class="h-textcolor-gold">{{other.artist}}</span>
											</h3>
										</div>
									</router-link>
								</div>
							</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class="box" v-else>
			<h1 class="title is-4"><i class="fas fa-info-circle"> {{content.message}}</i></h1>	
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProfileDescription from '@/components/general/profileDescription'
	import SimpleVideo from '@/components/general/simpleVideo'
	export default {
		name: 'Lyrics',
		components: {
			ProfileDescription,
			SimpleVideo
		},
		data() {
			return {
				content: {},
				lyric: {},
				initContentName: 'h_lyric_title'
			}
		},
		created() {
			this.setInternalPage()
			this.setLyric() 
		},
		computed: {
			api() {
				return this.$store.getters.GetApiPath
			},
			habeatatKey() {
				return this.$store.getters.GetHabeatatKey
			},
			pageData() {
				return {title: 'Letra de canciones', name: 'artist_lyrics', params: { artist: this.$route.params.artist } }
			},
			scrollTo() {
				return this.$store.getters.scrollMeTo
			}
		},
		methods: {
			setInternalPage() {
				this.$store.commit('inPage', this.pageData)
			},
			setLyric() {
				const formData = new FormData();
				formData.append('h_key', this.habeatatKey);
				formData.append('type', 2);
				formData.append('artist', this.$route.params.artist);
				formData.append('song', this.$route.params.song);
				this.$http
					.post(this.api+'/h/lyrics-performance/', formData)
					.then(response => {
						var result = response.data
						this.content = result
						this.lyric = JSON.parse(result.item.lyric.text)
						
					})
			}
		}
	}
</script>

<style type="text/css" scoped>
	.lyric-custom-box, .custom-info-box { overflow-y: auto; }
	.content ul li { list-style:  none; }
	.content ul { margin-left: 0; }

	@media screen and (min-width: 1024px) {
		.lyric-custom-box, .custom-info-box { height: 360px; }
	}

	@media screen and (min-width: 1407px) {
		.lyric-custom-box { height: 565px; }
		.custom-info-box{ height: 285px; }
	}

	.tile .is-parent .custom-parent {
		padding: 0.25rem !important;
	}
	.tile.is-vertical > .tile.is-child:not(:last-child) {
		margin-bottom: 0.50rem !important;
	}
	.video-box {
		height: 243px;
	}
	.custom-other-link:hover {
		background: hsl(0, 0%, 86%);
	}
</style>