<template>
	<div class="tile">
		<div class="columns h-fullwidth">
			<div class="column is-3">
				<div class="tile is-vertical">
					<button class="button is-info" @click="modals.newPostulation = true">
						<i class="fas fa-plus has-text-white"> Nuevo evento </i></button>
					<hr class="has-background-grey-lighter">
					<transition name="fadeDown">	
						<div v-if="buttons" class="tile is-vertical">
							<button class="button is-link is-rounded" @click="modals.updatePostulation = true">
								<i class="fas fa-edit has-text-white"> Modificar</i>
							</button>
							<br>
							<button class="button is-success is-rounded" @click="setPhase(1)">
								<i class="fas fa-sign-in-alt has-text-white"> Abrir postulacion</i>
							</button>
							<br>
							<button class="button is-warning is-rounded" @click="setPhase(2)">
								<i class="fas fa-hand-pointer has-text-white"> Iniciar seleccion</i>
							</button>
							<br>
							<button class="button is-link is-rounded" @click="setPhase(3)">
								<i class="fas fa-poll has-text-white"> Abrir votaciones</i>
							</button>
							<br>
							<button class="button is-light is-rounded" 
							v-if="postulation_selected.postu_active === false" 
							@click="changeStatus(postulation_selected)">
								<i class="fas fa-check"> Activar</i>
							</button>
							<button class="button is-black is-rounded" v-else 
							@click="changeStatus(postulation_selected)">
								<i class="fas fa-times has-text-white"> Desactivar</i>
							</button>
							<br>
							<button class="button is-danger is-rounded" @click="modals.confirm = true">
								<i class="fas fa-trash has-text-white"> Eliminar</i>
							</button>
							<br>
						</div>
					</transition>
				</div>
			</div>
			<div class="column">
				<div class="tile is-vertical">
					<table class="table h-top-separator20">
						<thead>
							<tr>
								<th>Evento</th>
								<th>Fechas</th>
								<th>Cantidades</th>
								<th>Fase</th>
							</tr>
						</thead>
						<transition-group  name="list" tag="tbody">
							<tr v-for="li in postulations" :key="li.id" @click="selectPostulation(li)" :class="{'is-active':li.id == postulation_selected.id}">
								<td>
									<i :class="['fas fa-dot-circle', li.postu_active ? 'has-text-success':'has-text-danger']"></i>&nbsp;
									{{setEventName(li.event)}}
								</td>
								<td>
									Registro: {{getPostulationDate(li.postu_date_from)}}<br>
									Jurado: {{getPostulationDate(li.vote_date_from)}} <br>
									Votacion: {{getPostulationDate(li.public_date_from)}}
								</td>
								<td>
									Seleccionados: {{li.postu_vote_quant}}<br>
									Ganadores: {{li.postu_winners}}
								</td>
								<td>
									<span v-if="li.postu_phase == 1" class="has-text-success">Registro</span>
									<span v-else-if="li.postu_phase == 2" class="has-text-warning">Jurado</span>
									<span v-else-if="li.postu_phase == 3" class="has-text-link">Votacion</span>
								</td>
							</tr>
						</transition-group>
					</table>
				</div>
			</div>
		</div>
		<!-- New Postulation Modal -->
		<FormModal2 v-if="modals.newPostulation"
		:show="modals.newPostulation"
		:formType="4"
		:charging="loading"
		:title="'Nuevo evento de postulacion'"
		@save-form="newPostulation"
		@modal-close="modals.newPostulation = false" />
		<!-- Update Postulation Modal -->
		<FormModal2 v-if="modals.updatePostulation"
		:show="modals.updatePostulation"
		:formType="4"
		:data="postulation_selected"
		:charging="loading"
		:title="'Actualizar evento de postulacion'"
		@save-form="updatePostulation"
		@modal-close="modals.updatePostulation = false" />

		<!-- Delete Confirm Modal -->
		<ConfirmModal 
			:show="modals.confirm"
			v-if="modals.confirm"
			:message="'Esta seguro de eliminar esta postulacion?'"
			@modal-close="modals.confirm = false"
			@modal-submit="deletePostulation(postulation_selected.id)" />
	</div>
</template>

<script>
	import FormModal2 from '@/components/modal/form2'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'AdminPostulations',
		props: ['token','api'],
		components: {
			FormModal2,
			ConfirmModal
		},
		data() {
			return {
				loading: false,
				buttons: false,
				postulations: [],
				postulation_selected: {},
				modals: {
					newPostulation: false,
					updatePostulation: false,
					updateLyric: false,
					confirm: false,
					media: false,
				}
			}
		},
		created() {
			this.setPostulations()
		},
		computed:{
			events() {
				return this.$store.getters.GetEvent
			}
		},
		methods: {
			newPostulation(data) {
				this.loading = true
				const formData = this.setFormData(data)
				this.$http
					.post(this.api+'/postulations/postulations/', formData, this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'El evento de postulacion ha sido creado.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.loading = false
							this.modals.newPostulation = false
							this.postulations.push(response.data)
							})
					})
			},
			setPostulations() {
				this.$http
					.get(this.api+'/postulations/postulations/',this.token)
					.then(response => {
						this.postulations = response.data
					})
			},
			selectPostulation(item) {
				this.postulation_selected = item
				this.buttons = true
			},
			updatePostulation(data) {
				this.loading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+'/postulations/postulations/'+data.id+'/', formData, this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "Correcto!",
							text: 'Se ha actualizado el evento de postulacion correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.modals.updatePostulation = false
							this.setPostulationItem(response.data)
							this.postulation_selected = response.data
							this.loading = false
							this.$store.commit('loader', 'n')
							})
					})
					.catch(error => {
						var message = error.response.data
						this.$swal({
							type: "warning",
							title: "Denegado",
							text: message,
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.loading = false
							this.$store.commit('loader', 'n')
							})
					})
			},
			setEventName(val){
				return this.events.find(x => x.id === val).name
			},
			changeStatus(item) {
				this.$store.commit('loader', 'y')
				var val = { 
					postu_active: !item.postu_active, 
					id:item.id 
				}
				this.updatePostulation(val)
			},
			setPhase(num) {
				this.$store.commit('loader', 'y')
				var val = { 
					postu_phase: num, 
					id: this.postulation_selected.id
				}
				this.updatePostulation(val)
			},
			getPostulationDate(xdate) {
				return this.customFormatter(xdate).toString().substr(0, 10)
			},
			customFormatter(date) {
				var moment = require('moment');
				return moment(date).format('YYYY-MM-DDThh:mm:ss');
			},
			setFormData(data) {
				const formData = new FormData()
				if("event" in data) {
					formData.append('event', data.event)
				}
				if("postu_date_from" in data) {
					formData.append('postu_date_from', this.customFormatter(data.postu_date_from))
				}
				if("postu_date_until" in data) {
					formData.append('postu_date_until', this.customFormatter(data.postu_date_until))
				}
				if("postu_vote_quant" in data) {
					formData.append('postu_vote_quant', data.postu_vote_quant)
				}
				if("postu_winners" in data) {
					formData.append('postu_winners', data.postu_winners)
				}
				if("public_date_from" in data) {
					formData.append('public_date_from', this.customFormatter(data.public_date_from))
				}
				if("public_date_until" in data) {
					formData.append('public_date_until', this.customFormatter(data.public_date_until))
				}
				if("vote_date_from" in data) {
					formData.append('vote_date_from', this.customFormatter(data.vote_date_from))
				}
				if("vote_date_until" in data) {
					formData.append('vote_date_until', this.customFormatter(data.vote_date_until))
				}
				if("postu_active" in data) {
					formData.append('postu_active', data.postu_active)
				}
				if("postu_phase" in data) {
					formData.append('postu_phase', data.postu_phase)
				}
				return formData
			},
			setPostulationItem(newItem){
				for (var x in this.postulations){
					if( this.postulations[x].id === newItem.id){
						this.postulations[x] = newItem
						break
					}
				}		
			},
			deletePostulation(p_id) {
				this.$store.commit('loader', 'y')
				this.$http
					.delete(this.api+'/postulations/postulations/'+p_id+'/', this.token)
					.then(() => {
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'El evento de postulacion ha sido correctamente eliminado.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
								this.$store.commit('loader', 'n')
								this.modals.confirm = false
								for (var x in this.postulations){
									if( this.postulations[x].id === p_id){
										this.postulations.splice(x, 1)
										break
									}
								}	
							})	
					})
			}
		}
	}
</script>

<style scoped>
	.table tbody td { cursor: pointer; }
	.table tbody tr { transition: box-shadow .6s, background .6s; }
	.table tbody tr.is-active { box-shadow: 0 0 5px black; background: whitesmoke; }

</style>