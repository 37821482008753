<template>
	<div>
		<div class="field">
			<label class="label">Título</label>
			<div class="control">
				<input 
					class="input" 
					type="text" 
					placeholder="Título de referencia" 
					name="title" 
					v-model="mform.title">
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormcCapsulaBackground',
		props: ['formdata'],
		data() {
			return {
				mform: {
					title: "",
					src: '',
					capsula: undefined
				},
				//tag:''
			}
		},
		created() {
			this.emitForm()
			this.setInfo()
			this.mform.capsula = this.capsulas.id
			
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		computed:{
			capsulas(){
				return this.$store.state.capsula
			},
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},

			setInfo() {
				
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if (typeof this.formdata.src != 'undefined') {
						this.mform.src = this.formdata.src
					}
					if (typeof this.formdata.capsula != 'undefined'){
						this.mform.capsula = this.formdata.capsula
					}
				}		
			}
		}
	}
</script>