<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':modalShow}">
			<div class="modal-background" @click="charging ? loadMessage() : $emit('modalClose')"></div>
				<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">{{mTitle}}</p>
					<button 
					class="delete" 
					aria-label="close"
					@click="$emit('modalClose')"
					:disabled="charging"></button>
				</header>
				<section class="modal-card-body">
					<!-- CROPPER MODAL -->
					<!-- <VueCropper
					ref="cropper"
					:viewMode="2"
					:src="imgSrc"
					alt="Source Image"
					v-if="modalShow && step == 1" /> -->

					<!-- FORMS -->
					<FormFlyer 
					@settingForm="getFormInfo"
					v-if="modalShow && step == 1 && mForm == 1"/>
					<FormArtist 
					@settingForm="getFormInfo"
					v-else-if="modalShow && step == 1 && mForm == 2"/>
					<FormFlyerDetail 
					@settingForm="getFormInfo"
					v-else-if="modalShow && step == 1 && mForm == 3"/>
					<FormContent 
					@settingForm="getFormInfo"
					v-else-if="modalShow && step == 1 && mForm == 4"/>
					<FormDescription 
					@settingForm="getFormInfo"
					v-else-if="modalShow && step == 1 && mForm == 5"/>

				</section>
				<footer class="modal-card-foot has-text-right">
					<div class="buttons h-fullwidth is-right">
						<button @click="$emit('modalClose')" class="button" :disabled="charging">Cancelar</button>
						<button 
						@click="send()" 
						:class="{'button is-info':true, 'is-loading': charging}">Guardar</button>
					</div>
					<!-- <button 
					@click="crop()" 
					:class="{'button is-info':true}"
					v-if="step == 1">Siguiente</button> -->
				</footer>
			</div>
		</div>
	</transition>
</template>

<script>
	// import VueCropper from 'vue-cropperjs' //CROPPER
	// import 'cropperjs/dist/cropper.css';
	import FormFlyer from '@/components/admin/forms/formFlyer'
	import FormFlyerDetail from '@/components/admin/forms/formFlyerDetail'
	import FormArtist from '@/components/admin/forms/formArtist'
	import FormContent from '@/components/admin/forms/formContent'
	import FormDescription from '@/components/admin/forms/formDescription'

	export default {
		name: 'cropperModal',
		props: ['modalShow', 'imgSrc', 'cropImage', 'charging', 'mTitle', 'mForm'],
		data() {
			return {
				step: 1,
				pictureCropped: '',
				pictureInfo: {}
			}
		},
		components: {
			// VueCropper,
			FormFlyer,
			FormFlyerDetail,
			FormArtist,
			FormContent,
			FormDescription
		},
		created() {
			this.pictureCropped = this.imgSrc
		},
		methods: {
			// crop() {
			// 	var cropImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
			// 	var dataURLtoBlob = require('blueimp-canvas-to-blob');
			// 	var blob = dataURLtoBlob(cropImage);
			// 	var pic = new File([blob], "uploaded_pic.jpg", { type: "image/jpeg", lastModified: Date.now() })
			// 	this.pictureCropped = pic
			// 	this.step = 2
			// },
			getFormInfo(info) {
				this.pictureInfo = info
			},
			send() {
				this.$validator.validateAll().then(result => {
					if (result) {
						this.$emit('imageCropped', { pic: this.pictureCropped, data: this.pictureInfo })
					}
				})
			},
			loadMessage() {
				alert('Cargando... Porfavor espere.')
			}
		}
	}
</script>
