<template>
	<div class="field">
		<label class="label">Yotube video ID</label>
		<div class="control">
			<input class="input" type="text" name="youtube" placeholder="Song video ID" v-model="formData.youtube" v-validate="'required'">
		</div>
		<p class="help h-text-style-reset"><b>Example:</b> https://www.youtube.com/watch?v=<b class="h-textcolor-gold">GV3z8eNLMbs</b></p>
		<transition name="fade">
			<p class="help is-danger" v-show="errors.has('youtube')">{{ errors.first('youtube') }}</p>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'FormMedia',
		inject: ['$validator'],
		props: ['data'],
		data() {
			return {
				formData: {
					youtube: '',
				}
			}
		},
		created() {
			if (this.data != undefined) {
				this.formData = this.data
			}
		},
		methods: {
			getFormData() {
				return this.formData
			}
		}
	}
</script>