<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':modalShow}">
			<div class="modal-background" @click="loading ? loadMessage() : $emit('modalClose')"></div>
				<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">{{mTitle}}</p>
					<button 
					class="delete" 
					aria-label="close"
					@click="$emit('modalClose')"
					:disabled="loading"></button>
				</header>
				<section class="modal-card-body scroll-3x">
					<div class="columns is-vcentered is-mobile">
						<div class="column">
							<figure class="only-cropper image">
								<img :src="prevImg" alt="Artist Prev Image Source">
							</figure>							
						</div>
						<div class="column is-1 is-2-mobile">
							<span class="icon fa-3x">
								<i class="icon-adelantar"></i>
							</span>
						</div>					
						<div class="column">
							<figure class="only-cropper image">
								<img :src="imgSrc" alt="Artist Image Source">
							</figure>							
						</div>
					</div>
					<!-- CROPPER MODAL -->
					<!-- <VueCropper
					ref="cropper"
					:viewMode="2"
					:src="imgSrc"
					alt="Source Image"
					v-if="modalShow" /> -->
				</section>
				<footer class="modal-card-foot">
					<div class="buttons is-right h-fullwidth">
						<button @click="$emit('modalClose')" class="button" :disabled="loading">Cancelar</button>
						<button 
							@click="crop()" 
							:class="{'button is-info':true, 'is-loading': loading}">
							Guardar
						</button>
					</div>
				</footer>
			</div>
		</div>
	</transition>
</template>

<script>
	// import VueCropper from 'vue-cropperjs'
	export default {
		name: 'onlyCropperModal',
		props: {
			loading: {
				type: Boolean,
				default: false
			}, 
			imgSrc: {
				type: String,
				default: ''
			},
			mTitle: {
				type: String,
				default: ''				
			}, 
			modalShow: {
				type: Boolean,
				default: false
			},
			prevImg: {
				type: String,
				default: ''
			}
		},
		// components: {
		// 	VueCropper
		// },
		methods: {
			crop() {
				// var cropImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
				// var dataURLtoBlob = require('blueimp-canvas-to-blob');
				// var blob = dataURLtoBlob(cropImage);
				// var pic = new File([blob], "uploaded_pic.jpg", { type: "image/jpeg", lastModified: Date.now() })
				this.$emit('imageCropped', this.imgSrc)
			},
			loadMessage() {
				alert('Cargando... Porfavor espere.')
			}
		}
	}
</script>

<style>
	.only-cropper.image img {
		max-width: 100%;
		width: initial !important;
		margin: 0 auto;
	}
</style>