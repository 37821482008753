<template>
	<div :class="['box', item.is_active ? 'has-background-'+color : 'box has-background-black-bis']">
		<h4 class="title is-4 has-text-white is-marginless">{{item.name}}</h4>
		<hr>

		<div class="field has-addons">
			<p class="control">
				<a v-tooltip.top="'La sección de '+item.name+(item.is_active ? ' se encuentra':' NO esta')+' protegida por llave.'" 
				class="button is-rounded is-white" 
				@click="changeStatus()">
					<i :class="['far', item.is_active ? 'fa-eye':'fa-eye-slash']"></i>
				</a>
			</p>
			<p class="control">
			<input 
			class="input is-rounded" 
			type="text"
			placeholder="Escriba una llave" 
			:name="item.rel"
			:value="item.key"
			:ref="item.rel" />
			</p>
			<p class="control">
				<a v-tooltip.top="'Registrar llave'" 
				:class="{'button is-success is-rounded':true, 'is-loading':loadingKey}" 
				@click="changeKey()">
					<i class="fas fa-check-circle has-text-white"></i>
				</a>
			</p>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'KeyBox',
		props: ['item', 'color'],
		data() {
			return {
				loadingKey: false,
				loadingActive: false,
			}
		},
		methods: {
			changeStatus() {
				this.$emit('new-status', this.item)
			},
			changeKey() {
				this.loadingKey = true
				var xKey = this.item
				xKey['key'] = this.$refs[this.item.rel].value
				this.$emit('new-key',xKey)
				this.loadingKey = false
			}
		}
	}
</script>