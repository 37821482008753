<template>
	<div class="is-flex is-justify-content-flex-end">
		
		<figure 
			v-for="image in artistPlaylist"
			:key="image.contentDetails.videoId">
			<a @click="updateVideo(image)">
			<img
				class="mx-1"
				:src="image.snippet.thumbnails.default.url"
				:alt="image.snippet.description" />
			</a>
		</figure>
		
	</div>
</template>
<script>
	export default {
		name: "YoutubeThumbnails",
		props: ['artistPlaylist'],
		data(){
			return {

			}
		},
		methods:{
			updateVideo(video){
				return this.$emit('update-video', video)
			}
		}
	}
</script>
<style scoped>
	img {
		width: 70px;
	}
	
</style>