<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background" @click="$emit('close-modal')"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">{{title}}</p>
					<button class="delete" aria-label="close" @click="$emit('close-modal')"></button>
				</header>
				<section class="modal-card-body">
				<!-- Content ... -->

				<LyricInfoForm v-show="step == 1" ref="infoComponent" />
				<LyricForm v-show="step == 2" ref="lyricComponent" />

				<!-- /Content ... -->
				</section>
				<footer class="modal-card-foot">
					<div class="buttons has-addons is-right h-fullwidth">
						<button class="button" @click="$emit('close-modal')">Cancel</button>
						<button class="button is-link" v-if="step > 1" @click="step-=1">Previous</button>
						<button :class="{'button is-success':true, 'is-loading':loading}" type="submit" v-if="step==2" @click="setProgress()">Save</button>
						<button class="button is-link" type="button" v-if="step==1" @click="setProgress()">Next</button>
					</div>
				</footer>
			</div>
		</div>
	</transition>
</template>

<script>
	import LyricInfoForm from '@/components/admin/forms/formLyricInfo'
	import LyricForm from '@/components/admin/forms/formLyric'
	export default {
		name: 'LyricModal',
		props: ['show','title', 'loading'],
		components: {
			LyricInfoForm,
			LyricForm
		},
		data() {
			return {
				step: 1
			}
		},
		methods: {
			setProgress() {
				if (this.step == 1) {
					this.$validator.validateAll().then(result => {
						if (result) {
							this.step+=1
						}
					})
				} else if (this.step == 2) {
					var data = this.$refs.infoComponent.getFormData()
					data['lyric'] = this.$refs.lyricComponent.getLyric()
					this.$emit('save-form', data)
				}
			},
		}
	}
</script>