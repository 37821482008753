<template>
	<div class="tile is-parent is-vertical">
		<div class="tile is-vertical">
			<div class="tile is-child">
				<h2 class="title is-4">{{title}}</h2>
			</div>
			<div class="tile is-child is-vertical">
			<p class="has-text-justified"><b>Info:</b> en esta sección se muestran a los jurados activos y su registro en las votaciones.</p>
			<br>
				<div class="columns is-centered">
					<div class="column">
						<b>Postulados seleccionados:</b><br> ({{postuParams.selected}}/{{postuParams.limit}}) <br>
					</div>
					<div class="column">
						<p><b>Cantidad de jueces: </b> <br>
							{{postuParams.judges}}
						</p>
					</div>
				</div>
			</div>
			<hr>
			<div class="tile is-parent is-vertical" v-if="judges.length > 0">
				<div class="tile box is-child has-background-grey-lighter is-vertical" v-for="j in judges" :key="j.id">
					<div class="columns is-marginless is-mobile">
						<div class="column">
							<h3 class="title is-6">{{j.name}} <br> <small>{{j.username}}</small></h3>
						</div>
						<div class="column">
							<p class="is-size-7"><b>Cantidad de votos:</b> <br> {{j.votes}}</p>
						</div>
					</div>
					<p>
						<b class="is-size-7">Ha votado por:</b> <br>
						<span class="tag is-link h-margin-rl" v-for="(i, n) in j.postulates" :key="n">{{i}}</span>
					</p>
				</div>
			</div>
			<div class="tile is-vertical" v-else>
				<div class="container">
					<div class="box">
						<h4 class="title is-5 has-text-grey">Los jueces aun no han registrado algun voto.</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AdminJudgeVotes',
		props: ['token','api'],
		data() {
			return {
				judges: [],
				title: 'Administración de los Votos del Jurado'
			}
		},
		created() {
			this.setJudgeVotes()
		},
		computed: {
			postuParams () {
				return this.$store.getters.GetPostuParams
			}
		},
		methods: {
			setJudgeVotes() {
				this.$http
					.get(this.api+'/postulations/judge-votes/', this.token)
					.then(response => {
						var result = response.data
						this.judges = result.judges
						this.$store.commit('setPostuParams', {
							limit:result.limit,
							judges:result.count, 
							selected: result.selected,
							phase: result.phase
						})
					})
			}
		}
	}
</script>