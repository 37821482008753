const state = {
    api: 'https://api.habeatat.com',//'http://127.0.0.1:8000'
    count: 0,
    dashMenu: 0,
    container: true,
    sleep:function(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    token_access:function(num){
        var config  = {
            headers: {
             'Accept'   : 'application/json, text/plain, */*',
            }
        };
        if( parseInt(num) > 0 ){
            let token = sessionStorage.getItem('token');
            config.headers['Authorization'] = 'token ' + token;

            if( parseInt(num) > 1 ){
            config.headers['content-type'] = 'multipart/form-data';
            }

        }
        return config
    },
    habeatatKey: 'h0-n*4o=t%^-rthskeo1^ds50mqlcp-epvm^_+yu2gueb$aya-&',
    googleAPIKey: 'AIzaSyDgTPpKtgxJ3RuVJvQoDlIsQc4ajd8WWMM',
    reCaptcha: {
        key: '6LetkosUAAAAAEYF4xBFnL7ooC2GVh2NodRZzib_',
        secret: '6LetkosUAAAAAAu4_en1eFIRUQ5eOpsr10F2L0yH'
    },
    pagesKeys: {
        repro: '#SUENABIEN'
    },
    banners: [],
    calendar: {},
    social: {},
    songs: [],
    artist: [],
    artistLyrics: {},
    event: [],
    content: [],
    capsula: {},
    countries: {},
    postulates: [],
    postuParams: {},
    pageInfo: {
      artist: {
        text: 'IMPULSAMOS TALENTOS MUSICALES CREANDO ESPACIOS APTOS PARA QUE SE DESARROLLEN EN PROPORCIÓN A SU MÁXIMO POTENCIAL'
      },
      event: {
        text: 'SALIMOS DE LO TRADICIONAL, ENTRETENEMOS, EDUCAMOS Y CONECTAMOS TALENTOS CON EL PÚBLICO'
      },
      content: {
        text: 'NUESTRAS PRODUCCIONES NO BUSCAN PARECERSE, SON PROPUESTAS ÚNICAS QUE TRASCIENDEN EN EL TIEMPO'
      }
    },
    internalPage: {
      into:false,
      page:'Home',
      pageName: 'index',
    },
    loading: false,
    modal: {
      pic: {status:false, info:{}},
      des: {status:false, info:{}},
    },
    isIn: !!sessionStorage.getItem('isIn'),
    isReproKey: !!sessionStorage.getItem('isReproKey'),
    alreadyVote: !!localStorage.getItem('postulationPublicVote'),
    //STORE USER
    isLoggedIn: !!sessionStorage.getItem('token'),
    isAcceptCookies: !!localStorage.getItem('isAcceptCookies'),
    AuthUserType: parseInt(sessionStorage.getItem('userType')),
    AuthId: parseInt(sessionStorage.getItem('userId')),
    AuthUserName: sessionStorage.getItem('userName'),
    AuthUserToken: sessionStorage.getItem('token')
}

export default state
