<template>
	<div :class="['notification', n_style]">
		<button class="delete" @click="$emit('close')"></button>
		<div class="columns">
			<div class="column is-1">
				<img :src="'/img/logo'+logo+'-min.png'" class="n-logo is-hidden-mobile">
			</div>
			<div class="column">
				<p class="has-text-white">{{ n_text }} <span v-if="n_enlace != undefined" class="has-text-white">
					<a :href="n_enlace.url" v-if="n_enlace.external" target="_blank">{{n_enlace.text}}</a>
					<router-link :to="{ path: n_enlace.url}" v-else>{{n_enlace.text}}</router-link>
				</span>
				</p>
			</div>
			<div class="column is-2">
				<button class="button h-back-gold h-textcolor-darkblue" 
				v-if="n_button != '' && typeof n_button != 'undefined'"
				@click="$emit('close')">
					{{n_button}}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'notification',
		props: ['n_text', 'n_style', 'n_button', 'logo', 'n_enlace']
	}
</script>

<style scoped>
	.notification {
		width: 95%;
		position: fixed;
		bottom: 1%;
		z-index: 99999 !important;
		left: 2.5%;
		right: 2.5%;
	}
	.n-logo {
		width:100%;
		vertical-align: middle;
	}
	.button {
		border:inherit !important;
		
	}
	.column {
		height: 100%;
		margin: auto;
	}
</style>