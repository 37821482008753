<template>
<transition name="fade" >
<div class="main-content" v-if="loaded" style="overflow:hidden;">

		<div class="container">
			<!-- NAVBAR -->
			<h-menu v-if="menuShow"/>
		</div>

		<div :class="[isContainer ? '':'container-fluid', 'mt-6']">
			<!-- MAIN CONTENT -->
			<transition name="fade" mode="out-in">
				<router-view :key="$route.fullPath" @hideMenu="menuShow=false"/>
			</transition>
			<!-- /MAIN CONTENT -->

			<!-- COOKIES POPUP -->
			<transition name="fadeUp">
				<Notif 
					v-if="notif == false"
					:n_text="nText"
					:n_style="'h-back-darkblue'"
					:n_button="'Aceptar'"
					:logo="8"
					:n_enlace="{external:false, url:'/politica-de-privacidad',text:'ver política de privacidad y cookies'}"
					@close="cookies()" />
			</transition>
			<!-- /COOKIES POPUP -->
		</div>
		<div class="my-5 is-hidden-tablet"></div>
			<!-- FOOTER -->
			<h-footer/>

		<!-- PICTURE MODAL -->
		<pic-modal :show="modal.pic.status"
			:title="modal.pic.info.title"
			:picture="modal.pic.info.picture"
			:link="modal?.pic?.info?.link"
			@close-modal="$store.commit('changeModalStatus', {modal:'pic','status':false})" />
		<!-- DESCRIPTION MODAL -->
		<des-modal :show="modal.des.status"
			:title="modal.des.info.title"
			:description="modal.des.info.description"
			@close-modal="$store.commit('changeModalStatus', {modal:'des','status':false})" />
</div>
</transition>
</template>

<script>
import Menu 	from '@/components/menu'
import Footer 	from '@/components/footer'
import Notif 	from '@/components/general/notification'
// MODALS
import DesModal         from '@/components/modal/description.vue'
import PicModal         from '@/components/modal/showPicture.vue'
	export default {
		name: 'Habeatat',
		data(){
			return {
				loaded: false,
				menuShow: true,
				notif: this.$store.state.isAcceptCookies,
				nText: 'Este sitio web utiliza cookies para que usted tenga la mejor experiencia de usuario. Si continúa navegando está dando su consentimiento para la aceptación de las mencionadas cookies y la aceptación de nuestra política de cookies.'
			}
		},
		components: {
			'h-menu': Menu,
			'h-footer': Footer,
			Notif,
			PicModal,
			DesModal
		},
		computed: {
			isContainer() {
				return this.$store.getters.GetContainer
			},
			habeatatKey() {
				return this.$store.getters.GetHabeatatKey
			},
			api() {
				return this.$store.getters.GetApiPath
			},
			modal() {
				return this.$store.getters.GetModalStatus
			}
		},
		async mounted() {
			this.$store.commit('loader', 'y')
			const formData = new FormData();
			formData.append('h_key', this.habeatatKey);
			let {data} = await this.$http.post(this.api+'/h/web-base-info/', formData)
			await this.$store.dispatch('restFullSet', data)
			this.loaded = true
			this.$store.commit('loader', 'n')
		},
		methods: {
			cookies() {
				this.$store.dispatch('cookies').then(() => {
					this.notif = true
				})
			}
		}
	}
</script>