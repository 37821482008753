<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background" @click="$emit('modal-close')"></div>
			<div class="modal-content">
				<div class="box">
					<div class="tile is-vertical">
						<p class="is-size-4 has-text-centered">{{message}}</p>
						<br>
						<div class="tile h-margin-center">
							<button type="button" class="button is-warning h-wbox-200 is-uppercase" @click="$emit('select-image')">
								<i class="fas fa-image has-text-white">&nbsp;{{image_desc}}</i>
							</button>
							<span class="is-hidden-touch">&nbsp;</span>
							<div class="is-hidden-tablet h-separator-t1"></div>
							<button type="button" class="button is-primary h-wbox-200 is-uppercase" @click="$emit('select-text')">
								<i class="fas fa-align-justify has-text-white">&nbsp;{{text_desc}}</i>
							</button>	
						</div>
						<div class="h-separator-t3"></div>
						<div class="columns">
							<div class="column is-5">
								<img :src="detail.picture" style="width: 100%;">
							</div>
							<div class="column">
								<pre class="has-text-justified is-size-7 h-ws-preline is-paddingless has-background-white">
									{{detail.description}}
								</pre>
							</div>
						</div>	
					</div>

				</div>
			</div>
		</div>	
	</transition>
</template>

<script>
	export default {
		name: 'confirmModal',
		props: ['show', 'message','image_desc','text_desc', 'detail'],
	}
</script>