<template>
	<aside class="menu has-text-left">
		<p class="menu-label has-text-white">
			<i class="far fa-caret-square-down has-text-white"></i> Postulaciones
		</p>
		<ul class="menu-list">
			<li>
				<a 
				@click="menuSelection(62)"
				:class="{'is-active':(selected == 62), 'has-text-white':true}">
					<i class="fas fa-grip-horizontal has-text-white"></i> Postulados
				</a>
			</li>
			<li>
				<a 
				@click="menuSelection(67)"
				:class="{'is-active':(selected == 67), 'has-text-white':true}">
					<i class="fas fa-greater-than-equal has-text-white"></i> Pre-Seleccionados
				</a>
			</li>
			<li>
				<a 
				@click="menuSelection(68)"
				:class="{'is-active':(selected == 68), 'has-text-white':true}">
					<i class="fas fa-greater-than has-text-white"></i> Seleccionados
				</a>
			</li>
		</ul>
		<p class="menu-label logout has-text-white">
			<a @click="logout()">Desconectar <i :class="['fas', logoutButton ? 'fa-compact-disc fa-pulse':'fa-sign-out-alt', 'has-text-white']"></i></a>
		</p>
	</aside>
</template>

<script>
	export default {
		name: 'JudgeMenu',
		props:['selected'],
		data() {
			return {
				logoutButton: false
			}
		},
		computed: {
			api() {
				return this.$store.getters.GetApiPath
			},
			token() {
				return this.$store.getters.GetAxiosConfig(2)
			},
		},
		methods: {
			menuSelection(selected) {
				this.$store.commit('changeDashMenu', selected)
			},
			logout() {
				this.logoutButton=true
				this.$http
				.get(this.api+'/logout/', this.token)
				.then(() => {
					this.$store.dispatch('logout').then(() => {
						window.location.replace('/login')
					})
				})
			},
		}

	}	
</script>

<style scoped>
	.menu-list a:hover {
		background: inherit;
	}
	.menu-list a.is-active {
		background: hsl(217, 71%, 53%);
	}
	.logout {
		font-weight: bold;
	}
</style>