<template>
	<div class="tile h-border-custom h-overflow-hide has-background-custom">
		<iframe 
			:src="setPlay" 
			:width="isMobile ? '95%':'100%'" 
			height="80" 
			frameborder="0" 
			allowtransparency="true" 
			allow="encrypted-media"></iframe>
	</div>
</template>

<script>
	export default {
		name: 'spotifyPlayer',
		props: ['playList'],
		computed: {
			setPlay() {
				const spotify = 'https://open.spotify.com/embed/'
				return spotify+this.playList
			},
			isMobile() {
				return this.$store.getters.GetMobileBrowser
			}
		}
	}
</script>

<style scoped>
	iframe {
		height: 80px;
	}
	.h-border-custom {
		border-radius: 10px;
	}
	.has-background-custom {
		background: white;
	}
@media screen and (max-width: 999px){
	.h-border-custom {
		border-radius: 0;
	}
	.has-background-custom {
		background: inherit;
	}
}
</style>