<template>
	<div>
		<h2 class="title is-4 has-text-left">Features</h2>
		<div class="field">
			<label class="label">Title</label>
			<div class="control">
			<input class="input" type="text" name="title" placeholder="Song title" v-model="formData.title" v-validate="'required'">
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('title')">{{ errors.first('title') }}</p>
			</transition>
		</div>

		<div class="field">
			<label class="label">Artist</label>
			<div class="control has-icons-left">
				<div class="select h-fullwidth">
					<select v-model="formData.artist" class="h-fullwidth" name="artist" v-validate="'required'">
						<option v-for="art in artists" :key="art.id" :value="art.id">{{art.name}}</option>
					</select>
					<span class="icon is-small is-left">
						<i class="fas fa-users"></i>
					</span>
				</div>
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('artist')">{{ errors.first('artist') }}</p>
			</transition>
		</div>

		<div class="field">
			<label class="label">Featuring</label>
			<div class="control">
			<input class="input" type="text" name="featuring" placeholder="Song artist featuring" v-model="formData.featuring">
			</div>
		</div>

		<div class="field">
			<label class="label">Album</label>
			<div class="control">
			<input class="input" type="text" name="album" placeholder="Song album title" v-model="formData.album">
			</div>
		</div>

		<div class="field">
			<label class="label">Year</label>
			<div class="control">
			<input class="input" type="text" name="year" placeholder="Song year" v-model="formData.year" v-validate="'required|numeric'">
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('year')">{{ errors.first('year') }}</p>
			</transition>
		</div>

		<div class="field">
			<label class="label">Comments</label>
			<div class="control">
				<textarea class="textarea" placeholder="Comments" v-model="formData.comments" name="comments"></textarea>
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('comments')">{{ errors.first('comments') }}</p>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormLyricInfo',
		inject: ['$validator'],
		props: ['data'],
		data() {
			return {
				formData: {
					comments: '',
					artist: null,
					title: '',
					album: '',
					year: null,
					featuring: ''
				}
			}
		},
		created() {
			if (this.data != undefined) {
				this.formData = this.data
			}
		},
		computed: {
			artists() {
				return this.$store.getters.GetArtists
			}
		},
		methods: {
			getFormData(){
				return this.formData
			}
		}
	}
</script>