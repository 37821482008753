const getters = {
  GetHabeatatKey(state) {
    return state.habeatatKey
  },  
  GetIfAlreadyIn(state) {
    return state.isIn
  },
  GetContainer(state) {
    return state.container
  },
  GetAxiosConfig: state => num =>  {
    return state.token_access(num)
  },
  isLoggedIn: state => {
    return state.isLoggedIn
   },
  GetUserType (state) {
    return state.AuthUserType
  },
  GetUserId (state) {
    return state.AuthId
  },
  GetUserName (state) {
    return state.AuthUserName
  },
  GetUserToken (state) {
    return state.AuthUserToken
  },
  GetApiPath (state) {
    return state.api
  },
  GetModalStatus (state) {
    return state.modal
  },
  GetDashMenu (state) {
    return state.dashMenu
  },
  GetBanners (state) {
    return state.banners
  },  
  GetGoogleAPIKey (state) {
    return state.googleAPIKey
  },
  GetCalendarData (state) {
    return state.calendar
  },
  GetCountry (state) {
    return state.countries
  },
  GetPostulates (state) {
    return state.postulates
  },
  GetPostuParams (state) {
    return state.postuParams
  },
  isLoading (state) {
    return state.loading
  },
  GetDateTimeNow () {
    var d = new Date,
        dformat = [ d.getFullYear(),
                    d.getMonth()+1,
                    d.getDate(),
                  ].join('-')+'T'+
                  [ d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':')+'Z';
    return dformat
  },
  GetArtists (state) {
    return state.artist
  },
  GetEvent (state) {
    return state.event
  },
  GetContent (state) {
    return state.content
  },
  GetCapsula (state) {
    return state.capsula
  },
  GetSongs (state) {
    return state.songs
  },
  GetMobileBrowser () {
    var check = false;
    (function(a){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  },
  GetCaptcha (state) {
    return state.reCaptcha
  },
  GetIfAcceptCookies(state) {
    return state.isAcceptCookies
  },
  GetIfReproKey(state) {
    return state.isReproKey
  },
  GetHabeatatSocial(state) {
    return state.social
  },
  GetPageskeys(state) {
    return state.pagesKeys
  },
  GetAlreadyVote(state) {
    return state.alreadyVote
  },
  GetSecondsToMinutes(){
    return function(val) {
      var minutes = Math.floor( val / 60 );
      var seconds = val % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes; 
      seconds = seconds < 10 ? '0' + seconds : seconds;
       
      var result = minutes + ":" + Math.trunc(seconds);
      return result
    }
  },
  scrollMeTo() {
    return function(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);      
    }
  },
  GetArtistsKeyWords() {
    return function(name) {
        switch (name) {
          case 'lianamalva':
            return {
              title: 'Liana Malva la mejor cantante venezolana de música pop y mixta del '+new Date().getFullYear(),
              subtitles: [
                'Canción de Liana Malva: La tortuga.',
                'Biografía de Liana Malva, cantante venezolana.',
                'Proyecto Gotas de Liana Malva.',
                'Canción de Liana Malva: Algo cambio.',
                'Colaboración de Liana malva junto a Canserbero.',
                'Canción de Liana Malva: Te quiero.',
                'Canción de Liana Malva: El pauji.',
                'Música venezolana del momento.',
                'Música alternativa.',
                'Las raíces de Liana Malva.',
                'Toda la música de Liana Malva.'
              ],
              relevant: [
                'Rap y hip hop venezolano.',
                'Música mixta del '+new Date().getFullYear(),
                'La mejor cantante de pop de Venezuela',
                'Cantante, compositora, multi-instrumentista',
                'La mejor música venezolana del '+new Date().getFullYear(),
                'Ambiente y el agua.'
              ]
            }
          case 'letra':
            return {
              title: 'Letra rapero venezolano insuperable en el freestyle. '+new Date().getFullYear(),
              subtitles: [
                'Letra es un rapero venezolano reconocido.',
                'Letra en batallas de rap.',
                'Letra tiene increibles habilidades en el freestyle.',
                'Venezolano responde increiblemente en guerra de rap',
                'Biografía de Letra, rapero venezolano.',
                'Batalla de Letra vs dominic',
              ],
              relevant: [
                'Batalla de gallos',
                'Letra mc venezuela',
                'Letra en la final BDM',
                'Batalla de rap letra vs',
                'Los mejores raperos del '+new Date().getFullYear(),
                'Rap venezolano del '+new Date().getFullYear(),
              ]
            }
          case 'horus':
            return {
              title: 'Horus el mejor artista de hip hop y rap en Venezuela '+new Date().getFullYear(),
              subtitles: [
                'Horus music.',
                'Biografía de Horus, rapero venezolano.',
                'Canción de Horus: Fiel.',
                'Canción de Horus: Pobre rico.',
                'Canción de Horus: Magia.',
                'Rap de Horus y frases.',
                'Discografía de horus, rapero venezolano.',
                'Canción de horus: Hijos del tiempo.',
                'Horus de la tierra.',
                'Horus faro de vida',
              ],
              relevant: [
                'Rap venezolano del '+new Date().getFullYear(),
                'Hip hop en Venezuela '+new Date().getFullYear(),
                'El mejor rapero venezolano.',
                'Canciones que apelan a la conciencia.',
                'Música reflexiva.',
                'Rap con buena letra.',
                'Rap que llega al alma.',
                'Nuevo rap venezolano.',
                'Rap en Venezuela',
              ]
            }
          case 'solosoul':
            return {
              title: 'Solo Soul ingeniero de sonido y productor musical '+new Date().getFullYear(),
              subtitles: [
              'Solo Soul es un productor musical que desarrolla talentos en Habeatat.',
              'Solo Soul como ingeniero de sonido y director creativo venezolano.',
              'Solo Soul ha trabajado  en diferentes agrupaciones.'],
              relevant: []
            }
          case 'figu':
            return {
              title: 'Figu Dj, compositor y productor musical '+new Date().getFullYear(),
              subtitles: [
              'Figu: Dj Venezolano del '+new Date().getFullYear(),
              'Dj Figu como compositor y productor musical.',
              'Dj Figu un experto en el mundo del hip hop y la electrónica.'],
              relevant: []
            }
          default:
            return {
              title: 'Artista de Habeatat',
              subtitles: [],
              relevant: []
            }
        }
    }
  },
}

export default getters