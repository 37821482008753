<template>
	<transition name="fade">
		<div :class="{'modal': true, 'is-active': show}" v-if="show">
			<div class="modal-background"></div>
				<div class="modal-content has-text-centered">
						<div class="loader-box my-6">
							<div class="loader"></div>
						</div>
				</div>
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'SoloPicModal',
		props: ['show'],
	}
</script>