<template>
	<div>
		<div class="field">
			<label class="label">Nombre del sitio web</label>
			<div class="control">
				<input class="input" type="text" placeholder="Título de referencia" name="text" v-model="mform.text">
			</div>
		</div>
		<div class="field">
			<label class="label">Enlace del sitio web</label>
			<div class="control">
				<input 
					class="input" 
					type="text" 
					placeholder="https://www..." 
					name="url" 
					v-model="mform.url">
			</div>
		</div>
		<div class="field">
			<label class="label">Código del formulario para contratación</label>
			<div class="control is-expanded">
				<input 
					class="input" 
					type="text" 
					placeholder="ID del formulario" 
					name="hiring" 
					v-model="mform.hiring">
			</div>
			<p class="help h-text-style-reset"><b>Ejemplo:</b> https://docs.google.com/forms/d/e/<b class="h-textcolor-gold">1FAIpQLSdY9ljTC9n5r9P1olU_-xrG1prGtHudnyLF2BGa_Bu5KZNiwg</b>/viewform?embedded=true</p>
		</div>
		<div class="field">
			<label class="label">ID del Calendario de Google</label>
			<div class="control is-expanded">
				<input 
					class="input" 
					type="text" 
					placeholder="Codigo del calendario personalizado de google para compartir" 
					name="calendar" 
					v-model="mform.calendar">
			</div>
			<p class="help h-text-style-reset"><b>Ejemplo:</b>&nbsp;<b class="h-textcolor-gold is-lowercase">F0BOV713915D45MACMIE395GC4@GROUP.CALENDAR.GOOGLE.COM</b></p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormWeb',
		props: ['formdata'],
		data() {
			return {
				mform: {
					text: "",
					url: "",
					hiring: "",
					calendar: ""
				}
			}
		},
		created() {
			this.setInfo()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setInfo() {
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.text != 'undefined') {
						this.mform.text = this.formdata.text
					}
					if (typeof this.formdata.url != 'undefined') {
						this.mform.url = this.formdata.url
					}
					if (typeof this.formdata.hiring != 'undefined') {
						this.mform.hiring = this.formdata.hiring
					}
					if (typeof this.formdata.calendar != 'undefined') {
						this.mform.calendar = this.formdata.calendar
					}
				}
			}
		}
	}
</script>