<template>
		<div class="field">
			<label class="label">Descripcion detallada</label>
			<div class="control">
				<textarea 
					class="textarea" 
					name="description"
					id="description" 
					placeholder="Descripcion del evento" 
					v-model="mform.description"
					v-validate="'required'"></textarea>
			</div>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('description')">
						<i class="fas fa-asterisk"></i> {{ errors.first('description') }}
					</span>
				</transition>			
		</div>
</template>

<script>
	export default {
		name: 'FormDescription',
		props: ['formdata'],
		inject: ['$validator'],
		data() {
			return {
				mform: {
					description: ""
				}
			}
		},
		created() {
			this.setMForm()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setMForm()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setMForm() {
				if (typeof this.formdata != 'undefined') {
					if(typeof this.formdata.description != 'undefined') {
						this.mform.description = this.formdata.description
					}
				}
			}
		}
	}
</script>