<template>
	<div class="tile is-vertical">
		<div class="tile h-margin-center-lat">
			<button class="button is-uppercase" @click="modals.create=true">
				crear juez |&nbsp;<i class="fas fa-user-plus"></i>
			</button>
		</div>
		<hr>
		<div class="tile is-parent">
			<table class="table h-fullwidth">
				<thead class="thead">
					<tr class="tr">
						<th class="th">Usuario</th>
						<th class="th">Nombre</th>
						<th class="th">Email</th>
						<th class="th">Acciones</th>
					</tr>
				</thead>
				<transition-group  name="list" tag="tbody">
					<tr v-for="jd in judges" :key="jd.id" :class="{'tr':true, 'has-background-warning':jd.is_active}">
						<td><b>{{jd.username}}</b></td>
						<td>{{jd.first_name}} {{jd.last_name}}</td>
						<td>{{jd.email}}</td>
						<td>
							<!-- Editar -->
							<a 	title="Editar"
							v-tooltip.bottom="'Modificar la Información básica de: '+jd.username" 
								@click="selectedData=jd, modals.update=true">
								<i class="fas fa-edit"></i>
							</a> |
							<!-- Password -->
							<a 	title="Editar"
							v-tooltip.bottom="'Cambiar contraseña de: '+jd.username" 
								@click="selectedData=jd, modals.password=true">
								<i class="fas fa-key"></i>
							</a> |  
							<!-- Activar | Desactivar -->
							<a 	:title="jd.is_active ? 'Desactivar':'Activar'"
							v-tooltip.bottom="(jd.is_active ? 'Desactivar':'Activar')+' '+jd.username+'.'"
								@click="updateJudge({id:jd.id, is_active:!jd.is_active})">
								<i :class="['fas', jd.is_active ? 'fa-stop-circle':'fa-play-circle']"></i>
							</a> |
							<!-- Eliminar -->
							<a 	title="eliminar"
							v-tooltip.bottom="'Eliminar completamente a '+jd.username+' de Habeatat'"
								@click="modals.confirm=true, selectedData=jd">
								<i class="fas fa-trash"></i>	
							</a>
						</td>
					</tr>
				</transition-group>
			</table>
		</div>
		<FormModal 
			:show="modals.create"
			:formType="5"
			:charging="loading"
			:title="'Crear un nuevo usuario Juez'"
			@save-form="saveJudge"
			@modal-close="modals.create=false"
			v-if="modals.create" />
		<FormModal 
			:show="modals.update"
			:formType="6"
			:data="selectedData"
			:title="'Actualizar un usuario Juez'"
			:charging="loading"
			@save-form="updateJudge"
			@modal-close="modals.update=false"
			v-if="modals.update" />
		<FormModal 
			:show="modals.password"
			:formType="7"
			:data="selectedData"
			:title="'Cambiar contraseña de usuario'"
			:charging="loading"
			@save-form="updateJudge"
			@modal-close="modals.password=false"
			v-if="modals.password" />
		<ConfirmModal 
			:show="modals.confirm"
			v-if="modals.confirm"
			:message="confirmText"
			@modal-close="modals.confirm = false"
			@modal-submit="destroyJudge()" />
		<CustomMessage 
			v-if="modals.custom"
			:show="modals.custom"
			:type="customType"
			:list="customList"
			:message="customText"
			:title="customTitle"
			@modal-close="modals.custom=false, customList=[]"/>
	</div>
</template>

<script>
	import FormModal from '@/components/modal/form2'
	import ConfirmModal from '@/components/modal/confirm'
	import CustomMessage from '@/components/modal/customMessage'
	export default {
		name: 'AdminJudge',
		props: ['token', 'api'],
		data() {
			return {
				judges: [],
				modals: {
					create: false,
					update: false,
					password: false,
					confirm: false,
					custom: false,
				},
				selectedData: {},
				loading: false,
				customText: '',
				customList: [],
				customTitle: '',
				customType: 'success',
				confirmText: 'Esta seguro de eliminar a este Juez?',
			}
		},
		created() {
			this.getCalendars()
		},
		components: {
			FormModal,
			ConfirmModal,
			CustomMessage
		},
		methods: {
			getCalendars() {
				this.$http
					.get(this.api+'/postulations/register-judge/', this.token)
					.then(response => {
						this.judges = response.data
					})
			},
			setFormData(data) {
				const formData = new FormData()
				if("username" in data) {
					formData.append('username', data.username);
				}
				if("email" in data) {
					formData.append('email', data.email);
				}
				if("first_name" in data) {
					formData.append('first_name', data.first_name);
				}
				if("last_name" in data) {
					formData.append('last_name', data.last_name);
				}
				if("user_type" in data) {
					formData.append('user_type', data.user_type);
				}
				if("password" in data) {
					formData.append('password', data.password);
				}
				if("is_active" in data) {
					formData.append('is_active', data.is_active);
				}
				return formData
			},
			saveJudge(data) {
				this.loading = true
				data['username'] = (data.first_name.split(" ")[0].substr(0,1) + data.last_name.split(" ")[0]).toLowerCase()
				data['user_type'] = 4
				const formData = this.setFormData(data);
				this.$http
					.post(this.api+'/postulations/register-judge/', formData, this.token)
					.then(response => {
						this.loading = false
						this.judges.unshift(response.data)
						this.customText = 'Se ha creado una cuenta de juez correctamente, porfavor verifique los datos a continuación:'
						this.customTitle = 'Enviado!'
						this.customList.push({key: 'Usuario', text: data.username})
						this.customList.push({key: 'Contraseña', text: data.password})
						this.customList.push({key: 'Nombre', text: data.first_name})
						this.customList.push({key: 'Apellido', text: data.last_name})
						this.customList.push({key: 'Email', text: data.email})
						this.modals.custom=true
						this.modals.create=false
					})
					.catch(error => {
						var text
						var type
						var title
						const responseData = error?.response?.data
						if ("email" in responseData)  {
							type = 'warning'
							title = 'Aviso'
							text = 'El correo electrónico que ha ingresado ya esta en uso.'
						} else if ("username" in responseData) {
							type = 'warning'
							title = 'Aviso'
							text = 'El nombre de usuario que ha ingresado ya esta en uso.'
						} else {
							type = 'error'
							title = "Ops, ha ocurrido un error."
							text = 'Ha ocurrido un error al intentar actualizar un usuario, si el error persiste contacte con el administrador.'
						}
						this.$swal({
							'type': type,
							'title': title,
							'text': text,
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})
						this.loading = false
					})	
			},
			updateJudge(data) {
				if("is_active" in data) {
					this.$store.commit('loader', 'y')
				} else {
					this.loading = true
				}
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+'/postulations/register-judge/'+data.id+'/', formData, this.token)
					.then(response => {
						this.loading = false
						this.modals.update = false
						this.modals.password = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado el usuario correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.$store.commit('loader', 'n')
							var result = response.data
							for (var i in this.judges) {
								if (this.judges[i].id === parseInt(result.id)) {
									this.judges[i].is_active = result.is_active
									this.judges[i].username = result.username
									this.judges[i].email = result.email
									this.judges[i].first_name = result.first_name
									this.judges[i].last_name = result.last_name
									break;
								}
							}
						})
					})
					.catch(error => {
						var text
						var type
						var title
						const responseData = error?.response?.data
						if ("email" in responseData)  {
							type = 'warning'
							title = 'Aviso'
							text = 'El correo electrónico que ha ingresado ya esta en uso.'
						} else if ("username" in responseData) {
							type = 'warning'
							title = 'Aviso'
							text = 'El nombre de usuario que ha ingresado ya esta en uso.'
						} else {
							type = 'error'
							title = "Ops, ha ocurrido un error."
							text = 'Ha ocurrido un error al intentar actualizar un usuario, si el error persiste contacte con el administrador.'
						}
						this.$swal({
							'type': type,
							'title': title,
							'text': text,
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})
						this.$store.commit('loader', 'n')
					})			
			},
			destroyJudge() {
				this.modals.confirm = false
				this.$store.commit('loader', 'y')
				this.$http
					.delete(this.api+'/postulations/register-judge/'+this.selectedData.id+'/', this.token)
					.then(() => {
						this.loadingModal = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha eliminado el Juez correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.$store.commit('loader', 'n')
							for (var i in this.judges) {
								if (this.judges[i] === this.selectedData) {
									this.judges.splice(i, 1)
									this.selectedData = {}
									break;
								}
							}
						})
					})
			}
		}
	}
</script>


<style type="text/css" scoped>
	.table tbody tr td { text-transform: initial !important; }
</style>