<template>
  <section class="is-flex is-justify-content-center is-align-items-center main-content_home padding-home">
    <div class="show-mobile is-flex is-justify-content-space-between is-hidden-tablet">
      <span @click="prevSlide" class="arrowLeft icon icon-play-prev cprev"></span>
      <span @click="nextSlide" class="arrowRight icon icon-play-next cnext"></span>
    </div>
    <span @click="prevSlide" class="arrowLeft icon icon-play-prev cprev is-hidden-mobile"></span>
    <div class="container">
      <CarouselArtist 
        :slidePosition="slidePosition"/>
      <CarouselEvents 
        v-if="events.length > 0" 
        :slidePosition="slidePosition"/>
      <CarouselContenido
        v-if="contents.length > 0" 
        :slidePosition="slidePosition"/>
      <CarouselLaCapsula 
        :slidePosition="slidePosition"/>
    </div>
    <span @click="nextSlide" class="arrowRight icon icon-play-next cnext is-hidden-mobile"></span>
  </section>
</template>

<script>
import CarouselArtist from '@/components/home/carouselArtist'
import CarouselEvents from '@/components/home/carouselEvents'
import CarouselContenido from '@/components/home/carouselContenido'
import CarouselLaCapsula from '@/components/home/carouselLaCapsula'

export default {
  name: "home",
  data(){
    return {
      slidePosition: 0,
      page: 'home',
      pageName: 'home',
    }
  },
  created(){
    this.setInternalPage()
  },
  computed:{
    slideLength(){
      return this.contents.length ? 4 : 3
    },
    artists(){
      return this.$store.state.artist
    },
    events(){
      return this.$store.state.event
    },
    contents(){
      return this.$store.state.content
    },
    capsula(){
      return this.$store.state.capsula
    }
  },
  methods:{
    nextSlide(){
      if (this.slidePosition >= this.slideLength - 1){
        //If it is the last slide, clicking next sends back to the 1st slide
        this.slidePosition = 0
      }else{
        this.slidePosition++
      }
    },
    prevSlide(){
      if (this.slidePosition <= 0){
        //if clicks prev slide button and it is the 1st element, they'll be redirected to the last slide.
        this.slidePosition = this.slideLength - 1
      }else{
        this.slidePosition--;
      }
    },
    setInternalPage() {
        this.$store.commit('outPage', {
          title: this.page,
          name: this.pageName})
      },
  },
  components: {
    CarouselArtist,
    CarouselEvents,
    CarouselContenido,
    CarouselLaCapsula,
  },
};
</script>

<style>
  section.main-content_home{
    height: 100%;
    max-height: 850px;
  }
  .padding-home{
    padding-top: .75rem;
  }
  .carousel-artist{
    height: 70vh;
  }

  .carousel-artist_info {
    padding-left: 2rem;
    height: 100%;
  }
  .arrowLeft, .arrowRight{
    margin: 0 .5rem 0;
    padding: 2rem;
    color: gray;
  }
  .arrowLeft:hover, .arrowRight:hover{
    cursor: pointer;
  }
  .arrowLeft::before, .arrowRight::before {
    font-size: 4rem;
    font-weight: lighter;
  }

  .carousel-dots_list {
    position:  absolute;
    bottom: 6%;
    right: 50%;
    transform: translate(50%,6%);
  }

  .carousel-dots_list > span {
  display: inline-block;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.carousel-dots_list >span:hover {
  cursor: pointer;
}

.carousel-dots_list > .active {
  border-color: white;
  background: white;
}

.social-width {
  width: 85%!important;
}
.social-width-desktop{
  width: 60%!important;
}
.artist_social_desktop{
  position: absolute;
  bottom: 5%;
  transform: translateY(5%);
  margin-left: 5.5rem;
  width: 100%;
  max-width: 650px;
}
@media screen and (max-width: 768px){
  section.main-content_home {
    flex-direction: column;
  }
  .show-mobile{
    width: 100%;
  }
}
@media screen and (max-height: 900px){
  .carousel-artist{
    height: auto;
  }
  .padding-home{
    padding-top: .4rem!important;
  }
}
@media screen and (max-width: 425px){
  .padding-home{
    padding-top: 0!important
  }
  .arrowLeft::before, .arrowRight::before{
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1400px){
  .carousel-artist.p-6{
    padding: 0!important;
  }
  p.p-description{
    font-size: 1rem!important;
  }
    .carousel-artist_info > h1.title.is-size-1 {
    font-size: 2.8rem!important;
  }
  .artist_social_desktop{
    margin-left: 3rem;
    bottom: 1%;
    transform: translateY(1%);
  }
  .carousel-dots_list{
    bottom: 4%;
    right: 50%;
    transform: translate(50%,4%);
  }
}
@media screen and (min-width: 1400px){
  .carousel-artist_info > h1.title.is-size-1 {
    font-size: 4rem!important;
    margin-bottom: 3.5rem;
  }
  .carousel-artist_info > div > p {
    width: 85% !important
  }
  .p-description.is-size-5-widescreen{
    font-size: 1.2rem!important;
  }
}
@media screen and (min-width: 1500px){
  .carousel-artist_info > div > p {
    width: 90% !important
  }
  .artist_bio {
    margin-bottom: 3rem!important;
  }
  .artist_social_desktop{
    margin-left: 5.5rem;
    max-width: 900px;
  }
}

@media screen and (min-width: 1600px){
  .carousel-item {
    padding: 1.5rem 0 !important;
  }
}
</style>
