<template>
	<div>
		<transition name="h-expand-y">
			<div class="bdtop h-separator-t1" 
			v-if="params.lshow"></div>
		</transition>
		<transition name="h-fade">
			<h1 class="text-under-bar 
			is-uppercase 
			is-size-4-fullhd 
			is-size-5-widescreen 
			is-size-6-desktop 
			is-size-7-touch 
			h-separator-t4
			h-line-1-3"
			v-if="params.tshow">
				{{ params.text }}
			</h1>
		</transition>
	</div>
</template>

<script>
	export default {
		props: ['params'],
		timers: {
			showText: { time: 300*6, autostart: true },
			showLine: { time: (300*5), autostart: true }
		},
		methods: {
			showText() {
				this.params.tshow = true;
			},
			showLine() {
				this.params.lshow = true;
			}
		}
	}
</script>

<style scoped>
	.bdtop { 
		margin-left: 12%;
		margin-right: 12%;
	}
@media screen and (max-width: 999px){
	.text-under-bar { 
		padding-left: 12%;
		padding-right: 12%;
	}
	.bdtop {
		border-width: 2px;
	}
}	
</style>