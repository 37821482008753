<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
					<h2 class="title">
						<i class="far fa-check-circle has-text-success" v-if="type=='success'"></i>
						<i class="far fa-times-circle has-text-danger" v-else-if="type=='danger'"></i>
					</h2>
					<h3 class="title is-4">{{title}}</h3>
					<p>{{message}}</p>
					<ul v-if="list != undefined && list.length > 0" class="has-text-left custom-list">
						<li v-for="(x,n) in list" :key="n"><b>{{x.key}}:</b> {{x.text}}</li>
					</ul>	
					<hr>
					<p class="has-text-centered">
						<button class="button is-primary" @click="$emit('modal-close')">
							OK!
						</button>
					</p>
				</div>

			</div>
		</div>	
	</transition>
</template>

<script>
	export default {
		name: 'CustomMessage',
		props: ['type','message','title','show','list']
	}
</script>

<style type="text/css" scoped>
	.custom-list li{
		text-transform: initial !important;
	}
</style>