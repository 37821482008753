<template>
	<section class="container">
		<div class="tile is-child">
		<div class="tile is-12 h-margin-center is-vertical h-separator-t4 has-background-black px-5 pt-2">
			<h1 class="title is-1 has-text-white mt-6 mb-2 ml-4 is-flex is-justify-content-start">CONTACTO</h1>
			<div class="tile is-parent">
				<div class="tile is-parent is-vertical box has-text-justified contact-box1 has-background-black">

					<div class="field">
						<label class="label has-text-white-ter">Nombre completo:</label>
						<div class="control has-icons-left has-icons-right">
							<input 
								class="input has-background-black borders has-text-white-ter no-border" 
								type="text" 
								v-validate="'required'"
								name="name"
								v-model="contactForm.name">
							<span class="icon is-small is-left">
								<i class="fas fa-user"></i>
							</span>
						</div>
					</div>

					<div class="field">
						<label class="label has-text-white-ter">Pais:</label>
						<div class="control">
							<div class="select h-fullwidth">
								<CountrySelect 
									class="has-background-black has-text-white-ter borders"
									:country="contactForm.country" 
									@country-selected="setCountry"
									:api="api" />
							</div>
						</div>
					</div>

					<div class="field">
						<label class="label has-text-white-ter">Email:</label>
						<div class="control has-icons-left has-icons-right">
							<input 
								class="input has-background-black has-text-white-ter borders" 
								type="email"
								v-validate="'required|email'"
								name="email"
								v-model="contactForm.email">
							<span class="icon is-small is-left">
								<i class="fas fa-envelope"></i>
							</span>
						</div>
					</div>
				</div>
				<div class="tile is-parent is-vertical box has-text-justified contact-box2 has-background-black">
					<div class="field">
						<label class="label has-text-white-ter">Asunto</label>
						<div class="control has-icons-left has-icons-right">
							<input 
								class="input has-text-white-ter has-background-black borders no-border" 
								type="text"
								v-validate="'required'"
								name="subject"
								v-model="contactForm.subject">
							<span class="icon is-small is-left">
								<i class="fas fa-pen"></i>
							</span>
						</div>
					</div>

					<div class="field">
						<label class="label has-text-white-ter">Mensaje</label>
						<div class="control">
							<textarea 
								class="textarea has-background-black has-text-white-ter borders" 
								placeholder="Escribe un mensaje..."
								name="message"
								v-validate="'required'"
								v-model="contactForm.message"></textarea>
						</div>
					</div>				
				</div>
			</div>
			<div class="tile is-parent is-vertical h-margin-center">
				<div class="field">
					<div class="control has-text-centered">
						<label class="checkbox is-flex is-align-items-center">
							<input class="h-checkbox" type="checkbox" v-model="terms" name="terms" v-validate="'required'">
							<span class="has-text-white-ter ml-2 mr-1">Acepto los </span><a href="/terminos-y-condiciones" target="_blank" class="h-textcolor-gold">Términos y Condiciones de Uso</a>
						</label>
					</div>
				</div>
				<div class="field is-grouped">
					<div class="control h-fullwidth has-text-centered">
						<button 
						:class="{
							'button is-success is-uppercase h-back-gold':true,
							'is-loading':loading
							}" 
						@click="sendEmail()">enviar</button>
					</div>
				</div>
				<!-- Validations -->
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('name')">
						<i class="fas fa-asterisk"></i> Por favor complete el campo de nombre.
					</span>
				</transition>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('country')">
						<i class="fas fa-asterisk"></i> Debe seleccionar un país.
					</span>
				</transition>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('email')">
						<i class="fas fa-asterisk"></i> Por favor escriba correctamente un correo electronico.
					</span>
				</transition>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('subject')">
						<i class="fas fa-asterisk"></i> Debe indicar un asunto.
					</span>
				</transition>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('message')">
						<i class="fas fa-asterisk"></i> El mensaje no puede quedar vacio.
					</span>
				</transition>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('terms')">
						<i class="fas fa-asterisk"></i> Debe aceptar los terminos y condiciones para enviar el mensaje.
					</span>
				</transition>
			</div>
		</div>
	</div>
	</section>
	
</template>

<script>
	import CountrySelect from '@/components/general/countries'
	import { load } from 'recaptcha-v3'
	export default {
		name: 'Contacto',
		data() {
			return {
				page: 'contact',
				pageName: 'contacto',
				loading: false,
				terms: false,
				reCaptchaBagde: false,
				reCaptchaToken: '',
				contactForm: {
					name: '',
					email:'',
					country: '',
					subject: '',
					message: ''
				}
			}
		},
		created() {
			this.setInternalPage()
			if (this.$store.getters.GetMobileBrowser) {
				this.reCaptchaBagde = true
			}

		},
		components: {
			CountrySelect
		},
		computed: {
			api() {
				return this.$store.getters.GetApiPath
			},
			reCaptcha() {
				return this.$store.getters.GetCaptcha
			},
			HabeatatKey() {
				return this.$store.getters.GetHabeatatKey
			}
		},
		methods: {
			setInternalPage() {
				this.$store.commit('outPage', {title: this.page, name: this.pageName})
			},
			setCountry: function(val) {
				this.contactForm.country = val
			},
			sendEmail() {
				this.$validator.validateAll().then(result => {

					if (result) {
						this.loading = true
						load(this.reCaptcha.key, {autoHideBadge: this.reCaptchaBagde})
						.then((recaptcha) => {
							recaptcha.execute('login').then((token) => {
								this.reCaptchaToken = token
								const formData = this.setFormData()
								
								this.$http
									.post(this.api+'/h/contact/', formData)
									.then(() => {
										this.$swal({
											type: "success",
											title: "¡Completado!",
											text: 'Se ha enviado su mensaje correctamente.',
											confirmButtonClass: "button",
											buttonsStyling: false,
											background: "white"
										}).then(() => {
											this.loading = false
											window.location.replace('/contacto')
										});

									})
									.catch(() => {
										this.$swal({
											type: "error",
											title: "Ops!",
											text: 'Ha ocurrido un error al intentar enviar su mensaje, porfavor intente de nuevo mas tarde.',
											confirmButtonClass: "button",
											buttonsStyling: false,
											background: "white"
										}).then(() => {
											this.loading = false
										});
									})
								

							})
						})				
					}
				})

			},
			setFormData() {
				const formData = new FormData();
				formData.append('token', this.reCaptchaToken);
				formData.append('h_key', this.HabeatatKey);

				//form user data
				formData.append('name', this.contactForm.name);
				formData.append('email', this.contactForm.email);
				formData.append('country', this.contactForm.country);
				formData.append('subject', this.contactForm.subject);
				formData.append('message', this.contactForm.message);
				return formData
			},

		}
	};
</script>

<style scoped>
	.add-padding{
		padding: 0 5rem;
	}
	textarea::placeholder{
		color:  grey;
	}
	.borders {
		border: 1px solid grey;
		border-radius: 0;
	}
	.no-border{
		border-top:  0;
		border-left: 0;
		border-right: 0;
	}
	.contact-box1, .contact-box2 {
		height: 17rem;
	}
	.contact-box2 {
		border-top-left-radius: inherit;
		border-bottom-left-radius: inherit;
	}
	.contact-box1 {
		border-top-right-radius: inherit;
		border-bottom-right-radius: inherit;		
	}
@media	 screen and (max-width: 768px){
	.add-padding{
		padding: 0;
	}
}
@media screen and (max-width: 999px) {
	.contact-box1, .contact-box2 {
		border-radius: inherit;
	}
	.contact-box1 {
		margin-bottom: inherit !important;
		padding-bottom: 0 !important;
		height: 16rem;
	}
	.contact-box2 {
		padding-top: 0 !important;
	}
}
</style>