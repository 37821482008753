<template>
	<div class="tile is-parent is-vertical">
		<div class="tile is-child">
			<button class="button" type="button" @click="modalActive=1, pressPicInput()">
				<span class="icon"> <i class="far fa-calendar-plus"></i></span> <span>Nuevo Evento</span>
			</button>
			<input 
				class="file-input is-invisible" 
				type="file" 
				name="eventPic" 
				ref="eventPic"
				accept="image/*" 
				@change="selectPic($event.target)">
		</div>
		<div class="tile is-child">
			<!-- EVENTS BLOCKS -->
			<transition-group  name="fadeRight" tag="div" class="columns is-mobile is-multiline is-centered">
				<div 
				v-for="ev in events" 
				:class="['column is-6-mobile', sectionTwo ? 'is-4-tablet':'is-2-tablet']" 
				:key="ev.id">
					<div :class="['box', 
					ev.id === event_selected.id ? 'has-background-link':'none' && 
					ev.is_active ? 'has-background-grey-light' : 'has-background-black-bis']">
						<div class="columns is-mobile">
							<div class="column is-8">
								<p class="has-text-white is-size-7-mobile is-size-5-fullhd is-size-6 h-separator60 h-overflow-hide">{{ev.name}}</p>
								<figure>
									<img :src="ev.picture">
								</figure>
							</div>
							<div class="column is-4 is-size-6-mobile is-size-4-fullhd is-size-5">
								<a title="Portada"
								v-tooltip.right="'Establecer una portada para '+ev.name" 
								@click="setPortraitInfo(ev.id)">
									<i class="far fa-newspaper has-text-white"></i>
								</a>
								<br>
								<a title="Foto"
								v-tooltip.right="'Cambiar foto de '+ev.name" 
								@click="modalActive=2, event_id=ev.id, event_selected=ev, pressPicInput()">
									<i class="fas fa-image has-text-white"></i>
								</a>
								<br>
								<a 	title="Información" 
								v-tooltip.right="'Información básica de '+ev.name"
									@click="formData = {
										name: ev.name,
										priority: ev.priority,
										email: ev.email,
										condition: ev.condition,
										id:ev.id
									}, formModal1	=true">
									<i class="fas fa-info-circle has-text-white"></i>
								</a>
								<br>
								<a title="Sitio web" 
								v-tooltip.right="'Gestionar el contenido web de '+ev.name"
								@click="setEventWeb(ev.id)">
									<i class="fas fa-window-maximize has-text-white"></i>
								</a>
								<br>
								<a title="Segmentos" 
								v-tooltip.right="'Ver los tipos de '+ev.name"
								@click="event_selected = ev, $emit('add-section', ev)">
									<i :class="{'far fa-list-alt':true,'h-animate-text-top':ev.id !== event_selected.id}"></i>
								</a>
								<a :title="ev.is_active ? 'Desactivar':'Activar'" 
								v-tooltip.right="(ev.is_active ? 'Desactivar':'Activar')+' a '+ev.name+' en Habeatat'"
								@click="changeStatus({id:ev.id, 'is_active':ev.is_active})">
									<i :class="['fas', ev.is_active ? 'fa-stop-circle':'fa-play-circle', 'has-text-white']"></i>
								</a>
								<br>
								<a title="Eliminar" 
								v-tooltip.right="'Eliminar completamente a '+ev.name+' de Habeatat'"
								@click="confirmDestroy(ev.id)">
									<i class="fas fa-trash has-text-white"></i>
								</a> 
							</div>
						</div>
					</div>
				</div>
			</transition-group>
		</div>

		<!-- Modals -->
		<!-- Cropper profile image and Event Form -->
		<cropPicModal
			v-if="selectedImage != ''"
			:modalShow="cropperModal"
			:imgSrc="selectedImage"
			:mTitle="'Crear un nuevo Evento'"
			:mForm="4"
			@imageCropped="saveEvent"
			@modalClose="closeCropperModal()" 
			:charging="modalLoading"/>
		<!-- Cropper portrait image and Event description Form -->
		<cropPicModal
			v-if="selectedImage != ''"
			:modalShow="portraitModals.init"
			:imgSrc="selectedImage"
			:mTitle="'Portada del Evento'"
			:mForm="5"
			@imageCropped="savePortrait"
			@modalClose="closeCropperModal()" 
			:charging="modalLoading"/>
		<!-- Cropper profile image -->
		<onlyCropPicModal 
			v-if="selectedImage != ''"
			:modalShow="onlyCropperModal"
			:imgSrc="selectedImage"
			:prevImg="event_selected.picture"
			:mTitle="'Actualizar imagen del Evento'"
			@modalClose="closeCropperModal()"
			@imageCropped="changeEventPhoto"
			:loading="modalLoading" />
		<!-- Cropper portrait image -->
		<onlyCropPicModal 
			v-if="selectedImage != '' && portraitModals.pic"
			:modalShow="portraitModals.pic"
			:imgSrc="selectedImage"
			:prevImg="portraitDetail.picture"
			:mTitle="'Actualizar imagen de portada del Evento'"
			@modalClose="closeCropperModal()"
			@imageCropped="changePortraitPhoto"
			:loading="modalLoading" />
		<!-- Event Form -->
		<FormModal 
			:show="formModal1"
			:formType="8"
			:data="formData"
			@modal-close="formModal1 = false"
			@save-form="changeInfo"
			:charging="modalLoading"
			v-if="formModal1" />
		<!-- Event Web Form -->
		<FormModal 
			:show="formModal4"
			:formType="6"
			:data="event_web"
			@modal-close="formModal4 = false"
			@save-form="changeWeb"
			:charging="modalLoading"
			v-if="formModal4" />
		<!-- Event Portrait Form -->
		<FormModal 
			:show="portraitModals.text"
			:formType="11"
			:data="portraitDetail"
			@modal-close="portraitModals.text = false"
			@save-form="changePortraitDescription"
			:charging="modalLoading"
			v-if="portraitModals.text" />

		<PicModal :show="picModal" />

		<PicOrTextModal 
		:show="portraitModals.edit"
		:message="'Que desea cambiar de la portada?'"
		:image_desc="'imagen'"
		:text_desc="'descripcion'"
		:detail="portraitDetail"
		@select-image="portraitModals.edit = false, modalActive=4, pressPicInput()"
		@select-text="portraitModals.edit = false, portraitModals.text=true"
		@modal-close="portraitModals.edit = false"
		v-if="portraitModals.edit" />

		<ConfirmModal 
			:show="confirmModal"
			v-if="confirmModal"
			:message="confirmText"
			@modal-close="confirmModal = false"
			@modal-submit="confirmTrue ? destroyEvent() : confirmDestroy(event_id)" />
	</div>
</template>

<script>
	import cropPicModal from '@/components/modal/cropper'
	import onlyCropPicModal from '@/components/modal/onlyCropper'
	import PicModal from '@/components/modal/pic'
	import FormModal from '@/components/modal/form'
	import ConfirmModal from '@/components/modal/confirm'
	import PicOrTextModal from '@/components/modal/picortext'
	export default {
		name: 'adminEvent',
		props: ['token','api','priorityOrder', 'sectionTwo'],
		components: {
			cropPicModal,
			onlyCropPicModal,
			PicModal,
			FormModal,
			ConfirmModal,
			PicOrTextModal
		},
		data() {
			return {
				events: [],
				event_id: 0,
				event_web: {},
				event_selected: {},
				modalActive: 1,
				cropperModal: false,
				onlyCropperModal: false,
				portraitModals: {
					init: false,
					edit: false,
					pic: false,
					text: false
				},
				portraitDetail: {},
				picModal: false,
				formModal1: false,
				formData: {},
				formModal4: false,
				bannerModal: false,
				confirmModal: false,
				confirmText: '',
				confirmTrue: false,
				confirmCount: 1,
				modalLoading: false,
				selectedImage: '',
				basePath: '/event/'
			}
		},
		created() {
			this.setEvents()
		},
		methods: {
			setEvents() {
				this.$http
					.get(this.api+this.basePath+'init/', this.token)
					.then(response => {
						this.events = response.data
					})
			},
			setEventWeb(event_id) {
				this.picModal = true
				this.$http
					.get(this.api+this.basePath+'web/'+event_id+'/', this.token)
					.then(response => {
						var result = response.data

						this.event_web = result
						this.formModal4 = true

						this.picModal = false
					})
					.catch(error => {
						if (error.response.status === 404) {
							const formData = new FormData();
							formData.append('event', event_id);
							this.$http
								.post(this.api+this.basePath+'web/',formData,this.token)
								.then(response => {
									var result = response.data

									this.event_web = result
									this.formModal4 = true

									this.picModal = false
								})
						}
					})
			},
			setFormData(data) {
				const formData = new FormData();
				if("name" in data) {
					formData.append('name', data.name);
				}
				if("email" in data) {
					formData.append('email', data.email);
				}
				if("priority" in data) {
					formData.append('priority', data.priority);
				}
				if("condition" in data) {
					formData.append('condition', data.condition);
				}
				if("is_active" in data) {
					formData.append('is_active', !data.is_active);
				}
				if("text" in data) {
					formData.append('text', data.text);
				}
				if("url" in data) {
					formData.append('url', data.url);
				}
				if("hiring" in data) {
					formData.append('hiring', data.hiring);
				}
				if("calendar" in data) {
					formData.append('calendar', data.calendar);
				}
				if("description" in data) {
					formData.append('description', data.description);
				}
				return formData
			},
			setPortraitInfo(event_id) {
				this.event_id = event_id
				this.picModal = true
				this.$http
					.get(this.api+this.basePath+'portrait/'+event_id+'/', this.token)
					.then(response => {
						this.picModal = false
						var result = response.data
						this.portraitDetail = result
						this.portraitModals.edit = true
					})
					.catch(error => {
						if (error.response.status === 404) {
							this.picModal = false
							this.modalActive = 3
							this.pressPicInput()
						}
					})
			},
			pressPicInput(){
				this.$refs.eventPic.click()
			},
			selectPic(val) {
				if (!val.files.length || !val.files[0].type.includes('image/')){ 
					alert('Por favor seleccione un archivo de tipo imagen.');
					return;
				}
				const picture = val.files[0];
				if (typeof FileReader === 'function') {
					const reader = new FileReader();

					reader.onload = (event) => {
						var review = event.target.result;
						this.selectedImage = review
					};

					reader.readAsDataURL(picture);
				} else {
					alert('Sorry, FileReader API not supported');
				}

				this.openCropperModal(this.modalActive);
			},
			openCropperModal(active) {
				if(active === 1) {
					this.cropperModal = true
				} else if (active === 2) {
					this.onlyCropperModal = true
				} else if (active === 3) {
					this.portraitModals.init = true
				} else if (active === 4) {
					this.portraitModals.pic = true
				}
			},
			closeCropperModal() {
				this.cropperModal = false
				this.onlyCropperModal = false
				this.portraitModals.init = false
				this.portraitModals.pic = false
				this.selectedImage = ''
				const input = this.$refs.eventPic
				input.type = 'text'
				input.type = 'file'
			},
			saveEvent(event) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('picture', event.pic);
				formData.append('name', event.data.name);
				formData.append('email', event.data.email);
				formData.append('priority', event.data.priority);
				formData.append('condition', event.data.condition);
				this.$http
					.post(this.api+this.basePath+'init/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha creado un nuevo Evento correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.events.push(response.data.item)
							this.priorityOrder(this.events)
						}); 
					})
					.catch(() => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido cargar la imagen del Evento al servidor.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})		
					})
			},
			savePortrait(portrait) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('picture', portrait.pic);
				formData.append('description', portrait.data.description);
				formData.append('event', this.event_id);
				this.$http
					.post(this.api+this.basePath+"portrait/", formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha creado una nueva portada de Evento correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.portraitDetail = response.data.item
						}); 
					})
					.catch(() => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido cargar la imagen del Evento al servidor.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})		
					})
			},
			changeEventPhoto(pic) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('picture', pic);
				this.$http
					.patch(this.api+this.basePath+'init/'+this.event_id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.closeCropperModal()
						var item = response.data.item
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'La imagen se ha actualizado correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							for (var art in this.events) {
								if (this.events[art].id === item.id) {
									this.events[art].picture = item.picture
									break
								}
							}
						}); 
					})
					.catch(() => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido actualizar la imagen del Evento.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			},
			changePortraitPhoto(pic) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('picture', pic);
				this.$http
					.patch(this.api+this.basePath+'portrait/'+this.portraitDetail.id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.closeCropperModal()
						var item = response.data.item
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'La cambiado la portada correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.portraitDetail = item
						}); 
					})
					.catch(() => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido actualizar la portada del evento.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			},
			changePortraitDescription(info) {
				this.modalLoading = true
				const formData = this.setFormData(info)
				this.$http
					.patch(this.api+this.basePath+"portrait/"+info.id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.portraitModals.text = false
						var item = response.data.item
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado la portada correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.portraitDetail = item
						}); 
					})
			},
			changeStatus(data) {
				this.picModal = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+'init/'+data.id+'/', formData, this.token)
					.then(response => {
						this.picModal = false
						var item = response.data.item
						for (var art in this.events) {
							if (this.events[art].id === item.id) {
								this.events[art].is_active = item.is_active
								break
							}
						}
					})	
			},
			changeInfo(info) {
				this.modalLoading = true
				const formData = this.setFormData(info)
				this.$http
					.patch(this.api+this.basePath+'init/'+info.id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.formModal1 = false
						var item = response.data.item
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado la información correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
						for (var art in this.events) {
							if (this.events[art].id === item.id) {
								this.events[art].name = item.name
								this.events[art].condition = item.condition
								this.events[art].email = item.email
								this.events[art].priority = item.priority
								break
							}
						}
						this.priorityOrder(this.events)
						}); 
					})
			},
			changeWeb(data) {
				this.modalLoading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+"web/"+data.id+'/', formData, this.token)
					.then(() => {
						this.modalLoading = false
						this.formModal4 = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado el sitio web correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
			},
			confirmDestroy(event_id) {
				this.event_id = event_id
				this.confirmModal = false
				if ( this.confirmCount === 1 ) {
					this.confirmText = "Esta seguro de que quiere eliminar por completo a este Evento, sus segementos y todo su contenido multimedia?"
					this.confirmCount++
					this.confirmModal = true
				} else {
					this.confirmTrue = true
					this.confirmText = "Esta usted completamente seguro? una vez realizado el proceso no se podra revertir."
					this.confirmModal = true
				}
			},
			destroyEvent() {
				this.confirmModal = false
				this.confirmTrue = false
				this.confirmCount = 1
				this.picModal = true
				this.$http
					.delete(this.api+this.basePath+'init/'+this.event_id+'/', this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha eliminado el Evento correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.$emit('rmv-section')
							this.picModal = false
							var item = parseInt(response.data.item.id)
							for (var i in this.events) {
								if (this.events[i].id === item) {
									this.events.splice(i, 1);
									break;
								}
							}
						})
					})
					.catch(() => {
						this.picModal = false
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido eliminar el contenido e imagenes del Evento.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			}

		}
	}
</script>