<template>
  <div class="has-text-justified custom-transform">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
    <div class="buttons has-addons is-centered">
      
        <a :class="{ 'is-active': isActive.bold(), 'button':true }" @click="commands.bold">
          <span class="icon is-small">
           <i class="fas fa-bold"></i>
          </span>
        </a>
      
        <a :class="{ 'is-active': isActive.italic(), 'button':true }" @click="commands.italic">
          <span class="icon is-small">
           <i class="fas fa-italic"></i>
          </span>
        </a>
      
        <a :class="{ 'is-active': isActive.strike(), 'button':true }" @click="commands.strike">
          <span class="icon is-small">
           <i class="fas fa-strikethrough"></i>
          </span>
        </a>

        <a :class="{ 'is-active': isActive.underline(), 'button':true }" @click="commands.underline">
          <span class="icon is-small">
           <i class="fas fa-underline"></i>
          </span>
        </a>
    </div>

    </editor-menu-bar>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  
} from 'tiptap-extensions'

export default {
  name: 'FormLyric',
  props: ['data'],
  components: {
    EditorMenuBar,
    EditorContent,
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Heading({ levels: [1, 2, 3] }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          
        ],
        content: this.data != undefined ? JSON.parse(this.data.lyric):'<p>Type the lyric here!</p>',
      }),
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    getLyric() {
     return this.editor.getHTML()
    }
  }
}
</script>

<style type="text/css">
	.custom-transform { text-transform: initial !important; }
</style>