<template>
<div class="subscription p-4">
	<h2 class="is-size-5-widescreen is-size-6-mobile has-text-black has-text-left">Suscríbete para estar atento a próximas novedades</h2>
	<div class="field">
		<label for="email" class="label has-text-left">Correo electrónico:</label>
		<div class="control">
			<input
				class="email-input"
				type="email" 
				name="email"
				id="email" 
				v-model="email"
				v-validate="'required'">
		</div>
		<transition name="fade">
			<span class="tag is-rounded" v-show="errors.has('email')">
				{{ errors.first('email') }}
			</span>
		</transition>
		<input 
			class="button mt-4 has-text-white-bis
                has-background-grey-darker" 
			type="submit" 
			name="submit" 
			value="Suscribirse"
			@click="saveEmail"
		>
	</div>
</div>
</template>

<script>
export default {

  name: 'contentSubscription',
  inject: ['$validator'],
  data () {
    return {
		email: '',
		api: this.$store.state.api,
		endpoint: '/content/subscription/',
		key: this.$store.state.habeatatKey,
		//eslint-disable-next-line
		reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
	}
},
    methods:{
		testEmail(){
			//Returns true if email is correct testing with the reg expression
			return this.reg.test(this.email)
		},
		saveEmail(){
			//if email is valid, sends to database
			return this.testEmail() ? this.sendEmail() : null
		},
		sendEmail(){
			const data = new FormData()
			data.append('email', this.email)
			this.$http
				.post(`${this.api}${this.endpoint}`,data)
				.then(() => {
					this.email = ''
					this.$swal({
							type: "success",
							title: "¡Suscrito!",
							text: 'Se ha suscrito correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
				})
				.catch(()=>{
					this.$swal({
						type:'warning',
						title:'Ocurrió un error',
						text:'Parece que el correo electrónico se encuentra registrado.',
						confirmButtonClass: "button",
						buttonsStyling: false,
						background: "white"
					})
				})
		}
	}
}
</script>

<style scoped>
	.subscription{
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	.email-input{
		padding: 10px;
    border: none;
    background: transparent;
    border-bottom: 1px solid black;
	}
@media screen and (max-width: 450px){
	.field{
		margin: 0!important;
	}
}
</style>