<template>
	<div>
		<h4>Listas de reproduccion</h4>
		<div class="field is-horizontal">
			<div class="field-label is-normal">
				<label class="label">Spotify</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control is-expanded">
						<input 
							class="input" 
							type="text" 
							name="spotify" 
							placeholder="Enlace de Artista, lista de reproduccion o album"
							v-model="mform.spotify">
					</p>
					<p class="help h-text-style-reset"><b>Ejemplo:</b> https://open.spotify.com/<b class="h-textcolor-gold">playlist/3fyZ51roAhccgaaDCvIzGg</b></p>
				</div>
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label is-normal">
				<label class="label">Youtube</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control is-expanded">
						<input 
							class="input" 
							type="text" 
							name="youtube" 
							placeholder="ID de referencia de una lista de reproduccion en youtube"
							v-model="mform.youtube">
					</p>
					<p class="help h-text-style-reset"><b>Ejemplo:</b> https://www.youtube.com/watch?v=q0F6YKK_xts&list=<b class="h-textcolor-gold">PLHJHNZ-glSP4aV3vIZl2BSSPJArXkUn2K</b></p>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default {
		name: 'FormPlaylist',
		props: ['formdata'],
		data() {
			return {
				mform: {
					youtube: "",
					spotify: ""
				}
			}
		},
		created() {
			this.setInfo()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setInfo() {
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.youtube != 'undefined') {
						this.mform.youtube = this.formdata.youtube
					}
					if (typeof this.formdata.spotify != 'undefined') {
						this.mform.spotify = this.formdata.spotify
					}
				}
			}
		}
	}
</script>