<template>
	<div class="tile is-vertical">
		<div class="tile h-margin-center-lat">
			<div class="file has-name">
				<label class="file-label">
					<input 
						class="file-input" 
						type="file" 
						name="banner" 
						ref="banner"
						accept="image/*"
						@change="selectPic($event.target)">
					<span class="file-cta">
						<span class="file-icon">
							<i class="fas fa-upload"></i>
						</span>
						<span class="file-label">
							Cargar
						</span>
					</span>
					<span class="file-name">
						{{ newFileText }}
					</span>
				</label>
			</div>
		</div>
		<div class="tile is-parent">
				<transition-group  name="fadeRight" tag="div" class="columns is-multiline is-centered h-margin-center-lat">
					<div 
					v-for="ban in banners" 
					class="column is-3" 
					:key="ban.id">
						<div :class="['box', ban.is_active ? 'has-background-grey-light' : 'has-background-black-bis']">
							<figure>
								<img :src="ban.src">
							</figure>
							<a title="Informacion"
							v-tooltip.bottom="'Modificar la Información básica de la imagen: '+ban.title" 
							@click="formData={title:ban.title, link:ban.link, priority:ban.priority, id:ban.id, pre_tags:ban.tags}, formModal=true">
								<i class="fas fa-edit has-text-white"></i>
							</a> | 
							<a :title="ban.is_active ? 'Desactivar':'Activar'"
							v-tooltip.bottom="(ban.is_active ? 'Desactivar':'Activar')+' la imagen '+ban.title+' en la pagina.'"
							@click="changeStatus({id:ban.id, 'is_active':ban.is_active})">
								<i :class="['fas', ban.is_active ? 'fa-stop-circle':'fa-play-circle', 'has-text-white']"></i>
							</a> | 
							<a title="Eliminar"
							v-tooltip.bottom="'Eliminar completamente a '+ban.title+' de Habeatat'"
							@click="confirmDestroy(ban.id)">
								<i class="fas fa-trash has-text-white"></i>
							</a> 
							
						</div>
					</div>
				</transition-group>
		</div>
			<cropPicModal
			:modalShow="cropperModal"
			:imgSrc="selectedImage"
			:mTitle="'Crear un nuevo Flyer'"
			:mForm="1"
			@imageCropped="saveFlyer"
			@modalClose="emitCloseModal()"
			v-if="selectedImage != ''"
			:charging="modalCharging" />
			<PicModal 
			:show="soloPicModal"
			@modal-close="soloPicModal = false" />
			<FormModal 
			:show="formModal"
			:formType="1"
			:data="formData"
			@modal-close="formModal = false"
			@save-form="saveFlyerInfo"
			:charging="modalCharging"
			v-if="formModal" />
			<ConfirmModal 
				:show="confirmModal"
				:message="confirmText"
				v-if="confirmModal"
				@modal-close="confirmModal = false"
				@modal-submit="deleteFlyer()" />

	</div>
</template>

<script>
	import cropPicModal from '@/components/modal/cropper'
	import PicModal from '@/components/modal/pic'
	import FormModal from '@/components/modal/form'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'adminBanner',
		props: ['token','api', 'priorityOrder'],
		data() {
			return {
				newFileText: 'Nuevo FLyer',
				banners: {},
				cropperModal: false,
				soloPicModal: false,
				formModal: false,
				formData: {},
				modalCharging: false,
				selectedImage: '',
				selectedFlyerId: 0,
				confirmModal: false,
				confirmText: ''
			}
		},
		created() {
			this.setBanners()
		},
		components: {
			cropPicModal,
			PicModal,
			FormModal,
			ConfirmModal
		},
		methods: {
			setBanners() {
				this.$http
					.get(this.api+'/h/flyer/', this.token)
					.then(response => {
						this.banners = response.data
					})
			},
			selectPic(val) {
				if (!val.files.length || !val.files[0].type.includes('image/')){ 
					alert('Por favor seleccione un archivo de tipo imagen.');
					this.emitCloseModal();
					return;
				}
				const picture = val.files[0];
				if (typeof FileReader === 'function') {
					const reader = new FileReader();

					reader.onload = (event) => {
						var review = event.target.result;
						this.selectedImage = review
					};

					reader.readAsDataURL(picture);
				} else {
					alert('Sorry, FileReader API not supported');
				}

				this.emitOpenModal();
			},
			emitCloseModal() {
				this.cropperModal = false
				this.selectedImage = ''
				const input = this.$refs.banner
				input.type = 'text'
				input.type = 'file'
			},
			emitOpenModal() {
				this.cropperModal = true
			},
			saveFlyer(flyer){
				this.modalCharging = true
				const formData = new FormData();
				formData.append('src', flyer.pic);
				formData.append('title', flyer.data.title);
				formData.append('link', flyer.data.link);
				var formTags = flyer.data.tags
				var tags = []
				if (formTags.length > 0) {
					for (var x in formTags) {
						tags.push(formTags[x].text.toLowerCase())
					}
				}
				formData.append('tags', tags);
				formData.append('priority', flyer.data.priority)
				this.$http
					.post(this.api+"/h/flyer/", formData, this.token)
					.then(response => {
						this.modalCharging = false
						this.emitCloseModal()
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha creado un flyer correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.banners.push(response.data.item)
							this.priorityOrder(this.banners)
						}); 
					})
					.catch(() => {
						this.modalCharging = false
						this.emitCloseModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido cargar el Flyer al servidor.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			},
			saveFlyerInfo(info) {
				this.modalCharging = true
				const formData = new FormData();
				formData.append('title', info.title);
				formData.append('link', info.link);
				formData.append('priority', info.priority);
				var formTags = info.tags
				var tags = []
				if (formTags.length > 0) {
					for (var x in formTags) {
						tags.push(formTags[x].text.toLowerCase())
					}
				}
				formData.append('tags', tags);
				this.$http
					.patch(this.api+"/h/flyer/"+info.id+'/', formData, this.token)
					.then(response => {
						this.modalCharging = false
						this.formModal = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado la informacion correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.updateFlyer(response.data.item)
							this.priorityOrder(this.banners)
						}); 
					})
			},
			changeStatus(data) {
				this.soloPicModal = true
				const formData = new FormData();
				formData.append('is_active', !data.is_active);
				this.$http
					.patch(this.api+"/h/flyer/"+data.id+'/', formData, this.token)
					.then(response => {
						this.updateFlyer(response.data.item)
						this.soloPicModal = false
					})
			},
			updateFlyer(newBan) {
				for (var i in this.banners) {
					if (this.banners[i].id === newBan.id) {
						this.banners[i].is_active = newBan.is_active
						this.banners[i].title = newBan.title
						this.banners[i].link = newBan.link
						this.banners[i].priority = newBan.priority
						this.banners[i].tags = newBan.tags
						break;
					}
				}
			},
			confirmDestroy(flyer_id) {
				this.selectedFlyerId = flyer_id
				this.confirmText = 'Esta seguro de eliminar este Flyer?'
				this.confirmModal = true
			},
			deleteFlyer() {
				this.soloPicModal = true
				this.confirmModal = false
				this.$http
					.delete(this.api+'/h/flyer/'+this.selectedFlyerId+'/', this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha eliminado el flyer correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.soloPicModal = false
							var item = parseInt(response.data.item.id)
							for (var i in this.banners) {
								if (this.banners[i].id === item) {
									this.banners.splice(i, 1);
									break;
								}
							}
						})
					})
					.catch(() => {
						this.soloPicModal = false
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido eliminar el Flyer.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			}
		}
	}
</script>