<template>
	<div class="container" v-if="artistLyrics.status != undefined">
		<div class="tile h-separator-t2" v-if="artistLyrics.status == 404">
			<div class="box">
				<h1 class="title is-4"><i class="fas fa-info-circle"> {{artistLyrics.message}}</i></h1>	
			</div>
		</div>
		<div class="columns is-vcentered is-centered h-fullwidth h-separator-t3" v-else>
			<div class="column is-1 is-hidden-mobile">
				<router-link :to="{ name: 'artist_lyrics', params: { artist: artistLyrics.item.prev[0] }}" 
				v-if="artistLyrics.item.prev.length > 0"
				v-tooltip.bottom="'Letras de '+artistLyrics.item.prev[0].toUpperCase()"
				:title="'Letras de '+artistLyrics.item.prev[0].toUpperCase()">
					<i class="icon-repro-prev is-size-3"></i>
				</router-link>
			</div>
			<div class="column is-2">
				<div class="tile is-vertical is-hidden-mobile">
					<figure>
						<img :src="artistLyrics.item.artist.picture" class="h-border-c15 custom-img">
					</figure>
				</div>
			</div>
			<div class="column">
				<div class="tile is-vertical">
					<h1 class="is-size-3-desktop is-size-5-touch">Letra de las canciones de <br><b>{{artistLyrics.item.artist.name}}</b></h1>
					<div class="box custom-height">
						<table class="table h-fullwidth">
							<thead>
								<tr>
									<th>Título</th>
									<th>Album</th>
									<th>Año</th>
								</tr>
							</thead>
							<transition-group name="list" tag="tbody">
								<tr v-for="i in artistLyrics.item.lyrics" :key="i.id" @click="getIntoLyric(i.title_id)">
									<td>{{i.title}}</td>
									<td>{{i.album}}</td>
									<td>{{i.year}}</td>
								</tr>
							</transition-group>
						</table>
					</div>
				</div>
			</div>
			<div class="column is-1 is-hidden-mobile">
				<router-link :to="{ name: 'artist_lyrics', params: { artist: artistLyrics.item.next[0] }}" 
				v-if="artistLyrics.item.next.length > 0"
				v-tooltip.bottom="'Letras de '+artistLyrics.item.next[0].toUpperCase()"
				:title="'Letras de '+artistLyrics.item.next[0].toUpperCase()">
					<i class="icon-repro-next is-size-3"></i>
				</router-link>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'ArtistLyrics',
		data() {
			return {
				artistLyrics: {}
			}
		},
		created() {
			this.setInternalPage()
			this.setArtistData()
		},
		computed: {
			api() {
				return this.$store.getters.GetApiPath
			},
			habeatatKey() {
				return this.$store.getters.GetHabeatatKey
			},
			pageData() {
				return {title: 'Canciones', name: 'artist_detail', params: { id: this.$route.params.artist } }
			}
		},
		methods: {
			setInternalPage() {
				this.$store.commit('inPage', this.pageData)
			},
			setArtistData() {
				const formData = new FormData();
				formData.append('h_key', this.habeatatKey);
				formData.append('type', 1);
				formData.append('artist', this.$route.params.artist);
				this.$http
					.post(this.api+'/h/lyrics-performance/', formData)
					.then(response => {
						var result = response.data
						this.artistLyrics = result
					})
			},
			getIntoLyric(tit) {
				this.$router.push({ name: 'lyrics', params: { artist: this.$route.params.artist, song: tit } })
			}
		}
	}
</script>

<style scoped>
	.table tbody tr { cursor: pointer; }
	.table tbody tr:hover { background: #F2F2F2; }
	.custom-height { overflow-y: scroll; }
	@media screen and (max-width: 1023px) {
		.box { border-radius: inherit; }
		.column { padding: inherit; }
	}
	@media screen and (min-width: 1024px) {
		.custom-height { height: 350px; }
		.custom-img { max-width: 90%; }		
	}
	@media screen and (min-width: 1200px) {
		.custom-height { height: 330px; }
		.custom-img { max-width: 70%; }
	}
	@media screen and (min-width: 1400px) {
		.custom-height { height: 485px; }
		.custom-img { max-width: 95%; }
	}
	@media screen and (min-width: 1600px) {
		.custom-img { max-width: 75%; }
		.custom-height { height: 450px; }
	}
	@media screen and (min-width: 1800px) {
		.custom-height { height: 635px; }
		.custom-img { max-width: 100%; }
	}
</style>