<template>
	<div>
		<div class="field" v-if="formdata.condition.includes(1)">
			<label class="label">Año</label>
			<div class="control">
				<input 
					class="input"
					type="text" 
					name="year"
					id="year" 
					maxlength="4"
					placeholder="Año en que se realizo el evento" 
					v-model="mform.year"
					v-validate="'numeric|required'">
			</div>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('year')">
						<i class="fas fa-asterisk"></i> {{ errors.first('year') }}
					</span>
				</transition>
		</div>
		<div class="field" v-if="formdata.condition.includes(2)">
			<label class="label is-uppercase">País:</label>
			<div class="control">
				<div class="select h-fullwidth">
					<CountrySelect 
						:country="mform.country" 
						@country-selected="setCountry"
						:api="$store.getters.GetApiPath" />
				</div>
			</div>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('country')">
						<i class="fas fa-asterisk"></i> Debe seleccionar un país.
					</span>
				</transition>
		</div>
		<div class="field" v-if="formdata.condition.includes(3)">
			<label class="label">Volumen</label>
			<div class="control">
				<input 
					class="input"
					type="text" 
					name="vol"
					id="vol" 
					placeholder="Numero de volumen del evento" 
					v-model="mform.vol"
					v-validate="'numeric|required'">
			</div>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('vol')">
						<i class="fas fa-asterisk"></i> {{ errors.first('vol') }}
					</span>
				</transition>
		</div>
		<div class="field">
			<label class="label">Título</label>
			<div class="control">
				<input 
					class="input"
					type="text" 
					name="title"
					id="title_id" 
					placeholder="Título del evento" 
					v-model="mform.title"
					v-validate="'required'">
			</div>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('title')">
						<i class="fas fa-asterisk"></i> {{ errors.first('title') }}
					</span>
				</transition>			
		</div>		
		<div class="field">
			<label class="label">Descripción</label>
			<div class="control">
				<textarea 
					class="textarea" 
					name="description"
					id="description" 
					placeholder="Descripcion del evento" 
					v-model="mform.description"
					v-validate="'required'"></textarea>
			</div>
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('description')">
						<i class="fas fa-asterisk"></i> {{ errors.first('description') }}
					</span>
				</transition>			
		</div>
	</div>
</template>

<script>
	import CountrySelect from '@/components/general/countries'
	export default {
		name: 'FormContentDetail',
		props: ['formdata'],
		inject: ['$validator'],
		data() {
			return {
				mform: {
					description: "",
					title: "",
					country: 'VE',
					year: '',
					vol: ''
				}
			}
		},
		created() {
			this.setMForm()
			this.emitForm()
		},
		components: {
			CountrySelect
		},
		watch: {
			formdata() {
				this.setMForm()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setCountry: function(val) {
				this.mform.country = val
			},
			setMForm() {
				if (typeof this.formdata != 'undefined') {
					if(typeof this.formdata.description != 'undefined') {
						this.mform.description = this.formdata.description
					}
					if(typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if(typeof this.formdata.country != 'undefined') {
						this.mform.country = this.formdata.country
					}
					if(typeof this.formdata.year != 'undefined') {
						this.mform.year = this.formdata.year
					}
					if(typeof this.formdata.vol != 'undefined' && this.formdata.condition.includes(3)) {
						this.mform.vol = this.formdata.vol
					}
				}
			}
		}
	}
</script>