<template>
	<div class="tile is-vertical">
		<div class="tile h-margin-center-lat">
			<button class="button is-uppercase" @click="formModalData={}, formModal = true">
				nuevo calendario |&nbsp;<i class="fas fa-plus-square"></i>
			</button>
		</div>
		<div class="tile is-parent">
			<table class="table h-fullwidth">
				<thead class="thead">
					<tr class="tr">
						<th class="th">Titulo</th>
						<th class="th">ID de Referencia</th>
						<th class="th">Cant. a mostrar</th>
						<th class="th">Acciones</th>
					</tr>
				</thead>
				<transition-group  name="list" tag="tbody">
					<tr v-for="cal in calendars" :key="cal.id" :class="{'tr':true, 'has-background-warning':cal.is_active}">
						<td>{{cal.title}}</td>
						<td>{{cal.ref}}</td>
						<td>{{cal.max_results}}</td>
						<td>
							<!-- Editar -->
							<a 	title="Editar"
							v-tooltip.bottom="'Modificar la Información básica de: '+cal.title" 
								@click="formModalData={id_ref:cal.ref, max_results:cal.max_results, id:cal.id, title:cal.title}, formModalUpdate=true">
								<i class="fas fa-edit"></i>
							</a> | 
							<!-- Activar | Desactivar -->
							<a 	:title="cal.is_active ? 'Desactivar':'Activar'"
							v-tooltip.bottom="(cal.is_active ? 'Desactivar':'Activar')+' '+cal.title+'.'"
								@click="changeStatus({id:cal.id, is_active:cal.is_active})">
								<i :class="['fas', cal.is_active ? 'fa-stop-circle':'fa-play-circle']"></i>
							</a> |
							<!-- Eliminar -->
							<a 	title="eliminar"
							v-tooltip.bottom="'Eliminar completamente a '+cal.title+' de Habeatat'"
								@click="confirmDestroy(cal.id)">
								<i class="fas fa-trash"></i>	
							</a>
						</td>
					</tr>
				</transition-group>
			</table>
		</div>
		<formModal 
			:show="formModal"
			:formType="2"
			:charging="modalLoading"
			@save-form="saveCalendar"
			@modal-close="formModal=false"
			v-if="formModal" />
		<formModal 
			:show="formModalUpdate"
			:formType="2"
			:data="formModalData"
			:charging="modalLoading"
			@save-form="updateCalendar"
			@modal-close="formModalUpdate=false"
			v-if="formModalUpdate" />
		<PicModal :show="loadingModal"/>
		<ConfirmModal 
			:show="confirmModal"
			v-if="confirmModal"
			:message="confirmText"
			@modal-close="confirmModal = false"
			@modal-submit="destroyCalendar()" />
	</div>
</template>

<script>
	import formModal from '@/components/modal/form'
	import PicModal from '@/components/modal/pic'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'AdminCalendar',
		props: ['token', 'api'],
		data() {
			return {
				calendars: [],
				formModal: false,
				formModalUpdate: false,
				formModalData: {},
				modalLoading: false,
				loadingModal: false,
				confirmModal: false,
				confirmText: '',
				selectedCalendarId: 0,
			}
		},
		created() {
			this.getCalendars()
		},
		components: {
			formModal,
			PicModal,
			ConfirmModal
		},
		methods: {
			getCalendars() {
				this.$http
					.get(this.api+'/h/calendar/', this.token)
					.then(response => {
						this.calendars = response.data
					})
			},
			saveCalendar(calendar) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('title', calendar.title);
				formData.append('ref', calendar.id_ref);
				formData.append('max_results', calendar.max_results);
				this.$http
					.post(this.api+'/h/calendar/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.formModal = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha creado el Calendario correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.calendars.unshift(response.data)
						})
					})
			},
			changeStatus(data) {
				this.loadingModal = true
				const formData = new FormData();
				formData.append('is_active', !data.is_active);
				this.$http
					.patch(this.api+"/h/calendar/"+data.id+'/', formData, this.token)
					.then(response => {
						const calendar = response.data.item
						for (var i in this.calendars) {
							if (this.calendars[i].id === parseInt(calendar.id)) {
								this.calendars[i].is_active = calendar.is_active
							} else {
								this.calendars[i].is_active = false
							}
						}
						this.loadingModal = false
					})
			},
			updateCalendar(data) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('title', data.title);
				formData.append('ref', data.id_ref);
				formData.append('max_results', data.max_results);
				this.$http
					.patch(this.api+'/h/calendar/'+data.id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.formModalUpdate = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha creado el Calendario correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							const calendar = response.data.item
							
							for (var i in this.calendars) {
								if (this.calendars[i].id === parseInt(calendar.id)) {
									this.calendars[i].title = calendar.title
									this.calendars[i].ref = calendar.ref
									this.calendars[i].max_results = calendar.max_results
									break;
								}
							}
						})
					})				
			},
			confirmDestroy(calendar_id) {
				this.selectedCalendarId = calendar_id
				this.confirmText = 'Esta seguro de eliminar este Calendario?'
				this.confirmModal = true
			},
			destroyCalendar() {
				this.loadingModal = true
				this.confirmModal = false
				this.$http
					.delete(this.api+'/h/calendar/'+this.selectedCalendarId+'/', this.token)
					.then(() => {
						this.loadingModal = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha eliminado el calendario correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							for (var i in this.calendars) {
								if (this.calendars[i].id === this.selectedCalendarId) {
									this.calendars.splice(i, 1);
									break;
								}
							}
						})
					})
			}
		}
	}
</script>