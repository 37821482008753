<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background" @click="charging ? loadMessage() : $emit('modal-close')"></div>
			<div class="modal-content">
				<div class="box">
					<form @submit.prevent="saveFormInfo">
						<FormFlyer @settingForm="getFormInfo" :formdata="data" v-if="formType == 1" />
						<FormCalendar @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 2" />
						<FormArtist @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 3" />
						<FormPlaylist @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 4" />
						<FormSocial @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 5" :mtitle="'Redes Sociales'" />
						<FormWeb @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 6" />
						<FormFlyerDetail @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 7" />
						<FormContent @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 8" />
						<FormContentDetail @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 9" />
						<FormMusic @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 10" />
						<FormDescription @settingForm="getFormInfo" :formdata="data" v-else-if="formType == 11" />
						<FormHomeBackground 
							v-else-if="formType == 12"
							@settingForm="getFormInfo" 
							:formdata="data" 
						/>
						<!--Update Home Background-->
						<FormHomeBackground 
							v-else-if="formType == 13"
							@settingForm="getFormInfo" 
							:formdata="data" 
						/>
						<!--Set Event Background-->
						<FormEventBackground 
							v-else-if="formType == 14"
							@settingForm="getFormInfo" 
							:formdata="data" 
						/>
						<!--Update Event Background-->
						<FormEventBackground 
							v-else-if="formType == 15"
							@settingForm="getFormInfo" 
							:formdata="data" 
						/>
						<!--Set Content Background-->
						<FormContentBackground 
							v-else-if="formType == 16"
							@settingForm="getFormInfo" 
							:formdata="data" 
						/>
						<!--Update Content Background-->
						<FormContentBackground 
							v-else-if="formType == 17"
							@settingForm="getFormInfo" 
							:formdata="data" 
						/>
						<FormCapsulaBackground 
						v-else-if="formType == 18"
						@settingForm="getFormInfo"
						:formdata="data"
						/>
						<FormCapsulaBackground 
						v-else-if="formType == 19"
						@settingForm="getFormInfo"
						:formdata="data"
						/>
						<br>
						<button type="submit"
						:class="{'button is-primary':true, 'is-loading':charging }">Guardar</button>
					</form>
				</div>
			</div>
			<button 
			class="modal-close is-large" 
			aria-label="close" 
			@click="$emit('modal-close')"
			:disabled="charging"></button>
		</div>	
	</transition>
</template>

<script>
	import FormFlyer from '@/components/admin/forms/formFlyer'
	import FormCalendar from '@/components/admin/forms/formCalendar'
	import FormArtist from '@/components/admin/forms/formArtist'
	import FormContent from '@/components/admin/forms/formContent'
	import FormContentDetail from '@/components/admin/forms/formContentDetail'
	import FormPlaylist from '@/components/admin/forms/formPlaylist'
	import FormSocial from '@/components/admin/forms/formSocial'
	import FormWeb from '@/components/admin/forms/formWeb'
	import FormFlyerDetail from '@/components/admin/forms/formFlyerDetail'
	import FormMusic from '@/components/admin/forms/formMusic'
	import FormDescription from '@/components/admin/forms/formDescription'

	/*New Admin Forms to handle Background*/
	import FormHomeBackground from '@/components/admin/forms/formHomeBackground'
	import FormEventBackground from '@/components/admin/forms/formEventBackground'
	import FormContentBackground from '@/components/admin/forms/formContentBackground'
	import FormCapsulaBackground from '@/components/admin/forms/formCapsulaBackground'

	export default {
		name: 'FormModal',
		props: ['show', 'formType', 'data', 'charging'],
		data() {
			return {
				formInfo:{}
			}
		},
		components: {
			FormFlyer,
			FormCalendar,
			FormArtist,
			FormPlaylist,
			FormSocial,
			FormWeb,
			FormFlyerDetail,
			FormContent,
			FormContentDetail,
			FormMusic,
			FormDescription,
			FormHomeBackground,
			FormEventBackground,
			FormContentBackground,
			FormCapsulaBackground,
		},
		methods: {
			getFormInfo(info) {
				this.formInfo = info
			},
			saveFormInfo() {
				this.$validator.validateAll().then(result => {
					if (result) {
						if (typeof this.data != 'undefined') {
							this.formInfo['id'] = this.data.id
						}
						this.$emit('save-form', this.formInfo)
					}
				})
			},
			loadMessage() {
				alert('Cargando... Porfavor espere.')
			}
		}
	}
</script>