<template>
	<div class="tile is-vertical">
		<h3 class="title is-4 has-text-centered is-marginless">{{item.plate_aka}}</h3>
		<h4 class="subtitle is-6 has-text-centered is-marginless" v-if="item.plate_label != ''">{{item.plate_label}}</h4>
		<hr>
		<SimpleVideo 
		:videoId="item.plate_url_yt"
		:title="'Video de '+item.plate_aka+', eventos HABEATAT.'"/>
		<!-- Buttons -->
		<div v-if="postuParams.section != 3">
			<h3 class="title is-5 is-marginless has-text-link" v-if="(postuParams.count >= postuParams.limit) && userType != 1">
				Bien! Usted ha alcanzado el limite de selección
			</h3>
			<div class="buttons has-addons is-centered is-marginless" v-else-if="!item.judges.includes(userId)">
				<span class="button is-danger" @click="modal.confirm=true" v-if="userType==1">
					<i class="fas fa-times has-text-white"> Eliminar</i>
				</span>
				<span :class="{'button h-back-gold has-text-white':true, 'is-loading':buttons.select}" @click="votePostulate()" v-if="userType==4">
					Seleccionar&nbsp;<i class="fas fa-thumbs-up has-text-white"></i>
				</span>
			</div>
			<h3 class="title is-5 is-marginless h-textcolor-gold" v-else>
				Usted ha seleccionado a este postulado!
			</h3>
		</div>
		<hr>
		<p><b>Información de contacto</b></p>
		<ul class="has-text-left h-separator-t2">
			<li><b>Teléfono:</b> {{item.plate_phone}}</li>
			<li><b>Email:</b> {{item.plate_email}}</li>
		</ul>
		<p class="h-separator-t2" v-if="item.plate_url_ig != '' || item.plate_url_fb != ''"><b>Redes sociales</b></p>
		<ul class="has-text-left h-separator-t2">
			<li v-if="item.plate_url_ig != ''">
				<a :href="item.plate_url_ig" target="_blank" rel="nofollow" class="h-textcolor-gold">Página de Instagram</a>
			</li>
			<li v-if="item.plate_url_fb != ''">
				<a :href="item.plate_url_fb" target="_blank" rel="nofollow" class="h-textcolor-gold">Página de Facebook</a>
			</li>
		</ul>
			<!-- Confirm -->
			<ConfirmModal 
			:show="modal.confirm"
			:message="'Esta seguro que quiere eliminar a este postulado?'"
			v-if="modal.confirm"
			@modal-close="modal.cofirm = false"
			@modal-submit="detelePostulate()" />

	</div>
</template>

<script>
	import SimpleVideo from '@/components/general/simpleVideo'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'AdminPostulatesTwo',
		props: ['token','api','item','userType','userId'],
		components: {
			SimpleVideo,
			ConfirmModal
		},
		data() {
			return {
				modal: { confirm: false },
				buttons: {
					select: false
				}
			}
		},
		computed: {
			postuParams () {
				return this.$store.getters.GetPostuParams
			}
		},
		methods: {
			votePostulate() {
				this.buttons.select = true
				const formData = new FormData()
				formData.append('plate', this.item.id)
				formData.append('judge', this.userId)
				formData.append('postu', this.item.postu)
				this.$http
				.post(this.api+'/postulations/votes/',formData, this.token)
				.then(() => {
					if (this.postuParams.section === 1) {
						if ((this.item.votes + 1) === this.postuParams.judges) {
							this.$store.commit('setPostuParams', {selected:(this.postuParams.selected)+1})
						}
						this.item.votes += 1
						this.item.judges.push(this.userId)
						this.$store.commit('setPostuParams', {count:(this.postuParams.count)+1})
					} else if (this.postuParams.section === 2) {
						if ((this.item.votes + 1) === this.postuParams.judges) {
							this.$store.commit('setPostuParams', {count:(this.postuParams.count)+1})
						}
						for (var i in this.$store.state.postulates) {
							if (this.$store.state.postulates[i].plate_id === parseInt(this.item.id)) {
								this.$store.state.postulates.splice(i, 1);
								break;
							}
						}
						this.$swal({
							'type': 'success',
							'title': 'Exito!',
							'text': 'Ha votado correctamente por un postulado pre-seleccionado, ahora esta persona tiene mas posibilidades de quedar seleccionado.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.$emit('rmv-section')
						})
						
					}
					this.buttons.select = false

				})
				.catch(error => {
					this.buttons.select = false
					var text
					var type
					var title
					const responseData = error?.response?.data
					if ("non_field_errors" in responseData)  {
						type = 'warning'
						title = 'Aviso'
						text = 'Usted ya ha votado por este postulado, intente con otro.'
					} else {
							type = 'error'
							title = "Ops, ha ocurrido un error."
							text = 'Ha ocurrido un error al intentar votar por un postulado, recargue la pagina e intente de nuevo, si el error persiste contacte con el administrador.'
					}
						this.$swal({
							'type': type,
							'title': title,
							'text': text,
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})
				})
			},
			detelePostulate() {
				this.modal.confirm = false
				this.$store.commit('loader', 'y')
				this.$http
					.delete(this.api+'/postulations/postulates/'+this.item.id+'/', this.token)
					.then(() => {
						this.modal.loading = false
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'El postulado ha sido eliminado.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.$store.commit('destroyPostulates', this.item.id)
							this.$store.commit('loader', 'n')
							this.$emit('rmv-section')
						})
					})
					.catch(() => {
						this.$store.commit('loader', 'n')
						this.$swal({
							type: "error",
							title: "Ops, ha ocurrido un error!",
							text: 'Error al intentar eliminar al postulado del servidor.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			}
		}
	}
</script>