<template>
    <div class="holds-the-iframe">
        <iframe src="https://habeatat.b9ticketing.com/" loading="lazy" name="myIFrame" class="frame-tickets" style="border: none #000000;"></iframe>
    </div>
</template>

<script>
export default {
    name: "TicketsPage"
}
</script>

<style scoped>
.holds-the-iframe {
    background:url("/img/loading-icon.gif") center center no-repeat;
    background-color: white;
  }
.frame-tickets {
    width: 100%;
    height: 520px;
}

@media screen and (min-width: 1400px) {
	.frame-tickets {
		height: 620px;
	}
}
@media screen and (min-width: 1800px) {
	.frame-tickets {
		height: 720px;
	}
}
</style>