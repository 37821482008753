<template>
	<div>
		<audio controls
		class="h-fullwidth" 
		@canplaythrough="mform.duration=$event.target.duration"
		ref="beat">
			<source :src="formdata.src" type="audio/mpeg">
		</audio>
		<div class="field">
			<div class="control">
				<label class="label">TITLE</label>
				<input 
				type="text"  
				class="input" 
				placeholder="Beat Title" 
				id="title" 
				name="title" 
				v-validate="'required'" 
				v-model="mform.title">

				<transition name="expand" mode="out-in">
					<span class="tag is-rounded" v-show="errors.has('title')">{{ errors.first('title') }}</span>
				</transition>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<label class="label">PRODUCER</label>
				<input 
				type="text"  
				class="input" 
				placeholder="Beat Producer" 
				id="producer" 
				name="producer" 
				v-validate="'required'" 
				v-model="mform.producer">

				<transition name="expand" mode="out-in">
					<span class="tag is-rounded" v-show="errors.has('producer')">{{ errors.first('producer') }}</span>
				</transition>
			</div>
		</div>
		<label class="label">TIME</label>
		<div class="field is-horizontal">
			<div class="field-label is-normal">
				<label class="label">MINUTES:</label>
			</div>
			<div class="field-body">
				<div class="control">
					<input 
					type="text"
					class="input" 
					id="min" 
					name="min" 
					v-validate="'required|numeric'" 
					v-model="mform.time.min">

					<transition name="expand" mode="out-in">
						<span class="tag is-rounded" v-show="errors.has('min')">{{ errors.first('min') }}</span>
					</transition>		
				</div>
			</div>
			<div class="field-label is-normal">
				<label class="label">SECONDS:</label>
			</div>
			<div class="field-body">
				<div class="control">

					<input 
					type="text"
					class="input"
					id="sec"
					name="sec"
					v-validate="'required|numeric'" 
					v-model="mform.time.sec">

					<transition name="expand" mode="out-in">
						<span class="tag is-rounded" v-show="errors.has('sec')">{{ errors.first('sec') }}</span>
					</transition>	
				</div>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<label class="label">GENRE</label>
				<input 
				type="text" 
				name="genre"
				class="input" 
				v-model="mform.genre"
				v-validate="'required'">

				<transition name="expand" mode="out-in">
					<span class="tag is-rounded" v-show="errors.has('genre')">{{ errors.first('genre') }}</span>
				</transition>
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label is-normal">
				<label class="label">BPM:</label>
			</div>
			<div class="field-body">
				<div class="control">
					<input 
					type="text"
					class="input" 
					id="bpm" 
					name="bpm" 
					v-validate="'required|numeric'" 
					v-model="mform.bpm">

					<transition name="expand" mode="out-in">
						<span class="tag is-rounded" v-show="errors.has('bpm')">{{ errors.first('bpm') }}</span>
					</transition>		
				</div>
			</div>
			<div class="field-label is-normal">
				<label class="label">PRICE:</label>
			</div>
			<div class="field-body">
				<div class="control">

					<input 
					type="text"
					class="input"
					id="price"
					name="price"
					v-validate="'required|numeric'" 
					v-model="mform.price">

					<transition name="expand" mode="out-in">
						<span class="tag is-rounded" v-show="errors.has('price')">{{ errors.first('price') }}</span>
					</transition>	
				</div>
			</div>
			<div class="field-label is-normal">
				<label class="label is-uppercase">priority:</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control select">
						<select name="priority" id="priority" v-model="mform.priority">
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9</option>
							<option value="10">10</option>
						</select>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'formMusic',
		props: ['formdata'],
		inject: ['$validator'],
		data() {
			return {
				mform: {
					title: "",
					duration: "",
					genre: "",
					time: {
						min:'',
						sec:''
					},
					price: '',
					bpm: "",
					producer: '',
					priority: 10
				}
			}
		},
		created() {
			this.setInfo()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.$refs.beat.load()
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setInfo() {
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if (typeof this.formdata.duration != 'undefined') {
						this.mform.duration = this.formdata.duration
					}
					if (typeof this.formdata.time != 'undefined') {
						var time = this.formdata.time.split(':')
						this.mform.time.min = time[0]
						this.mform.time.sec = time[1]
					}
					if (typeof this.formdata.bpm != 'undefined') {
						this.mform.bpm = this.formdata.bpm
					}
					if (typeof this.formdata.genre != 'undefined') {
						this.mform.genre = this.formdata.genre
					}
					if (typeof this.formdata.price != 'undefined') {
						this.mform.price = this.formdata.price
					}
					if (typeof this.formdata.priority != 'undefined') {
						this.mform.priority = this.formdata.priority
					}
					if (typeof this.formdata.producer != 'undefined') {
						this.mform.producer = this.formdata.producer
					}
				}		
			}
		}
	}
</script>

<style scoped>
	.field-label {
		margin-right: inherit;
		margin-left: 1.5rem;
	}
	audio {
		height: 50px !important;
	}
</style>