<template>
	<div class="tile is-vertical">
		<FormMusic :formdata="item" @settingForm="setBeatData" />
		<div class="tile is-parent">
			<button :class="{'button is-success h-margin-center':true, 'is-loading':isLoading}" @click="updateBeat()">Guardar</button>
		</div>
	</div>
</template>

<script>
	import FormMusic from '@/components/admin/forms/formMusic'
	export default {
		name: 'AdminMusic2Section',
		props: ['token','api','item','priorityOrder'],
		components: {
			FormMusic
		},
		data() {
			return {
				beatData: {},
				isLoading: false
			}
		},
		methods: {
			setBeatData(data) {
				this.beatData = data
			},
			updateBeat() {
				this.$validator.validateAll().then(result => {
					if (result) {
						this.isLoading = true
						const formData = new FormData();
						formData.append('priority', this.beatData.priority);
						formData.append('producer', this.beatData.producer);
						formData.append('title', this.beatData.title);
						formData.append('genre', this.beatData.genre);
						formData.append('bpm', this.beatData.bpm);
						formData.append('time', this.beatData.time.min+':'+this.beatData.time.sec);
						formData.append('price', this.beatData.price);
						this.$http
							.patch(this.api+'/h/music/'+this.item.id+'/', formData, this.token)
							.then(response => {
								this.$swal({
									type: "success",
									title: "Success!",
									text: 'A Beat has been updated.',
									confirmButtonClass: "button",
									buttonsStyling: false,
									background: "white"
								}).then(() => {
									this.isLoading = false
									this.$store.commit('updateMusic', response.data)
									this.priorityOrder(this.$store.state.songs)
								}); 
							})
					}
				})
			}
		}
	}
</script>