<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':show}" v-if="show">
			<div class="modal-background" @click="$emit('close-calendar')"></div>
			<div class="modal-content box">
				<h-calendar />
			</div>
			<button @click="$emit('close-calendar')" class="modal-close is-large" aria-label="close"></button>
		</div>
	</transition>
</template>

<script>
	import Calendar from '@/components/general/calendar'
	export default {
		props: ['show'],
		components: {
			'h-calendar':Calendar
		}
	}
</script>