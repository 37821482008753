<template>
	<div class="tile is-marginless box has-background-grey-darker repro-player" :style="cssRepro">
		<div class="columns is-mobile is-marginless">
			<!-- Play Button -->
			<div :class="['column repro-circle-column', isMobile ? 'is-3':'is-2']">
				<div @click="play()"
				class="h-border-midcircle has-text-centered repro-play-circle">
					<div class="repro-play-icon"> <!-- v-if="!playing" -->
						<i class="icon-repro-play has-text-white repro-play-icon-size"></i>
					</div>
					<!--
					<div class="repro-pause-icon" v-else>
						<i class="fas fa-pause has-text-white repro-play-icon-size"></i>
					</div>
					-->
				</div>
			</div>
			<!-- Progres Bar and Descriptions for Mobile -->
			<div class="column is-hidden-tablet is-paddingless">
				<div class="tile is-vertical">
					<div class="columns is-mobile is-vcentered is-marginless mobile-content-box">
						<div class="column is-2 is-paddingless repro-text-controls has-text-left arrow-box">
							<a @click="$emit('get-prev')"><i class="icon-repro-prev has-text-grey is-size-5"></i></a>
						</div>
						<div class="column is-paddingless">
							<div class="tile is-vertical">
								<h1 class="has-text-centered has-text-weight-bold has-text-white repro-text-producer repro-font-bold">
									{{song.producer}}
								</h1>
								<p class="has-text-centered has-text-white repro-text-title repro-font-regular">
									{{song.title}}
								</p>
							</div>
						</div>
						<div class="column is-2 is-paddingless repro-text-controls has-text-right arrow-box">
							<a @click="$emit('get-next')"><i class="icon-repro-next is-size-5 has-text-grey"></i></a>
						</div>
					</div>
					<div class="columns is-marginless is-vcentered is-mobile">
						<div class="column is-paddingless">
							<progress
							:value="progress.value" 
							:max="progress.max"
							@click="setTime"></progress>	
						</div>
<!-- 						<div class="column is-3 is-paddingless">
							<p class="has-text-right repro-text-controls2">
								<a @click="setLoop()"><i :class="['fas','fa-retweet', retweet ? 'has-text-white':'has-text-grey']"></i></a>&nbsp;
								<a @click="setStop()"><i class="fas fa-stop has-text-grey"></i></a>&nbsp;
								<a @click="$emit('set-random')"><i :class="['fas','fa-random', random ? 'has-text-white':'has-text-grey']"></i></a>
							</p>
						</div> -->
					</div>
				</div>
			</div>
			<!-- Progress Bar and Descriptions for Desktop-->
			<div class="column is-marginless is-paddingless is-hidden-mobile">
				<div class="columns is-mobile is-marginless">
					<div class="column is-paddingless">
						<div class="has-text-white is-uppercase is-marginless">
							<h1 class="has-text-justified is-marginless has-text-weight-bold has-text-white repro-text-producer repro-font-bold">
								{{song.producer}}
							</h1>
							<p class="has-text-justified has-text-white repro-text-title repro-font-regular">
								{{song.title}}
							</p>
						</div>
					</div>
					<div class="column is-3 is-hidden-mobile">
						<figure>
							<img src="/img/therepro/repro_logo_1.png">
						</figure>
					</div>
				</div>
				<div class="h-custom-separator"></div>
				<div class="columns is-marginless is-paddingless is-vcentered">
					<div class="column is-paddingless">
						<p class="has-text-justified repro-text-controls">
							<a @click="$emit('get-prev')"><i class="icon-repro-prev has-text-grey"></i></a>&nbsp;&nbsp;
							<a @click="setStop()"><i class="icon-repro-stop has-text-grey"></i></a>&nbsp;&nbsp;
							<a @click="$emit('get-next')"><i class="icon-repro-next has-text-grey"></i></a>&nbsp;&nbsp;
							<a @click="$emit('set-random')"><i :class="['icon-repro-shuffle', random ? 'has-text-white':'has-text-grey']"></i></a>&nbsp;&nbsp;
							<a @click="setLoop()"><i :class="['icon-repro-loop', retweet ? 'has-text-white':'has-text-grey']"></i></a>
						</p>
					</div>
					<div class="column is-paddingless is-8">
						<p class="has-text-right repro-font-italic has-text-grey-light repro-text-description is-italic">
							{{song.genre}} // 
							{{song.bpm}}BPM //
							{{song.time}}MIN //
							{{song.price}}$
						</p>
					</div>
				</div>
				<progress
				:value="progress.value" 
				:max="progress.max"
				@click="setTime"></progress>
								<div class="h-custom-separator-2"></div>
			</div>
			<!-- Volume Bar -->
			<div class="column is-1 custom-volume-column">
				<div :class="{'dropdown is-hidden-tablet is-right':true, 'is-active':mobileMenu}">
					<div class="dropdown-trigger">
						<a @click="mobileMenu=!mobileMenu"
						class="custom-ellipsis" 
						aria-haspopup="true" 
						aria-controls="repro-menu">
							<i class="fas fa-ellipsis-h has-text-white"></i>
						</a>
					</div>
					<div class="dropdown-menu" id="repro-menu" role="menu">
						<div class="dropdown-content">
							<a @click="$emit('set-random')" :class="{'dropdown-item is-uppercase':true, 'is-active':random}">
								<i class="icon-repro-shuffle"></i> shuffle
							</a>
							<a @click="setLoop()" :class="{'dropdown-item is-uppercase':true, 'is-active':retweet}">
								<i class="icon-repro-loop"></i> loop	
							</a>
						</div>
					</div>
				</div>

				<div class="tile is-vertical is-hidden-mobile">
					<div v-for="x in 10" :key="x"
					@click="volume=10-x+1, setVolume()" 
					:class="['repro-volume-bar', x > 10-volume ? 'repro-back-red':'has-background-white']"></div>
				</div>
				<!-- <div class="repro-app-list"></div> -->
			</div>
		</div>
		<audio ref="reproAudio" 
		:volume="volume/10"
		preload="auto"
		@timeupdate="timeBar"
		@onplay="this.autoplay='autoplay'"
		@onerror="setStop()" >
			<source :src="song.src" type="audio/mpeg">
		</audio>
	</div>
</template>

<script>
	export default {
		name: 'ReproPlayer',
		props: ['song', 'random'],
		data() {
			return {
				playing: false,
				volume: 8,
				mobileMenu:false,
				retweet: false,
				progress: {
					max: 100,
					value: 0
				}
			}
		},
		mounted() {
			this.setVolume()
		},
		watch: {
			song: function() {
				var promise = this.$refs.reproAudio.play();
				if ( promise !== undefined ) {
					promise.then(() => {
						this.$refs.reproAudio.load()
						if ( this.playing ) {
							this.progress.max = this.song.duration
							this.$refs.reproAudio.play()
						} else {
							this.play()
						}
					}).catch(() => {
						this.setStop()
					});
				}
				
			}
		},
		computed: {
			isMobile() {
				return this.$store.getters.GetMobileBrowser
			},
			cssRepro() {
				return { 
					'--circle-button-color': this.playing ? '#ff6861':'#c4c4c4',
					'--circle-button-animation': this.playing ? 'none':'pulse 2s infinite'
				}
			}
		},
		methods: {
			play() {
				if (typeof this.song.src != 'undefined' && this.song.src != '' ) {
					if(this.playing) {
						this.$refs.reproAudio.pause()
					} else {
						this.progress.max = this.song.duration
						this.$refs.reproAudio.play()
					}
					this.playing = !this.playing
				}
			},
			setVolume() {
				this.$refs.reproAudio.volume = this.volume/10
			},
			setTime(e) {
				var percent = e.offsetX / e.path[0].offsetWidth;
				this.$refs.reproAudio.currentTime = percent * this.$refs.reproAudio.duration
			},
			timeBar() {
				this.progress.value = this.$refs.reproAudio.currentTime
				if (this.progress.value >= this.progress.max && !this.retweet) {
					this.setStop()
					this.$emit('ended')
				}
			},
			resetProgress() {
				this.$refs.reproAudio.currentTime = 0
				this.progress.value = 0
			},
			setStop() {
				if( this. playing ) {
					this.play()
					this.resetProgress()
				}
			},
			setLoop() {
				this.retweet = !this.retweet
				this.$refs.reproAudio.loop = !this.$refs.reproAudio.loop
			}

		}
	}
</script>

<style scoped>


progress {
  color: hsl(0, 0%, 71%);
  font-size: 12px;
  width: 100%;
  height: .5rem;
  border: none;
  margin-right: 10px;
  background: white;
  border-radius: 9px;
  vertical-align: middle;
}
progress::-moz-progress-bar { 
  color:hsl(0, 0%, 71%);
  background:white; 
}

progress[value]::-webkit-progress-bar {
  background-color: white;
  border-radius: 9px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-color: hsl(0, 0%, 71%);
  border-radius: 9px;
}
.mobile-content-box {
	min-height: 50px;
}
.custom-volume-column {
	position: relative;
}
.custom-ellipsis {
	margin-left: auto;
	position: absolute;
	top: -10px;

}
	.repro-player { 
		border-top-left-radius: 12px; 
		border-top-right-radius: 12px;
		border-bottom-right-radius: inherit;
		border-bottom-left-radius: inherit;
		padding-bottom: 0;
		border-bottom-width: 1px;
		border-bottom-style: ridge; 
		border-bottom-color: white;
	}
	.repro-circle-column {
		padding-left: inherit !important;
		padding-top: inherit !important;
	}
	.repro-volume-bar {
		border-radius: 8px;
		margin-top: 25%;
		border-style: inherit;
		height: 6px;
		cursor: pointer;
		margin-left: auto;
	}
	.repro-play-circle {
		background: var(--circle-button-color);
		cursor: pointer;
		transition: background .6s;
		animation: var(--circle-button-animation);
		position: relative;
		margin-left: 12%; 
		margin-right: 12%;
		margin-bottom: 14%;
		margin-top: 8%;
		height: 78%; 
	}
/*	.repro-play-circle:hover {
		animation: none;
	}*/
	.repro-play-circle, 
	.repro-play-circle:focus, 
	.repro-play-circle:active,
	.repro-play-circle:linked {
		outline: none;
	}
	.repro-play-circle:active {
		animation: pulse-expand .2s;
	}
	.repro-play-icon, .repro-pause-icon {
		position: absolute;
	}
	/*MOBILE*/
	@media screen and (max-width: 900px) {
		.repro-player {
			border-radius: inherit;
		}
	}
	@media screen and (min-width: 300px) and (max-width: 900px){
		.repro-text-producer {
			margin-bottom: 5px;
		}
		.repro-play-circle {
			margin: 0;
		}
		progress {
			height: 2px;
		}
		.custom-volume-column {
			padding: 0;
		}
	}
	@media screen and (min-width: 300px){
		.arrow-box { margin-top: 6px; }
		.repro-play-circle { height: 60px; }
		.repro-play-icon-size {font-size: 30px;}
		.repro-play-icon {top: 14%; left: 29%;}
		.repro-pause-icon {top: 25%; left: 27%;}
		.repro-text-producer,.repro-text-controls { font-size: 22px; }
		.repro-text-title, .repro-text-controls2 { font-size: 11px; }
		.repro-volume-bar {
			height: 3px;
			width: 10px;
			margin-top: 2px;
		}
	}
	@media screen and (min-width: 350px){
		.arrow-box { margin-top: 8px; }
		.repro-play-circle { height: 65px; }
		.repro-play-icon-size {font-size: 34px;}
		.repro-play-icon {top: 13%; left: 31%;} 
		.repro-pause-icon {top: 23%; left: 28.5%;}
		.repro-text-producer,.repro-text-controls { font-size: 26px; }
		.repro-text-title, .repro-text-controls2 { font-size: 13px; }
		.repro-volume-bar {
			width: 11px;
			margin-top: 3px;
		}
	}
	@media screen and (min-width: 400px){
		.arrow-box { margin-top: 11px; }
		.repro-play-circle { height: 75px; }
		.repro-text-producer { font-size: 26px; }
		.repro-play-icon-size {font-size: 38px;}
		.repro-play-icon {top: 12.5%; left: 32%;} 
		.repro-pause-icon {top: 26%; left: 30%;}
		.repro-text-producer,.repro-text-controls { font-size: 30px; }
		.repro-text-title, .repro-text-controls2 { font-size: 15px; }
		.repro-volume-bar {
			width: 12px;
		}
	}
	/*TABLET*/
	@media screen and (min-width: 750px){
		.repro-play-circle { height: 100px; }
		.repro-circle-column {padding: 0.75rem;}
		.repro-play-icon-size {font-size: 56px;}
		.h-custom-separator { height: 20px; }
		.h-custom-separator-2 { height: 3px; }
		.repro-play-icon {top: 8.6%; left: 30%;} 
		.repro-pause-icon {top: 23%; left: 28%;}
		.repro-text-producer,.repro-text-controls { font-size: 42px; }
		.repro-text-title, .repro-text-controls2 { font-size: 21px; }
		.repro-volume-bar {
			height: 5px;
			width: 16px;
			margin-top: 3px;
		}
	}
	@media only screen and (min-width: 1000px) {
		.repro-play-circle { height: 78%; }
		.h-custom-separator { height: 10px; }
		.h-custom-separator-2 { height: 3px; }
		.repro-play-icon-size { font-size: 38px; }
		.repro-play-icon { top: 15%; left: 32%; } 
		.repro-pause-icon { top: 26.5%; left: 30%; }
		.repro-text-producer { font-size: 38px; }
		.repro-text-title { font-size: 18px; }
		.repro-text-controls{ font-size: 12px; }
		.repro-text-description { font-size: 14px; }
		.repro-volume-bar {
			height: 5px;
			width: 16px;
			margin-top: 7%;
		}
	}
	/*DESKTOP*/
	@media only screen and (min-width: 1200px) {
		.h-custom-separator { height: 25px; }
		.h-custom-separator-2 { height: 3px; }
		.repro-play-icon-size { font-size: 52px; }
		.repro-play-icon { top: 13.5%; left: 31%; }
		.repro-pause-icon { top: 25%; left: 28%; }
		.repro-text-producer { font-size: 52px; }
		.repro-text-title { font-size: 21px; }
		.repro-text-controls { font-size: 14px; } 
		.repro-text-description { font-size: 16px; }
		.repro-volume-bar {
			height: 6px;
			width: 20px;
			margin-top: 8%;
		}
	}
	@media only screen and (min-width: 1400px) {
		.h-custom-separator { height: 25px; }
		.h-custom-separator-2 { height: 3px; }
		.repro-play-icon-size { font-size: 62px; }
		.repro-play-icon { top: 11.25%; left: 30%; }
		.repro-pause-icon { top: 24%; left: 27%; }
		.repro-text-producer { font-size: 62px; }
		.repro-text-title { font-size: 24px; }
		.repro-text-controls { font-size: 16px; } 
		.repro-text-description { font-size: 18px; }
		.repro-volume-bar {
			height: 7px;
			width: 24px;
		}
	}
	@media only screen and (min-width: 1600px) {
		.h-custom-separator { height: 37px; }
		.h-custom-separator-2 { height: 3px; }
		.repro-play-icon-size { font-size: 72px; }
		.repro-play-icon {top: 13%; left: 30%;} 
		.repro-pause-icon {top: 23.5%; left: 27%;}
		.repro-text-producer { font-size: 72px; }
		.repro-text-title { font-size: 27px; }
		.repro-text-controls { font-size: 18px; }
		.repro-text-description { font-size: 20px; }
		.repro-volume-bar {
			height: 8px;
			width: 28px;
			margin-top: 9%;
		}
	}
	@media only screen and (min-width: 1800px) {
		.h-custom-separator { height: 40px; }
		.h-custom-separator-2 { height: 5px; }
		.repro-play-icon-size {font-size: 86px;}
		.repro-play-icon {top: 11%; left: 30%;}
		.repro-pause-icon {top: 23.5%; left: 27%;}
		.repro-text-producer { font-size: 84px; }
		.repro-text-title { font-size: 32px; }
		.repro-text-controls { font-size: 21px; } 
		.repro-text-description { font-size: 24px; }
		.repro-volume-bar {
			width: 32px;
		}
	}

.dropdown-item, .dropdown-item > i {
	transition: .6s;
}

.dropdown-item.is-active {
	background: #ff6861;
}
.dropdown-item.is-active, .dropdown-item.is-active > i {
	color: white !important;
}

</style>