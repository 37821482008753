const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";

const mutations = {
  
  [LOGIN] (state) {
    state.pending = true;
  },
  [LOGIN_SUCCESS] (state) {
    state.isLoggedIn  = true;
    state.pending     = false; 
  },
  [LOGOUT](state) {
    state.isLoggedIn = false;
  },

  inPage (state, xpage) {
    state.internalPage['into'] = true
    if ('title' in xpage) state.internalPage['page'] = xpage.title
    if ('name' in xpage) state.internalPage['pageName'] = xpage.name
    if ('container' in xpage) state.container = xpage.container
    if ('params' in xpage) state.internalPage['params'] = xpage.params 
  },
  outPage (state, xpage) {
    state.internalPage['into'] = false
    if ('title' in xpage) state.internalPage['page'] = xpage.title
    if ('name' in xpage) state.internalPage['pageName'] = xpage.name
    if ('container' in xpage) state.container = xpage.container
  },
  restorePicBox (state, picbox) {
    for (var x in state[picbox]) {
      state[picbox][x].init = false
    }
  },
  makeBanners (state, newBanners) {
    state.banners = newBanners
  },
  changeContainer (state, value) {
    state.container = value
  },
  // Music Mutations Functions
  makeMusic (state, newMusic) {
    for (var x in newMusic) {
      state.songs.push(newMusic[x])
    }
  },
  addMusic (state, newMusic) {
    state.songs.push(newMusic)
  },
  changeModalStatus (state, params) {
    if ('modal' in params) {
      state.modal[params.modal].status = params.status
      if('info' in params) state.modal[params.modal].info = params.info
    }
  },
  updateMusic (state, newMusic) {
    for (var s in state.songs) {
      if (newMusic.id === state.songs[s].id) {
        state.songs[s]['priority'] = newMusic.priority
        state.songs[s]['producer'] = newMusic.producer
        state.songs[s]['title'] = newMusic.title
        state.songs[s]['genre'] = newMusic.genre
        state.songs[s]['bpm'] = newMusic.bpm
        state.songs[s]['time'] = newMusic.time
        state.songs[s]['price'] = newMusic.price
        state.songs[s]['is_active'] = newMusic.is_active
        break
      }
    }
  },
  destroyMusic(state, idMusic) {
    for (var i in state.songs) {
      if (state.songs[i].id === parseInt(idMusic)) {
        state.songs.splice(i, 1);
        break;
      }
    }
  },
  resetMusic(state) {
    state.songs = []
  },
  // Music Mutations Functions End
  changeDashMenu(state, newMenu) {
    state.dashMenu = newMenu
  },
  makeCalendar (state, newCalendar) {
    state.calendar = newCalendar
  },
  makeSocial (state, newSocial) {
    state.social = newSocial
  },
  makeArtist (state, newArtist) {
    state.artist = newArtist
  },
  makeEvent (state, newEvent) {
    state.event = newEvent
  },
  makeContent (state, newContent) {
    state.content = newContent
  },
  makeCapsula (state, newCapsula) {
    state.capsula = newCapsula
  },
  makeKeys (state, newKeys) {
    state.pagesKeys = newKeys
  },
  makeCountries (state, countries) {
    state.countries = countries
  },
  makePostulates (state, newPostulates) {
    state.postulates = newPostulates
  },
  setPostuParams (state, newParams) {
    if("limit" in newParams) {
      state.postuParams.limit = newParams.limit
    }
    if("count" in newParams) {
      state.postuParams.count = newParams.count
    }
    if("judges" in newParams) {
      state.postuParams.judges = newParams.judges
    }
    if("selected" in newParams) {
      state.postuParams.selected = newParams.selected
    }
    if("section" in newParams) {
      state.postuParams.section = newParams.section
    }
    if("phase" in newParams) {
      state.postuParams.phase = newParams.phase
    }
  },
  destroyPostulates (state, postulate_id) {
    for (var i in state.postulates) {
      if (state.postulates[i].id === parseInt(postulate_id)) {
        state.postulates.splice(i, 1);
        break;
      }
    } 
  },
  loader(state, val) {
    if( val === 'y'){
      state.loading = true
    } else{
      state.loading = false
    }
  },
  changeEventDetail(state, newDetail) {
    for (var i in state.event) {
      if ( state.event[i].name_id === newDetail.name_id ) {
        state.event[i].channel = newDetail.channel
        state.event[i].playlist = newDetail.playlist
        state.event[i].description = newDetail.description
        state.event[i].album = newDetail.album
        state.event[i].year = newDetail.year
        state.event[i].country = newDetail.country
        state.event[i].vol = newDetail.vol
        if (typeof newDetail.related_years != 'undefined') {
          for (var x in state.event[i].filters.countries) {
            if ( state.event[i].filters.countries[x].code === newDetail.country_code ) {
              state.event[i].filters.countries[x].related_years = newDetail.related_years
              break
            }
          }
        }
        break
      }

    }
  },
  changeContentDetail(state, newDetail) {
    for (var i in state.content) {
      if ( state.content[i].name_id === newDetail.name_id ) {
        state.content[i].channel = newDetail.channel
        state.content[i].playlist = newDetail.playlist
        state.content[i].description = newDetail.description
        state.content[i].album = newDetail.album
        state.content[i].year = newDetail.year
        state.content[i].country = newDetail.country
        state.content[i].vol = newDetail.vol
        if (typeof newDetail.related_years != 'undefined') {
          for (var x in state.content[i].filters.countries) {
            if ( state.content[i].filters.countries[x].code === newDetail.country_code ) {
              state.content[i].filters.countries[x].related_years = newDetail.related_years
              break
            }
          }
        }
        break
      }

    }
  }
}

export default mutations