<template>
  <section 
    @mouseover="stopCarousel"
    @mouseleave="changeDisplayedData"
    v-if="slidePosition === positionIndexArray" 
    class="main-container">
    <div class="carousel" id="carousel">
      <div v-for="(event,index) in events"
        :key="event.id"
        :class="['carousel-item',
          index === slide ? 'visible' : 'hidden'
        ]">
        <img
          :class="['imgBackground',
            event.background === '' ? 'has-background-black' : null]"
          loading="lazy"
          :src="event.background != '' ? event.background : '/img/black-bg.png'"
          :alt="event.name"
        />
        <div class="carousel-artist p-6">
          <div class="carousel-artist_info is-flex is-flex-direction-column is-justify-content-start">
            <h1 class="title is-size-1 is-size-3-mobile is-uppercase has-text-white has-text-left px-5 py-4 opensans">{{event.name}}</h1>
            <div
              class="
                    has-text-white
                    artist_bio
                    px-5
                  "
            >
              <p class="mb-3 is-size-5-widescreen has-text-white subtitle is-6 has-text-left opensans p-description">
                {{event.portrait.description.substring(0,314) + '...'}}
              </p>
            </div>
            <router-link 
                :to="{
                  name: 'event_portrait',
                  params: {
                    id: event.name_id
                  }
                }"
                class="button 
                m-5
                is-size-6
                has-text-white-bis
                has-background-grey-darker
                button-width"
              >Ver más
            </router-link>
            <div class="artist_social mt-6 px-4">
<!--               <Social class="is-flex is-justify-content-start" :social="artist.social"/> -->
            </div>
          </div>
          <div class="carousel-artist_videos">
            <div class="main_video">
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="artist_social_desktop">
      <Social
        v-for="(event,index) in events"
        :key="event.id"
        :class="[index === slide ? null : 'is-hidden',
          'is-flex is-justify-content-start social-width-desktop is-hidden-touch']" 
        :social="event.social"
        :color="color" 
        />
    </div>
    <div class="carousel-dots_list">
      <span
        v-for="(event,index) in events"
        :key="event.id"
        @click="changeSlide(index)" 
        :class="['mx-2','p-1',index === slide ? 'has-background-white' : 'has-background-grey']">
      </span>
    </div>
  </section>
</template>

<script>
  import Social from '@/components/general/social'
export default {
  name: "CarouselEvents",
  props: ['slidePosition'],
  data(){
    return {
      slide: 0,
      play: false,
      positionIndexArray: 1,
    }
  },
  components:{Social},
  created(){
    this.changeDisplayedData()
  },
  methods:{
    changeDisplayedData(){
      return this.play = setInterval(()=>this.playCarousel(),4000)
    },
    stopCarousel(){
      return clearInterval(this.play)
    },
    changeSlide(index){
      this.slide = index
    },
    playCarousel(){
      if (this.slide === this.slideLength -1){
        this.slide= 0
      }else{
        this.slide++
      }
    }
  },
  computed:{
    //Gets events information
    events(){
      return this.$store.state.event
    },
    slideLength(){
      return this.events.length
    },
    startCarousel(){
      /*Starts carousel, if user changes section then it should
      stop where it left
      */
      return this.slidePosition === this.positionIndexArray ? this.changeDisplayedData() : this.stopCarousel()
    }
  }
};
</script>

<style scoped>

.carousel {
  overflow: hidden;
  position: relative;
}
.carousel-item {
  position:relative;

}
.carousel-item,
.carousel-item_hidden {
  display: none;
  animation: fadeVisibility 2s;
}

.carousel-item .imgBackground {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;

  /*For some reason, I had to use translateX 
  otherwise the background image would only
  be shown at the container's center left side*/

  transform: translateX(-50%);
}

.carousel-item.visible {
  display: block;
  background-size: cover;
}

/* Carousel Information */
.carousel-artist {
  position: relative;
  display: grid;
  grid-template: repeat(auto-fit, minmax(430px, 1fr)) / repeat(
      auto-fit,
      minmax(375px, 1fr)
    );
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.carousel-artist_videos{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

.button-width{
  width: 100px;
}
/*TExt modifiers*/
  p.has-text-left{
    line-height: 1.5;
  }
  h1.title.is-1{
    font-size: 5rem;
  }
@keyframes fadeVisibility {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>