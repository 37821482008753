<template>
<transition name="fade">
	<div :class="{'modal':true, 'is-active':show}">
		<div class="modal-background" @click="$emit('modal-close')"></div>
		<div class="modal-card">
			<header class="modal-card-head">
			<p class="modal-card-title">Imagenes de {{item.name}}</p>
			<button class="delete" aria-label="close" @click="$emit('modal-close')"></button>
		</header>
		<section class="modal-card-body">
			<div class="tile has-text-centered">
				<div class="file has-name h-margin-center-lat">
					<label class="file-label">
						<input 
							class="file-input" 
							type="file" 
							name="banner" 
							ref="banner"
							accept="image/*"
							@change="selectPic($event.target)">
						<span class="file-cta">
							<span class="file-icon">
								<i class="fas fa-upload"></i>
							</span>
							<span class="file-label">
								Cargar
							</span>
						</span>
						<span class="file-name">
							Imagen
						</span>
					</label>
				</div>
			</div>
			<div class="tile is-parent">
					<transition-group  name="fadeUp" tag="div" class="columns is-multiline is-centered h-margin-center-lat">
						<div 
						v-for="ban in banners" 
						class="column is-4 is-6-mobile" 
						:key="ban.id">
							<div :class="['box', ban.is_cover ? 'has-background-primary':'none' && ban.is_active ? 'has-background-grey-light' : 'has-background-black-bis']">
								<figure>
									<img :src="ban.src">
								</figure>
								<a 
								v-tooltip.bottom="(ban.is_cover ? 'Quitar de':'Colocar de')+' portada la imagen: '+ban.title" 
								@click="changeDetail({id:ban.id, 'is_cover':!ban.is_cover})">
									<i :class="['fas', ban.is_cover ? 'fa-toggle-on':'fa-toggle-off', 'has-text-white']"></i>
								</a> | 
								<a title="Informacion" 
								v-tooltip.bottom="'Información básica de la imagen '+ban.title"
								@click="formData={priority:ban.priority, is_cover:ban.is_cover, id:ban.id, title:ban.title}, formModal=true">
									<i class="fas fa-edit has-text-white"></i>
								</a> | 
								<a :title="ban.is_active ? 'Desactivar':'Activar'" 
								v-tooltip.bottom="(ban.is_active ? 'Desactivar':'Activar')+' la imagen '+ban.title+' en la pagina.'"
								@click="changeDetail({id:ban.id, 'is_active':ban.is_active})">
									<i :class="['fas', ban.is_active ? 'fa-stop-circle':'fa-play-circle', 'has-text-white']"></i>
								</a> | 
								<a title="Eliminar"
								v-tooltip.bottom="'Eliminar completamente a '+ban.title+' de Habeatat'"
								@click="confirmDestroy(ban.id)">
									<i class="fas fa-trash has-text-white"></i>
								</a>
							</div>
						</div>
					</transition-group>
			</div>
		</section>
				<footer class="modal-card-foot has-text-right">
				</footer>
	</div>
				<cropPicModal
				:modalShow="cropperModal"
				:imgSrc="selectedPic"
				:mTitle="'Agregar imagen'"
				:mForm="3"
				@imageCropped="savePic"
				@modalClose="emitCloseModal()"
				v-if="selectedPic != ''"
				:charging="loading" />

				<PicModal :show="loadModal" />
				<FormModal 
				:show="formModal"
				:formType="7"
				:data="formData"
				@modal-close="formModal = false"
				@save-form="changeDetail"
				:charging="loading"
				v-if="formModal" />
				<ConfirmModal 
					:show="confirmModal"
					v-if="confirmModal"
					:message="confirmText"
					@modal-close="confirmModal = false"
					@modal-submit="deletePic()" />
	</div>
</transition>
</template>

<script>
	import cropPicModal from '@/components/modal/cropper'
	import PicModal from '@/components/modal/pic'
	import FormModal from '@/components/modal/form'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'modalBanners',
		props: ['token','api', 'item', 'type', 'show', 'priorityOrder'],
		components: {
			cropPicModal,
			PicModal,
			FormModal,
			ConfirmModal
		},
		data() {
			return {
				banners: [],
				loadModal: false,
				formModal: false,
				cropperModal: false,
				confirmModal: false,
				confirmText: '',
				selectedPic: '',
				selectedPicId: '',
				loading: false,
				formData: {}
			}
		},
		created() {
			this.setBanners()
		},
		methods: {
			emitCloseModal() {
				this.cropperModal = false
				this.selectedPic = ''
				const input = this.$refs.banner
				input.type = 'text'
				input.type = 'file'
			},
			emitOpenModal() {
				this.cropperModal = true
			},
			setBanners() {
				this.$http
					.get(this.api+'/'+this.type+"/album/"+this.item.id+'/', this.token)
					.then(response => {
						this.banners = response.data
					})
			},
			selectPic(val) {
				if (!val.files.length || !val.files[0].type.includes('image/')){ 
					alert('Por favor seleccione un archivo de tipo imagen.');
					this.emitCloseModal();
					return;
				}
				const picture = val.files[0];
				if (typeof FileReader === 'function') {
					const reader = new FileReader();

					reader.onload = (event) => {
						var review = event.target.result;
						this.selectedPic = review
					};

					reader.readAsDataURL(picture);
				} else {
					alert('Sorry, FileReader API not supported');
				}

				this.emitOpenModal();
			},
			savePic(info) {
				this.loading = true
				const formData = new FormData();
				formData.append('src', info.pic);
				formData.append(this.type, this.item.id);
				formData.append('is_cover', info.data.is_cover);
				formData.append('priority', info.data.priority)
				formData.append('title', info.data.title)
				var path = this.api+'/'+this.type+'/album/'

				this.$http
					.post(path, formData, this.token)
					.then(response => {
						this.loading = false
						var result = response.data.item
						this.emitCloseModal()
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha agregado una imagen correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							if (result.is_cover === true) {
								for (var a in this.banners) {
									this.banners[a].is_cover = false
								}
							}
							this.banners.push(response.data.item)
							this.reOrderData(this.banners)
						}); 
					})
					.catch(() => {
						this.loading = false
						this.emitCloseModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido cargar la imagen al servidor.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})
					})
			},
			changeDetail(data) {
				this.loading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+'/'+this.type+"/album/"+data.id+"/", formData, this.token)
					.then(response => {
						var result = response.data.item
						if( result.is_cover ) {
							for (var x in this.banners) {
								this.banners[x].is_cover = false
							}
						}
						for (var b in this.banners){
							var ban = this.banners[b]
							if (ban.id === result.id) {
								ban.is_active = result.is_active
								ban.is_cover = result.is_cover
								ban.priority = result.priority
								ban.title = result.title
								break
							}
						}
						this.loading = false
						this.formModal = false
						this.reOrderData(this.banners) 
					})
			},
			reOrderData(data) {
				data.sort(function(a, b) { 
							return b.is_cover - a.is_cover || a.priority - b.priority || b.id - a.id;
						});
			},
			setFormData(data) {
				const formData = new FormData();
				if("is_cover" in data) {
					formData.append('is_cover', data.is_cover);
				}
				if("is_active" in data) {
					formData.append('is_active', !data.is_active);
				}
				if("priority" in data) {
					formData.append('priority', data.priority);
				}
				if("title" in data) {
					formData.append('title', data.title);
				}
				return formData
			},
			confirmDestroy(pic_id) {
				this.selectedPicId = pic_id
				this.confirmText = "Esta seguro que quiere eliminar esta imagen?"
				this.confirmModal = true
			},
			deletePic() {
				this.loadModal = true
				this.confirmModal = false
				this.$http
					.delete(this.api+'/'+this.type+'/album/'+this.selectedPicId+'/', this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha eliminado la imagen correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.loadModal = false
							var item = parseInt(response.data.item.id)
							for (var i in this.banners) {
								if (this.banners[i].id === item) {
									this.banners.splice(i, 1);
									break;
								}
							}
						})
					})
					.catch(() => {
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido eliminar la imagen.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})
					})

			}
		}

	}
</script>