<template>
	<div>
		<div class="field">
			<label class="label">Evento</label>
			<div class="control has-icons-left">
				<div class="select h-fullwidth">
					<select v-model="formData.event" class="h-fullwidth" name="event" v-validate="'required'">
						<option v-for="ev in events" :key="ev.id" :value="ev.id">{{ev.name}}</option>
					</select>
					<span class="icon is-small is-left">
						<i class="fas fa-boxes"></i>
					</span>
				</div>
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('event')">{{ errors.first('event') }}</p>
			</transition>
		</div>

		<div class="field">
			<label class="label">Fecha del registro de postulantes</label>
			<div class="control">
				<div class="columns">
					<div class="column">
						<div class="field">
							<label class="label">Inicio</label>
							<div class="control">
								<datepicker
								placeholder="Seleccione una fecha" 
								v-model="formData.postu_date_from"
								:language="spanish"
								:input-class="'input'"
								v-validate="'required'"
								:calendar-button-icon="'fas fa-calendar'"
								:name="'postu_date_from'"></datepicker>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('postu_date_from')">{{ errors.first('postu_date_from') }}</p>
							</transition>
						</div>
					</div>
					<div class="column">
						<div class="field">
							<label class="label">Fin</label>
							<div class="control">
								<datepicker
								placeholder="Seleccione una fecha" 
								v-model="formData.postu_date_until"
								:language="spanish"
								:input-class="'input'"
								v-validate="'required'"
								:name="'postu_date_until'"></datepicker>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('postu_date_until')">{{ errors.first('postu_date_until') }}</p>
							</transition>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="field">
			<label class="label">Fecha de las votaciones de los jueces</label>
			<div class="control">
				<div class="columns">
					<div class="column">
						<div class="field">
							<label class="label">Inicio</label>
							<div class="control">
								<datepicker
								placeholder="Seleccione una fecha" 
								v-model="formData.vote_date_from"
								:language="spanish"
								:input-class="'input'"
								v-validate="'required'"
								:calendar-button-icon="'fas fa-calendar'"
								:name="'vote_date_from'"></datepicker>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('vote_date_from')">{{ errors.first('vote_date_from') }}</p>
							</transition>
						</div>
					</div>
					<div class="column">
						<div class="field">
							<label class="label">Fin</label>
							<div class="control">
								<datepicker
								placeholder="Seleccione una fecha" 
								v-model="formData.vote_date_until"
								:language="spanish"
								:input-class="'input'"
								v-validate="'required'"
								:name="'vote_date_until'"></datepicker>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('vote_date_until')">{{ errors.first('vote_date_until') }}</p>
							</transition>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="field">
			<label class="label">Numero de seleccionados</label>
			<div class="control">
			<input class="input" 
			type="text" name="postu_vote_quant" 
			placeholder="El numero limite de votados por los jueces" 
			v-model="formData.postu_vote_quant" 
			v-validate="'required|numeric'">
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('postu_vote_quant')">{{ errors.first('postu_vote_quant') }}</p>
			</transition>
		</div>

		<div class="field">
			<label class="label">Fecha de las votaciones del publico</label>
			<div class="control">
				<div class="columns">
					<div class="column">
						<div class="field">
							<label class="label">Inicio</label>
							<div class="control">
								<datepicker
								placeholder="Seleccione una fecha" 
								v-model="formData.public_date_from"
								:language="spanish"
								:input-class="'input'"
								v-validate="'required'"
								:calendar-button-icon="'fas fa-calendar'"
								:name="'public_date_from'"></datepicker>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('public_date_from')">{{ errors.first('public_date_from') }}</p>
							</transition>
						</div>
					</div>
					<div class="column">
						<div class="field">
							<label class="label">Fin</label>
							<div class="control">
								<datepicker
								placeholder="Seleccione una fecha" 
								v-model="formData.public_date_until"
								:language="spanish"
								:input-class="'input'"
								v-validate="'required'"
								:name="'public_date_until'"></datepicker>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('public_date_until')">{{ errors.first('public_date_until') }}</p>
							</transition>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="field">
			<label class="label">Numero de ganadores</label>
			<div class="control">
			<input class="input" 
			type="text" name="postu_winners" 
			placeholder="El numero limite de ganadores de la votacion publica" 
			v-model="formData.postu_winners" 
			v-validate="'required|numeric'">
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('postu_winners')">{{ errors.first('postu_winners') }}</p>
			</transition>
		</div>
	</div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker';
	import {es} from 'vuejs-datepicker/dist/locale'
	export default {
		name: 'FormPostulation',
		inject: ['$validator'],
		props: ['data'],
		components: {
			Datepicker
		},
		data() {
			return {
				formData: {
					postu_date_from:'',
					postu_date_until:'',
					postu_vote_quant:'',
					postu_winners:'',
					public_date_from:'',
					public_date_until:'',
					vote_date_from:'',
					vote_date_until:'',
					event:'',
				},
				spanish:es
			}
		},
		created() {
			if (this.data != undefined) {
				this.formData = this.data
			}
		},
		computed: {
			events() {
				return this.$store.getters.GetEvent
			}
		},
		methods: {
			getFormData(){
				return this.formData
			}
		}
	}
</script>