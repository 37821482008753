<template>
	<section class="container">
		<div class=" container columns content-detail" v-if="pageInfo.name != undefined">
		<p class="is-size-4 is-hidden-tablet">
			{{pageInfo.name}}
		</p>
		<div class="column is-child">
			<HVideoBlock 
			:ownerkey="key"
			:channel="pageInfo.channel"
			:galery="pageInfo.album"
			:name="pageInfo.name" />
		</div>
		<div class="column is-child is-vertical">
			<HDetails 
			:hname="pageInfo.name"
			:hdescription="pageInfo.description"
			:hemail="pageInfo.email"
			:hasPlayer="pageInfo.playlist != ''"
			@show-more-description="openDesModal()"/>
			<div class="h-separator20"></div>
			<HSong v-if="pageInfo.playlist != ''" :playList="pageInfo.playlist" />
		</div>
	</div>
	</section>
	
</template>

<script>
	import HVideoBlock 		from '@/components/general/videoBlock'
	import HDetails 		from '@/components/general/details'
	import HSong 			from '@/components/general/song'
	export default{
		name: 'Capsula',
		data() {
			return {
				key: this.$store.state.googleAPIKey,
				page: 'capsula',
				pageName: 'capsula',
				container: true
			}
		},
		created() {
			this.setInternalPage()
		},
		components: {
			HVideoBlock,
			HDetails,
			HSong
		},
		computed: {
			pageInfo() {
				return this.$store.getters.GetCapsula
			}
		},
		methods: {
			setInternalPage() {
				this.$store.commit('outPage', {title: this.page, name: this.pageName, container: this.container})
			},
			openDesModal() {
				this.$store.commit('changeModalStatus', {
					modal:'des', 
					status:true, 
					info: {
						title: this.pageInfo.name,
						description: this.pageInfo.description
					}
				})
			}
		}
	};
</script>