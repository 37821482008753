<template>
<select 
	name="country" 
	class="h-fullwidth " 
	v-validate="'required'"
	@change="$emit('country-selected', $event.target.value)">
		<option class="has-background-white" value="">Seleccione un país</option>
		<option class="has-background-white" v-for="c in countries" 
		:value="c[0]" :key="c[0]"
		:selected="c[0] == country">{{c[1]}}</option>
</select>
</template>

<script>
	export default {
		props: ['country', 'api'],
		inject: ['$validator'],
		data() {
			return {
				countries: []
			}
		},
		created() {
			this.setCountries()
		},
		methods: {
			setCountries() {
				this.$http
					.get(this.api+'/country/')
					.then(response => {
						this.countries = response.data
					})
			},
		}
	}
</script>

<style scoped>
	select.borders {
		border:  1px solid grey;
		border-radius: 0;
	}
</style>