<template>
	<div class="tile is-parent is-vertical" v-if="postulates != undefined">
		<div class="tile is-vertical" v-if="postuParams.phase == 2 || userType == 1">
			<div class="tile is-child">
				<h2 class="title is-4">{{title}}</h2>
			</div>
			<div class="tile is-child is-vertical">
			<p class="has-text-justified"><b>Info:</b> en esta sección se muestra la lista de postulantes, para que un postulado sea oficialmente seleccionado necesita el voto de todos los jueces, sin embargo, con al menos 1 voto este queda pre-seleccionado para una segunda votación.</p>
			<br>
				<div class="columns">
					<div class="column">
						<b>Postulados seleccionados:</b><br> ({{postuParams.selected}}/{{postuParams.limit}}) <br>
						<span v-if="userType == 4">
							<b>Mi selección:</b><br> ({{postuParams.count > postuParams.limit ? postuParams.limit:postuParams.count}}/{{postuParams.limit}})<span v-if="postuParams.count > postuParams.limit">(+{{postuParams.count-postuParams.limit}})</span> <br>
							<b>Votos necesarios:</b> {{postuParams.judges}}
						</span>
					</div>
					<div class="column" v-if="userType==1">
						<p><b>Cantidad de jueces: </b> <br>
							{{postuParams.judges}}
						</p>
					</div>
					<div class="column" v-if="userType==4">
						<p class="has-text-justified">
							<b>Leyenda:</b><br>
							<span class="tag is-info">Filas Cian</span> - Postulados de mi selección <br>
							<span class="tag is-link">Filas Azules</span> - Postulado en revisión <br>
							<span class="tag is-success">Filas Verdes</span> - Postulados seleccionados
						</p>
					</div>
				</div>
			</div>
			<hr>
			<div class="tile is-child" v-if="postulates.length > 0">
				<table class="table h-fullwidth">
					<thead>
						<tr>
							<th>N°</th>
							<th>AKA</th>
							<th>Ciudad</th>
							<th>Pais</th>
							<th>Votos</th>
						</tr>
					</thead>
					<transition-group  name="list" tag="tbody">
						<tr v-for="i in postulates" :key="i.id" 
						:class="{
							'is-unselectable':true,
							'has-background-success has-text-white':i.judges.length >= postuParams.judges && i.id != postulateSelected.id,
							'has-background-info has-text-white':i.judges.includes(userId) && i.id != postulateSelected.id,
							'has-background-link has-text-white':i.id == postulateSelected.id,
						}"
						@click="rowSelection(i)">
							<td>{{i.id}}</td>
							<td>{{i.plate_aka}}</td>
							<td>{{i.plate_city}}</td>
							<td>{{country[i.plate_country]}}</td>
							<td><span :class="['tag',i.judges.includes(userId) ? 'is-light':'is-dark']" v-if="i.votes > 0">{{i.votes}}</span></td>
						</tr>
					</transition-group>
				</table>
			</div>
			<div class="tile is-vertical" v-else>
				<div class="container">
					<div class="box">
						<h4 class="title is-5 has-text-grey">Aun no hay personas postuladas para este evento.</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="tile is-vertical" v-else>
			<div class="container">
				<div class="box">
					<h4 class="title is-5 has-text-grey">El evento de postulaciones aún no se encuentra en fase de selección.</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AdminPostulates',
		props: ['token','api','userId', 'userType'],
		data() {
			return {
				title: 'Postulados',
				postulateSelected: {}
			}
		},
		created() {
			this.$store.commit('loader','y')
			this.setPostulates().then(() => {
				this.$store.commit('loader','n')
			})
		},
		timers: {
			setPostulates: { time: 16000, autostart: true, repeat:true },
		},
		computed: {
			country () {
				return this.$store.getters.GetCountry
			},
			postulates () {
				return this.$store.getters.GetPostulates
			},
			postuParams () {
				return this.$store.getters.GetPostuParams
			}
		},
		methods: {
			rowSelection(row) {
				if (row !== this.postulateSelected) {
					this.postulateSelected = row
					this.$emit('add-section', this.postulateSelected)
				} else {
					this.postulateSelected = {}
					this.$emit('rmv-section', this.postulateSelected)	
				}
			},
			setPostulates() {
				return new Promise((resolve) => {
				
					this.$http
						.get(this.api+'/postulations/postulates/', this.token)
						.then(response => {
							var postulates = response.data
							this.$http
								.get(this.api+'/postulations/votes/', this.token)
								.then(response => {
									var votes = response.data
									this.$store.commit('makePostulates', this.settingData(postulates, votes.items))
									this.$store.commit('setPostuParams', {
										limit:votes.limit, 
										count:votes.count, 
										judges:votes.judges, 
										selected: votes.selected,
										section:1,
										phase: votes.phase
									})
									resolve()
								})

						})
				})
			},
			settingData(data1, data2) {
				var resultData
				for (var d1 in data1) {
					data1[d1]['votes'] = ''
					data1[d1]['judges'] = []
					for (var d2 in data2) {
						if (data1[d1].id === data2[d2].plate_id) {
							data1[d1]['votes'] = data2[d2].votes
							data1[d1]['judges'] = data2[d2].judge_id
							break
						}
					}
				}
				resultData = data1
				return resultData
			}
		}
	}
</script>

<style scoped>
	.has-text-white > td, .has-text-white > td > a > i {
		color: white !important;
	}
	.table tbody tr {
		cursor: pointer;
	}
	.table tbody tr:hover {
		background: #F2F2F2;
	}
</style>