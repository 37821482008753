<template>
	<div class="tile is-parent is-vertical">
			<div class="tile is-child"><h1 class="is-size-4 has-text-left">La Cápsula</h1></div>
			<div class="tile">
				<div class="tile is-parent is-2">
					<div class="tile box is-vertical">
						<button class="button is-info"
						v-tooltip.bottom="'Listas de reproducción de La Cápsula'"
						@click="setCapsulaPlaylist()">
							<i class="fa fa-film is-size-4 has-text-white"></i>
						</button>
						<hr>
						<button class="button is-primary" 
						v-tooltip.bottom="'Album con imagenes de La Cápsula'"
						@click="bannerModalShow=true">
							<i class="far fa-images is-size-4 has-text-white"></i>
						</button>
					</div>
				</div>
				<div class="tile">
					<div class="box has-background-light h-fullwidth">
						<div class="field">
							<label class="label has-text-left">Titulo</label>
							<div class="control has-icons-right">
							<input 
							class="input" 
							type="text" 
							placeholder="Ingrese un titulo" 
							name="name" 
							v-model="capsula.name"
							v-validate="'required'">
								<span class="icon is-small is-right" v-show="errors.has('name')">
									<i class="fas fa-exclamation-triangle has-text-danger"></i>
								</span>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('name')">{{ errors.first('name') }}</p>
							</transition>
						</div>
						<div class="field">
							<label class="label has-text-left">Descripcion</label>
							<div class="control">
							<textarea 
							class="textarea" 
							name="description" 
							placeholder="Ingrese un texto descriptivo" 
							v-model="capsula.description"
							v-validate="'required'"></textarea>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('description')">{{ errors.first('description') }}</p>
							</transition>
						</div>
						<div class="field">
							<label class="label has-text-left">Email</label>
							<div class="control has-icons-left has-icons-right">
								<input 
								name="email" 
								class="input" 
								type="email" 
								placeholder="Correo electronico" 
								v-model="capsula.email"
								v-validate="'email|required'">
								<span class="icon is-small is-left">
									<i class="fas fa-envelope has-text-grey-light"></i>
								</span>
								<span class="icon is-small is-right" v-show="errors.has('email')">
									<i class="fas fa-exclamation-triangle has-text-danger"></i>
								</span>
							</div>
							<transition name="fade">
								<p class="help is-danger" v-show="errors.has('email')">{{ errors.first('email') }}</p>
							</transition>
						</div>
						<div class="field">
							<button :class="{'button is-link':true, 'is-loading':loading}" 
							@click="saveCapsula">
								<i class="fas fa-check has-text-white"></i> Guardar
							</button>
						</div>
					</div>
				</div>
			</div>
		<!-- PICTURES -->
		<bannerModal 
			:show="bannerModalShow" 
			:item="capsula"
			:type="'capsula'"
			@modal-close="bannerModalShow=false"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder"
			v-if="bannerModalShow" />
		<!-- PLAYLISTS FORM -->
		<FormModal 
			:show="playlistModalShow"
			:formType="4"
			:data="playlists"
			@modal-close="playlistModalShow = false"
			@save-form="saveCapsulaPlaylist"
			:charging="loading"
			v-if="playlistModalShow" />
		<!-- LOAD PAGE -->
		<PicModal :show="pageLoad" />
	</div>
</template>

<script>
	import PicModal from '@/components/modal/pic'
	import FormModal from '@/components/modal/form'
	import bannerModal from '@/components/modal/banner'
	export default {
		name: 'AdminCapsula',
		props: ['token','api','priorityOrder'],
		components: {
			PicModal,
			FormModal,
			bannerModal,
		},
		data() {
			return {
				loading: false,
				bannerModalShow: false,
				playlistModalShow: false,
				pageLoad: false,
				capsula: {
					id: undefined,
					name: '',
					description: '',
					email: ''
				},
				playlists: {},
				basePath: '/capsula/'
			}
		},
		created() {
			this.setCapsula()
		},
		methods: {
			setCapsula() {
				this.$http
					.get(this.api+this.basePath+'init/', this.token)
					.then(response => {
						this.capsula = response.data
					})
			},
			saveCapsula() {
				this.$validator.validateAll().then(result => {
					if (result) {
						this.loading = true
						const formData = this.setFormData(this.capsula)
						this.$http
							.patch(this.api+this.basePath+'init/'+this.capsula.id+'/', formData,this.token)
							.then(() => {
								this.$swal({
									type: "success",
									title: "Bien!",
									text: 'Se ha actualizado la informacion de la Capsula correctamente.',
									confirmButtonClass: "button",
									buttonsStyling: false,
									background: "white"
								}).then(() => {
									this.loading = false
								})

							})
					}
				})
			},
			setCapsulaPlaylist() {
				this.pageLoad = true
				this.$http
					.get(this.api+this.basePath+'playlist/'+this.capsula.id+'/', this.token)
					.then(response => {
						this.playlists = response.data
						this.pageLoad = false
						this.playlistModalShow = true
					})
			},
			saveCapsulaPlaylist(data) {
				this.loading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+"playlist/"+data.id+'/', formData, this.token)
					.then(() => {
						this.loading = false
						this.playlistModalShow = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se han actualizado las listas de reproduccion correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
			},
			setFormData(data) {
				const formData = new FormData();
				if("name" in data) {
					formData.append('name', data.name);
				}
				if("description" in data) {
					formData.append('description', data.description);
				}
				if("email" in data) {
					formData.append('email', data.email);
				}
				if("spotify" in data) {
					formData.append('spotify', data.spotify);
				}
				if("youtube" in data) {
					formData.append('youtube', data.youtube);
				}
				return formData		
			}
		}
	}
</script>