<template>
	<div class="tile video-box has-background-custom h-overflow-hide h-border-custom is-clickable" 
	:style="{
		position: 'relative',
        'background-image': itemRel == 2 ? `url(${picSrc})` : '',
		'background-repeat': 'no-repeat',
		'background-position': 'center',
        '-webkit-background-size':'cover',
        '-moz-background-size':'cover',
        '-o-background-size':'cover',
        'background-size':'cover',
	}"
	@click="itemRel == 2 ? openPicture(picSrc): null">
	<!-- 'transition':'background 1s' -->
		<iframe 
		v-if="itemRel == 1"
		id="ytplayer" 
		type="text/html" 
		width="100%" 
		:src="'https://www.youtube.com/embed/'+videoId+'?rel=0&autoplay=1'"
		frameborder="0"
		class="custom-frame"
		:title="(item.isAlbum ? name+' - ' : '')+item.snippet.title"
		allowfullscreen />

		<!-- <img v-else-if="itemRel == 2" 
		:src="picSrc" 
		width="100%" 
		class="custom-frame"
		:title="(item.isAlbum ? name+' - ' : '')+item.snippet.title"
		:alt="(item.isAlbum ? name+': ' : '')+item.snippet.title"> -->

		<div class="custom-text-box" v-if="itemRel == 2" >
			<p class="custom-text has-text-white title">
				{{item.snippet.title}}
			</p>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'Video',
		props: ['item','name'],
		data() {
			return {
				videoId: '',
				picSrc: '',
				itemRel: 0
			}
		},
		created() {
			this.initVideo()
		},
		watch: {
			item: function() {
				this.initVideo()
			}
		},
		methods: {
			initVideo() {
				if(typeof this.item != 'undefined' && this.item != '') {
					if( typeof this.item.isAlbum === 'undefined' || !this.item.isAlbum ) {
						this.videoId = this.item.contentDetails.videoId
						this.itemRel = 1
					} else {
						this.picSrc = this.item.src
						this.itemRel = 2
					}
				}
			},
			openPicture(picture) {
				this.$store.commit('changeModalStatus', {
					modal:'pic',
					status: true,
					info: {
						picture: picture,
						title: this.item.snippet.title
					}
				})
			}
		}
	}
</script>

<style scoped>
	.custom-frame { z-index: 10; }
	.h-border-custom {
		border-radius: 10px;
	}
	.video-box {
		max-width: 100%;
	}
	.has-background-custom {
		background: white;
	}
	@media only screen and (min-width: 1000px) {
		.video-box,.custom-text,img {
			min-height: 20rem;
		}
		.custom-text{line-height: 20rem;}
	}
	@media only screen and (min-width: 1200px) {
		.video-box,.custom-text,img {
			min-height: 19.4rem;
		}
		.custom-text{line-height: 19.4rem;}
	}
	@media only screen and (min-width: 1600px) {
		.video-box,.custom-text,img {
			min-height: 21.8rem;
		}
		.custom-text{line-height: 21.8rem;}
	}
	@media only screen and (min-width: 1800px) {
		.video-box,.custom-text, img {
			min-height: 26.04rem;
		}
		.custom-text{line-height: 26.04rem;}
	}
	@media screen and (max-width: 999px) { 
		iframe, .custom-text, .video-box {
			height: 12rem;
		}
		.custom-text{line-height: 20rem;}
		.video-box {
			/* height: inherit; */
			border-radius: inherit;
		}
		.has-background-custom {
			background: inherit;
		}
	}
	.custom-text-box {
		width: 100%;
		position: absolute;
		opacity: 0;
		background: black;
		z-index: 12;
		transition: opacity 1s;
	}
	.custom-text-box:hover {
		opacity: .6;
	}
	.custom-text {
		vertical-align: middle;
	}
</style>