<template>
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background" @click="$emit('modal-close')"></div>
			<div class="modal-content">
				<div class="box">
					<div class="field">
						<label class="label">Correo electrónico</label>
						<div class="control">
							<div class="field has-addons">
								<div class="control h-fullwidth">
									<input 
									class="input"
									type="text" 
									name="email"
									id="email" 
									v-model="email"
									v-validate="'required|email'">
									</div>
									<div class="control">
									<a :class="{'button h-back-gold':true, 'is-loading':load}" @click="$emit('send',email)">
										Enviar
									</a>
								</div>
							</div>
						</div>
						<transition name="fade">
							<span class="tag is-rounded" v-show="errors.has('email')">
								{{ errors.first('email') }}
							</span>
						</transition>
					</div>
					<p class="help">{{message}}</p>
				</div>
			</div>
		</div>	
</template>

<script>
	export default {
		name: 'EmailModal',
		props: ['show', 'message','load'],
		data() {
			return {
				email: ''
			}
		}
	}
</script>