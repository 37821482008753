<template>
	<div>
		<div class="field">
			<label class="label has-text-left">Nueva contraseña</label>
			<div class="control">
			<input class="input" 
			type="password" 
			name="password" 
			placeholder="Clave de usuario" 
			v-model="formData.password" 
			v-validate="'required'" 
			ref="password">
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('password')">{{ errors.first('password') }}</p>
			</transition>
		</div>

		<div class="field">
			<label class="label has-text-left">Repita la Contraseña</label>
			<div class="control">
			<input class="input" 
			type="password" 
			name="re_password" 
			placeholder="Ingrese nuevamente la contraseña" 
			v-validate="'required|confirmed:password'"
			data-vv-as="password">
			</div>
			<transition name="fade">
				<p class="help is-danger" v-show="errors.has('re_password')">{{ errors.first('re_password') }}</p>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormJudge',
		inject: ['$validator'],
		props: ['data'],
		data() {
			return {
				formData: {
					password: ''
				}
			}
		},
		created() {
			if (this.data != undefined) {
				this.formData = this.data
			}
		},
		methods: {
			getFormData(){
				return this.formData
			}
		}
	}
</script>