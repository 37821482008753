<template>
	<div class="tile is-parent is-vertical" v-if="postulates != undefined">
		<div class="tile is-vertical" v-if="postuParams.phase == 2 || userType == 1">
			<div class="tile is-child">
				<h2 class="title is-4">{{title}}</h2>
			</div>
			<div class="tile is-child is-vertical">
				<p><b>Info:</b> los seleccionados son los postulantes que consiguieron los votos de todos los jueces, estos pasáran a la ronda final de votación del público</p><br>
			</div>
			<hr>
			<div class="tile is-child" v-if="postulates.length > 0">
				<table class="table h-fullwidth">
					<thead>
						<tr>
							<th>N°</th>
							<th>AKA</th>
							<th>Ciudad</th>
							<th>Pais</th>
						</tr>
					</thead>
					<transition-group  name="list" tag="tbody">
						<tr v-for="i in postulates" :key="i.plate_id" 
						:class="{
							'is-unselectable':true,
							'has-background-link has-text-white':i.plate_id == postulateSelected.id,
						}"
						@click="rowSelection(i)">
							<td>{{i.plate_id}}</td>
							<td>{{i.plate.aka}}</td>
							<td>{{i.plate.city}}</td>
							<td>{{i.plate.country}}</td>
						</tr>
					</transition-group>
				</table>
			</div>
			<div class="tile is-vertical" v-else>
				<div class="container">
					<div class="box">
						<h4 class="title is-5 has-text-grey">Aun no hay postulados seleccionados aun para este evento.</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="tile is-vertical" v-else>
			<div class="container">
				<div class="box">
					<h4 class="title is-5 has-text-grey">El evento de postulaciones aún no se encuentra en fase de selección.</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'JudgesSelected',
		props: ['token','api','userId', 'userType'],
		data() {
			return {
				title: 'seleccionados',
				postulateSelected: {}
			}
		},
		created() {
			this.$store.commit('loader','y')
			this.setPostulates().then(() => {
				this.$store.commit('loader','n')
			})
		},
		timers: {
			setPostulates: { time: 16000, autostart: true, repeat:true },
		},
		computed: {
			postulates () {
				return this.$store.getters.GetPostulates
			},
			postuParams () {
				return this.$store.getters.GetPostuParams
			}
		},
		methods: {
			rowSelection(row) {
				var selected = {
						id: row.plate_id,
						judges: row.judge_id,
						plate_aka: row.plate.aka,
						plate_city: row.plate.city,
						plate_country: row.plate.country,
						plate_email: row.plate.email,
						plate_label: row.plate.label,
						plate_phone: row.plate.phone,
						plate_url_fb: row.plate.url_fb,
						plate_url_ig: row.plate.url_ig,
						plate_url_yt: row.plate.url_yt,
						votes: row.votes,
						postu: row.postu_id
					}
				if (this.postulateSelected !== selected) {
					this.postulateSelected = selected
					this.$emit('add-section', this.postulateSelected)
				} else {
					this.postulateSelected = {}
					this.$emit('rmv-section', this.postulateSelected)	
				}
			},
			setPostulates() {
				return new Promise((resolve) => {
					this.$http
						.get(this.api+'/postulations/votes/2/', this.token)
						.then(response => {
							var votes = response.data
							this.$store.commit('makePostulates', votes.items)
							this.$store.commit('setPostuParams', {
								limit:votes.limit,
								judges:votes.judges, 
								selected: votes.selected,
								count:votes.selected,
								section:3
							})
							resolve()
						})
				})
			},
		}
	}
</script>

<style scoped>
	.has-text-white > td, .has-text-white > td > a > i {
		color: white !important;
	}
	.table tbody tr {
		cursor: pointer;
	}
	.table tbody tr:hover {
		background: #F2F2F2;
	}
</style>