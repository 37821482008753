<template>
	<div class="tile is-vertical h-margin-center">
		<HVideo 
		:item="selectedVideo"
		:name="name"/>

		<div class="tile is-size-1 h-separator-t5">
			<a v-if="pageControl.prev" 
			@click="prevItems()" 
			class="is-unselectable is-hidden-mobile">
				<i class="icon-flecha-atras"></i>
			</a>
			<i v-else class="icon-flecha-atras is-unselectable h-opacity4 is-hidden-mobile"></i>

			<HVideoList 
			:inList="centerItems"
			:name="name"
			class="is-hidden-mobile"
			@videoclicked="emitVideo"
			v-if="centerItems.length > 0" />

			<a v-if="pageControl.next" 
			@click="nextItems()" 
			class="is-unselectable is-hidden-mobile">
				<i class="icon-flecha"></i>
			</a>
			<i v-else class="icon-flecha is-unselectable h-opacity4 is-hidden-mobile"></i>

		</div>
	</div>
</template>

<script>
	import HVideo 		from '@/components/general/video'
	import HVideoList 	from '@/components/general/video_list'
	export default {
		props:['ownerkey', 'channel', 'galery', 'name'],
		components: {
			HVideo,
			HVideoList
		},
		data() {
			return {
				nextPage: '',
				prevPage: '',
				items: [],
				centerItems: [],
				rightItems: [],
				leftItems: [],
				selectedVideo: '',
				coverImage: false,
				pageControl: {
					next:false,
					prev:false,
					prevPages:1,
					nextPages:1
				},
				pageNum: 50
			}
		},
		created() {
			this.initVideos()
		},
		watch: {
			leftItems: function() {
				if (this.leftItems.length === 0) {
					this.pageControl.prev = false
				} else { this.pageControl.prev = true }
			},
			rightItems: function() {
				if (this.rightItems.length === 0) {
					this.pageControl.next = false
				} else { this.pageControl.next = true }
			},
			galery: {
				handler: function () {
					this.restartVideos()
					this.initVideos()
				},
				deep: true
			}
		},
		methods: {
			restartVideos() {
				this.coverImage = false
				if (this.items.length > 0) {this.items.splice(0,this.items.length)}
				if (this.centerItems.length > 0) {this.centerItems.splice(0,this.centerItems.length)}
				if (this.rightItems.length > 0) {this.rightItems.splice(0,this.rightItems.length)}
				if (this.leftItems.length > 0) {this.leftItems.splice(0,this.leftItems.length)}
			},
			initVideos() {
				this.setPictures()
				const params = this.setParams(false, false, this.pageNum)
				if (typeof this.channel != 'undefined' && this.channel != '') {
					this.setVideos(params)
				} else {
					this.setItems()
				}
			},
			setVideos(params) {
				this.$http
					.get('https://www.googleapis.com/youtube/v3/playlistItems', { params })
					.then((response) => {
						var result = response.data
						this.setVideoInfo(result)
						this.nextPage = result.nextPageToken
						this.prevPage = result.prevPageToken
						this.setItems()

					})
					.catch(() => {
						this.setItems()
					});
			},
			setParams(prev, next, pag) {
				let params = {
					part: 'id, snippet, contentDetails',
					key: this.ownerkey,
					maxResults: pag,
					playlistId: this.channel
				}
				if (next) { params['pageToken'] = this.nextPage }
				else if (prev) { params['pageToken'] = this.prevPage }

				return params
			},
			nextItems() {
				var g_item = this.rightItems[0]
				var r_item = this.centerItems[0]

				this.leftItems.push(r_item)
				this.centerItems.shift()

				this.centerItems.push(g_item)
				this.rightItems.shift()
			},
			prevItems() {
				var g_item = this.leftItems[this.leftItems.length -1]
				var r_item = this.centerItems[this.centerItems.length -1]

				this.leftItems.pop()
				this.centerItems.unshift(g_item)

				this.centerItems.pop()
				this.rightItems.unshift(r_item)		
			},
			emitVideo(val) {
				this.selectedVideo = val;
			},
			setVideoInfo(info) {
				for (var i in info.items) {
					if('default' in info.items[i].snippet.thumbnails){
						info.items[i]['priority'] = 8
						this.items.push(info.items[i])
					} else {
						continue
					}
				}
			},
			setPictures() {
				if (this.galery.length > 0) {
					for (var e in this.galery) {
						if( !this.galery[e].isCover ){
							this.items.push(this.galery[e])
						}
						else {
							this.coverImage = this.galery[e]
						}
						
					}
				}
			},
			setItems() {
				this.orderItems()
				if (this.coverImage) {
					this.items.unshift(this.coverImage)
				}
				var cont = 0
				for (var it in this.items) {
					if (cont >= 4) {
						this.rightItems.push(this.items[it])
					} else {
						this.centerItems.push(this.items[it])
					}
					cont++
				}
				if (this.items.length > 0) {
					this.selectedVideo = this.items[0]
				} else {
					this.selectedVideo = {
						id: 1,
						src: '',
						isAlbum: true,
						isCover: true,
						contentDetails: {
							videoPublishedAt: '',
						},
						snippet: {
							thumbnails: {
								default: {
									url: ''
								}
							}
						}
					}
				}
				this.$emit('data-ready')
				
			},
			orderItems() {
				this.items.sort(function(a,b){
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					return a.priority - b.priority || new Date(b.contentDetails.videoPublishedAt) - new Date(a.contentDetails.videoPublishedAt);
				});
			}
		}
	}
</script>