var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.postulates != undefined)?_c('div',{staticClass:"tile is-parent is-vertical"},[(_vm.postuParams.phase == 2 || _vm.userType == 1)?_c('div',{staticClass:"tile is-vertical"},[_c('div',{staticClass:"tile is-child"},[_c('h2',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"tile is-child is-vertical"},[_vm._m(0),_c('br'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b',[_vm._v("Postulados seleccionados:")]),_c('br'),_vm._v(" ("+_vm._s(_vm.postuParams.selected)+"/"+_vm._s(_vm.postuParams.limit)+") "),_c('br'),(_vm.userType == 4)?_c('span',[_c('b',[_vm._v("Mi selección:")]),_c('br'),_vm._v(" ("+_vm._s(_vm.postuParams.count > _vm.postuParams.limit ? _vm.postuParams.limit:_vm.postuParams.count)+"/"+_vm._s(_vm.postuParams.limit)+")"),(_vm.postuParams.count > _vm.postuParams.limit)?_c('span',[_vm._v("(+"+_vm._s(_vm.postuParams.count-_vm.postuParams.limit)+")")]):_vm._e(),_vm._v(" "),_c('br'),_c('b',[_vm._v("Votos necesarios:")]),_vm._v(" "+_vm._s(_vm.postuParams.judges)+" ")]):_vm._e()]),(_vm.userType==1)?_c('div',{staticClass:"column"},[_c('p',[_c('b',[_vm._v("Cantidad de jueces: ")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.postuParams.judges)+" ")])]):_vm._e(),(_vm.userType==4)?_c('div',{staticClass:"column"},[_vm._m(1)]):_vm._e()])]),_c('hr'),(_vm.postulates.length > 0)?_c('div',{staticClass:"tile is-child"},[_c('table',{staticClass:"table h-fullwidth"},[_vm._m(2),_c('transition-group',{attrs:{"name":"list","tag":"tbody"}},_vm._l((_vm.postulates),function(i){return _c('tr',{key:i.id,class:{
						'is-unselectable':true,
						'has-background-success has-text-white':i.judges.length >= _vm.postuParams.judges && i.id != _vm.postulateSelected.id,
						'has-background-info has-text-white':i.judges.includes(_vm.userId) && i.id != _vm.postulateSelected.id,
						'has-background-link has-text-white':i.id == _vm.postulateSelected.id,
					},on:{"click":function($event){return _vm.rowSelection(i)}}},[_c('td',[_vm._v(_vm._s(i.id))]),_c('td',[_vm._v(_vm._s(i.plate_aka))]),_c('td',[_vm._v(_vm._s(i.plate_city))]),_c('td',[_vm._v(_vm._s(_vm.country[i.plate_country]))]),_c('td',[(i.votes > 0)?_c('span',{class:['tag',i.judges.includes(_vm.userId) ? 'is-light':'is-dark']},[_vm._v(_vm._s(i.votes))]):_vm._e()])])}),0)],1)]):_c('div',{staticClass:"tile is-vertical"},[_vm._m(3)])]):_c('div',{staticClass:"tile is-vertical"},[_vm._m(4)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"has-text-justified"},[_c('b',[_vm._v("Info:")]),_vm._v(" en esta sección se muestra la lista de postulantes, para que un postulado sea oficialmente seleccionado necesita el voto de todos los jueces, sin embargo, con al menos 1 voto este queda pre-seleccionado para una segunda votación.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"has-text-justified"},[_c('b',[_vm._v("Leyenda:")]),_c('br'),_c('span',{staticClass:"tag is-info"},[_vm._v("Filas Cian")]),_vm._v(" - Postulados de mi selección "),_c('br'),_c('span',{staticClass:"tag is-link"},[_vm._v("Filas Azules")]),_vm._v(" - Postulado en revisión "),_c('br'),_c('span',{staticClass:"tag is-success"},[_vm._v("Filas Verdes")]),_vm._v(" - Postulados seleccionados ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("N°")]),_c('th',[_vm._v("AKA")]),_c('th',[_vm._v("Ciudad")]),_c('th',[_vm._v("Pais")]),_c('th',[_vm._v("Votos")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-5 has-text-grey"},[_vm._v("Aun no hay personas postuladas para este evento.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-5 has-text-grey"},[_vm._v("El evento de postulaciones aún no se encuentra en fase de selección.")])])])
}]

export { render, staticRenderFns }