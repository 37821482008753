<template>
	<div>
		<div class="field">
			<label class="label">Título</label>
			<div class="control">
				<input 
					class="input" 
					type="text" 
					placeholder="Título de referencia" 
					v-model="mform.title"
					v-validate="'required'"
					name="title">

				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('title')">
						{{ errors.first('title') }}
					</span>
				</transition>
			</div>
		</div>
		<div class="field">
			<label class="label">ID del GoogleCalendar</label>
			<div class="control">
				<input 
					class="input" 
					type="text" 
					placeholder="ID de Referencia" 
					v-model="mform.id_ref"
					v-validate="'required'"
					name="id_ref">

				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('id_ref')">
						{{ errors.first('id_ref') }}
					</span>
				</transition>
			</div>
		</div>
		<div class="field">
			<label class="label">Cantidad de elementos a mostrar</label>
			<div class="control">
				<input 
					class="input" 
					type="text" 
					maxlength="3" 
					placeholder="Cantidad de resultados" 
					v-model="mform.max_results"
					v-validate="'required|numeric'"
					name="max_results">
				<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('max_results')">
						{{ errors.first('max_results') }}
					</span>
                </transition>
			</div>
		</div>
	</div>	
</template>

<script>
	export default {
		name: 'FormCalendar',
		inject: ['$validator'],
		props: ['formdata'],
		data() {
			return {
				mform: {
					title: '',
					id_ref: '',
					max_results:''
				}
			}
		},
		created() {
			this.setInfo()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setInfo() {
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if (typeof this.formdata.id_ref != 'undefined') {
						this.mform.id_ref = this.formdata.id_ref
					}
					if (typeof this.formdata.max_results != 'undefined') {
						this.mform.max_results = this.formdata.max_results
					}
				}
			}
		}
	}
</script>