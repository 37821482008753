<template>
    <div class="container">
        <transition name="fade">
        <div class="tile h-separator-t5" v-if="isPostulation">
            <div class="tile is-parent is-vertical">
                <div class="tile is-child">
                    <div class="box has-background-grey-light h-margin-rl-2 h-border-c15 h-padding10">

                        <!-- BOX 1 -->
                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Aka</label>
                            <div class="control">
                                <input class="input" 
                                type="text" 
                                name="aka" 
                                placeholder="Nombre artístico" 
                                v-model="formData.aka" 
                                v-validate="'required'">
                            </div>
                            <transition name="fade">
                                <p class="help is-danger" v-show="errors.has('aka')">{{ errors.first('aka') }}</p>
                            </transition>
                        </div>

                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Ciudad</label>
                            <div class="control">
                                <input class="input" 
                                type="text" 
                                name="city" 
                                placeholder="Ciudad de procedencia" 
                                v-model="formData.city" 
                                v-validate="'required'">
                            </div>
                            <transition name="fade">
                                <p class="help is-danger" v-show="errors.has('city')">{{ errors.first('city') }}</p>
                            </transition>
                        </div>

                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">País</label>
                            <div class="select h-fullwidth">
                                <Countries :api="api" @country-selected="country" />
                            </div>
                            <transition name="fade">
                                <p class="help is-danger" v-show="errors.has('country')">{{ errors.first('country') }}</p>
                            </transition>
                        </div>

                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Crew / Sello</label>
                            <div class="control">
                                <input class="input" 
                                type="text" 
                                name="label" 
                                placeholder="Grupo o sello discográfico" 
                                v-model="formData.label">
                            </div>
                        </div>

                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Url Instagram</label>
                            <div class="control">
                                <input class="input" 
                                type="text" 
                                name="label" 
                                placeholder="Dirección de la página de Instagram" 
                                v-model="formData.instagram">
                            </div>
                        </div>

                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Url Facebook</label>
                            <div class="control">
                                <input class="input" 
                                type="text" 
                                name="label" 
                                placeholder="Dirección de la página de Facebook" 
                                v-model="formData.facebook">
                            </div>
                        </div>
                        <div class="h-separator10"></div>
                    </div>
                </div>
            </div>
            <div class="tile is-parent is-vertical">
                <div class="tile is-child">
                    <div class="box has-background-grey-light h-margin-rl-2 h-border-c15 h-padding10">

                        <!-- BOX 2 -->
                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Email</label>
                            <div class="control">
                                <input class="input" 
                                type="text" 
                                name="email" 
                                placeholder="Correo electrónico" 
                                v-model="formData.email" 
                                v-validate="'required|email'">
                            </div>
                            <transition name="fade">
                                <p class="help is-danger" v-show="errors.has('email')">{{ errors.first('email') }}</p>
                            </transition>
                        </div>

                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Tlf / Whatsapp</label>
                            <div class="control">
                                <input class="input" 
                                type="text" 
                                name="phone" 
                                placeholder="Teléfono de contacto" 
                                v-model="formData.phone" 
                                v-validate="'required|numeric'">
                            </div>
                            <transition name="fade">
                                <p class="help is-danger" v-show="errors.has('phone')">{{ errors.first('phone') }}</p>
                            </transition>
                        </div>

                        <div class="h-separator10"></div>
                    </div>
                </div>
                <div class="tile is-child">
                    <div class="box has-background-grey-light h-margin-rl-2 h-border-c15 h-padding10">
                        
                        <div class="field">
                            <label class="label has-text-left is-marginless is-size-5">Url Youtube <small>(Max. 1min)</small></label>
                            <div class="control">
                                <div class="field has-addons">
                                    <div class="control h-fullwidth">
                                        <input class="input" 
                                        type="text" 
                                        placeholder="Dirección del video en Youtube"
                                        name="youtube"
                                        v-model="youtubeLink"
                                        v-validate="'required'">
                                    </div>
                                    <div class="control">
                                        <a :class="{'button h-back-gold':true, 'is-loading':buttons.verify}" @click="verifyVideo()">
                                            <span v-if="!videoVerify" class="has-text-white">Verificar</span>
                                            <i class="fas fa-check has-text-white" v-else></i>
                                        </a>
                                    </div>
                                </div>  
                            </div>
                            <p class="help h-text-style-reset"><b>Ejemplo:</b> https://www.youtube.com/watch?v=GV3z8eNLMbs</p>
                            <transition name="fadeDown">
                                <p class="help is-warning tag is-rounded" v-if="youtubeError">
                                    <b>{{youtubeMessage}}</b>
                                </p>
                            </transition>
                        </div>
                        <div class="h-separator10"></div>
                    </div>
                </div>

                <div class="tile is-child has-text-centered">
                    <button :class="{'button is-large is-rounded h-back-gold':true, 'is-loading':buttons.send}" 
                    type="submit" @click="proccessForm()">ENVIAR</button>
                </div>
            </div>
        </div>
        <div class="box h-separator-t5" v-else>
            <h4 class="title is-4"><i class="fas fa-info-circle"> {{message}}</i></h4> 
        </div>
        </transition>
        <!-- VIDEO MODAL -->
        <transition name="fade">
            <videoModal v-if="modals.video"
            :show="modals.video"
            :message="'Es este tu video?'"
            :videoData="{id:formData.youtube, title: youtubeTitle}"
            @modal-submit="videoVerify=true, modals.video=false"
            @modal-close="modals.video=false, formData.youtube='', videoVerify=false"/>
        </transition>
        <!-- CONFIRM MODAL -->
        <transition name="fade">
            <ConfirmModal 
            :show="modals.confirm"
            v-if="modals.confirm"
            :message="'Usted esta a un clic de enviar el formulario, verifique si esta completamente seguro de la información que nos ha suministrado.'"
            @modal-close="modals.confirm = false"
            @modal-submit="sendForm()" />
        </transition>
    </div>
</template>

<script>
    import Countries from '@/components/general/countries'
    import videoModal from '@/components/postulations/videoModal'
    import ConfirmModal from '@/components/modal/confirm'
    import { load } from 'recaptcha-v3'
    export default {
        name: 'Postulation',
        components: {Countries, videoModal, ConfirmModal},
        data() {
            return {
                isPostulation: false,
                postulation: {},
                event: {},
                message:'En estos momentos no se encuentra activo un evento para postularse, vuelva en otro momento.',
                formData: {
                    aka: '',
                    city: '',
                    country: '',
                    label: '',
                    instagram: '',
                    facebook: '',
                    email: '',
                    phone: '',
                    youtube: ''
                },
                videoVerify: false,
                modals: {
                    video: false,
                    confirm: false
                },
                buttons: {
                    send: false,
                    verify: false
                },
                youtubeLink: '',
                youtubeTitle: '',
                youtubeError: false,
                youtubeMessage: '',
                reCaptchaToken: '',
            }
        },
        created() {
            const formData = new FormData()
            formData.append('h_key', this.habeatatKey)
            this.$http
                .post(this.api+'/postulations/in/', formData)
                .then(response => {
                    var result = response.data
                    this.postulation = result.postulation
                    this.event = result.event
                    this.$store.commit('inPage', {title: result.event.name, name: 'event_detail', params: { id: result.event.name_id }})
                    this.isPostulation = true
                })
                .catch(error => {
                    if (error.response.status == 404) {
                        this.isPostulation = false
                    }
                })
        },
        computed: {
			api() {
				return this.$store.getters.GetApiPath
			},
            googleKey() {
                return this.$store.state.googleAPIKey
            },
            reCaptcha() {
                return this.$store.getters.GetCaptcha
            },
            habeatatKey() {
                return this.$store.getters.GetHabeatatKey
            }
		},
        methods: {
            country(c) {
                this.formData.country = c
            },
            customFormatter(time) {
                var moment = require('moment');
                var x = moment.duration(time)._data
                var seconds = 0
                if (x.hours > 0) { seconds = x.hours*3600 }
                if (x.minutes > 0) { seconds += (x.minutes*60) }
                if (x.seconds > 0) { seconds += x.seconds }
                return seconds
            },
            verifyVideo() {
                if (this.youtubeLink === '') { return false}
                this.youtubeError = false

                var videoId
                var videoSplit = this.youtubeLink.split("v=")
                if (videoSplit.length > 1) {
                    videoId = videoSplit[1].slice(0, 11)
                } else {
                    this.youtubeError = true
                    this.youtubeMessage = 'El enlace del video es incorrecto, verifique e intente nuevamente.'
                    return false
                }

                this.buttons.verify = true
                let params = {
                    part: 'id, snippet, contentDetails',
                    key: this.googleKey,
                    id: videoId
                }
                this.$http
                    .get('https://www.googleapis.com/youtube/v3/videos', { params })
                    .then(response => {
                        this.buttons.verify = false
                        var result = response.data.items
                        if (result.length > 0) {
                            if(this.customFormatter(result[0].contentDetails.duration) > 70) {
                                this.youtubeError = true
                                this.youtubeMessage = 'El video es muy largo, la duración maxima es 1 minuto.'
                                return false
                            } else {
                                this.formData.youtube = videoId
                                this.youtubeTitle = result[0].snippet.title
                                this.modals.video = true
                            }
                        } else {
                            this.youtubeError = true
                            this.youtubeMessage = 'El video no ha sido encontrado, verifique el enlace e intente de nuevo.'
                        }
                        
                    })
                
            },
            setFormData() {
                const formData = new FormData()
                formData.append('token', this.reCaptchaToken);
                formData.append('h_key', this.habeatatKey);
                
                formData.append('aka', this.formData.aka)
                formData.append('city', this.formData.city)
                formData.append('country', this.formData.country)
                formData.append('label', this.formData.label)
                formData.append('instagram', this.formData.instagram)
                formData.append('facebook', this.formData.facebook)
                formData.append('email', this.formData.email)
                formData.append('phone', this.formData.phone)
                formData.append('youtube', this.formData.youtube)
                formData.append('post_id', this.postulation.id)
                return formData
            },
            proccessForm() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        if(this.videoVerify) {
                            this.modals.confirm = true
                        } else {
                            this.youtubeError = true
                            this.youtubeMessage = 'Debe de verificar su video antes de continuar.'
                        }
                    }
                })
            },
            sendForm() {
                this.modals.confirm = false
                this.buttons.send = true

                load(this.reCaptcha.key)
                .then((recaptcha) => {
                    recaptcha.execute('login').then((token) => {
                        this.reCaptchaToken = token

                        //Send form
                        this.$http
                            .post(this.api+'/postulations/out/', this.setFormData())
                            .then(response => {
                                var message
                                var type
                                var title
                                var result = response.data
                                if (result.proccess == 'Ok') {
                                    message = 'Se ha enviado su información correctamente, porfavor espere atentamente a los resultados mediante las redes sociales. Recuerde que enviar más de una solicitud NO obtiene mayores probabilidades de ser seleccionado.'
                                    type = "success"
                                    title = "¡Completado!"
                                } else if (result.proccess == 'Not ok') {
                                    this.formData.youtube = ''
                                    this.videoVerify = false
                                    message = result.info
                                    type = 'warning'
                                    title = 'Petición denegada'
                                } else {
                                    this.formData.youtube = ''
                                    this.videoVerify = false
                                    message = 'Revise su conexión a internet y vuelva a intentarlo mas tarde, si el problema persiste contacte con un administrador.'
                                    type = 'error'
                                    title = 'Ops, ha ocurrido un error!'
                                }
                                    this.$swal({
                                        type: type,
                                        title: title,
                                        text: message,
                                        confirmButtonClass: "button",
                                        buttonsStyling: false,
                                        background: "white"
                                    }).then(() => {
                                        this.buttons.send = false
                                        if (type === 'success') { this.$router.push(
                                            { name: 'event_detail', params: { id: this.event.name_id } }) 
                                        }
                                    })

                            })
                            .catch( () => {
                                this.formData.youtube = ''
                                this.videoVerify = false
                                this.$swal({
                                    type: 'error',
                                    title: 'Ops, ha ocurrido un error!',
                                    text: 'Revise su conexión a internet y vuelva a intentarlo mas tarde, si el problema persiste contacte con un administrador.',
                                    confirmButtonClass: "button",
                                    buttonsStyling: false,
                                    background: "white"
                                }).then(() => {
                                    this.buttons.send = false
                                })    
                            })
                    })
                })
                .catch(() => {
                    this.formData.youtube = ''
                    this.videoVerify = false
                    this.$swal({
                        type: 'error',
                        title: 'Ops, ha ocurrido un error!',
                        text: 'Revise su conexión a internet y vuelva a intentarlo mas tarde, si el problema persiste contacte con un administrador.',
                        confirmButtonClass: "button",
                        buttonsStyling: false,
                        background: "white"
                    }).then(() => {
                        this.buttons.send = false
                    })
                })
            }
        },
    }
    
</script>