var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tile video-box has-background-custom h-overflow-hide h-border-custom is-clickable",style:({
		position: 'relative',
        'background-image': _vm.itemRel == 2 ? `url(${_vm.picSrc})` : '',
		'background-repeat': 'no-repeat',
		'background-position': 'center',
        '-webkit-background-size':'cover',
        '-moz-background-size':'cover',
        '-o-background-size':'cover',
        'background-size':'cover',
	}),on:{"click":function($event){_vm.itemRel == 2 ? _vm.openPicture(_vm.picSrc): null}}},[(_vm.itemRel == 1)?_c('iframe',{staticClass:"custom-frame",attrs:{"id":"ytplayer","type":"text/html","width":"100%","src":'https://www.youtube.com/embed/'+_vm.videoId+'?rel=0&autoplay=1',"frameborder":"0","title":(_vm.item.isAlbum ? _vm.name+' - ' : '')+_vm.item.snippet.title,"allowfullscreen":""}}):_vm._e(),(_vm.itemRel == 2)?_c('div',{staticClass:"custom-text-box"},[_c('p',{staticClass:"custom-text has-text-white title"},[_vm._v(" "+_vm._s(_vm.item.snippet.title)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }