var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tile is-flex is-justify-content-center is-flex-direction-column"},[_c('div',{staticClass:"file has-name is-flex is-justify-content-center"},[_c('label',{staticClass:"file-label"},[_c('input',{ref:"artist-bg",staticClass:"file-input",attrs:{"type":"file","name":"artist-bg","accept":"image/*"},on:{"change":function($event){return _vm.selectPic($event.target)}}}),_vm._m(0),_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.newFileText)+" ")])])]),_c('div',{staticClass:"tile is-parent"},[_c('transition-group',{staticClass:"columns is-multiline is-centered h-margin-center-lat",attrs:{"name":"fadeRight","tag":"div"}},_vm._l((_vm.images),function(image){return _c('div',{key:image.id,staticClass:"column is-3"},[_c('div',{class:['box', image.is_active ? 'has-background-grey-light' : 'has-background-black-bis']},[_c('figure',[_c('img',{attrs:{"alt":image.title,"src":image.src}})]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Modificar la Información básica de la imagen: '+image.title),expression:"'Modificar la Información básica de la imagen: '+image.title",modifiers:{"bottom":true}}],attrs:{"title":"Informacion"},on:{"click":function($event){_vm.background={
								id: image.id,
								title:image.title,
								content: image.content,
								src:image.src},
								_vm.showUpdateHomeBackground=true}}},[_c('i',{staticClass:"fas fa-edit has-text-white"})]),_vm._v(" | "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:((image.is_active ? 'Desactivar':'Activar')+' la imagen '+image.title+' en la pagina de Contenido.'),expression:"(image.is_active ? 'Desactivar':'Activar')+' la imagen '+image.title+' en la pagina de Contenido.'",modifiers:{"bottom":true}}],attrs:{"title":image.is_active ? 'Desactivar':'Activar'},on:{"click":function($event){return _vm.changeStatus({id:image.id,content:image.content, 'is_active':image.is_active})}}},[_c('i',{class:['fas', image.is_active ? 'fa-stop-circle':'fa-play-circle', 'has-text-white']})]),_vm._v(" | "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Eliminar completamente el background '+image.title+' del Home'),expression:"'Eliminar completamente el background '+image.title+' del Home'",modifiers:{"bottom":true}}],attrs:{"title":"Eliminar"},on:{"click":function($event){return _vm.confirmDestroy(image.id)}}},[_c('i',{staticClass:"fas fa-trash has-text-white"})])])])}),0)],1),_c('PicModal',{attrs:{"show":_vm.soloPicModal},on:{"modal-close":function($event){_vm.soloPicModal = false}}}),(_vm.confirmModal)?_c('ConfirmModal',{attrs:{"show":_vm.confirmModal,"message":_vm.confirmText},on:{"modal-close":function($event){_vm.confirmModal = false},"modal-submit":function($event){return _vm.destroyEventImageBackground()}}}):_vm._e(),(_vm.showHomeBackground)?_c('FormModal',{attrs:{"show":_vm.showHomeBackground,"formType":16,"data":_vm.background,"charging":_vm.loading},on:{"modal-close":function($event){_vm.showHomeBackground = false},"save-form":_vm.saveHomeBackground}}):_vm._e(),(_vm.showUpdateHomeBackground)?_c('FormModal',{attrs:{"show":_vm.showUpdateHomeBackground,"formType":17,"data":_vm.background,"charging":_vm.loading},on:{"modal-close":function($event){_vm.showUpdateHomeBackground = false},"save-form":_vm.updateHomeBackground}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-icon"},[_c('i',{staticClass:"fas fa-upload"})]),_c('span',{staticClass:"file-label"},[_vm._v(" Cargar ")])])
}]

export { render, staticRenderFns }