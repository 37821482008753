const actions = {
    setIn() {
        sessionStorage.setItem("isIn", true);
    },
    reproKey() {
        sessionStorage.setItem("isReproKey", true);
    },
    login({ commit }, creds) {
     commit('LOGIN'); // show spinner
     return new Promise(resolve => {
       setTimeout(() => {
         sessionStorage.setItem("token", creds.token);
         sessionStorage.setItem("userType", creds.userType);
         sessionStorage.setItem('userId', creds.userId);
         sessionStorage.setItem('userName', creds.userName);
         commit('LOGIN_SUCCESS');
         resolve();
       }, 1000);
     });
    },
    logout({ commit }) {
     sessionStorage.removeItem("token");
     sessionStorage.removeItem("userType");
     sessionStorage.removeItem('userId');
     sessionStorage.removeItem('userName');
     commit('LOGOUT');
    },
    cookies() {
        localStorage.setItem("isAcceptCookies", true);
    },
    restFullSet({commit}, webinfo) {
        commit('makeBanners', webinfo.flyers)
        commit('makeCalendar', webinfo.calendar)
        commit('makeSocial', webinfo.social)
        commit('makeArtist', webinfo.artist)
        commit('makeEvent', webinfo.event)
        commit('makeContent', webinfo.content)
        commit('makeCapsula', webinfo.capsula)
        commit('makeKeys', webinfo.keys)
        commit('makeCountries', webinfo.countries)
        // Set ramdom flyer on home
        if (webinfo?.flyers?.length > 0 && !sessionStorage.getItem("flyerModal")) {
            const selectedFlyer = webinfo.flyers[Math.floor(Math.random() * webinfo.flyers.length)]
            sessionStorage.setItem("flyerModal", selectedFlyer?.src ?? undefined);
            commit('changeModalStatus', {
                modal:'pic',
                status: true,
                info: {
                    picture: selectedFlyer?.src ?? "",
                    title: selectedFlyer?.title ?? "",
                    link: selectedFlyer?.link ?? "",
                }
            })
        }
    },
    refreshArtists({commit}, artist){
        commit('makeArtist', artist)
    },
    refreshEvents({commit}, event){
        commit('makeEvent',event)
    },
    refreshContent({commit},content){
        commit('makeContent', content)
    }
}

export default actions