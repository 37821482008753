<template>
	<div style="position: relative; z-index: 10;">
		<div class="event-box is-marginless h-scroll 4x has-background-grey-lighter">
			<transition-group  name="list" tag="ul" class="mb-2">
				<div v-for="(e, index) in eventsData" :key="e.id">
					<div class="bdtop h-margin-rl" v-if="index != 0"></div>
					<div class="tile is-parent has-text-left is-vertical">
						<a :href="e.htmlLink" target="_blank">
							<div id="index">
								<p class="
									is-uppercase 
									is-size-5-mobile 
									is-size-7-fullhd 
									is-size-7-widescreen 
									is-size-7-desktop 
									is-size-7-touch">
									{{ GetDay(new Date(e.start.dateTime).getDay()) || 
										GetDay(new Date(e.start.date+'T11:00:00-04:00').getDay()) }} 
									{{ new Date(e.start.dateTime).getDate() || 
										new Date(e.start.date+'T11:00:00-04:00').getDate() }} de 
									{{ GetMonth(new Date(e.start.dateTime).getMonth()) || 
										GetMonth(new Date(e.start.date+'T11:00:00-04:00').getMonth()) }} 
									{{ new Date(e.start.dateTime).getFullYear() ||
										new Date(e.start.date+'T11:00:00-04:00').getFullYear() }}
								</p>
								<p class="
									has-text-weight-bold 
									is-size-3-mobile 
									is-size-6-fullhd 
									is-size-7-widescreen 
									is-size-7-desktop 
									is-size-6-touch">
									{{ e.summary }}
								</p>
							</div>
						</a>
					</div>
				</div>
			</transition-group>
		</div>
		<div class="learn-more is-size-6 has-background-grey-lighter" v-show="eventsData.length > 0">
			<a @click="$emit('open-calendar')">Ver más</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'EventCalendar',
		props: ['Calendar'],
		data() {
			return {
				eventsData: []
			}
		},
		created() {
			this.GetCalendar()
		},
		timers: {
			GetCalendar: { time: 16000, autostart: true, repeat:true },
		},
		methods: {
			GetCalendar() {
				var path = 'https://www.googleapis.com/calendar/v3/calendars/'+this.Calendar.ref+'/events'
				const params = this.CalendarParams()
				this.$http
					.get(path, { params })
					.then(response => {
						const events = response.data
						this.eventsData = events.items
					})
			},
			CalendarParams() {
				const datetime = this.$store.getters.GetDateTimeNow

				let params = {
					key: this.$store.getters.GetGoogleAPIKey,
					orderBy: "startTime",
					singleEvents: true,
					timeMin: datetime,
					maxResults: this.Calendar.max_results
				}
				return params
			},
			GetMonth(num) {
				const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
					"Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
				]
				var selectedMonth = monthNames[num]
				return selectedMonth
			},
			GetDay(num) {
				const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
				var selectedDay = days[num]
				return selectedDay
			}
		}
	};
</script>

<style scoped>
	.event-box {
		overflow-y: scroll;
		position: relative !important;
		width: 250px;
		height: 130px;
	}
	@media only screen and (min-width: 1024px) {
		.event-box {
			max-height: 140px!important;
		}
	}
	@media only screen and (min-width: 1200px) {
		.event-box {
			max-height: 150px;
		}
	}
	@media only screen and (min-width: 1400px) {
		.event-box {
			max-height: 160px;
		}
	}
	@media only screen and (min-width: 1600px) {
		.event-box {
			max-height: 170px;
		}		
	}
	@media only screen and (min-width: 1800px) {
		.event-box {
			max-height: 180px;
		}
	}
	.learn-more {
		background: white;
		position: absolute;
		z-index: 8;	
		width: 100%;
		text-align: center;
		bottom: 0;
		color:#E68433;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	@media screen and (max-width: 1023px){
		.box, .learn-more {
			border-radius: inherit !important;
		}
		.event-box {
			max-height: 405px;
		}
	}
</style>