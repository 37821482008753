// EVENTS COMPONENTS
import Content        from '@/components/content/index'
import ContentPortrait  from '@/components/content/portrait'
import ContentDetail  from '@/components/content/library'

var d = new Date
const content = [
//CONTENIDO
{
  path: '/contenido',
  name: 'content',
  component: Content,
  meta: {
    forVisitors: true,
    title: 'ø Contenido musical único y original | HABEATAT '+d.getFullYear(),
    metaTags: [
      {
        name: 'description',
        content: 'Nuestras producciones no buscan parecerse, 👉 son propuestas únicas que trascienden en el tiempo. '
      }
    ]
  }
},
{
  path: '/contenido/:id',
  name: 'content_detail',
  component: ContentDetail,
  meta: {
    forVisitors: true,
    title: route => {
      var event = route.params.id
      switch (event) {
        case 'radiokz':
          return '》RadioKZ: Biblioteca privada de música | HABEATAT '+d.getFullYear()
        case 'empatia':
          return '》Empatía: Capacidad de ponerse en el lugar de otro | HABEATAT '+d.getFullYear()
        default:
          return '》Contenido | HABEATAT '+d.getFullYear()
      }
    },
    metaTags: [
      {
        name: 'description',
        content: route => {
          var event = route.params.id
          switch (event) {
            case 'radiokz':
              return 'Colección de mixtapes exclusivos de artistas con renombre. Una dosis de música inédita reservada para los oídos más cultos 👌, dar cátedra de hip-hip y perderse entre líricas que solo habeatat les trae. cassettes de diseños tan únicos como su contenido. nuestra mejor cosecha.'
            case 'empatia':
              return 'Buscamos de conectar ❤️ a reconocidos artistas en distintas partes del mundo para brindarle ayuda a jóvenes, no solo de forma motivacional, sino en acciones tangibles; que contribuyen en la construcción de sus carreras sin importar su origen, color o credo.'
            default:
              return 'Contenido musical único 📲 | Habeatat.com'  
          }
        } 
      }
    ]
  }
},
{
  path: '/contenido/:id/portada',
  name: 'content_portrait',
  component: ContentPortrait,
  meta: {
    forVisitors: true,
    title: route => {
      var event = route.params.id
      switch (event) {
        case 'radiokz':
          return '》RadioKZ: Biblioteca privada de música | HABEATAT '+d.getFullYear()
        case 'empatia':
          return '》Empatía: Capacidad de ponerse en el lugar de otro | HABEATAT '+d.getFullYear()
        default:
          return '》Contenido | HABEATAT '+d.getFullYear()
      }
    },
    metaTags: [
      {
        name: 'description',
        content: route => {
          var event = route.params.id
          switch (event) {
            case 'radiokz':
              return 'Colección de mixtapes exclusivos de artistas con renombre. Una dosis de música inédita reservada para los oídos más cultos 👌, dar cátedra de hip-hip y perderse entre líricas que solo habeatat les trae. cassettes de diseños tan únicos como su contenido. nuestra mejor cosecha.'
            case 'empatia':
              return 'Buscamos de conectar ❤️ a reconocidos artistas en distintas partes del mundo para brindarle ayuda a jóvenes, no solo de forma motivacional, sino en acciones tangibles; que contribuyen en la construcción de sus carreras sin importar su origen, color o credo.'
            default:
              return 'Contenido musical único 📲 | Habeatat.com'  
          }
        } 
      }
    ]
  }
}
]

export default content