<template>
	<div class="tile is-vertical">
		<h2 class="title is-4 has-text-grey-light is-marginless">Llaves para el acceso a secciones o aplicaciones de la web </h2>
		<hr>
		<h3 class="title is-5 has-text-grey-light has-text-left">Aplicaciones</h3>
		<transition-group name="fadeRight" tag="div" class="columns is-multiline is-vcentered">
			<div class="column is-4" v-for="i in keys.app" :key="i.id">
				<KeyBox :item="i" :color="'danger'" @new-key="setNewKey" @new-status="setNewStatus" />
			</div>
		</transition-group>
		<hr>
		<h3 class="title is-5 has-text-grey-light has-text-left">Documentos</h3>
		<transition-group name="fadeRight" tag="div" class="columns is-multiline is-vcentered">
			<div class="column is-4" v-for="i in keys.document" :key="i.id">
				<KeyBox :item="i" :color="'link'" @new-key="setNewKey" @new-status="setNewStatus" />
			</div>
		</transition-group>
	</div>
</template>

<script type="text/javascript">
	import KeyBox from '@/components/general/keyContentBox'
	export default {
		name: 'AdminKeys',
		props: ['token','api'],
		components: { KeyBox },
		data() {
			return {
				keys: {
					app: [],
					document: []
				}
			}
		},
		created() {
			this.$http
				.get(this.api+'/h/private-key/', this.token)
				.then(response => {
					var result = response.data
					for (var x in result) {
						if (result[x].type === 2) { this.keys.app.push(result[x]) }
						if (result[x].type === 3) { this.keys.document.push(result[x]) }
					}
				})
		},
		methods: {
			setNewKey(val) {
				const formData = new FormData();
				formData.append('key', val.key);
				this.$http
					.patch(this.api+'/h/private-key/'+val.id+'/', formData, this.token)
					.then(() => {
						this.$swal({
							type: "success",
							title: "¡Listo!",
							text: 'Se ha actualizado la llave correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})
					})
			},
			setNewStatus(val) {
				const formData = new FormData();
				formData.append('is_active', !val.is_active);
				this.$http
					.patch(this.api+'/h/private-key/'+val.id+'/', formData, this.token)
					.then(response => {
						var result = response.data
						if (result.typ === 2) {
							for (var app in this.keys.app) {
								if (this.keys.app[app].id === result.id) {
									this.keys.app[app].is_active = result.is_active
									break
								}
							}
						} else if (result.typ === 3) {
							for (var doc in this.keys.document) {
								if (this.keys.document[doc].id === result.id) {
									this.keys.document[doc].is_active = result.is_active
									break
								}
							}
						}

					})	
			}
		}
	}
</script>