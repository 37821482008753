<template>
	<div class="tile is-parent is-vertical">
		<div class="tile is-child">
			<button class="button" type="button" @click="newBeatButton()">
				<i class="fa fa-volume-up"></i>&nbsp; | New Beat
			</button>
			<input 
				class="file-input is-invisible" 
				type="file" 
				name="beat" 
				ref="beat"
				accept="audio/*" 
				@change="selectBeat($event.target)">
		</div>
		<div class="tile is-child">
			<table class="table h-fullwidth">
				<thead>
					<tr>
						<th>Producer</th>
						<th>Title</th>
						<th>Actions</th>
					</tr>
				</thead>
				<transition-group  name="list" tag="tbody">
					<tr v-for="i in beats" :key="i.id" :class="{'has-background-link has-text-white':i.id == beatSelected.id}">
						<td>{{i.producer}}</td>
						<td>{{i.title}}</td>
						<td>
							<!-- Editar -->
							<a 	title="Informacion"
								v-tooltip.top="'Información básica del beat: '+i.title"
								@click="$emit('add-section', i), beatSelected = i">
								<i class="fas fa-file-audio has-text-info"></i>
							</a> | 
							<!-- Activar | Desactivar -->
							<a 	:title="i.is_active ? 'Desactivar':'Activar'"
								v-tooltip.top="'El Beat '+i.title+' se encuentra '+(i.is_active ? 'Activo':'Inactivo')+' en la pagina.'"
								@click="changeStatus({id:i.id, is_active:i.is_active})">
								<i :class="['fas', i.is_active ? 'fa-microphone-alt':'fa-microphone-alt-slash']"></i>
							</a> |
							<!-- Eliminar -->
							<a 	title="eliminar"
								v-tooltip.top="'Eliminar completamente el Beat: '+i.title"
								@click="confirmDestroy(i.id)">
								<i class="fas fa-trash"></i>	
							</a>
						</td>
					</tr>
				</transition-group>
			</table>
		</div>
		<!-- MODALS -->
			<!-- Loading -->
			<PicModal :show="modal.loading" />
			<!-- NewBeat -->
			<FormModal 
			:show="modal.newBeat"
			:formType="10"
			:data="beatSelected"
			@modal-close="closeModal()"
			@save-form="saveBeat"
			:charging="isLoading"
			v-if="modal.newBeat" />
			<!-- Confirm -->
			<ConfirmModal 
			:show="modal.confirm"
			:message="'Are you completely sure you want to delete this Beat?'"
			v-if="modal.confirm"
			@modal-close="modal.cofirm = false"
			@modal-submit="deleteBeat()" />

	</div>
</template>

<script>
	import PicModal from '@/components/modal/pic'
	import FormModal from '@/components/modal/form'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'AdminMusic',
		props: ['token','api','priorityOrder'],
		components: {
			PicModal,
			FormModal,
			ConfirmModal
		},
		data() {
			return {
				beatSelected: {},
				fileSelected: '',
				beatIdSelected: 0,
				modal: {
					newBeat: false,
					confirm: false,
					loading: false
				},
				isLoading: false
			}
		},
		created() {
			this.setBeats()
		},
		computed: {
			beats() {
				return this.$store.getters.GetSongs
			}
		},
		methods: {
			setBeats() {
				this.$store.commit('resetMusic')
				this.$http
					.get(this.api+'/h/music/', this.token)
					.then(response => {
						var result = response.data
						this.$store.commit('makeMusic', result)
					})
			},
			newBeatButton() {
				this.$refs.beat.click()
			},
			closeModal() {
				this.modal.newBeat = false
				this.beatSelected = {}
				this.fileSelected = ''
				const input = this.$refs.beat
				input.type = 'text'
				input.type = 'file'
			},
			selectBeat(song) {
				if (!song.files.length || !song.files[0].type.includes('audio/')){ 
					this.$swal({
						type: "error",
						title: "Error!",
						text: 'Please select an audio file.',
						confirmButtonClass: "button",
						buttonsStyling: false,
						background: "white"
					})
					return;
				} else {
					var beat = song.files[0]
					this.fileSelected = beat
					var beatName = beat.name.split('.')
					beatName = beatName[0]
					this.beatSelected = {
						src: URL.createObjectURL(beat),
						title: beatName
					}
					this.modal.newBeat = true
				}
			},
			saveBeat(beat) {
				this.isLoading = true
				beat['src'] = this.fileSelected
				this.$http
					.post(this.api+"/h/music/", this.setFormData(beat), this.token)
					.then(response => {
						this.isLoading = false
						this.closeModal()
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'A new Beat has been created.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.$store.commit('addMusic', response.data.item)
							this.priorityOrder(this.beats)
						}); 
					})
					.catch(() => {
						this.isLoading = false
						this.closeModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'An error has occurred, the song could not be uploaded to the server.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			},
			setFormData(data) {
				const formData = new FormData();
				if("src" in data) {
					formData.append('src', data.src);
				}
				if("title" in data) {
					formData.append('title', data.title);
				}
				if("producer" in data) {
					formData.append('producer', data.producer)
				}
				if("genre" in data) {
					formData.append('genre', data.genre)
				}
				if("bpm" in data) {
					formData.append('bpm', data.bpm)
				}
				if("is_active" in data) {
					formData.append('is_active', !data.is_active)
				}
				if("price" in data) {
					formData.append('price', data.price)
				}
				if("time" in data) {
					formData.append('time', data.time.min+':'+data.time.sec)
				}
				if("duration" in data) {
					formData.append('duration', data.duration)
				}
				if("priority" in data) {
					formData.append('priority', data.priority)
				}
				return formData
			},
			changeStatus(detail) {
				this.modal.loading = true
				this.$http
					.patch(this.api+'/h/music/'+detail.id+'/', this.setFormData(detail), this.token)
					.then(response => {
						this.modal.loading = false
						this.$store.commit('updateMusic', response.data)
					})
			},
			confirmDestroy(beat_id) {
				this.beatIdSelected = beat_id
				this.modal.confirm = true
			},
			deleteBeat() {
				this.modal.confirm = false
				this.modal.loading = true
				this.$http
					.delete(this.api+'/h/music/'+this.beatIdSelected+'/', this.token)
					.then(response => {
						this.modal.loading = false
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'A Beat has been removed.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.$emit('rmv-section')
							this.$store.commit('destroyMusic', response.data.item.id)
						})
					})
					.catch(() => {
						this.modal.loading = false
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'An error has occurred, the song could not be remove from the server.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			}
		}
	}
</script>

<style scoped>
	.has-text-white > td, .has-text-white > td > a > i {
		color: white !important;
	}
</style>