<template>
	<div class="tile is-vertical">
		<FormSocial 
			@settingForm="setSocial"
			:formdata="social"
			:mtitle="'Actualizar las redes sociales Habeatat'" />
		<br>
		<div class="tile is-4 h-margin-center">
			<button 
				:class="{'button is-success h-fullwidth':true, 'is-loading':formLoading}"
				@click="updateSocial()">
				Actualizar
			</button>
		</div>
		
	</div>
</template>

<script>
	import FormSocial from '@/components/admin/forms/formSocial'
	export default {
		name: 'adminSocial',
		props: ['token', 'api'],
		data() {
			return {
				social: {
					youtube: '',
					spotify: '',
					facebook: '',
					instagram: '',
					twitter: ''
				},
				formLoading: false
			}
		},
		created() {
			this.$http
				.get(this.api+'/h/social/', this.token)
				.then(response => {
					const social = response.data

					if (social.length > 0) {
						for (var i in social){
							this.social = social[i]
							break
						}
					} else {
						const formData = this.makeSocialForm()
						this.$http
							.post(this.api+'/h/social/', formData, this.token)
					}
				})
		},
		components: {
			FormSocial
		},
		methods: {
			setSocial(formData) {
				this.social = formData
			},
			makeSocialForm() {
				const formData = new FormData();
				formData.append('facebook', this.social.facebook);
				formData.append('spotify', this.social.spotify);
				formData.append('instagram', this.social.instagram);
				formData.append('twitter', this.social.twitter);
				formData.append('youtube', this.social.youtube);
				return formData
			},
			updateSocial() {
				this.formLoading = true
				const formData = this.makeSocialForm()
				this.$http
					.put(this.api+'/h/social/1/', formData, this.token)
					.then(() => {
						this.formLoading = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado las redes sociales correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})
					})
			}
		}
	}
</script>