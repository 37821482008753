<template>
	<div>
		<div class="calendar-box h-fullwidth">
			<kalendar :configuration="calendar_settings" :appointments="appointments">
				<div slot="popup-form" slot-scope="{popup_scope}" style="display: flex; flex-direction: column;">
					<!-- This is the popup that is displayed when user has finished dragging(selecting) appointment start and end values. -->
					<!-- You can use the popup_scope variable to access various fields, such as: appointment_data (similar to appointment_props) and close. -->
					<!-- There is a listener attached to the close field. Whenever it gets a false value, the popup closes. -->
					<!-- You can add as many fields as you want. When saving them, make sure to insert them in the data field of the payload for appointments array. In that way you can access them even in the *details-card* slot.-->
					<h4 style="margin-bottom: 10px" class="is-uppercase">Nueva Reservación</h4>
					<p>
						{{(popup_scope.appointment_props.end+1) - popup_scope.appointment_props.start}} 
						Hora<span v-if="(popup_scope.appointment_props.end+1) - popup_scope.appointment_props.start > 1">s</span>
					</p>
					<div class="buttons">
						<!--button class="cancel button is-uppercase" @click="popup_scope.close_popup = true">cancelar</button-->
						<button class="button is-info is-uppercase" @click="completeAppointment(popup_scope)">Reservar</button>
					</div>
				</div>				
			</kalendar>
		</div>
	</div>
</template>

<script>
	import { Kalendar } from 'kalendar-vue';
	import 'kalendar-vue/dist/KalendarVue.css';
	export default {
		name: 'revervations',
		components: {
			Kalendar
		},
		data() {
			return {
				appointments: [],
				calendar_settings: {
					style: 'material_design', // ['flat_design', 'material_design']
					view_type: 'Month', // ['Month', 'Day']
					split_value: 60, // Value % 60 === 0
					cell_height: 20, // !isNaN(Value)
					scrollToNow: true, // Boolean
					current_day: new Date(), // Valid date
					military_time: false
				}
			}
		},
		computed: {
			currentDate() {
				return this.$store.getters.GetDateTimeNow
			}
		}
	}
</script>

<style>
	.calendar-box {
		height: 25rem;
		overflow-y: scroll;
		overflow-x: hidden;
	}
</style>