<template>
<transition name="fade" >
    <div :class="{'modal':true, 'is-active':show}" v-if="show">
        <div class="modal-background" @click="$emit('close-modal')"></div>
        <!-- <div class="modal-header">
            <h4 class="modal-header-title title h-text-dark">{{title}}</h4>
        </div> -->
        <div class="modal-content">
            <figure :class="{'image': true, 'is-clickable': this.link}"  @click="handlerClickImage()">
                <img :src="picture" :alt="title">
            </figure>
        </div>
    </div>	
</transition>
</template>

<script>
	export default {
		name: 'DescriptionModal',
		props: {
            show: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: ''
            },
            picture: {
                type: [Text, String],
                default: ''
            },
            link: {
                type: String,
                default: "",
            }
        },
        methods: {
            handlerClickImage() {
                this.link ? window.location.href = this.link : this.$emit('close-modal')
            },
        }
	}
</script>