import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Actions from './actions'
import State from './state'
import Mutations from './mutations'
import Getters from './getters'

export const store = new Vuex.Store({
  state: State,
  mutations: Mutations,
  actions: Actions,
  getters: Getters
//https://api.spotify.com/v1/playlists/3fyZ51roAhccgaaDCvIzGg
//tracks.items.track.id, tracks.items.track.external_urls, tracks.items.track.name, tracks.total, tracks.items.track.track_number, tracks.items.track.preview_url


})
