/* COMPONENTS */
// import News           from '@/components/news/index'

//HOME
import Home from '@/components/home/index'
// ARTISTS
import Artist         from '@/components/artists/index'
import ArtistDetail   from '@/components/artists/detail'
// EVENTS
import Events         from './routeEvents'
// TICKETS
import Tickets        from '@/components/tickets/index.vue'
// CONTENT
import Content        from './routeContent'
// MUSIC
import TheRepro       from '@/components/therepro/index'
import Lyrics         from '@/components/lyrics/template'
import ArtistLyrics   from '@/components/lyrics/artist_template'
// CAPSULA
import Capsula        from '@/components/capsula/index'
// MISC
import Contact        from '@/components/contact/index'
import Login          from '@/components/admin/login'
import Dashboard      from '@/components/admin/dashboard'
import PP             from '@/components/pp'
import TmCd           from '@/components/tmcd'
/* STORE */
import {store} from '@/vuex/store'
/* ROUTER */
import Vue          from 'vue'
import Router       from 'vue-router'
Vue.use(Router)

var d = new Date

export default new Router({
  mode: 'history',
  routes: [
//INICIO
  {
    path: '/',
    name: 'index',
    component: Home,
    meta: {
      forVisitors: true,
      title: '【 HABEATAT 】 ≭ Productora latinoamericana de música urbana ≭ '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: '♫♪.ılılıllllılılı.♫♪ Hacemos música que transforma, eventos que conectan y contenido que no caduca. ✔️ Siempre a la vanguardia con los  mejores ritmos del rap, hip hop y otros generos del momento como el pop y electrónica, junto a la integración de artistas y productores Venezolanos con otros raperos Latinoamericanos.'
        }
      ]
    },
  },
//TICKETS
  {
    path: '/tickets',
    name: 'tickets',
    component: Tickets,
    meta: {
      forVisitors: true,
      title: '≭ Consigue tus boletos para los próximos eventos Habeatat '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: 'Venta de boletos disponibles para los próximos eventos Habeatat, conoce en vivo a los mejores artistas Venezolanos de música urbana, consigue tus tickets ahora!'
        }
      ]
    }
  },
//ARTISTAS
 {
    path: '/artistas',
    name: 'artist',
    component: Artist,
    meta: {
      forVisitors: true,
      title: '⋗ Artistas en ritmos urbanos y música del '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: 'Impulsamos talentos musicales 🎤 creando espacios aptos para que se desarrollen en proporción a su maximo potencial. Te presentamos a los mejores artistas en ritmos urbanos y otros generos musicales del momento, creando los mejores temas de rap, hip hop, pop y/o electrónica.'
        }
      ]
    }
  },
 {
    path: '/artistas/:id',
    name: 'artist_detail',
    component: ArtistDetail,
    meta: {
      forVisitors: true,
      title: route => {
        var artist = route.params.id
        switch (artist) {
          case 'letra':
            return 'ℒ 【 Letra 】 Rapero venezolano insuperable en el freestyle. '+d.getFullYear()
          case 'horus':
            return '༒ 【 Horus 】 El mejor artista de hip hop y rap en Venezuela. '+d.getFullYear()
          case 'lianamalva':
            return 'ꕥ 【 Liana Malva 】 Cantautora venezolana de música variada. '+d.getFullYear()
          case 'figu':  
            return '☾ 【 Figu 】 Dj, compositor y productor musical venezolano. '+d.getFullYear()
          case 'solosoul':  
            return '≭ 【 Solo Soul 】 Ingeniero de sonido y productor musical. '+d.getFullYear()
          default:
            return '♯ Artista | HABEATAT '+d.getFullYear()  
        }
      },
      metaTags: [
        {
          name: 'description',
          content:  route => {
            var artist = route.params.id
            switch (artist) {
              case 'letra':
                return 'Letra es un rapero venezolano ⭐️ con increibles habilidades en el freestyle, con el tiempo su improvisación lo convirtio en uno de los mejores raperos de toda latinoamerica.'
              case 'horus':
                return 'Horus es un rapero venezolano ⭐️ el cual se dedica a componer canciones con temas reflexivos y de conciencia, su música sigue inspirando a personas a nivel internacional sobresaliendo en latinoamerica.'
              case 'lianamalva':
                return 'Liana Malva es una artista venezolana ⭐️ cantautora y multi-instrumentista, su música es un mixto entre el reggae, rock, pop, soul y hip hop. Apasionada cantante con mensajes positivos y música alternativa que impacta a su audiencia.'
              case 'figu':  
                return 'Figu es un compositor y productor musical venezolano ⭐️ experto en el mundo del hip hop y la electrónica con mas de una decada de experiencia. '
              case 'solosoul':  
                return 'Solo Soul es un productor musical, ingeniero de sonido y director creativo venezolano ⭐️. Ha trabajado en diferentes agrupaciones gestando y desarrollando talentos en Habeatat.'
              default:
                return ' Artista venezolano ⭐️ de musica urbana | Habeatat.com'  
            }
          }
        }
      ]
    }
  },
//THE REPRO
 {
    path: '/therepro',
    name: 'therepro',
    component: TheRepro,
    meta: {
      forVisitors: true,
      title: () => {
        store.state.isIn = true
        return 'The Repro | HABEATAT'
      },
      metaTags: [
        {
          name: 'description',
          content: 'Sitio web musical para productores.'
        }
      ]
    }
  },
//LYRICS
 {
    path: '/letras/:artist',
    name: 'artist_lyrics',
    component: ArtistLyrics,
    meta: {
      forVisitors: true,
      title: route => {
        var artist = route.params.artist
        switch (artist) {
          case 'letra':
            return 'ℒ 【 Letra 】 Canciones ♬'
          case 'horus':
            return '༒ 【 Horus 】 Letra de canciones ♬'
          case 'lianamalva':
            return 'ꕥ 【 Liana Malva 】 Letra de canciones ♬'
          case 'figu':  
            return '☾ 【 Dj Figu 】 Letra de canciones ♬'
          case 'solosoul':  
            return '≭ 【 Solo Soul 】Letra Canciones ♬'
          default:
            return '♯ 【 HABEATAT 】 Letra de canciones ♬'
        }
      },
      metaTags: [
        {
          name: 'description',
          content:  route => {
            var artist = route.params.artist
            var alterText = ", videos y mucho más contenido. ⭐️ Conoce las letras de las mejores canciones de tu artista favorito junto a su trayectoria."
            switch (artist) {
              case 'letra':
                return 'Todas las canciones de Letra, el rapero venezolano'+alterText
              case 'horus':
                return 'Letra de todas las canciones del rapero venezolano Horus'+alterText
              case 'lianamalva':
                return 'Letra de todas las canciones de la artista venezolana Liana Malva'+alterText
              case 'figu':  
                return 'Letra de todas las canciones de Dj Figu, videos y mucho más contenido'+alterText
              case 'solosoul':  
                return 'Letra de todas las canciones del productor venezolano Solo Soul'+alterText
              default:
                return 'Letra de todas las canciones de artistas'+alterText
            }
          }
        }
      ]
    }
  },
 {
    path: '/letras/:artist/:song',
    name: 'lyrics',
    component: Lyrics,
    meta: {
      forVisitors: true,
      title: route => {
        var artist = route.params.artist
        var song = route.params.song
        var songCapitalized = song.charAt(0).toUpperCase() + song.slice(1)
        song = songCapitalized.replace(/-/g, " ")
        switch (artist) {
          case 'letra':
            artist = 'Letra ℒ'
            break
          case 'horus':
            artist = 'Horus ༒'
            break
          case 'lianamalva':
            artist = 'Liana Malva ꕥ'
            break
          case 'figu':  
            artist = 'Dj Figu ☾'
            break
          case 'solosoul':  
            artist = 'Solo Soul ≭'
            break
          default:
            artist = route.params.artist.charAt(0).toUpperCase() + route.params.artist.slice(1)
            break
        }
        return `⇨ Letra de la canción ${song} de ${artist}`
      },
      metaTags: [
        {
          name: 'description',
          content:  route => {
            var artist = route.params.artist
            var song = route.params.song
            var songCapitalized = song.charAt(0).toUpperCase() + song.slice(1)
            song = songCapitalized.replace(/-/g, " ")
            switch (artist) {
              case 'letra':
                artist = 'Letra'
                break
              case 'horus':
                artist = 'Horus'
                break
              case 'lianamalva':
                artist = 'Liana Malva'
                break
              case 'figu':  
                artist = 'Dj Figu'
                break
              case 'solosoul':  
                artist = 'Solo Soul'
                break
              default:
                artist = route.params.artist.charAt(0).toUpperCase() + route.params.artist.slice(1)
                break
            }
            return `⭐️ Ven y conoce la letra de la canción ${song} interpretada por ${artist}, el video y mucho más contenido. Letra de canciones de los mejores artistas venezolanos en hip hop y rap de la actualidad.`
          }
        }
      ]
    }
  },
//LA CAPSULA
 {
    path: '/lacapsula',
    name: 'capsula',
    component: Capsula,
    meta: {
      forVisitors: true,
      title: '⋐ La Cápsula: estudio de grabación y producción musical | HABEATAT '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: 'Estudio de grabación y producción musical de vanguardia, servicios de audio digital y post producción para contenido audiovisual, edición, corrección, doblaje, foley, jingles, mezcla y master "arte sonoro".'
        }
      ]
    }
  },
//CONTACTO
 {
    path: '/contacto',
    name: 'contact',
    component: Contact,
    meta: {
      forVisitors: true,
      title: 'Contáctanos | HABEATAT',
      metaTags: [
        {
          name: 'description',
          content: 'Queremos saber tu opinion acerca de nuestro contenido musical, por favor se libre de contactarnos, tu opinion es muy valiosa para nosotros y nos ayuda a mejorar en lo que hacemos.'
        }
      ]
    }
  },
  //ADMINISTRADOR
    //LOGIN
 {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      forVisitors: true,
      title: 'Inicio de Sesión | HABEATAT'
    }
  },
    //DASHBOARD
 {
    path: '/tablero',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      forAuth: true,
      title: 'Panel de control | HABEATAT',
    }
  },
    //PRIVACY POLICY
 {
    path: '/politica-de-privacidad',
    name: 'pp',
    component: PP,
    meta: {
      forVisitors: true,
      title: 'Políticas de privacidad | HABEATAT',
      metaTags: [
        {
          name: 'description',
          content: 'Política de Privacidad que establece los términos en que Habeatat usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web.'
        }
      ]
    }
  },
    //TERMS AND CONDITIONS
 {
    path: '/terminos-y-condiciones',
    name: 'tc',
    component: TmCd,
    meta: {
      forVisitors: true,
      title: 'Términos y Condiciones de Uso | HABEATAT',
      metaTags: [
        {
          name: 'description',
          content: 'Habeatat a través de su sitio web concede una licencia para que los usuarios utilicen los productos y servicios proporcionados en este sitio web de acuerdo a los Términos y Condiciones que se describen en este documento.'
        }
      ]
    }
  },
].concat(Events, Content)

});