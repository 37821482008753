<template>
	<div class="tile is-parent is-vertical">
		<div class="tile is-child">
			<button class="button" type="button" @click="modalActive=1, pressPicInput(null)">
				<span class="icon"> <i class="fa fa-user-plus"></i></span> <span> Nuevo Artista</span>
			</button>
			<input 
				class="file-input is-invisible" 
				type="file" 
				name="artistPic" 
				ref="artistPic"
				accept="image/*" 
				@change="selectPic($event.target)">
		</div>
		<div class="tile is-child">
			<!-- ARTIST BLOCKS -->
			<transition-group  name="fadeRight" tag="div" class="columns is-multiline is-centered h-margin-center-lat is-mobile">
				<div 
				v-for="art in artists" 
				class="column is-6-mobile is-2-tablet" 
				:key="art.id">
					<div :class="['box', art.is_active ? 'has-background-grey-light' : 'has-background-black-bis']">
						<div class="columns is-mobile">
							<div class="column is-8">
								<p class="has-text-white is-size-7-mobile is-size-5-fullhd is-size-6 h-separator60 h-overflow-hide">{{art.name}}</p>
								<figure>
									<img :src="art.picture">
								</figure>
							</div>
							<div class="column is-4 is-size-5-mobile is-size-4-fullhd is-size-5">
								<a title="Foto" v-tooltip.right="'Cambiar foto de perfil de '+art.name"
								@click="modalActive=2, artist_id=art.id, pressPicInput(art)">
									<i class="far fa-id-badge has-text-white"></i>
								</a>
								<br>
								<a 	title="Información" 
								v-tooltip.right="'Información básica de '+art.name"
									@click="formData = {
										name: art.name, 
										email: art.email, 
										description: art.description, 
										priority: art.priority,
										id:art.id
									}, formModal1=true">
									<i class="fas fa-info-circle has-text-white"></i>
								</a>
								<br>
								<a title="Listas de reproducción" v-tooltip.right="'Listas de reproducción de '+art.name"
								@click="setArtistDetail(art.id, 'playlist')">
									<i class="fas fa-film has-text-white"></i>
								</a>
								<br>
								<a title="Redes Sociales" v-tooltip.right="'Ver las redes sociales de '+art.name"
								@click="setArtistDetail(art.id, 'social')">
									<i class="fas fa-comments has-text-white"></i>
								</a>
								<br>
								<a title="Sitio web" v-tooltip.right="'Gestionar el contenido web de '+art.name"
								@click="setArtistDetail(art.id, 'web')">
									<i class="fas fa-window-maximize has-text-white"></i>
								</a>
								<br>
								<a title="Album" v-tooltip.right="'Album de imagenes '+art.name"
								@click="setArtistBanners({id:art.id, name:art.name})">
									<i :class="'fas fa-images h-animate-text-top'"></i>
								</a>
								<br>
								<a :title="art.is_active ? 'Desactivar':'Activar'" 
								v-tooltip.right="(art.is_active ? 'Desactivar':'Activar')+' el perfil de '+art.name+' en Habeatat'"
								@click="changeStatus({id:art.id, 'is_active':art.is_active})">
									<i :class="['fas', art.is_active ? 'fa-stop-circle':'fa-play-circle', 'has-text-white']"></i>
								</a>
								<br>
								<a title="Eliminar" v-tooltip.right="'Eliminar completamente a '+art.name+' de Habeatat'"
								@click="confirmDestroy(art.id)">
									<i class="fas fa-trash has-text-white"></i>
								</a> 
							</div>
						</div>
					</div>
				</div>
			</transition-group>
		</div>

		<!-- Modals -->
		<!-- Cropper profile image and Artist Form -->
		<cropPicModal
			v-if="selectedImage != ''"
			:modalShow="cropperModal"
			:imgSrc="selectedImage"
			:mTitle="'Crear un nuevo Artista'"
			:mForm="2"
			@imageCropped="saveArtist"
			@modalClose="closeCropperModal()" 
			:charging="modalLoading"/>
		<!-- Cropper profile image -->
		<onlyCropPicModal 
			v-if="selectedImage != ''"
			:modalShow="onlyCropperModal"
			:imgSrc="selectedImage"
			:prevImg="artist_selected.picture"
			:mTitle="'Actualizar foto de Artista'"
			@modalClose="closeCropperModal()"
			@imageCropped="changeArtistPhoto"
			:loading="modalLoading" />
		<!-- Artist Form -->
		<FormModal 
			:show="formModal1"
			:formType="3"
			:data="formData"
			@modal-close="formModal1 = false"
			@save-form="changeInfo"
			:charging="modalLoading"
			v-if="formModal1" />
		<!-- Artist Playlist Form -->
		<FormModal 
			:show="formModal2"
			:formType="4"
			:data="artist_playlist"
			@modal-close="formModal2 = false"
			@save-form="changePlaylist"
			:charging="modalLoading"
			v-if="formModal2" />
		<!-- Artist Social Form -->
		<FormModal 
			:show="formModal3"
			:formType="5"
			:data="artist_social"
			@modal-close="formModal3 = false"
			@save-form="changeSocial"
			:charging="modalLoading"
			v-if="formModal3" />
		<!-- Artist WEB Form -->
		<FormModal 
			:show="formModal4"
			:formType="6"
			:data="artist_web"
			@modal-close="formModal4 = false"
			@save-form="changeWeb"
			:charging="modalLoading"
			v-if="formModal4" />

		<bannerModal 
			:show="bannerModal" 
			:item="artist_selected"
			:type="'artist'"
			@modal-close="artist_selected={}, bannerModal=false"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder"
			v-if="'id' in artist_selected" />

		<PicModal :show="picModal" />

		<ConfirmModal 
			:show="confirmModal"
			v-if="confirmModal"
			:message="confirmText"
			@modal-close="confirmModal = false"
			@modal-submit="confirmTrue ? destroyArtist() : confirmDestroy(artist_id)" />
	</div>
</template>

<script>
	import cropPicModal from '@/components/modal/cropper'
	import onlyCropPicModal from '@/components/modal/onlyCropper'
	import PicModal from '@/components/modal/pic'
	import FormModal from '@/components/modal/form'
	import bannerModal from '@/components/modal/banner'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'adminArtist',
		props: ['token','api','priorityOrder'],
		components: {
			cropPicModal,
			onlyCropPicModal,
			PicModal,
			FormModal,
			bannerModal,
			ConfirmModal
		},
		data() {
			return {
				artists: [],
				artist_id: 0,
				artist_playlist: {},
				artist_social: {},
				artist_web: {},
				artist_selected: {},
				modalActive: 1,
				cropperModal: false,
				onlyCropperModal: false,
				picModal: false,
				formModal1: false,
				formData: {},
				formModal2: false,
				formModal3: false,
				formModal4: false,
				bannerModal: false,
				confirmModal: false,
				confirmText: '',
				confirmTrue: false,
				confirmCount: 1,
				modalLoading: false,
				selectedImage: '',
				basePath: '/artist/'
			}
		},
		created() {
			this.setArtists()
		},
		methods: {
			setArtists() {
				this.$http
					.get(this.api+this.basePath+'init/', this.token)
					.then(response => {
						this.artists = response.data
					})
			},
			setArtistDetail(artist_id, detail) {
				this.picModal = true
				this.$http
					.get(this.api+this.basePath+detail+'/'+artist_id+'/', this.token)
					.then(response => {
						var result = response.data
						if (detail === 'playlist') {
							this.artist_playlist = result
							this.formModal2 = true
						} else if ( detail === 'social') {
							this.artist_social = result
							this.formModal3 = true
						} else if ( detail === 'web') {
							this.artist_web = result
							this.formModal4 = true
						}
						this.picModal = false
					})
					.catch(error => {
						if (error.response.status === 404) {
							const formData = new FormData();
							formData.append('artist', artist_id);
							this.$http
								.post(this.api+this.basePath+detail+'/', formData, this.token)
								.then(response => {
									var result = response.data
									if (detail === 'playlist') {
										this.artist_playlist = result
										this.formModal2 = true
									} else if ( detail === 'social') {
										this.artist_social = result
										this.formModal3 = true
									} else if ( detail === 'web') {
										this.artist_web = result
										this.formModal4 = true
									}
									this.picModal = false
								})
								.catch(() => {
									this.picModal = false
								})
						}
					})
			},
			setArtistBanners(data) {
				this.artist_selected = data
				this.bannerModal = true
			},
			setFormData(data) {
				const formData = new FormData();
				if("name" in data) {
					formData.append('name', data.name);
				}
				if("description" in data) {
					formData.append('description', data.description);
				}
				if("email" in data) {
					formData.append('email', data.email);
				}
				if("priority" in data) {
					formData.append('priority', data.priority);
				}
				if("facebook" in data) {
					formData.append('facebook', data.facebook);	
				}
				if("spotify" in data) {
					formData.append('spotify', data.spotify);
				}
				if("instagram" in data) {
					formData.append('instagram', data.instagram);
				}
				if("twitter" in data) {
					formData.append('twitter', data.twitter);
				}
				if("youtube" in data) {
					formData.append('youtube', data.youtube);
				}
				if("is_active" in data) {
					formData.append('is_active', !data.is_active);
				}
				if("text" in data) {
					formData.append('text', data.text);
				}
				if("hiring" in data) {
					formData.append('hiring', data.hiring);
				}
				if("calendar" in data) {
					formData.append('calendar', data.calendar);
				}
				if("url" in data) {
					formData.append('url', data.url);
				}
				return formData
			},
			pressPicInput(art){
				if(art) this.artist_selected = art
				this.$refs.artistPic.click()
			},
			selectPic(val) {
				if (!val.files.length || !val.files[0].type.includes('image/')){ 
					alert('Por favor seleccione un archivo de tipo imagen.');
					return;
				}
				const picture = val.files[0];
				if (typeof FileReader === 'function') {
					const reader = new FileReader();

					reader.onload = (event) => {
						var review = event.target.result;
						this.selectedImage = review
					};

					reader.readAsDataURL(picture);
				} else {
					alert('Sorry, FileReader API not supported');
				}

				this.openCropperModal(this.modalActive);
			},
			openCropperModal(active) {
				if(active === 1) {
					this.cropperModal = true
				} else {
					this.onlyCropperModal = true
				}
			},
			closeCropperModal() {
				this.cropperModal = false
				this.onlyCropperModal = false
				this.selectedImage = ''
				const input = this.$refs.artistPic
				input.type = 'text'
				input.type = 'file'
			},
			saveArtist(artist) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('picture', artist.pic);
				formData.append('name', artist.data.name);
				formData.append('email', artist.data.email);
				formData.append('description', artist.data.description);
				formData.append('priority', artist.data.priority);
				this.$http
					.post(this.api+this.basePath+'init/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha creado un nuevo Artista correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.artists.push(response.data.item)
							this.priorityOrder(this.artists)
						}); 
					})
					.catch(() => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido cargar la imagen del Artista al servidor.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})		
					})
			},
			changeArtistPhoto(pic) {
				this.modalLoading = true
				const formData = new FormData();
				formData.append('picture', pic);
				this.$http
					.patch(this.api+this.basePath+'init/'+this.artist_id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.closeCropperModal()
						var item = response.data.item
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'La foto de perfil se ha actualizado correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							for (var art in this.artists) {
								if (this.artists[art].id === item.id) {
									this.artists[art].picture = item.picture
									break
								}
							}
						}); 
					})
					.catch(() => {
						this.modalLoading = false
						this.closeCropperModal()
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido actualizar la imagen del Artista.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			},
			changeStatus(data) {
				this.picModal = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+'init/'+data.id+'/', formData, this.token)
					.then(response => {
						this.picModal = false
						var item = response.data.item
						for (var art in this.artists) {
							if (this.artists[art].id === item.id) {
								this.artists[art].is_active = item.is_active
								break
							}
						}
					})	
			},
			changeInfo(info) {
				this.modalLoading = true
				const formData = this.setFormData(info)
				this.$http
					.patch(this.api+this.basePath+'init/'+info.id+'/', formData, this.token)
					.then(response => {
						this.modalLoading = false
						this.formModal1 = false
						var item = response.data.item
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado la informacion correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
						for (var art in this.artists) {
							if (this.artists[art].id === item.id) {
								this.artists[art].name = item.name
								this.artists[art].description = item.description
								this.artists[art].email = item.email
								this.artists[art].priority = item.priority
								break
							}
						}
						this.priorityOrder(this.artists)
						}); 
					})
			},
			changePlaylist(data) {
				this.modalLoading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+"playlist/"+data.id+'/', formData, this.token)
					.then(() => {
						this.modalLoading = false
						this.formModal2 = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se han actualizado las listas de reproduccion correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
			},
			changeSocial(data) {	
				this.modalLoading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+"social/"+data.id+'/', formData, this.token)
					.then(() => {
						this.modalLoading = false
						this.formModal3 = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado las Redes Sociales correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
			},
			changeWeb(data) {
				this.modalLoading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+this.basePath+"web/"+data.id+'/', formData, this.token)
					.then(() => {
						this.modalLoading = false
						this.formModal4 = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha actualizado el Sitio Web correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
			},
			confirmDestroy(artist_id) {
				this.artist_id = artist_id
				this.confirmModal = false
				if ( this.confirmCount === 1 ) {
					this.confirmText = "Esta seguro de que quiere eliminar por completo a este Artista junto a todo su contenido social y multimedia?"
					this.confirmCount++
					this.confirmModal = true
				} else {
					this.confirmTrue = true
					this.confirmText = "Esta usted completamente seguro? una vez realizado el proceso no se podra revertir."
					this.confirmModal = true
				}
			},
			destroyArtist() {
				this.confirmModal = false
				this.confirmTrue = false
				this.confirmCount = 1
				this.picModal = true
				this.$http
					.delete(this.api+this.basePath+'init/'+this.artist_id+'/', this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha eliminado al Artista correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.picModal = false
							var item = parseInt(response.data.item.id)
							for (var i in this.artists) {
								if (this.artists[i].id === item) {
									this.artists.splice(i, 1);
									break;
								}
							}
						})
					})
					.catch(() => {
						this.picModal = false
						this.$swal({
							type: "error",
							title: "Ops!",
							text: 'Ha ocurrido un error, no se ha podido eliminar el contenido e imagenes del Artista.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						})	
					})
			}

		}
	}
</script>
