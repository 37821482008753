<template>
	<div>
		<div class="field">
			<label class="label">Título</label>
			<div class="control">
				<input class="input" type="text" placeholder="Título de la imagen" name="title" v-model="mform.title">
			</div>
		</div>
		<div class="field">
			<div class="control has-text-centered">
				<label class="checkbox">
					<input class="h-checkbox" type="checkbox" name="is_cover" v-model="mform.is_cover">
					Colocar de portada?
				</label>
			</div>
		</div>
		<div class="field is-horizontal" v-if="!mform.is_cover">
			<div class="field-label is-normal">
				<label class="label">Prioridad</label>
			</div>
			<div class="field-body">
				<div class="field has-text-left">
					<p class="control select">
						<select name="priority" id="priority" v-model="mform.priority">
							<option value="1">1 - Prioridad maxima</option>
							<option value="2">2</option>
							<option value="3">3 - Prioridad alta</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6 - Prioridad media</option>
							<option value="7">7</option>
							<option value="8">8 - Prioridad de videos</option>
							<option value="9">9 - Prioridad baja</option>
							<option value="10">10 - Por defecto</option>
						</select>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormFlyerDetail',
		props: ['formdata'],
		data() {
			return {
				mform: {
					priority: 10,
					title: '',
					is_cover: false
				}
			}
		},
		created() {
			this.setInfo()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setInfo() {
				if (typeof this.formdata != 'undefined') {
					if(typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if(typeof this.formdata.priority != 'undefined') {
						this.mform.priority = this.formdata.priority
					}
					if(typeof this.formdata.is_cover != 'undefined') {
						this.mform.is_cover = this.formdata.is_cover
					}
				}
			}
		}
	}
</script>