<template>
	<div class="content-detail columns is-vcentered" v-if="typeof pageInfo != 'undefined'">
		<div class="column is-2-fullhd is-1"
			@mouseover="animate.left = true"
			@mouseout="animate.left = false">
			<!-- INTERNAL PAGINATION TOLEFT DESKTOP -->
			<div class="lat-buttons left is-hidden-mobile">
				<router-link 
				v-if="prevPages.length > 0"
				v-tooltip.bottom="prevPages[prevPages.length-1].name"
				:to="'/'+$store.state.internalPage.page+'/'+prevPages[prevPages.length-1].name_id">
					<span class="icon fa-3x"><i :class="{'icon-play-prev':true, 'h-animate-text-left':animate.left}"></i></span>
				</router-link>
			</div>
			<!-- INTERNAL PAGINATION MOBILE -->
			<div class="columns is-mobile is-hidden-tablet is-centered is-vcentered">
				<div class="column is-3">
					<router-link 
					v-if="prevPages.length > 0"
					:to="'/'+$store.state.internalPage.page+'/'+prevPages[prevPages.length-1].name_id"
					v-tooltip.bottom="prevPages[prevPages.length-1].name">
						<i class="icon-play-prev is-size-4"></i>
					</router-link>
				</div>
				<div class="column">
					<p class="is-size-4">
						{{pageInfo.name}}
					</p>
				</div>
				<div class="column is-3 is-text-left">
					<router-link 
					v-if="nextPages.length > 0"
					:to="'/'+$store.state.internalPage.page+'/'+nextPages[0].name_id"
					v-tooltip.bottom="nextPages[0].name">
						<i class="icon-play-next is-size-4"></i>
					</router-link>	
				</div>
			</div>

		</div>
		<div class="column  px-0">
			<div class="columns">
				<div class="column pl-0">
					<HVideoBlock 
					:ownerkey="key"
					:channel="pageInfo.channel"
					:galery="pageInfo.album"
					:name="pageInfo.name" />					
				</div>
				<div class="column pr-0">
					<HDetails 
					:hname="pageInfo.name"
					:hdescription="pageInfo.description"
					:hweb="pageInfo.web"
					:hemail="pageInfo.email"
					:hasPlayer="pageInfo.playlist != ''"
					@show-more-description="openDesModal()"/>
					<div class="h-separator20"></div>
					<HSong v-if="pageInfo.playlist != ''" :playList="pageInfo.playlist" />

					<div class="h-separator-t4">
						<HSocial :social="pageInfo.social"/>
					</div>					
				</div>
			</div>
		</div>
		<!-- INTERNAL PAGINATION TORIGHT DESKTOP -->
		<div class="column is-2-fullhd is-1 lat-buttons right is-hidden-mobile "
				@mouseover="animate.right = true"
				@mouseout="animate.right = false"		>
			<div class="lat-buttons right">
				<router-link 
				v-if="nextPages.length > 0"
				v-tooltip.bottom="nextPages[0].name"
				:to="'/'+$store.state.internalPage.page+'/'+nextPages[0].name_id">
					<span class="icon fa-3x"><i :class="{'icon-play-next':true, 'h-animate-text-right':animate.right}"></i></span>
				</router-link>	
			</div>
		</div>
		<!-- KEYWORDS -->
			<h-keywords
			:h1="keywords.title" 
			:h2List="keywords.subtitles"
			:h3List="keywords.relevant"/>
	</div>
</template>

<script>
	import HVideoBlock 		from '@/components/general/videoBlock'
	import HDetails 		from '@/components/general/details'
	import HSong 			from '@/components/general/song'
	import HSocial 			from '@/components/general/social'
	import Keywords 		from '@/components/general/keywords'
	export default {
		name: 'ArtistDetail',
		data() {
			return {
				hId: this.$route.params.id,
				key: this.$store.state.googleAPIKey,
				nextPages: [],
				prevPages: [],
				page: 'artistas',
				pageName: 'artist',
				container: false,
				animate: {
					left: false,
					right: false
				}
			}
		},
		components: {
			HVideoBlock,
			HDetails,
			HSong,
			HSocial,
			'h-keywords':Keywords
		},
		created() {
			this.setInternalPage()
		},
		computed: {
			pageInfo() {
				return this.getPageInfo()
			},
			keywords() {
				return this.$store.getters.GetArtistsKeyWords(this.pageInfo.name_id)
			}
		},
		methods: {
			setInternalPage() {
				this.$store.commit('inPage', {title: this.page, name: this.pageName, container: this.container})
			},
			getPageInfo() { 
				var result
				this.$store.state[this.pageName].sort(function(a, b) { 
					return a.priority - b.priority || b.id - a.id  ||  a.name.localeCompare(b.name);
				});
				var isChecked = false
				for (var art in this.$store.state[this.pageName]) {
					var QArt = this.$store.state[this.pageName][art]
					if (QArt.name_id === this.hId) {
						result = this.$store.state[this.pageName][art]
						isChecked = true
					} else if (isChecked) {
						this.nextPages.push(QArt)
					} else {
						this.prevPages.push(QArt)
					}
				}
				return result			
			},
			openDesModal() {
				this.$store.commit('changeModalStatus', {
					modal:'des', 
					status:true, 
					info: {
						title: this.pageInfo.name,
						description: this.pageInfo.description
					}
				})
			}
		}
	}
</script>

<style>
	/* .lat-buttons {
		width: 100%;
	}
	.lat-buttons i {
		display: inline-block;
		vertical-align: middle;
		line-height: normal;
		font-size: 3em;
	}	
	.lat-buttons.left {
		text-align: center;
	}
	.lat-buttons.right {
		text-align: left;
	}
	.lat-buttons.left, .lat-buttons.right {
		line-height: 25em;
	} */
</style>