// EVENTS COMPONENTS
import Event          from '@/components/events/index'
import EventPortrait  from '@/components/events/portrait'
import EventLibrary   from '@/components/events/library'
// POSTULATIONS
import Postulation    from '@/components/postulations/post_sign'
import PublicVotes    from '@/components/postulations/publicVotes'

var d = new Date
const events = [
//EVENTOS
{
    path: '/eventos',
    name: 'event',
    component: Event,
    meta: {
      forVisitors: true,
      title: '≓ Los mejores eventos de rap y hip hop en toda latinoamerica del '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: '🔶 Salimos de lo tradicional, entretenemos, educamos y conectamos talentos con el público. Te ofrecemos eventos musicales de contenido original nunca antes visto, temas de hip hop, rap y música del momento.'
        },
      ]
    }
  },
//EVENT DETAILS
 {
    path: '/eventos/:id/portada',
    name: 'event_portrait',
    component: EventPortrait,
    meta: {
      forVisitors: true,
      title: route => {
        var event = route.params.id
        switch (event) {
          case 'sinfronteras':
            return '➲ Sin Fronteras: festival latinoamericano de hip hop. '+d.getFullYear()
          case 'raploncesto':
            return '➲ Raploncesto: evento deportivo y musical en Venezuela. '+d.getFullYear()
          case 'bdm':
            return '➲ BDM: Torneo de freestyle. '+d.getFullYear()
          case 'noseaceptanpollos':
            return '➲ No se aceptan pollos: torneo clasificatorio de freestyle. '+d.getFullYear()
          default:
            return '➲ Evento | HABEATAT '+d.getFullYear()
        }
      },
      metaTags: [
        {
          name: 'description',
          content: route => {
            var event = route.params.id
            switch (event) {
              case 'sinfronteras':
                return 'Sin Fronteras es un Festival internacional 🎉 que forma parte de un circuito latinoamericano de música independiente. Ofrece una experiencia interesante al mezclar en un escenario distintos talentos del hip hop latino.'
              case 'raploncesto':
                return 'Raploncesto 🏀 es un evento deportivo / musical que involucra a los mejores raperos de Venezuela y los enfrenta en una cancha de baloncesto. Este evento contiene diferentes temas de música urbana y da libre paso al freestyle.'
              case 'bdm':
                return 'BDM ⭐ Torneo de improvisación fundado en CHILE en el que participan 17 países de latinoamérica. En cada país se presentan los mejores exponentes del freestyle en una batalla para seleccionar al representante que usará su destreza vocal y agilidad mental para defender su bandera en un campeonato internacional.'
              case 'noseaceptanpollos':
                return 'No se aceptan pollos ⭐ Junto a Mcklopedia organizamos la batalla de freestyle clasificatoria para elegir al representante que irá a la Red Bull Batalla de Los Gallos Internacional. Hasta la fecha hemos realizado cinco ediciones en la ciudad de Caracas, Venezuela.'
              default:
                return 'Evento musical original ⛹️ | Habeatat.com'  
            }
          } 
        }
      ]
    }
  },
  {
    path: '/eventos/:id',
    name: 'event_library',
    component: EventLibrary,
    meta: {
      forVisitors: true,
      title: route => {
        var event = route.params.id
        switch (event) {
          case 'sinfronteras':
            return '➲ Sin Fronteras: festival latinoamericano de hip hop. '+d.getFullYear()
          case 'raploncesto':
            return '➲ Raploncesto: evento deportivo y musical en Venezuela. '+d.getFullYear()
          case 'bdm':
            return '➲ BDM: Torneo de freestyle. '+d.getFullYear()
          case 'noseaceptanpollos':
            return '➲ No se aceptan pollos: torneo clasificatorio de freestyle. '+d.getFullYear()
          default:
            return '➲ Evento | HABEATAT '+d.getFullYear()
        }
      },
      metaTags: [
        {
          name: 'description',
          content: route => {
            var event = route.params.id
            switch (event) {
              case 'sinfronteras':
                return 'Sin Fronteras es un Festival internacional 🎉 que forma parte de un circuito latinoamericano de música independiente. Ofrece una experiencia interesante al mezclar en un escenario distintos talentos del hip hop latino.'
              case 'raploncesto':
                return 'Raploncesto 🏀 es un evento deportivo / musical que involucra a los mejores raperos de Venezuela y los enfrenta en una cancha de baloncesto. Este evento contiene diferentes temas de música urbana y da libre paso al freestyle.'
              case 'bdm':
                return 'BDM ⭐ Torneo de improvisación fundado en CHILE en el que participan 17 países de latinoamérica. En cada país se presentan los mejores exponentes del freestyle en una batalla para seleccionar al representante que usará su destreza vocal y agilidad mental para defender su bandera en un campeonato internacional.'
              case 'noseaceptanpollos':
                return 'No se aceptan pollos ⭐ Junto a Mcklopedia organizamos la batalla de freestyle clasificatoria para elegir al representante que irá a la Red Bull Batalla de Los Gallos Internacional. Hasta la fecha hemos realizado cinco ediciones en la ciudad de Caracas, Venezuela.'
              default:
                return 'Evento musical original ⛹️ | Habeatat.com'  
            }
          } 
        }
      ]
    }
  },
  //EVENT PUBLIC VOTES
 {
    path: '/eventos/:event/votar',
    name: 'event_votes',
    component: PublicVotes,
    meta: {
      forVisitors: true,
      title: 'Votaciones | HABEATAT '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: 'Votaciones'
        }
      ]
    }
  },
 {
    path: '/eventos/:event/votar/:person',
    name: 'event_votes_with_details',
    component: PublicVotes,
    meta: {
      forVisitors: true,
      title: 'Votaciones | HABEATAT '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: 'Votaciones'
        }
      ]
    }
  },
//POSTULATIONS
{
    path: '/postulaciones',
    name: 'postulations',
    component: Postulation,
    meta: {
      forVisitors: true,
      title: 'Postulaciones | HABEATAT '+d.getFullYear(),
      metaTags: [
        {
          name: 'description',
          content: 'Esta es tu oportunidad de postularte a uno de los eventos que promociona Habeatat, para asi tener la posibilidad de ser seleccionado y ganar brillantes recompenzas del mundo de la musica urbana.'
        }
      ]
    }
  }
]

export default events