<template>
	<div class="tile is-flex is-justify-content-center is-flex-direction-column">
			<div class="file has-name is-flex is-justify-content-center">
				<label class="file-label">
					<input 
						class="file-input" 
						type="file" 
						name="artist-bg" 
						ref="artist-bg"
						accept="image/*"
						@change="selectPic($event.target)">
					<span class="file-cta">
						<span class="file-icon">
							<i class="fas fa-upload"></i>
						</span>
						<span class="file-label">
							Cargar
						</span>
					</span>
					<span class="file-name">
						{{ newFileText }}
					</span>
				</label>
			</div>
			<!--Banner-->
			<div class="tile is-parent">
				<transition-group  
					name="fadeRight" 
					tag="div" 
					class="columns is-multiline is-centered h-margin-center-lat">
					<div 
						v-for="image in images" 
						class="column is-3" 
						:key="image.id">
						<div 
							:class="['box', image.is_active ? 'has-background-grey-light' : 'has-background-black-bis']">
							<figure>
								<img :alt="image.title" :src="image.src">
							</figure>
							<a 
								title="Informacion"
								v-tooltip.bottom="'Modificar la Información básica de la imagen: '+image.title" 
								@click="background={
									id: image.id,
									title:image.title,
									event: image.event,
									src:image.src},
									showUpdateHomeBackground=true">
								<i class="fas fa-edit has-text-white"></i>
							</a> | 
							<a 
								:title="image.is_active ? 'Desactivar':'Activar'"
								v-tooltip.bottom="(image.is_active ? 'Desactivar':'Activar')+' la imagen '+image.title+' en la pagina de eventos.'"
								@click="changeStatus({id:image.id,event:image.event, 'is_active':image.is_active})">
								<i :class="['fas', image.is_active ? 'fa-stop-circle':'fa-play-circle', 'has-text-white']"></i>
							</a> | 
							<a 
								title="Eliminar"
								v-tooltip.bottom="'Eliminar completamente el background '+image.title+' del Home'"
								@click="confirmDestroy(image.id)">
								<i class="fas fa-trash has-text-white"></i>
							</a> 
							
						</div>
					</div>
				</transition-group>
			</div>
			<!--Loading modal-->
			<PicModal 
			:show="soloPicModal"
			@modal-close="soloPicModal = false" />
			<!--Confirm Modal-->
			<ConfirmModal 
				:show="confirmModal"
				:message="confirmText"
				v-if="confirmModal"
				@modal-close="confirmModal = false"
				@modal-submit="destroyEventImageBackground()" />
			<!-- Form Home Background -->
			<FormModal 
				:show="showHomeBackground"
				:formType="14"
				:data="background"
				@modal-close="showHomeBackground = false"
				@save-form="saveHomeBackground"
				:charging="loading"
				v-if="showHomeBackground" />
			<!--Update Form Modal
				This modal recycles the used above but with an update function at 
				@save-form
			-->
			<FormModal 
				:show="showUpdateHomeBackground"
				:formType="15"
				:data="background"
				@modal-close="showUpdateHomeBackground = false"
				@save-form="updateHomeBackground"
				:charging="loading"
				v-if="showUpdateHomeBackground" />
	</div>
</template>

<script>
	import FormModal from '@/components/modal/form'
	import PicModal from '@/components/modal/pic'
	import ConfirmModal from '@/components/modal/confirm'
export default {

  name: 'eventBackground',
  props: ['token','api'],
  components: {
    FormModal,
    PicModal,
    ConfirmModal,
  },
  data () {
		return {
			picture: null,
			selectedImage: '',
			loading:false,
			newFileText: 'Fondo Evento',
			cropperModal: false,
			showHomeBackground: false,
			showUpdateHomeBackground: false,
			soloPicModal:false,
			basePath: '/event/background/',
			background:{
				event: undefined,
				title: '',
				src: '',
			},
			images: [],
			selectedImageId: undefined,
			confirmDestroyText: '',
			confirmModal: false,
			event_endpoint: '/event/init/'
		}
  },
  created(){
		this.getBackground()
		this.event()
  },
	methods: {
		selectPic(val) {
			if (!val.files.length || !val.files[0].type.includes('image/')){ 
				alert('Por favor seleccione un archivo de tipo imagen.');
				this.emitCloseModal();
				return;
			}
			this.picture = val.files[0];
			if (typeof FileReader === 'function') {
				const reader = new FileReader();

				reader.onload = (event) => {
					var review = event.target.result;
					this.selectedImage = review
				};

			} else {
				alert('Sorry, FileReader API not supported');
			}

			this.emitOpenModal();
		},
		emitOpenModal(){
			this.showHomeBackground=true
		},
		emitCloseModal(){
			this.showHomeBackground=false
			this.showUpdateHomeBackground=false
		},
		setFormData(data){
			const formData = new FormData();
			
				if ("id" in data && !!data.id){
					formData.append('id', data.id)
				}
				if("event" in data) {
					formData.append('event', data.event);
				}
				if("title" in data) {
					formData.append('title', data.title);
				}
				if("src" in data) {
					formData.append('src', data.src);
				}
				return formData
		},
		saveHomeBackground(data){
			this.loading = true;
			data.src = this.picture
			const formData = this.setFormData(data)
			this.$http
					.post(this.api+this.basePath, formData, this.token)
					.then(() => {
						this.loading = false
						this.playlistModalShow = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha agregado la imagen de fondo correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
					.then(()=>this.emitCloseModal())
					.then(()=> this.getBackground())
		},
		updateHomeBackground(data){
			this.loading = true;
			
			const formData = this.setFormData(data)
			
			this.$http
					.patch(`${this.api}${this.basePath}${data.id}/`, formData, this.token)
					.then(() => {
						this.loading = false
						this.playlistModalShow = false
						this.$swal({
							type: "success",
							title: "¡Completado!",
							text: 'Se ha agregado la imagen de fondo correctamente.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
							})
					})
					.then(()=>this.emitCloseModal())
					.then(()=> this.getBackground())
		},
		getBackground(){
			this.$http
				.get(`${this.api}${this.basePath}`, this.token)
				.then(response => this.images = response.data)
		},
		/*Updates is_active in Event background field*/
		changeStatus(status){
			this.soloPicModal=true
			const formData = new FormData()
			formData.append('is_active', !status.is_active)
			formData.append('event_id', status.event)
			this.$http
				.patch(
					`${this.api}${this.basePath}${status.id}/`,
					formData,
					this.token
				).then(
					() => this.getBackground())
				.then(()=> this.soloPicModal=false)
			},
		confirmDestroy(bg_id){
			this.selectedImageId = bg_id
			this.confirmText = '¿Está seguro que desea eliminar este background del Home Evento?'
			this.confirmModal = true
		},
		destroyEventImageBackground(){
			this.soloPicModal=true
			this.confirmModal = false
			this.$http
				.delete(`${this.api}${this.basePath}${this.selectedImageId}/`,
					this.token)
				.then(() => {
					this.$swal({
						type: "success",
						title: "¡Completado!",
						text: 'Se ha eliminado el fondo correctamente.',
						confirmButtonClass: "button",
						buttonsStyling: false,
						background: "white"
					})
					.then(()=> this.soloPicModal=false)
					.then(()=> this.getBackground())
				})
		},
		event(){
			this.$http.get(`${this.api}${this.event_endpoint}`, this.token)
				.then(response => this.$store.commit('makeEvent', response.data))
		}
    }
  }
</script>

<style scoped></style>