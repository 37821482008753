<template>
	<iframe 
	:src="'https://calendar.google.com/calendar/b/2/embed?title='+CalendarData.title.replace(/ /g,'%20')+'&amp;showTz=0&amp;showCalendars=0&amp;height=500&amp;wkst=2&amp;hl=es_419&amp;bgcolor=%23ffffff&amp;src='+CalendarData.ref+'&amp;color=%238C500B&amp;ctz=America%2FCaracas'" 
	style="border-width:0" 
	width="100%"
	frameborder="0" 
	scrolling="no"></iframe>

</template>

<script>
	export default {
		name: 'calendar',
		computed: {
			CalendarData() {
				return this.$store.getters.GetCalendarData
			},
		}
	}
</script>

<style scoped>
	iframe {
		height: 450px;
	}
	@media screen and (max-width: 999px){
		iframe {
			height: 350px;
		}
	}
</style>