<template>
<div class="container content-detail">
  <div class="columns is-vcentered">
      <div class="column is-1"
        @mouseover="animate.left = true"
        @mouseout="animate.left = false">
        <router-link 
        v-if="'name_id' in portrait.prev"
        :to="`/${page}/${portrait.prev.name_id}/portada`"
        v-tooltip.bottom="portrait.prev.name">
            <span class="icon fa-3x"><i :class="{'icon-play-prev':true, 'h-animate-text-left':animate.left}"></i></span>
        </router-link>
      </div>
    
    <div class="column">
        <transition name="fade" mode="out-in">
            <portrait
                :title="portrait.title"
                :description="portrait.description"
                :picture="portrait.picture"
                :showMore="portrait.more"
                @show-more="setUpLibrary()" />
        </transition>
    </div>

      <div class="column is-1"
        @mouseover="animate.right = true"
        @mouseout="animate.right = false">
        <router-link 
        v-if="'name_id' in portrait.next"
        :to="`/${page}/${portrait.next.name_id}/portada`"
        v-tooltip.bottom="portrait.next.name">
            <span class="icon fa-3x"><i :class="{'icon-play-next':true, 'h-animate-text-right':animate.right}"></i></span>
        </router-link>
      </div>
  </div>
</div>

</template>

<script>
import Portrait from '@/components/general/new_portrait'
export default {
    name:'EventPortrait',
    data:()=>({
        portrait: {
            next:{},
            prev:{},
            title:'',
            picture:'',
            description:'',
            more: false
        },
        page: 'eventos',
        pageName: 'event',
        container: true,
        base_path: '/event/list/portrait/',
        messages: {
            descriptionError: 'Descripción no disponible.'
        },
        animate: {
            left: false,
            right: false
        }
    }),
    components: {
        Portrait
    },
    created() {
        this.$store.commit('inPage', {title: this.page, name: this.pageName, container: this.container})
    },
    mounted() {
        this.setUp()
    },
    computed: {
        api() {
            return this.$store.getters.GetApiPath
        },
        events() {
            return this.$store.getters.GetEvent
        }
    },
    methods: {
        async setUp() {
            this.$store.commit('loader', 'y')
            await this.setPageInfo(this.$route.params.id)
            this.$store.commit('loader', 'n')
        },
        setPageInfo(portrait) {
            var cond = false,
                selected = {},
                prevList = [],
                nextList = []

            for (var event in this.events) {
                if (this.events[event].name_id === portrait) {
                    cond = true
                    selected = this.events[event]
                } else {
                    var item = this.events[event]
                    cond ? nextList.push(item):prevList.push(item)
                }
            }
            if(nextList.length > 0) this.portrait.next = nextList[0]
            if(prevList.length > 0) this.portrait.prev = prevList[prevList.length-1]
            //General Config
            this.portrait.title = selected.name
            if(selected) (this.portrait.picture = 
                selected.portrait 
                    ? (selected.portrait.picture != '' 
                            ? selected.portrait.picture : selected.picture)
                        : selected.picture, 
                this.portrait.description = 
                    selected.portrait 
                        ? (selected.portrait.description != '' 
                                ? selected.portrait.description : this.messages.descriptionError) 
                            : this.messages.descriptionError)
            
            selected.details.length > 0 ? this.portrait.more = true : this.portrait.more = false
            return true
        },
        async setUpLibrary(){
            this.$router.push(`/${this.page}/${this.$route.params.id}`)
        }
    }
}
</script>

<style>

</style>