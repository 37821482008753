<template>
	<div class="columns is-mobile is-centered h-margin-center h-fullwidth">
		<div class="column is-1" v-if='social.youtube != "" && typeof social.youtube != "undefined"'>
			<a @click="socialEvents(1)" rel="nofollow">
				<img 
				:src="color === 'white' ? '/img/youtube.svg' : '/img/RRSS1.svg'" 
				alt="Habeatat youtube social picture">
			</a>
		</div>
		<div class="column is-1" v-if='social.spotify != "" && typeof social.spotify != "undefined"'>
			<a @click="socialEvents(2)" rel="nofollow">
				<img :src="color === 'white' ? '/img/spotify.svg' : '/img/RRSS2.svg'" alt="Habeatat spotify social picture">
			</a>
		</div>
		<div class="column is-1" v-if='social.facebook != "" && typeof social.facebook != "undefined"'>
			<a @click="socialEvents(3)" rel="nofollow">
				<img :src="color === 'white' ? '/img/facebook.svg' : '/img/RRSS3.svg'" align="Habeatat facebook social picture">
			</a>
			<!--div id="fb-root"></div>
			<div 
			class="fb-like" 
			:data-href="facebookLink" 
			data-layout="button" 
			data-action="like" 
			data-size="small" 
			data-show-faces="true" 
			data-share="false"></div-->	
		</div>
		<div class="column is-1" v-if='social.instagram != "" && typeof social.instagram != "undefined"'>
			<a @click="socialEvents(4)" rel="nofollow">
				<img :src="color === 'white' ? '/img/instagram.svg' : '/img/RRSS4.svg'" alt="Habeatat instagram social picture">
			</a>			
		</div>
		<div class="column is-1" v-if='social.twitter != "" && typeof social.twitter != "undefined"'>
			<a @click="socialEvents(5)" rel="nofollow">
				<img :src="color === 'white' ? '/img/twitter.svg' : '/img/RRSS5.svg'" alt="Habeatat twitter social picture">
			</a>				
		</div>
	</div>
</template>

<script>
	export default {
		props: ['social', 'color'],
		/*mounted() {
			(function(d, s, id) {
				var js, fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) return;
				js = d.createElement(s); js.id = id;
				js.src = 'https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v3.2';
				fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));
		},*/
		computed: {
			youtubeLink() {
				return 'http://www.youtube.com/channel/'+
					this.social.youtube
					+'?sub_confirmation=1'
			},
			spotifyLink() {
				return 'https://open.spotify.com/'+
					this.social.spotify
			},
			facebookLink() {
				return 'https://www.facebook.com/'+
					this.social.facebook
			},
			instagramLink() {
				return 'https://www.instagram.com/accounts/login/?next=%2F'+
					this.social.instagram
					+'%2F&source=follow'
			},
			twitterLink() {
				return 'https://twitter.com/'+
					this.social.twitter
					+'?lang=es'
			}
		},
		methods: {
			socialEvents(event) {
				if (event === 1) { 
					window.open(this.youtubeLink, '_blank'); 
				} else if (event === 2) { 
					window.open(this.spotifyLink, '_blank') 
				} else if (event === 3) { 
					window.open(this.facebookLink, '_blank') 
				} else if (event === 4) { 
					window.open(this.instagramLink, '_blank') 
				} else if (event === 5) { 
					window.open(this.twitterLink, '_blank') 
				}
			}
		}
	}
</script>

<style scoped>
	img {
		width: 100% !important;
		opacity: .8;
		z-index: 10;
	}
	.column {
		padding-left: inherit !important;
		padding-right: inherit !important; 

	}
</style>