<template>
	<iframe 
	id="ytplayer" 
	type="text/html" 
	width="100%"
	:src="'https://www.youtube.com/embed/'+videoId+'?rel=0&autoplay=1'"
	frameborder="0"
	:title="title"
	allowfullscreen />
</template>

<script type="text/javascript">
	export default {
		name: 'SimpleVideo',
		props: ['videoId', 'title']
	}
</script>

<style type="text/css" scoped>
	iframe {
		min-height: 243px;
	}
</style>