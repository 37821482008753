<template>
<transition name="fade" >
    <div :class="{'modal':true, 'is-active':show}" v-if="show">
        <div class="modal-background" @click="$emit('close-modal')"></div>
        <div class="modal-card">

            <header class="modal-card-head h-background-dark">
                <p class="modal-card-title has-text-white">{{title}}</p>
                <button 
                class="delete" 
                aria-label="close"
                @click="$emit('close-modal')"></button>
            </header>
            <section class="modal-card-body h-scroll 3x">
                <pre class="h-ws-preline has-text-justified h-catamaran is-size-5">
                    {{description}}
                </pre>
            </section>

        </div>
    </div>	
</transition>
</template>

<script>
	export default {
		name: 'DescriptionModal',
		props: {
            show: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: [Text, String],
                default: ''
            }
        },
	}
</script>