<template>
  <section 
    @mouseover="stopCarousel"
    @mouseleave="changeDisplayedData"
    v-if="slidePosition === positionIndexArray" 
    class="main-container">
    <div class="carousel" id="carousel">
      <div v-for="(content,index) in contents"
        :key="content.id"
        :class="['carousel-item',
          index === slide ? 'visible' : 'hidden'
        ]">
        <img
          :class="['imgBackground',
            content.background === '' ? 'has-background-black' : null]"
          loading="lazy"
          :src="content.background != '' ? content.background : '/img/black-bg.png'"
          :alt="content.name"
        />
        <div class="carousel-artist p-6">
          <div class="carousel-artist_info is-flex is-flex-direction-column is-justify-content-start">
            <h1 
              class="
              title is-size-1 
              is-size-3-mobile 
              is-uppercase 
              has-text-white 
              has-text-left 
              px-5 py-4 opensans"
              >{{content.name}}</h1>
            <div
              class="
                    has-text-white
                    artist_bio
                    px-5
                  "
            >
              <p class="mb-3 is-size-5-widescreen has-text-white subtitle is-6 has-text-left opensans p-description">
                {{content.portrait.description.substring(0,314) + '...'}}
              </p>
            </div>
            <router-link 
              :to="{
                name: 'content_portrait',
                params: {id: content.name_id}}"
              class="button 
                  m-5
                  is-size-6
                  has-text-white-bis
                  has-background-grey-darker
                  button-width"
              >Ver más
          </router-link>
          </div>
          <div class="carousel-content is-flex">
            <div class="main_video m-2">
              <Event 
                v-if="typeof Calendar.ref != 'undefined' && Calendar.ref != ''"
                @open-calendar="showCalendar=true" 
                :Calendar="Calendar"
              />
              <Calendar 
                :show="showCalendar"
                @close-calendar="showCalendar = false" />
            </div>
            <div class="subscription-box m-2 is-centered-right width-caja is-flex has-background-grey-lighter">
              <contentSubscription/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="artist_social_desktop">
      <Social
        v-for="(content,index) in contents"
        :key="content.id"
        :class="[index === slide ? null : 'is-hidden',
          'is-flex is-justify-content-start social-width-desktop is-hidden-touch']" 
        :social="content.social"
        :color="color" 
        />
    </div>
    <div class="carousel-dots_list">
      <span
        v-for="(content,index) in contents"
        :key="content.id"
        @click="changeSlide(index)" 
        :class="['mx-2','p-1',index === slide ? 'has-background-white' : 'has-background-grey']">
      </span>
    </div>
  </section>
</template>

<script>
  import Event  from '@/components/news/event'
  import Calendar from '@/components/news/calendar'
  import contentSubscription from '@/components/general/contentSubscription'
  import Social from '@/components/general/social'
export default {
  name: "CarouselContenido",
  props: ['slidePosition'],
  data(){
    return {
      slide:0,
      play: false,
      positionIndexArray:2,
      showCalendar:false,
    }
  },
  created(){
    
  },
  methods:{
    changeDisplayedData(){
      return this.play = setInterval(()=>this.playCarousel(),4000)
    },
    stopCarousel(){
      /*using just this.play stops the carousel,
      if I do something else like this.play=false
      that won't work
      */
      return clearInterval(this.play)
    },
    changeSlide(index){
      this.slide = index
    },
    playCarousel(){
      if (this.slide === this.slideLength -1){
        this.slide= 0
      }else{
        this.slide++
      }
    }
  },
  components: {Event, Calendar, contentSubscription, Social},
  computed:{
    //Gets contents information
    contents(){
      return this.$store.state.content
    },
    Calendar(){
      return this.$store.state.calendar
    },
    slideLength(){
      return this.contents.length
    },
    startCarousel(){
      /*Starts carousel, if user changes section then it should
      stop where it left
      */
      return this.slidePosition === this.positionIndexArray ? this.changeDisplayedData() : this.stopCarousel()
    }
  }
};
</script>

<style scoped>
.carousel {
  overflow: hidden;
  position: relative;
}
.carousel-item {
  position:relative;

}
.carousel-item,
.carousel-item_hidden {
  display: none;
  animation: fadeVisibility 2s;
}

.carousel-item .imgBackground {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;

  /*For some reason, I had to use translateX 
  otherwise the background image would only
  be shown at the container's center left side*/

  transform: translateX(-50%);
}

.carousel-item.visible {
  display: block;
  background-size: cover;
}

/* Carousel Information */
.carousel-artist {
  position: relative;
  display: grid;
  grid-template: repeat(auto-fit, minmax(430px, 1fr)) / repeat(
      auto-fit,
      minmax(375px, 1fr)
    );
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.carousel-content{
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.button-width{
  width: 100px;
}
.width-caja{
  width: 100%;
  max-width: 250px;
}
/*TExt modifiers*/
  p.has-text-left{
    line-height: 1.5;
  }
  h1.title.is-1{
    font-size: 5rem;
  }
@keyframes fadeVisibility {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* iframe Calendar*/
iframe{
  height: 180px;
}

/*SUbscription box*/
.subscription-box{
  height: 320px;
}

@media screen and (max-width: 425px){
  .subscription-box{
    height: 250px;
  }
}
@media screen and (max-width: 1024px){
  .carousel-content{
    flex-direction: column;
  }
}
</style>