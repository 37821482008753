<template>
<div class="modal">
  <div class="modal-background"></div>
  <div class="modal-content">
    <h1 v-if="h1 != undefined">{{h1}}</h1>
    <div v-if="h2List != undefined">
      <h2 v-for="(h2, index) in h2List" :key="index">{{h2}}</h2> 
    </div>
    <div v-if="h3List != undefined">
      <h3 v-for="(h3, index) in h3List" :key="index">{{h3}}</h3>
    </div>
  </div>
  <button class="modal-close is-large" aria-label="close"></button>
</div>
</template>

<script type="text/javascript">
	export default {
		name:'Keywords',
		props: ['h1','h2List','h3List']
	}
</script>