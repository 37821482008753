<template>
	<aside class="menu has-text-left">
		<!---New Home Options --->
		<div class="dropdown is-hoverable">
			<p class="menu-label is-unselectable has-text-white">
				<i class="far fa-caret-square-down has-text-white"></i> Home
			</p>
			<div class="dropdown-menu">
				<ul class="menu-list dropdown-content">
					<li>
						<a 
						@click="menuSelection(69)"
						:class="{'has-background-link':(selected == 69)}">
							<i class="fas fa-users"></i> Artistas
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(70)"
						:class="{'has-background-link':(selected == 70)}">
							<i class="far fa-calendar-alt"></i> Eventos
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(71)"
						:class="{'has-background-link':(selected == 71)}">
							<i class="fas fa-box-open"></i> Contenido
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(72)"
						:class="{'has-background-link':(selected == 72)}">
							<i class="fas fa-capsules"></i> La Cápsula
						</a>
					</li>
				</ul>
			</div>
		</div>
		<br>
		<!--End new Home-->
		<div class="dropdown is-hoverable">
			<p class="menu-label has-text-white is-unselectable">
				<i class="far fa-caret-square-down has-text-white"></i> General
			</p>

			<div class="dropdown-menu">
				<ul class="menu-list dropdown-content">
					<li>
						<a 
						@click="menuSelection(11)"
						:class="{'has-background-link':(selected == 11)}">
							<i class="fas fa-images"></i> Flyers
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(12)"
						:class="{'has-background-link':(selected == 12)}">
							<i class="fas fa-calendar"></i> Calendario
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(13)"
						:class="{'has-background-link':(selected == 13)}">
							<i class="fas fa-comments"></i> Redes Sociales
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(14)"
						:class="{'has-background-link':(selected == 14)}">
							<i class="fas fa-key"></i> Llaves de acceso
						</a>
					</li>
				</ul>		
			</div>

		</div>
		<br>
		<div class="dropdown is-hoverable">
			<p class="menu-label is-unselectable has-text-white">
				<i class="far fa-caret-square-down has-text-white"></i> Administración
			</p>
			<div class="dropdown-menu">
				<ul class="menu-list dropdown-content">
					<li>
						<a 
						@click="menuSelection(21)"
						:class="{'has-background-link':(selected == 21)}">
							<i class="fas fa-users"></i> Artistas
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(22)"
						:class="{'has-background-link':(selected == 22)}">
							<i class="far fa-calendar-alt"></i> Eventos
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(23)"
						:class="{'has-background-link':(selected == 23)}">
							<i class="fas fa-box-open"></i> Contenido
						</a>
					</li>
				</ul>
			</div>
		</div>
		<br>
		<div class="dropdown is-hoverable">
			<p class="menu-label is-unselectable has-text-white">
				<i class="far fa-caret-square-down has-text-white"></i> La Capsula
			</p>
			<div class="dropdown-menu">
				<ul class="menu-list dropdown-content">
					<li>
						<a 
						@click="menuSelection(36)"
						:class="{'has-background-link':(selected == 36)}">
							<i class="fas fa-pager"></i> Página
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(37)"
						:class="{'has-background-link':(selected == 37)}">
							Usuarios
						</a>
					</li>
				</ul>
			</div>
		</div>
		<br>
		<div class="dropdown is-hoverable">
			<p class="menu-label is-unselectable has-text-white">
				<i class="far fa-caret-square-down has-text-white"></i> Música
			</p>
			<div class="dropdown-menu">
				<ul class="menu-list dropdown-content">
					<li>
						<a 
						@click="menuSelection(51)"
						:class="{'has-background-link':(selected == 51)}">
							<i class="fas fa-music"></i> Beats
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(52)"
						:class="{'has-background-link':(selected == 52)}">
							<i class="fas fa-sliders-h"></i> Lyrics
						</a>
					</li>
				</ul>
			</div>
		</div>
		<br>
		<div class="dropdown is-hoverable">
			<p class="menu-label is-unselectable has-text-white">
				<i class="far fa-caret-square-down has-text-white"></i> Postulaciones
			</p>
			<div class="dropdown-menu">
				<ul class="menu-list dropdown-content">
					<li>
						<a 
						@click="menuSelection(61)"
						:class="{'has-background-link':(selected == 61)}">
							<i class="fas fa-table"></i> Inicio
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(62)"
						:class="{'has-background-link':(selected == 62)}">
							<i class="far fa-address-card"></i> Postulados
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(63)"
						:class="{'has-background-link':(selected == 63)}">
							<i class="fas fa-users"></i> Jueces
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(64)"
						:class="{'has-background-link':(selected == 64)}">
							<i class="fas fa-poll"></i> Votos
						</a>
					</li>
					<li>
						<a 
						@click="menuSelection(68)"
						:class="{'has-background-link':(selected == 68)}">
							<i class="fas fa-thumbs-up"></i> Seleccionados
						</a>
					</li>
				</ul>
			</div>
		</div>

		<p class="menu-label logout has-text-white">
			<a @click="logout()">Desconectar <i :class="['fas', logoutButton ? 'fa-compact-disc fa-pulse':'fa-sign-out-alt', 'has-text-white']"></i></a>
		</p>
	</aside>
</template>

<script>
	export default {
		name: 'adminMenu',
		props:['selected', 'Titleselected'],
		data() {
			return {
				logoutButton: false
			}
		},
		computed: {
			api() {
				return this.$store.getters.GetApiPath
			},
			token() {
				return this.$store.getters.GetAxiosConfig(2)
			},
		},
		methods: {
			menuSelection(selected) {
				this.$store.commit('changeDashMenu', selected)
			},
			logout() {
				this.logoutButton=true
				this.$http
				.get(this.api+'/logout/', this.token)
				.then(() => {
					this.$store.dispatch('logout').then(() => {
						window.location.replace('/login')
					})
				})
			},
		}

	}
</script>

<style scoped>
	.menu-list a {
		transition: background .4s;
	}
	.menu-list a:hover {
		background: inherit;
	}
	.logout {
		font-weight: bold;
	}
	.menu-label { cursor: pointer; padding:5px }
	.has-background-link, .has-background-link i {
		color: white !important;
	}
</style>