<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background" @click="charging ? loadMessage() : $emit('modal-close')"></div>
			<div class="modal-content">
				<div class="box">
					<h4 class="title is-4">{{title}}</h4>
					<form @submit.prevent="saveFormInfo">
						<LyricInfoForm 		ref="formComponent" :data="data" v-if="formType == 1" />
						<LyricForm 			ref="formComponent" :data="data" v-else-if="formType == 2" />
						<MediaForm 			ref="formComponent" :data="data" v-else-if="formType == 3" />
						<PostulationForm 	ref="formComponent" :data="data" v-else-if="formType == 4" />
						<JudgeForm 			ref="formComponent" :data="data" v-else-if="formType == 5" />
						<JudgeFormUpdate 	ref="formComponent" :data="data" v-else-if="formType == 6" />
						<PasswordForm 		ref="formComponent" :data="data" v-else-if="formType == 7" />
						<br>
						<button type="submit"
						:class="{'button is-primary':true, 'is-loading':charging }">Guardar</button>
					</form>
				</div>
			</div>
			<button 
			class="modal-close is-large" 
			aria-label="close" 
			@click="$emit('modal-close')"
			:disabled="charging"></button>
		</div>	
	</transition>
</template>

<script>
	import LyricInfoForm from '@/components/admin/forms/formLyricInfo'
	import LyricForm from '@/components/admin/forms/formLyric'
	import MediaForm from '@/components/admin/forms/formMedia'
	import PostulationForm from '@/components/admin/forms/formPostulation'
	import JudgeForm from '@/components/admin/forms/formJudge'
	import JudgeFormUpdate from '@/components/admin/forms/formJudgeUpdate'
	import PasswordForm from '@/components/admin/forms/formPassword'
	export default {
		name: 'FormModal2',
		props: ['show', 'formType', 'data', 'charging','title'],
		components: {
			LyricInfoForm,
			LyricForm,
			MediaForm,
			PostulationForm,
			JudgeForm,
			JudgeFormUpdate,
			PasswordForm
		},
		methods: {
			saveFormInfo() {
				this.$validator.validateAll().then(result => {
					if (result) {
						if( this.formType == 2 ) {
							this.$emit('save-form', { lyric: this.$refs.formComponent.getLyric() })
						} else {
							this.$emit('save-form', this.$refs.formComponent.getFormData())
						}
					}
				})
			},
			loadMessage() {
				alert('Cargando... Porfavor espere.')
			}
		}
	}
</script>