<template>
	<div class="columns is-mobile is-centered is-vcentered">
		<div class="column is-1">
			<transition name="fade">		
				<a @click="goLeft()" v-if="(box.left != undefined && box.left.length > 0) && arrowShow">
					<i class="icon-repro-prev is-size-4-fullhd is-size-5-desktop is-size-6-touch is-unselectable"></i>
				</a>
			</transition>
		</div>
		<div class="column is-2" 
		v-for="b in box.center" 
		:key="b.id" 
		style="position:relative;">
			<router-link :to="'path' in b && b.path != '' ? b.path : '/'+section+'/'+b.name_id">
				<figure
				@mouseover="b.showName = true" 
				@mouseout="b.showName = false">
					<img :src="b.picture" 
					:class="{
						'art-picture':true, 
						'h-border-custom':true, 
						'h-filter-gray':b.showName,
						'h-rotate-10':b.init
					}"
					:ref="'img_'+b.id"
					:alt="(section =='artistas' ? 'foto perfil ':'Imagen de despliegue para ')+b.name">
				</figure>
				<transition name="fade">
					<p class="
						art-text 
						h-margin-center 
						is-size-3-fullhd 
						is-size-4-widescreen 
						is-size-5
						is-size-6-mobile
						is-size-6-touch
						is-unselectable 
						is-uppercase 
						has-text-weight-bold"
					v-show="b.showName"
					@mouseover="b.showName = true"
					@mouseout="b.showName = false">
						{{ b.name }}
					</p>
				</transition>
			</router-link>
		</div>
		<div class="column is-1">
			<transition name="fade">
				<a @click="goRight()" v-if="(box.right != undefined && box.right.length > 0) && arrowShow">
					<i class="icon-repro-next is-size-4-fullhd is-size-5-desktop is-size-6-touch is-unselectable"></i>
				</a>
			</transition>
		</div>
	</div>
</template>

<script>
	export default {
		props:['boxes','rbox','lbox','section'],
		name: 'ProfilePictures',
		data() {
			return {
				artistPics:{},
				arrowShow: false,
				box: {
					left: [],
					right: [],
					center: []
				}
			}
		},
		created(){
			this.makeInfo().then(() => {
				this.setAnimation(false)
			})
			
		},
		methods: {
			makeInfo() {
				return new Promise((resolve) => {
					this.box.left = [...this.lbox]
					this.box.center = [...this.boxes]
					this.box.right = [...this.rbox]
					resolve()
				})
			},
			setAnimation: async function(reverse){
				for ( var x in this.box.center ) {
					await this.$store.state.sleep(reverse ? 50:300);
					this.box.center[x].init = true;
				}
				await this.$store.state.sleep(300);
				this.arrowShow = true
			},
			goRight() {
				if (this.box.right.length > 0) {
					var lItem = this.box.center[0]
					var rItem = this.box.right[0]

					this.box.left.push(lItem)
					this.box.center.shift()

					this.box.center.push(rItem)
					this.box.right.shift()

					this.setAnimation(true)
					this.box.left[this.box.left.length -1].init = false
				}
			},
			goLeft() {
				if (this.box.left.length > 0) {
					this.box.right.unshift(this.box.center[this.box.center.length -1])
					this.box.center.pop()

					this.box.center.unshift(this.box.left[this.box.left.length -1])
					this.box.left.pop()

					this.setAnimation(true)
					this.box.right[0].init = false
				}
			}
		}
	}
</script>

<style scoped>
	.art-picture{
		width: 100%;
		transition: filter 1s, transform 1s, opacity 1s;
		transform:rotateY(90deg);
	}
	.art-text{
		writing-mode: vertical-lr;
		transform: rotate(180deg);
		position: absolute;
		left: 10%;
		bottom: 10%;
		color: white !important;
		text-shadow: 1px 1px 3px black;
	}
	.column{
		padding: 0.75rem 6px !important;
	}
	img.xxx{
		transform:rotateY(10deg);
	}
	.h-border-custom {
		border-radius: 15px;
	}
@media screen and (max-width: 999px){
	.h-border-custom {
		border-radius: 12px;
	}
	.art-text {
		bottom: 12%;
	}
}
</style>