<template>
	<div>
		<div class="main_video is-flex is-flex-direction-column is-align-content-flex-start is-justify-content-flex-start">
			<!-- shouldVideoStop is true only to the current artist shown in the carousel
			when the carousel changes artist, that prop changes to false, and in this case
			it'll update the missing param to an empty string -->
			<iframe
				class="video"
				:src="`https://www.youtube.com/embed/${shouldVideoStop === false ? '' : videoId}`"
				:title="title"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen>
			</iframe>
			<h2 class="is-flex is-justify-content-start is-size-5 my-2 is-uppercase has-text-white is-centered-left opensans">{{title.substring(0,28)}}...</h2>
    </div>
        <div class="other_video mt-4 is-centered-right">
          <YoutubeThumbnails 
						@update-video="updateVideo" 
						:artistPlaylist="thumbnails"
          />
        </div>
	</div>

</template>

<script>
	import YoutubeThumbnails from '@/components/general/youtubeThumbnails'
export default {

  name: 'YoutubeVideo',
  props: {
	playlist:{
		type:String,
		required: true
	},
	shouldVideoStop:{
		type: Boolean,
		required: true
	}
  },
  components: {
	YoutubeThumbnails
  },
  data () {
    return {
		youtubeEndpoint: "https://www.googleapis.com/youtube/v3/playlistItems",
		API_KEY: this.$store.state.googleAPIKey,
		data: undefined,
		videoId: '',
		title: '',
		thumbnails: [],
		MAX_RESULTS:4,
    }
  },
  created(){
	this.getYoutubePlaylist()
  },
  methods:{
		updateVideo(video){
			this.videoId =  video.contentDetails.videoId
			this.title = video.snippet.title
		},
	getYoutubePlaylist(){
		this.$http
			.get(this.youtubeEndpoint, {
				params:{
					part:'snippet, contentDetails',
					key:this.API_KEY,
					maxResults: this.MAX_RESULTS,
					playlistId: this.playlist
				}
			})
			.then(response => {
				this.handleData(response.data.items)
				// this.videoId = response.data.items.contentDetails.videoId
				// this.description= response.data.items.snippet.description
				// response.pop()
				// this.thumbnails = response.data.items
			})
	},
	handleData(data){
		this.videoId = data[0].contentDetails.videoId
		this.title = data[0].snippet.title
		data.shift() //removes 1st element from array
		this.thumbnails = data
	},
  }
}
</script>

<style lang="css" scoped>
	iframe {
		width: 430px;
		height: 242px;
	}
	@media screen and (max-width: 768px){
		iframe{
			width: 300px;
			height: 170px;
		}
	}
</style>