<template>
	<div>
		<div class="field">
			<label class="label">Título</label>
			<div class="control">
				<input 
					class="input" 
					type="text" 
					placeholder="Título de referencia" 
					name="title" 
					v-model="mform.title">
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label is-normal">
				<label class="label">Contenido</label>
			</div>
			<div class="field-body">
				<div class="field has-text-left">
					<p class="control select">
						<select 
							name="contentId" 
							id="content" 
							v-validation="'required'"
							v-model="mform.content">
							<option 
								v-for="content in contents"
								:key="content.id" 
								:value="content.id">
							{{content.name}}
						</option>
						</select>
						<transition name="fade">
							<span class="tag is-rounded" v-show="errors.has('contentId')">
							{{ errors.first('contentId') }}
							</span>
						</transition>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormContentBackground',
		props: ['formdata'],
		inject:['$validator'],
		data() {
			return {
				mform: {
					title: "",
					src: '',
					content: undefined
				},
				//tag:''
			}
		},
		created() {
			this.emitForm()
			this.setInfo()
			
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		computed:{
			contents(){
				return this.$store.state.content
			},
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},

			setInfo() {
				
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if (typeof this.formdata.src != 'undefined') {
						this.mform.src = this.formdata.src
					}
					if (typeof this.formdata.content != 'undefined'){
						this.mform.content = this.formdata.content
					}
				}		
			}
		}
	}
</script>