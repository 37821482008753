import Vue 				from 'vue'
import App 				from './App.vue'
import '@/assets/css/style.css' 		//Custom CSS
import '@/assets/css/font.css' 			//Custom Fonts CSS
import '@/assets/css/transition.css' 	//Custom Transitions CSS
import '@/assets/css/animations.css'  //Custom Animations CSS
import '@/assets/css/tooltips.css'  //Custom Tooltips CSS
// Router
import router from './router'
// Vuex
import {store} from './vuex/store'
// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
// Vue Timer
import VueTimers from 'vue-timers'
Vue.use(VueTimers)
// Vue Tooltips
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
// VeeValidate
import VeeValidate from 'vee-validate'

const dictionary = {
  en: {
    messages:{
      required: () => 'Este campo es requerido.',
      numeric: () => 'Debe expresarse en numeros.',
      email: () => 'El email ingresado no es correcto.',
      url: () => 'El enlace no es valido.',
      image: () => 'El archivo debe ser de tipo imagen.',
      confirmed: () => 'Las contraseñas no coinciden.',
    }
  },
};

const config = {
  dictionary: dictionary,
  classes: true,
  classNames: {
    valid: 'is-success',
    invalid: 'is-danger'
  }
};
Vue.use(VeeValidate, config)
// SweetAlert
import 'sweetalert2/src/sweetalert2.scss'
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

require('./assets/sass/main.scss'); //SASS Main File with (Bulma, AnimateCSS)

Vue.config.productionTip = false

// Router Config
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    var setTitle = nearestWithTitle.meta.title
    if ( typeof setTitle === 'function' ) { document.title = setTitle(to) }
    else { document.title = setTitle }
  }
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      if (typeof tagDef[key] === 'function') {
        tag.setAttribute(key, tagDef[key](to));
      } else {
        tag.setAttribute(key, tagDef[key]);
      }
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));
  next();
});

//Vue Tags Input
import VueTagsInput from '@johmun/vue-tags-input';
Vue.use(VueTagsInput);

new Vue({
  render: h => h(App),
  router,
  axios,
  store
}).$mount('#app')

