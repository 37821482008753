<template>
	<div class="tile h-separator-t8">
		<div class="container">
			<div class="is-hidden-desktop">
				<h2 class="title h-letspace-3 ">{{selected.aka}}</h2>
				<p class="subtitle">
					{{selected.city}}, {{selected.country}}<br>
					{{selected.label}}
				</p>
			</div>
			<div class="columns is-vcentered is-centered" v-if="!error">
				<div class="column is-1 is-hidden-touch">
					<a @click="prevElement()" v-if="qualifiers[indexNow-1] != undefined">
						<i class="icon-play-prev is-size-3"></i>
					</a>
				</div>
				<div class="column is-hidden-touch"></div>
				<div class="column is-5">
					<div class="tile is-vertical">
						<div class="tile h-border-c15 is-clipped">
							<SampleVideo :videoId="selected.url_yt" :title="'Votaciones'" />
						</div>
						<div class="columns is-vcentered is-hidden-touch">
							<div class="column is-1">
								<a v-if="videosLeft.length > 0" 
								@click="prevItems()" 
								class="is-unselectable">
									<i class="icon-flecha-atras is-unselectable is-size-4"></i>
								</a>
							</div>
							<div class="column">
								<VideoList 
								@videoclicked="emitVideo"
								:inList="videos" 
								name="Votaciones"/>
							</div>
							<div class="column is-1">
								<a v-if="videosRight.length > 0" 
								@click="nextItems()" 
								class="is-unselectable">
									<i class="icon-flecha is-unselectable is-size-4"></i>
								</a>
							</div>
						</div>

					</div>
				</div>
				<div class="column is-4 is-hidden-touch">
					<div class="tile is-vertical">
						<h2 class="title h-letspace-3">{{selected.aka}}</h2>
						<p class="subtitle">
							{{selected.city}}, {{selected.country}}<br>
							{{selected.label}}
						</p>
						<div class="has-text-centered" v-if="selected.id != undefined">
							<button class="button h-back-gold h-wbox-100" 
							@click="emailModal=true"
							v-if="!alreadyVote && !postulationPublicVote">
								VOTAR
							</button>
							<p class="help">Votos: <b>{{selected.votes}}</b></p>
						</div>
						
						<Social :social="{instagram:selected.url_ig, facebook:selected.url_fb}" />
					</div>
				</div>
				<div class="column is-1 is-hidden-touch">
					<a @click="nextElement()" v-if="qualifiers[indexNow+1] != undefined">
						<i class="icon-play-next is-size-3"></i>
					</a>
				</div>
			</div>
			<div class="box" v-else>
				<h4 class="title is-4"><i class="fas fa-info-circle"> {{message}}</i></h4> 	
			</div>
			<div class="columns is-vcentered is-centered is-mobile is-hidden-desktop">
				<div class="column is-2">
					<a @click="prevElement()" v-if="qualifiers[indexNow-1] != undefined">
						<i class="icon-play-prev is-size-3"></i>
					</a>
				</div>
				<div class="column is-6" v-if="selected.id != undefined">
					<button class="button h-back-gold h-wbox-100" 
					@click="emailModal=true"
					v-if="!alreadyVote && !postulationPublicVote">
						VOTAR
					</button>
					<p class="help">Votos: <b>{{selected.votes}}</b></p>	
				</div>
				<div class="column is-2">
					<a @click="nextElement()" v-if="qualifiers[indexNow+1] != undefined">
						<i class="icon-play-next is-size-3"></i>
					</a>
				</div>
			</div>
		</div>
		<!-- Email Modal -->
		<transition name="fade">
			<EmailModal v-if="emailModal"
			:show="emailModal" 
			:message="'Info: usted solo puede votar una vez, por lo tanto, asegurese de elegir bien a su competidor.'"
			:load="loading" 
			@send="vote"
			@modal-close="emailModal=false"/>
		</transition>
	</div>
</template>

<script>
	import Social 		from '@/components/general/social'
	import EmailModal 	from '@/components/modal/email'
	import SampleVideo 	from '@/components/postulations/video'
	import VideoList 	from '@/components/general/video_list'
	export default {
		name:"PublicVotes",
		components: {
			SampleVideo,
			Social,
			VideoList,
			EmailModal
		},
		data(){
			return {
				alreadyVote: false,
				error:false,
				message: '',
				event: {},
				selected: {},
				postulation: {},
				qualifiers: [],
				videos: [],
				loading:false,
				videosLeft: [],
				videosRight: [],
				emailModal: false,
				userIp:''
			}
		},
		created() {
			this.$store.commit('loader', 'y')
			this.makeQualifiers()
			this.getIp()
		},
		computed: {
			api() {
				return this.$store.getters.GetApiPath
			},
			googleKey() {
				return this.$store.state.googleAPIKey
			},
			pageData() {
				return {title: this.event.name, name: 'event_detail', params: { id: this.event.name_id } }
			},
			postulationPublicVote() {
				return this.$store.getters.GetAlreadyVote
			},
			indexNow() {
				return this.qualifiers.indexOf(this.selected)
			}
		},
		methods: {
			destroyLocal() {
				localStorage.removeItem('postulationPublicVote')
			},
			vote(email) {
				this.loading = true
				const formData = new FormData()
				formData.append('public_email',email)
				formData.append('public_ip',this.userIp)
				formData.append('plate',this.selected.id)
				formData.append('postu',this.postulation.id)
				this.$http
					.post(this.api+'/postulations/public-votes/', formData)
					.then(() => {
						this.$swal({
							type: "success",
							title: "Enviado!",
							text: 'Se ha votado correctamente por su competidor favorito.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.loading = false
							this.emailModal = false
							this.alreadyVote = true
							localStorage.setItem("postulationPublicVote", true);
						});
					})
					.catch(error => {
						this.loading = false
						var text
						var type
						var title
						const responseData = error?.response?.data
						if ("non_field_errors" in responseData)  {
							type = 'warning'
							title = 'Aviso'
							text = 'Usted ya ha votado anteriormente por un competidor, le recordamos que solo puede votar una vez por concurso.'
						} else {
								type = 'error'
								title = "Ops, ha ocurrido un error."
								text = 'Ha ocurrido un error al intentar votar por un competidor, verifique su conexión a internet e intente de nuevo mas tarde.'
						}
							this.$swal({
								'type': type,
								'title': title,
								'text': text,
								confirmButtonClass: "button",
								buttonsStyling: false,
								background: "white"
							})
					})
			},
			makeQualifiers() {
				var aka_ref = this.$route.params.person
				var event = this.$route.params.event
				var path = this.api+'/postulations/qualifiers/'
				path += '?event='+event
				if(aka_ref) {
					path += '&aka_ref='+aka_ref
				}
				this.$http
					.get(path)
					.then(response => {
						var result = response.data
						this.qualifiers = result.qualifiers
						this.postulation = result.postulation
						this.event = result.event
						var videosIds = []
						for (var v in result.qualifiers) {
							videosIds.push(result.qualifiers[v].url_yt)
						}
						this.getThumps(videosIds.join()).then(items => {
							var count = 0
							if (aka_ref) {
								this.reOrderList(items, result.postulate)
							} else {
								this.selected = items[0]
								for (var x2 in items) {
									if(count >= 4) {
										this.videosRight.push(items[x2])
									} else {
										count++
										this.videos.push(items[x2])
									}
								}
							}
							this.$store.commit('inPage', this.pageData)
							this.$store.commit('loader', 'n')
						})
					})
					.catch(error => {
						this.error = true
						this.message = error.response.data
					})
			},
			getThumps(videosIds) {
				return new Promise((resolve) => {
					let params = {
						part: 'id, snippet',
						key: this.googleKey,
						id: videosIds
					}
					this.$http
						.get('https://www.googleapis.com/youtube/v3/videos', { params })
						.then(response => {
							var items = response.data.items
							for (var i in items) {
								for (var q in this.qualifiers) {
									if (items[i].id === this.qualifiers[q].url_yt) {
										this.qualifiers[q]['isAlbum'] = false
										this.qualifiers[q]['snippet'] = items[i].snippet
									}
								}
							}
							resolve(this.qualifiers)
						})
						.catch(() => {
							resolve(this.qualifiers)
						})

				})
			},
			nextItems() {
				var g_item = this.videosRight[0]
				var r_item = this.videos[0]

				this.videosLeft.push(r_item)
				this.videos.shift()

				this.videos.push(g_item)
				this.videosRight.shift()
			},
			resetVideoLists() {
				this.videos = []
				this.videosRight = []
				this.videosLeft = []
			},
			nextElement() {
				this.selected = this.qualifiers[this.indexNow+1]
				var contain = this.containsObject(this.selected, this.videos)
				if (!contain) {
					this.resetVideoLists()
					this.reOrderList(this.qualifiers, this.selected)
				}
			},
			prevItems() {
				var g_item = this.videosLeft[this.videosLeft.length -1]
				var r_item = this.videos[this.videos.length -1]

				this.videosLeft.pop()
				this.videos.unshift(g_item)

				this.videos.pop()
				this.videosRight.unshift(r_item)		
			},
			prevElement() {
				this.selected = this.qualifiers[this.indexNow-1]
				var contain = this.containsObject(this.selected, this.videos)
				if (!contain) {
					this.resetVideoLists()
					this.reOrderList(this.qualifiers, this.selected)
				}
			},
			emitVideo(video) {
				this.selected = video
			},
			getIp() {
				this.$http
					.get('https://api.ipify.org?format=json')
					.then(response => {
						this.userIp = response.data.ip
					})
			},
			containsObject(obj, list) {
				var i;
				for (i = 0; i < list.length; i++) {
					if (list[i] === obj) {
						return true;
					}
				}

				return false;
			},
			reOrderList(items, xitem) {
				var count = 0
				var setIn = false
				for (var x1 in items) {
					if (items[x1].id === xitem.id) {
						setIn = true
						this.selected = items[x1]
						this.videos.push(items[x1])
					} else if (setIn) {
						if(count >= 3) {
							this.videosRight.push(items[x1])
						} else {
							count++
							this.videos.push(items[x1])
						}
					} else {
						this.videosLeft.push(items[x1])
					}
				}
				var videosLen = this.videos.length
				if(videosLen < 4) {
					for (var i = 0; i < 4-videosLen; i++) {
						var g_item = this.videosLeft[this.videosLeft.length -1]
						this.videos.unshift(g_item)
						this.videosLeft.pop()
					}
				}
			}
		}
	}
</script>