<template>
	<div class="tile repro-playlist-box has-background-white is-vertical">
		<div class="repro-playlist-table is-fullwidth">
			<table class="table is-hoverable is-striped is-fullwidth">
				<thead class="has-background-grey-darker">
					<tr class="is-uppercase is-size-6 is-size-7-mobile">
						<th class="has-text-white">name</th>
						<th class="has-text-white">genre</th>
						<th class="has-text-white">bpm</th>
						<th class="has-text-white">time</th>
						<th class="has-text-white">price</th>
					</tr>
				</thead>
				<tbody class="tbody repro-playlist-table-body">
					<tr v-for="(pi, number) in playItems" 
						:key="pi.id"
						@click="selectRow(pi)"
						:class="{'is-uppercase is-size-5-desktop is-size-6-touch is-unselectable':true,'is-selected':pi.id == selectedRow.id}">
						<td class="has-text-grey">{{number+1}}. {{pi.title}} by {{pi.producer}}</td>
						<td class="has-text-grey">{{pi.genre}}</td>
						<td class="has-text-grey">{{pi.bpm}}</td>
						<td class="has-text-grey">{{pi.time}}</td>
						<td class="has-text-grey">{{pi.price}}$</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ReproPlaylist',
		props: ['playItems', 'selectedRow'],
		methods: {
			selectRow(row) {
				this.$emit('select-row', row)
			}
		}
	}
</script>

<style scoped>
	tbody > tr { cursor: pointer; }
	.repro-playlist-table {
		overflow: auto;
	}
	.repro-playlist-box {
		overflow: hidden;
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
	}
	.is-selected { background: inherit !important; }
	.is-selected > td { color: hsl(0, 0%, 21%) !important; }

	/*Mobile*/
	@media screen and (max-width: 1023px) {
		.repro-playlist-box {
			border-radius: inherit;
		}
		.repro-playlist-table {
			min-height: content !important;
		}
	}
	@media screen and (min-height: 400px) {
		.repro-playlist-table {
			max-height: 55.5vh !important;
		}	
	}
	@media screen and (min-height: 500px) {
		.repro-playlist-table {
			max-height: 57.5vh !important;
		}	
	}
	@media screen and (min-height: 600px) {
		.repro-playlist-table {
			max-height: 59.5vh !important;
		}	
	}
	@media screen and (min-height: 700px) {
		.repro-playlist-table {
			max-height: 61.5vh !important;
		}	
	}
	@media screen and (min-height: 800px) {
		.repro-playlist-table {
			max-height: 63.5vh !important;
		}	
	}


	@media screen and (min-width: 1024px) {
		.repro-playlist-table {
			height: 25rem !important;
		}
	}
	@media screen and (min-width: 1400px) {
		.repro-playlist-table {
			height: 27.5rem !important;
		}
	}
	@media screen and (min-width: 1600px) {
		.repro-playlist-table {
			height: 30rem !important;
		}
	}
	@media screen and (min-width: 1800px) {
		.repro-playlist-table {
			height: 32.5rem !important;
		}
	}
</style>