<template>
	<router-link :to="link">
		<div class="columns is-mobile">
			<div class="column">
				<figure class="custom-picture">
					<img :src="img" class="custom-img" :alt="'foto perfil '+name">
				</figure>
			</div>
			<div class="column">
				<h2><b>{{name}}</b></h2>
				<p class=" is-size-7 is-hidden-touch has-text-justified">
					{{description.substr(0, max_length || 255)}}...<br>
				</p>
				<p class=" is-size-7 is-hidden-desktop has-text-justified">
					{{description.substr(0, max_length_mobile || 150)}}...<br>
				</p>
				<p class="is-size-7 has-text-centered h-textcolor-gold">Leer mas</p>
			</div>
		</div>
	</router-link>
</template>

<script type="text/javascript">
	export default {
		name: 'PerfilDescription',
		props: ['link', 'img', 'name', 'description', 'max_length','max_length_mobile']
	}
</script>

<style type="text/css" scoped>
	.custom-img { transform: translateY(-2em); }
	.custom-picture {
		height: 230px;
		overflow: hidden;
		border-radius: 10px;
	}
	@media screen and (max-width: 1023px) {
		.custom-picture {
			height:195px;
		}
	}
</style>