<template>
	<div class="has-text-centered">
		<h2 class="is-size-5">{{mtitle}}</h2>
		<br>
		<div class="field is-horizontal">
			<div class="field-label h-label-custom is-normal">
				<label class="label">http://www.youtube.com/channel/</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control has-icons-left">
						<input class="input" type="text" placeholder="ID del canal de Youtube"  v-model="mform.youtube">
						<span class="icon is-small is-left">
							<i class="fab fa-youtube"></i>
						</span>
					</p>
				</div>
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label h-label-custom is-normal">
				<label class="label">https://open.spotify.com/</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control has-icons-left">
						<input class="input" type="text" placeholder="Enlace de Spotify" v-model="mform.spotify">
						<span class="icon is-small is-left">
							<i class="fab fa-spotify"></i>
						</span>
					</p>
				</div>
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label h-label-custom is-normal">
				<label class="label">https://www.facebook.com/</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control has-icons-left">
						<input class="input" type="text" placeholder="Enlace a la pagina de Facebook" v-model="mform.facebook">
						<span class="icon is-small is-left">
							<i class="fab fa-facebook"></i>
						</span>
					</p>
				</div>
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label h-label-custom is-normal">
				<label class="label">https://www.instagram.com/</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control has-icons-left">
						<input class="input" type="text" placeholder="Enlace a la pagina de Instragram" v-model="mform.instagram">
						<span class="icon is-small is-left">
							<i class="fab fa-instagram"></i>
						</span>
					</p>
				</div>
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label h-label-custom is-normal">
				<label class="label">https://twitter.com/</label>
			</div>
			<div class="field-body">
				<div class="field">
					<p class="control has-icons-left">
						<input class="input" type="text" placeholder="Enlace a la pagina de Twitter" v-model="mform.twitter">
						<span class="icon is-small is-left">
							<i class="fab fa-twitter"></i>
						</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'FormSocial',
		props: ['formdata', 'mtitle'],
		data() {
			return {
				mform: {
					youtube: '',
					spotify: '',
					facebook: '',
					instagram: '',
					twitter: ''
				}
			}
		},
		created() {
			this.setInfo()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setInfo() {
				if (typeof this.formdata != 'undefined') {
					this.mform = this.formdata
				}
			}
		}
	}
</script>

<style scoped>
	.h-label-custom {
		
	}
</style>