<template>
  <div id="app">

      <transition name="h-fade" mode="out-in">
        <Index v-if="(!setIn && !isAlreadyIn) && indexPage()"
        @setInChange="goInto"/>
        <In v-else/>
      </transition>

    <h-loading :show="loading" />
  </div>
</template>

<script>
import Index    from '@/components/Index'
import In       from '@/components/In'
import PicModal from '@/components/modal/pic'

export default {
  name: 'app',
  data(){
    return {
      setIn: false,
      loadModal: false
    }
  },
  components: {
    Index,
    In,
    'h-loading': PicModal
  },
  computed: {
    isAlreadyIn() {
      return this.$store.getters.GetIfAlreadyIn
    },
    loading() {
      return this.$store.getters.isLoading
    }
  },
  methods: {
    goInto:function(){
      this.setIn = !this.setIn;
      this.$store.dispatch('setIn')
    },
    indexPage() {
      var currentUrl = document.URL
      let indexPages = [
        'http://www.habeatat.com',
        'http://www.habeatat.com/',
        'https://www.habeatat.com',
        'https://www.habeatat.com/',
        'http://habeatat.com',
        'http://habeatat.com/',
        'https://habeatat.com',
        'https://habeatat.com/',
        'http://localhost:8080',
        'http://localhost:8080/'
      ]
      let filtered = indexPages.filter(x => x === currentUrl)
      var check = filtered.length > 0
      return check
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #21292F;
}
</style>
