<template>
<div class="columns is-vcentered ">
      <div :class="{'column':true,}">
        <figure :class="{'image':true, 'h-border-c8 is-clipped':!isMobile}">
            <img :src="picture" 
            :title="title" 
            :alt="'Portada de '+title" >
        </figure>
      </div>
      <div class="column">
          <h2 class="is-size-2 is-size-4-mobile">{{title}}</h2>
          <pre class="
            h-back-default h-ws-preline has-text-justified h-catamaran h-line-1-5
            is-size-5-fullhd 
			is-size-6-widescreen 
			is-size-7-desktop 
			is-size-7-touch">{{description}}</pre>
            <a @click="$emit('show-more')" v-if="showMore" @mouseover="seeMore=true" @mouseleave="seeMore=false">
                <span class="icon h-vertical mr-2">
                    <i :class="{'icon-repro-next-i is-size-7 h-text-dark':true, 'h-animate-text-right':seeMore}"></i>
                </span>  
                <span class="h-text-dark is-size-5">{{text.seeMore}}</span>
            </a>
      </div>
</div>
</template>

<script>
export default {
    name: 'EventNewPortrait',
    props: {
        title: {
            default: '',
            type:String
        },
        description: {
            default: '',
            type: String
        },
        picture: {
            default: '',
            type: String
        },
        showMore: {
            default:false,
            type:Boolean
        }
    },
    data:()=>({
        seeMore:false,
        text: {
            seeMore: 'Ver más'
        }
    }),
    computed: {
        isMobile() {
            return this.$store.getters.GetMobileBrowser
        }
    }
}
</script>

<style scoped>
    figure.image img {
        width: initial;
        max-width: 100%;
        margin:auto;
    }
</style>