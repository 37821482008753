<template>
	<div class="tile is-parent">
		<div class="tile is-child is-3 h-padding30">
			<div class="box h-background-dark">
				<!-- ADMIN LEFT MENU -->
				<adminMenu
				:selected="menuSelected"
				v-if="userType == 1" />
				<!-- JUDGE MENU -->
				<judgeMenu
				:selected="menuSelected"
				v-else-if="userType == 4" />
				<!-- REGULAR MENU -->
				<userMenu
				:selected="menuSelected"
				v-else />
			</div>
		</div>
		<div :class="{'tile is-child h-padding15 box custom-height':true, 'is-5':sectionTwo}">
			<p v-if="menuSelected == 0" 
			class="is-size-3 is-uppercase has-text-grey">
				Bienvenido al Panel de Control <br>
				<small class="has-text-grey is-size-5">
					para comenzar, intente con las opciones del menu izquierdo.
				</small>
			</p>
			<!-- Flyers -->
			<adminBanner 
			v-else-if="menuSelected == 11" 
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder" />
			<!-- Calendar -->
			<adminCalendar 
			v-else-if="menuSelected == 12" 
			:token="token"
			:api="api" />
			<!-- Social -->
			<adminSocial 
			v-else-if="menuSelected == 13" 
			:token="token"
			:api="api" />
			<!-- Keys -->
			<adminKeys 
			v-else-if="menuSelected == 14" 
			:token="token"
			:api="api" />
			<!-- Artist -->
			<adminArtist
			v-else-if="menuSelected == 21"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder" />
			<!-- Event -->
			<adminEvent
			v-else-if="menuSelected == 22"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder"
			@add-section="addSection"
			@rmv-section="rmvSection"
			:sectionTwo="sectionTwo" />
			<!-- Content -->
			<adminContent
			v-else-if="menuSelected == 23"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder"
			@add-section="addSection"
			@rmv-section="rmvSection"
			:sectionTwo="sectionTwo" />
			<!-- Capsula -->
			<adminCapsula
			v-else-if="menuSelected == 36"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder"
			@add-section="addSection"
			@rmv-section="rmvSection"
			:sectionTwo="sectionTwo" />
			<!-- Music -->
			<adminMusic
			v-else-if="menuSelected == 51"
			:token="token"
			:api="api"
			:priorityOrder="priorityOrder"
			@add-section="addSection"
			@rmv-section="rmvSection" />
			<!-- Lyrics -->
			<adminLyrics
			v-else-if="menuSelected == 52"
			:token="token"
			:api="api" />
			<!-- REGULAR USER -->
				<!-- Reservations -->
			<Reservation 
			v-else-if="menuSelected == 31" />
			<!-- POSTULATIONS -->
				<!-- List -->
			<adminPostulation
			v-else-if="menuSelected == 61"
			:token="token"
			:api="api" />
			<Postulates
			v-else-if="menuSelected == 62"
			:token="token"
			:userType="userType"
			:api="api"
			:userId="userId"
			@add-section="addSection"
			@rmv-section="rmvSection" />
			<adminJudge
			v-else-if="menuSelected == 63"
			:token="token"
			:api="api" />
			<adminVotes 
			v-else-if="menuSelected == 64"
			:token="token"
			:api="api" />
			<PreSelected
			v-else-if="menuSelected == 67"
			:token="token"
			:userType="userType"
			:api="api"
			:userId="userId"
			@add-section="addSection"
			@rmv-section="rmvSection" />
			<Selected
			v-else-if="menuSelected == 68"
			:token="token"
			:userType="userType"
			:api="api"
			:userId="userId"
			@add-section="addSection"
			@rmv-section="rmvSection" />
			<artistBackground
			v-else-if="menuSelected ==69"
			:token="token"
			:api="api"
			/>
			<eventBackground
			v-else-if="menuSelected ==70"
			:token="token"
			:api="api"
			/>
			<contentBackground
			v-else-if="menuSelected == 71"
			:token="token"
			:api="api"
			/>
			<capsulaBackground
			v-else-if="menuSelected==72"
			:token="token"
			:api="api"
			/>
		</div>
		<transition name="fadeRight">
			<div v-if="sectionTwo"
			class="tile is-child h-padding30">
				<div class="box custom-height-2">
					<adminEventTwo 
					v-if="menuSelected == 22"
					:token="token"
					:api="api"
					:item="selectedItem"
					:priorityOrder="priorityOrder" />
					<adminContentTwo 
					v-if="menuSelected == 23"
					:token="token"
					:api="api"
					:item="selectedItem"
					:priorityOrder="priorityOrder" />
					<adminMusicTwo 
					v-if="menuSelected == 51"
					:token="token"
					:api="api"
					:item="selectedItem"
					:priorityOrder="priorityOrder"
					@rmv-section="rmvSection" />
					<PostulatesTwo 
					v-if="menuSelected == 62 || menuSelected == 67 || menuSelected == 68"
					:token="token"
					:api="api"
					:item="selectedItem"
					:userId="userId"
					:userType="userType"
					@rmv-section="rmvSection" />
				</div>
			</div>
		</transition>
		<div class="tile is-1" v-if="!sectionTwo">
		</div>
	</div>
</template>

<script>
	import adminMenu 			from '@/components/admin/menu/admin'
	import judgeMenu 			from '@/components/admin/menu/judge'
	import userMenu 			from '@/components/admin/menu/general'
	import adminBanner 			from '@/components/admin/content/banner'
	import adminCalendar		from '@/components/admin/content/calendar'
	import adminSocial			from '@/components/admin/content/social'
	import adminKeys			from '@/components/admin/content/keys'
	import adminArtist			from '@/components/admin/content/artist'
	import adminEvent			from '@/components/admin/content/event'
	import adminEventTwo		from '@/components/admin/content/eventTwo'
	import adminContent			from '@/components/admin/content/content'
	import adminContentTwo		from '@/components/admin/content/contentTwo'
	import adminMusic			from '@/components/admin/content/music'
	import adminMusicTwo		from '@/components/admin/content/musicTwo'
	import adminLyrics			from '@/components/admin/content/lyrics'
	import adminPostulation 	from '@/components/admin/content/postulation'
	import adminJudge 			from '@/components/admin/content/postulationJudge'
	import adminVotes			from '@/components/admin/content/postulationVotes'
	import adminCapsula			from '@/components/admin/content/capsula'

	//Home Background
	import artistBackground 	from '@/components/admin/content/artistBackground'
	import eventBackground		from '@/components/admin/content/eventBackground'
	import contentBackground	from '@/components/admin/content/contentBackground'
	import capsulaBackground	from '@/components/admin/content/capsulaBackground'

	//User Components
	import Reservation 			from '@/components/admin/user_content/reservations'
	//Judge Components
	import Postulates 		from '@/components/admin/judge_content/postulates'
	import PostulatesTwo 	from '@/components/admin/judge_content/postulatesTwo'
	import PreSelected 		from '@/components/admin/judge_content/preSelected'
	import Selected 		from '@/components/admin/judge_content/selected'

	export default {
		name:'Dashboard',
		data() {
			return {
				page: 'Panel de Control',
				pageName: 'index',
				container: false,
				menuTitleSelected: 0,
				sectionTwo: false,
				selectedItem: {}
			}
		},
		created() {
			this.setInternalPage()
			this.loggedIn()
		},
		watch: {
			menuSelected: function() {
				if (this.sectionTwo) { this.sectionTwo = false }
			}
		},
		components: {
			adminMenu,
			judgeMenu,
			userMenu,
			adminBanner,
			adminCalendar,
			adminSocial,
			adminKeys,
			adminArtist,
			adminEvent,
			adminEventTwo,
			adminContent,
			adminContentTwo,
			adminMusic,
			adminMusicTwo,
			adminCapsula,
			Reservation,
			adminLyrics,
			adminPostulation,
			adminVotes,
			adminJudge,
			artistBackground,
			eventBackground,
			contentBackground,
			capsulaBackground,
			Postulates,
			PostulatesTwo,
			PreSelected,
			Selected
		},
		computed: {
			token() {
				return this.$store.getters.GetAxiosConfig(2)
			},
			userType() {
				return this.$store.getters.GetUserType
			},
			userId() {
				return this.$store.getters.GetUserId
			},
			api() {
				return this.$store.getters.GetApiPath
			},
			menuSelected() {
				return this.$store.getters.GetDashMenu
			}
		},
		methods: {
			setInternalPage() {
				this.$store.commit('inPage', {title: this.page, name: this.pageName, container: this.container})
			},
			loggedIn() {
				const isLoggedIn = this.$store.getters.isLoggedIn;
				if(!isLoggedIn){
					this.$router.push('/login');
				}
			},
			setContent(val) {
				this.menuSelected = val
			},
			priorityOrder(obj){
				obj.sort(function(a, b) { 
							return a.priority - b.priority || b.id - a.id;
						});
			},
			addSection(item) {
				this.sectionTwo = true
				this.selectedItem = item
			},
			rmvSection() {
				this.sectionTwo = false
				this.selectedItem = {}
			}

		}	
	}
</script>

<style scoped>
	.tile { transition: width .4s !important; }
	.custom-height, .custom-height-2 {
		min-height: auto !important;
		overflow-y: auto;
		overflow-x: hidden;
	}
	@media only screen and (min-width: 1200px) {
		.custom-height {
			max-height: 29em !important;
		}
		.custom-height-2 {
			max-height: 24em !important;
		}
	}
	@media only screen and (min-width: 1400px) {
		.custom-height {
			max-height: 37em !important;
		}
		.custom-height-2 {
			max-height: 32em !important;
		}
	}
	@media only screen and (min-width: 1600px) {
		.custom-height {
			max-height: 40em !important;
		}
		.custom-height-2 {
			max-height: 35em !important;
		}
	}
	@media only screen and (min-width: 1800px) {
		.custom-height {
			max-height: 45.5em !important;
		}
		.custom-height-2 {
			max-height: 40.5em !important;
		}
	}
</style>