<template>
	<div>
		<div class="field">
			<label class="label">Título</label>
			<div class="control">
				<input 
					class="input" 
					type="text" 
					placeholder="Título de referencia" 
					name="title" 
					v-model="mform.title">
			</div>
		</div>
		<div class="field is-horizontal">
			<div class="field-label is-normal">
				<label class="label">Artista</label>
			</div>
			<div class="field-body">
				<div class="field has-text-left">
					<p class="control select">
						<select 
							name="artistId" 
							id="artist"
							v-validate="'required'"
							v-model="mform.artist">
							<option 
								v-for="artist in artists"
								:key="artist.id" 
								:value="artist.id">
							{{artist.name}}
						</option>
						</select>
						<transition name="fade">
					<span class="tag is-rounded" v-show="errors.has('artistId')">
						{{ errors.first('artistId') }}
					</span>
				</transition>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormHomeBackground',
		props: ['formdata'],
		inject: ['$validator'],
		data() {
			return {
				mform: {
					title: "",
					src: '',
					artist: undefined
				},
				//tag:''
			}
		},
		created() {
			this.emitForm()
			this.setInfo()
			
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		computed:{
			artists(){
				return this.$store.state.artist
			},
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},

			setInfo() {
				
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if (typeof this.formdata.src != 'undefined') {
						this.mform.src = this.formdata.src
					}
					if (typeof this.formdata.artist != 'undefined'){
						this.mform.artist = this.formdata.artist
					}
				}		
			}
		}
	}
</script>