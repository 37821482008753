<template>
	<div class="tile is-3 h-margin-center is-vertical">
		<div class=" box login-box is-vertical h-separator-t5">
			<form name="login_form" @submit.prevent="login">
				<h2 class="is-uppercase is-size-3">acceso</h2>
				<div class="field">
					<p class="control has-icons-left has-icons-right">
						<input 
						class="input" 
						type="text"
						name="username" 
						placeholder="Usuario"
						v-model="userData.user"
						v-validate="'required'">
						<span class="icon is-small is-left">
							<i class="fas fa-user"></i>
						</span>
					</p>
				</div>
				<div class="field">
					<p class="control has-icons-left">
						<input 
						class="input" 
						type="password" 
						name="password" 
						placeholder="Contraseña"
						v-model="userData.pass"
						v-validate="'required'">
						<span class="icon is-small is-left">
							<i class="fas fa-lock"></i>
						</span>
					</p>
				</div>
				<div class="field">
					<p class="control has-text-centered">
						<button 
						type="submit" 
						:class="{'button is-uppercase is-dark':true, 'is-loading':loading}"
						@click="login()">
						Ingresar
						</button>
					</p>
				</div>
				<span></span>
			</form>
		</div>
		<transition name="fadeUp">
			<Notification
			v-if="notification" 
			:n_text="'Por favor rellene todos los campos.'"
			:n_style="'h-back-darkblue'"
			:logo="8"
			@close="notification = false"/>
		</transition>
	</div>
</template>

<script>
	import Notification from '@/components/general/notification'
	export default {
		name: 'adminLogin',
		data() {
			return {
				page: 'Iniciar sesión',
				pageName: 'index',
				container: false,
				userData: {
					user: '',
					pass: ''
				},
				notification: false,
				loading: false
			}
		},
		created(){
			this.setInternalPage()
			this.loggedIn()
		},
		components: {
			Notification
		},
		methods: {
			setInternalPage() {
				this.$store.commit('inPage', {title: this.page, name: this.pageName, container:this.container})
			},
			loggedIn() {
				const isLoggedIn = this.$store.getters.isLoggedIn;
				if(isLoggedIn){
					this.$router.push('/tablero');
				}
			},
			login() {
				this.$validator.validateAll().then(result => {
					if (result) {
						this.loading = true
						const user = {
							username: this.userData.user,
							password: this.userData.pass
						};
						this.$http
							.post(this.$store.state.api+"/login/", user)
							.then(response => {
								//if (response.data.type != 1 ) {
								/*	this.loading = false
									this.$swal({
										type: "error",
										title: "Acceso Denegado.",
										text: "No posee los permisos suficientes para ingresar.",
										confirmButtonClass: "button",
										buttonsStyling: false,
										background: "#f2f2f2"
									});*/
								//} else {
									this.$store.dispatch('login', {
										userType: response.data.type,
										userId:   response.data.id,
										userName: response.data.user,
										token:    response.data.token,
									}).then(() => {
										this.$swal({
											type: "success",
											title: "¡Bienvenido!",
											text: 'Ha ingresado correctamente al panel de control.',
											confirmButtonClass: "button",
											buttonsStyling: false,
											background: "white"
										}).then(() => {
											window.location.replace('/tablero')
										});  

									}); 
								//}              
							})
						.catch(() => {
							this.loading = false;
							this.$swal({
								type: "error",
								title: "Ha ocurrido un error.",
								text: "Usuario o contraseña es incorrecto.",
								confirmButtonClass: "button",
								buttonsStyling: false,
								background: "#f2f2f2"
							});

						});
					} else {
						this.notification = true
					}
				});
			}
		}		
	}
</script>