<template>
	<div class="tile box-video-list">
		<transition-group  name="list" tag="ul" style="position: relative;" v-if="inList.length != 0">
			<li v-for="item in inList" :key="item.id">
				<!-- VIDEO -->
				<a @click="clickItem(item)">
					<figure>
						<img 
							:src="item.snippet.thumbnails.default.url" 
							class="h-border-c5 img-list"
							v-tooltip.bottom="(item.isAlbum ? name+' - ' : '')+item.snippet.title"
							:title="(item.isAlbum ? name+' - ' : '')+item.snippet.title"
							:alt="(item.isAlbum ? name+': ' : '')+item.snippet.title+' (miniatura)'">
					</figure>
				</a>				
			</li>
		</transition-group>
	</div>
</template>

<script>
	export default {
		props: ['inList','name'],
		methods: {
			clickItem(itemDetail) {
				this.$emit('videoclicked', itemDetail)
			},
		}
	}
</script>

<style scoped>
	ul {
		list-style-type: none;
		margin: auto;
		padding: 0;
		overflow: hidden;
	}
	li {
		float: left;
		padding: 5px;
	}
	.box-video-list {
		min-height: 50px;
	}
	.img-list {
		height: 30px;
		width: 50px;
	}
	@media only screen and (min-width: 1000px) {
		.img-list {
			height: 40px;
			width: 50px;
		}
	}
	@media only screen and (min-width: 1200px) {
		.img-list {
			height: 50px;
			width: 70px;
		}
	}
	@media only screen and (min-width: 1800px) {
		.img-list {
			height: 60px;
			width: 80px;
		}
	}
</style>