var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.slidePosition === _vm.positionIndexArray)?_c('section',{staticClass:"main-container",on:{"mouseover":_vm.stopCarousel,"mouseleave":_vm.changeDisplayedData}},[_c('div',{staticClass:"carousel",attrs:{"id":"carousel"}},_vm._l((_vm.events),function(event,index){return _c('div',{key:event.id,class:['carousel-item',
          index === _vm.slide ? 'visible' : 'hidden'
        ]},[_c('img',{class:['imgBackground',
            event.background === '' ? 'has-background-black' : null],attrs:{"loading":"lazy","src":event.background != '' ? event.background : '/img/black-bg.png',"alt":event.name}}),_c('div',{staticClass:"carousel-artist p-6"},[_c('div',{staticClass:"carousel-artist_info is-flex is-flex-direction-column is-justify-content-start"},[_c('h1',{staticClass:"title is-size-1 is-size-3-mobile is-uppercase has-text-white has-text-left px-5 py-4 opensans"},[_vm._v(_vm._s(event.name))]),_c('div',{staticClass:"has-text-white artist_bio px-5"},[_c('p',{staticClass:"mb-3 is-size-5-widescreen has-text-white subtitle is-6 has-text-left opensans p-description"},[_vm._v(" "+_vm._s(event.portrait.description.substring(0,314) + '...')+" ")])]),_c('router-link',{staticClass:"button m-5 is-size-6 has-text-white-bis has-background-grey-darker button-width",attrs:{"to":{
                  name: 'event_portrait',
                  params: {
                    id: event.name_id
                  }
                }}},[_vm._v("Ver más ")]),_c('div',{staticClass:"artist_social mt-6 px-4"})],1),_vm._m(0,true)])])}),0),_c('div',{staticClass:"artist_social_desktop"},_vm._l((_vm.events),function(event,index){return _c('Social',{key:event.id,class:[index === _vm.slide ? null : 'is-hidden',
          'is-flex is-justify-content-start social-width-desktop is-hidden-touch'],attrs:{"social":event.social,"color":_vm.color}})}),1),_c('div',{staticClass:"carousel-dots_list"},_vm._l((_vm.events),function(event,index){return _c('span',{key:event.id,class:['mx-2','p-1',index === _vm.slide ? 'has-background-white' : 'has-background-grey'],on:{"click":function($event){return _vm.changeSlide(index)}}})}),0)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-artist_videos"},[_c('div',{staticClass:"main_video"})])
}]

export { render, staticRenderFns }