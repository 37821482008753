<template>
<aside class="menu has-text-left">
		<p class="menu-label">
			La Capsula
		</p>
		<ul class="menu-list">
			<li>
				<a 
				@click="menuSelection(31)"
				:class="{'is-active':(selected == 31)}">
					Reservar
				</a>
			</li>
			<li>
				<a 
				@click="menuSelection(32)"
				:class="{'is-active':(selected == 32)}">
					Mis Reservaciones
				</a>
			</li>
			<li>
				<a 
				@click="menuSelection(33)"
				:class="{'is-active':(selected == 33)}">
					Comprar Horas
				</a>
			</li>
		</ul>
		<p class="menu-label">
			Mi Cuenta
		</p>
		<ul class="menu-list">
			<li>
				<a 
				@click="menuSelection(91)"
				:class="{'is-active':(selected == 91)}">
					Datos
				</a>
			</li>
		</ul>
		<p class="menu-label logout h-textcolor-darkblue">
			<a @click="logout()">Desconectar</a>
		</p>
	</aside>
</template>

<script>
	export default {
		name: 'dashboard-menu',
		props:['selected'],
		methods: {
			menuSelection(selected) {
				this.$emit('selection', selected)
			},
			logout() {
				this.$store.dispatch('logout').then(() => {
					window.location.replace('/login')
				})
			},
		}

	}	
</script>

<style scoped>
	.menu-list a.is-active {
		background: #21292F;
		color: white !important;
	}
	.logout {
		font-weight: bold;
	}
</style>