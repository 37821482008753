<template>
	<div class="tile">
		<div class="columns h-fullwidth">
			<div class="column is-3">
				<div class="tile is-vertical">
					<button class="button is-info" @click="modals.newLyric = true"><i class="fas fa-plus has-text-white"> New Lyric </i></button>
					<hr class="has-background-grey-lighter">
					<transition name="fadeDown">	
						<div v-if="buttons" class="tile is-vertical">
							<button class="button is-info is-rounded" @click="modals.updateLyricInfo = true">
								<i class="fas fa-info-circle has-text-white"> Edit Info</i>
							</button>
							<br>
							<button class="button is-link is-rounded" @click="modals.updateLyric=true">
								<i class="fas fa-edit has-text-white"> Edit Lyric</i>
							</button>
							<br>
							<button class="button is-success is-rounded" @click="setMedia()">
								<i class="fas fa-film has-text-white"> Edit Media</i>
							</button>
							<br>
							<button class="button is-light is-rounded" 
							v-if="lyric_selected.is_active === false" 
							@click="changeStatus({is_active: !lyric_selected.is_active, id:lyric_selected.id })">
								<i class="fas fa-check"> Activate</i>
							</button>
							<button class="button is-black is-rounded" v-else 
							@click="changeStatus({is_active: !lyric_selected.is_active, id:lyric_selected.id })">
								<i class="fas fa-times has-text-white"> Deactivate</i>
							</button>
							<br>
							<button class="button is-danger is-rounded" @click="modals.confirm = true">
								<i class="fas fa-trash has-text-white"> Delete</i>
							</button>
							<br>
						</div>
					</transition>
				</div>
			</div>
			<div class="column">
				<div class="tile is-vertical">
					<table class="table h-top-separator20">
						<thead>
							<tr>
								<th>Title</th>
								<th>Artist</th>
								<th>Album</th>
								<th>Year</th>
							</tr>
						</thead>
						<transition-group  name="list" tag="tbody">
							<tr v-for="li in lyrics" :key="li.id" @click="selectLyric(li)" :class="{'is-active':li.id == lyric_selected.id}">
								<td><i :class="['fas fa-dot-circle', li.is_active ? 'has-text-success':'has-text-danger']"></i>&nbsp;{{li.title}}</td>
								<td>{{setArtistName(li.artist)}}</td>
								<td>{{li.album}}</td>
								<td>{{li.year}}</td>
							</tr>
						</transition-group>
					</table>
				</div>
			</div>
		</div>
		<!-- New Lyrics Modal -->
		<LyricsModal v-if="modals.newLyric"
		:title="'New Lyric'"
		:show="modals.newLyric"
		:loading= "loading"
		@save-form = "newLyric"
		@close-modal="modals.newLyric = false" />
		<!-- Update Info Modal -->
		<FormModal2 v-if="modals.updateLyricInfo"
		:show="modals.updateLyricInfo"
		:formType="1"
		:data="lyric_selected"
		:charging="loading"
		:title="'Update Lyric Information'"
		@save-form="updateLyricInfo"
		@modal-close="modals.updateLyricInfo = false" />
		<!-- Update Lyric Modal -->
		<FormModal2 v-if="modals.updateLyric"
		:show="modals.updateLyric"
		:formType="2"
		:data="lyric_selected"
		:charging="loading"
		:title="'Update Lyric'"
		@save-form="updateLyric"
		@modal-close="modals.updateLyric = false" />
		<!-- Artist Media Form -->
		<FormModal2 v-if="modals.media"
		:show="modals.media"
		:formType="3"
		:data="lyric_media"
		:charging="loading"
		:title="'Change Lyric Media'"
		@save-form="changeMedia"
		@modal-close="modals.media = false" />
		<!-- Delete Confirm Modal -->
		<ConfirmModal 
			:show="modals.confirm"
			v-if="modals.confirm"
			:message="'Are you sure to delete this lyric?'"
			@modal-close="modals.confirm = false"
			@modal-submit="deleteLyric(lyric_selected.id)" />
	</div>
</template>

<script>
	import LyricsModal from '@/components/modal/lyric'
	import FormModal2 from '@/components/modal/form2'
	import ConfirmModal from '@/components/modal/confirm'
	export default {
		name: 'Lyrics',
		props: ['token','api'],
		components: {
			LyricsModal,
			FormModal2,
			ConfirmModal
		},
		data() {
			return {
				loading: false,
				buttons: false,
				lyrics: [],
				lyric_selected: {},
				lyric_media: {},
				modals: {
					newLyric: false,
					updateLyricInfo: false,
					updateLyric: false,
					confirm: false,
					media: false,
				}
			}
		},
		created() {
			this.setLyrics()
		},
		computed:{
			artists() {
				return this.$store.getters.GetArtists
			}
		},
		methods: {
			newLyric(data) {
				this.loading = true
				const formData = this.setFormData(data)
				this.$http
					.post(this.api+'/h/lyrics/', formData,this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'The lyric has been created.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.loading = false
							this.modals.newLyric = false
							this.lyrics.push(response.data)
							})
					})
			},
			setLyrics() {
				this.$http
					.get(this.api+'/h/lyrics/',this.token)
					.then(response => {
						this.lyrics = response.data
					})
			},
			selectLyric(item) {
				this.lyric_selected = item
				this.buttons = true
			},
			updateLyricInfo(data) {
				this.loading = true
				const formData = this.setFormData({
					title:data.title,
					artist:data.artist,
					comments:data.comments,
					year:data.year,
					album:data.album
				})
				this.$http
					.patch(this.api+'/h/lyrics/'+data.id+'/', formData, this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'The lyric has been update.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.modals.updateLyricInfo = false
							this.setLyricItem(response.data)
							this.lyric_selected = response.data
							this.loading = false
							})
					})
			},
			updateLyric(data) {
				this.loading = true
				const formData = this.setFormData(data)
				this.$http
					.patch(this.api+'/h/lyrics/'+this.lyric_selected.id+'/', formData, this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'The lyric has been update.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.modals.updateLyric = false
							this.setLyricItem(response.data)
							this.lyric_selected = response.data
							this.loading = false
							})
					})
			},
			setArtistName(val){
				return this.artists.find(x => x.id === val).name
			},
			changeStatus(val) {
				this.$store.commit('loader', 'y')
				const formData = this.setFormData(val)
				this.$http
					.patch(this.api+'/h/lyrics/'+val.id+'/', formData, this.token)
					.then(response => {
						this.setLyricItem(response.data)
						this.lyric_selected = response.data
						this.$store.commit('loader', 'n')
					})
			},
			setFormData(data) {
				const formData = new FormData()
				if("title" in data) {
					formData.append('title', data.title)
					formData.append('title_id', data.title.toLowerCase().replace(/ /g, "-"))
				}
				if("artist" in data) {
					formData.append('artist', data.artist)
				}
				if("comments" in data) {
					formData.append('comments', data.comments)
				}
				if("year" in data) {
					formData.append('year', data.year)
				}
				if("album" in data) {
					formData.append('album', data.album)
				}
				if("lyric" in data) {
					formData.append('lyric', JSON.stringify(data.lyric))
				}
				if("youtube" in data) {
					formData.append('youtube', data.youtube)
				}
				if("is_active" in data) {
					formData.append('is_active', data.is_active)
				}
				if("featuring" in data) {
					formData.append('featuring', data.featuring)
				}
				return formData
			},
			setLyricItem(newItem){
				for (var x in this.lyrics){
					if( this.lyrics[x].id === newItem.id){
						this.lyrics[x] = newItem
						break
					}
				}		
			},
			deleteLyric(lyric_id) {
				this.$store.commit('loader', 'y')
				this.$http
					.delete(this.api+'/h/lyrics/'+lyric_id+'/', this.token)
					.then(() => {
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'The lyric has been deleted.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
								this.$store.commit('loader', 'n')
								this.modals.confirm = false
								for (var x in this.lyrics){
									if( this.lyrics[x].id === lyric_id){
										this.lyrics.splice(x, 1)
										break
									}
								}	
							})	
					})
			},
			changeMedia(data) {
				this.loading = true
				const formData = this.setFormData({youtube:data.youtube})
				this.$http
					.patch(this.api+'/h/lyrics-media/'+data.id+'/', formData, this.token)
					.then(response => {
						this.$swal({
							type: "success",
							title: "Success!",
							text: 'The lyric media has been updated.',
							confirmButtonClass: "button",
							buttonsStyling: false,
							background: "white"
						}).then(() => {
							this.lyric_media = response.data
							this.loading = false
							this.modals.media = false
						})
					})

			},
			setMedia() {
				this.$store.commit('loader', 'y')
				this.$http
					.get(this.api+'/h/lyrics-media/'+this.lyric_selected.id+'/', this.token)
					.then(response => {
						this.lyric_media = response.data
						this.$store.commit('loader', 'n')
						this.modals.media = true
					})
			}
		}
	}
</script>

<style scoped>
	.table tbody td { cursor: pointer; }
	.table tbody tr { transition: box-shadow .6s, background .6s; }
	.table tbody tr.is-active { box-shadow: 0 0 5px black; background: whitesmoke; }

</style>