<template>

  <div class="tile is-ancestor is-vertical">
    <div class="tile is-parent is-hidden-touch">
      <div class="h-separator-t10"></div>
    </div>
    <!-- DISPLAY IN DESKTOP -->
    <div class="tile is-parent is-hidden-mobile">
      <transition name="fadeRight">
        <div class="tile is-child is-6" v-show="vlogoShow">
          <a href='http://copyright.es/copyright/certificado-de-deposito.html?numdepot=DEP636613160888014343' target='_blank'>
            <figure class="image has-text-right has-text-centered-mobile">
              <img src="/img/logo3-min.png" class="logo-size" title="Habeatat Logotipo" alt="Habeatat Logotipo">
            </figure>
          </a>
        </div>
      </transition>
      <!--transition name="fadeUp">
        <div :class="{'tile':true, 'is-child':true, 'is-1':vtextShow}" 
        v-show="vbarShow">
          <figure>
            <img src="/img/linea.png">
          </figure>
        </div>
      </transition-->
      <transition name="fadeLeft">
        <div class="tile is-child is-6 bdleft has-text-left h-padding30" v-show="vtextShow">
          <div class="h-separator-t9"></div>
          <p class="
            is-uppercase 
            is-size-3-fullhd 
            is-size-4-widescreen 
            is-size-6-touch
            is-size-5
            h-line-1-3">
            hacemos música que transforma, <br/>
            eventos que conectan <br/>
            y contenido que no caduca.
          </p>
        </div>
      </transition>

    </div>

    <!--DISPLAY IN MOBILE -->
    <div class="tile is-parent is-hidden-desktop">
      <div class="tile is-child is-vertical">
        <div class="h-separator50"></div>
        <a href='http://copyright.es/copyright/certificado-de-deposito.html?numdepot=DEP636613160888014343' target='_blank'>
          <figure class="image has-text-right has-text-centered-mobile">
            <img src="/img/logo3-min.png" class="logo-size" title="Habeatat Logotipo" alt="Habeatat Logotipo">
          </figure>
        </a>
      </div>
      <div class="tile is-child h-padding30">
        <p class="is-uppercase is-size-6" style="">
          hacemos música que transforma, <br/>
          eventos que conectan <br/>
          y contenido que no caduca.
        </p>
      </div>
    </div>

    <!-- BOTH -->
    <transition name="fade">
      <div class="tile is-child is-vertical is-paddingless" v-show="vbuttonShow">
        <div class="h-separator-t2 is-hidden-mobile"></div>
        <div class="tile is-child has-text-centered h-padding15 box-in is-paddingless">
          <a @click="changeToDashboard()" class="is-uppercase is-size-4 has-text-grey is-size-3-fullhd">
            <span class="icon-play is-size-1  has-text-grey"></span> entrar
          </a>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
export default {
  name: 'index',
  data(){
    return {
      vlogoShow:false,
      vtextShow:false,
      vbarShow:false,
      vbuttonShow:false
    }
  },
  timers: {
    /*barIn: { time: 500, autostart: true },*/
    logoIn: { time: 1000, autostart: true },
    textIn: { time: 1000, autostart: true },
    buttonIn: { time: 2000, autostart: true }
  },
  methods:{
    logoIn(){
      this.vlogoShow = !this.vlogoShow
    },
    textIn(){
      this.vtextShow = !this.vtextShow
    },
    barIn(){
      this.vbarShow = !this.vbarShow
    },
    buttonIn(){
      this.vbuttonShow = !this.vbuttonShow
    },
    changeToDashboard(){
      this.$emit('setInChange');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .logo-size{
    width: 35%;
    display: inline;
  }
  .icon-play{
    vertical-align: middle;
  }
  .tile.is-1{
    width: inherit;
  }
  .tile.is-1{
    transition: width 1s;
  }
</style>
