<template>
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
					<p class="is-size-4 has-text-centered">{{message}}</p>
					<br>
						<button type="button" class="button is-primary h-wbox-200" @click="$emit('modal-submit')">
							<i class="fas fa-check has-text-white"></i>
						</button>
						<span class="is-hidden-mobile">&nbsp;</span>
						<div class="is-hidden-tablet h-separator-t1"></div>
						<button type="button" class="button is-danger h-wbox-200" @click="$emit('modal-close')">
							<i class="fas fa-times has-text-white"></i>
						</button>
				</div>
			</div>
		</div>	
</template>

<script>
	export default {
		name: 'confirmModal',
		props: ['show', 'message'],
	}
</script>