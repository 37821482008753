<template>
	<section class="container">
		<div class="tile is-parent is-vertical">
			<div class="tile is-8 h-margin-center is-vertical">
				<picBox v-if="boxData.length > 0"
					:boxes="boxData"
					:rbox="rBox"
					:lbox="lBox"
					:section="page"/>
				<textBar :params="textData" />
			</div>
		</div>
	</section>
</template>

<script>
	import picBox 	from '@/components/general/picbox'
	import textBar 	from '@/components/general/textwithbar'
	export default {
		name:'ContentIndex',
		data(){
			return {
				page: 'contenido',
				pageName: 'content',
				container: true,
				textData: {
					text: this.$store.state.pageInfo.content.text,
					tshow:false,
					lshow:false,
				},
				rBox: [],
				lBox: []
			}
		},
		created() {
			this.$store.commit('restorePicBox', this.pageName)
			this.setInternalPage()
		},
		components:{
			picBox,
			textBar
		},
		computed: {
			boxData() {
				return this.makeBoxes()
			},
			isMobile() {
				return this.$store.getters.GetMobileBrowser
			}
		},
		methods: {
			setInternalPage() {
				this.$store.commit('outPage', {title: this.page, name: this.pageName, container: this.container})
			},
			makeBoxes() {
				var count = 0
				var boxes = []
				this.rBox = []
				this.lBox = []
				var data = this.$store.state[this.pageName]
				for (var i in data) {
					data[i].path = `/${this.page}/${data[i].name_id}/portada`
					count++
					if ((this.isMobile && count > 3) || (!this.isMobile && count > 5)) {
						this.rBox.push(data[i])
					} else {
						boxes.push(data[i])
					}
				}
				return boxes
			}
		}
	}
</script>