<template>
	<footer class="footer">
		<div class="content has-text-centered">
			<p class=" 
				footer-text 
				is-size-6-fullhd 
				is-size-7
				has-text-grey">
				<router-link :to="{ name: 'tc'}" >Condiciones de Uso</router-link>&nbsp;|&nbsp;<router-link :to="{ name: 'pp'}">Política de privacidad</router-link>&nbsp;<i class="far fa-copyright has-text-grey"> 2015-{{isYear}} habeatat</i>.
			</p>
		</div>
	</footer>	
</template>

<script>
	export default {
		name: 'H-Footer',
		computed: {
			isYear() {
				var d = new Date
				return d.getFullYear()
			}
		}
	}
</script>

<style>
	.footer{
		background-color: inherit !important;
		bottom: 0;
		width: 100%;
		padding: 5px !important;
		position: fixed;
		z-index: 2;
	}
	.footer-text{
		font-family: 'Arial' !important;
	}
@media screen and (max-width: 999px){
	.footer {
		background: #21292F !important;
		z-index: 9;
	}
	.footer p, .footer p i {
		font-size: 8px !important;
		color: white !important;
	}
}
</style>