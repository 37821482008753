<template>
	<div>
		<div class="field">
			<label class="label">Título</label>
			<div class="control">
				<input class="input" type="text" placeholder="Título de referencia" name="title" v-model="mform.title">
			</div>
		</div>
		<div class="field">
			<label class="label">Enlace</label>
			<div class="control">
				<input class="input" type="text" placeholder="Link de referencia" name="link" v-model="mform.link">
			</div>
		</div>
		<div class="field">
			<label class="label">Etiquetas</label>
			<div class="control">
				<vue-tags-input
				v-model="tag"
				:tags="mform.tags"
				:allow-edit-tags="true"
				:add-on-key="[13,':', ';', ',', '.']"
				class="is-lowercase"
				placeholder="Etiquetas de la imagen"
				@tags-changed="newTags => mform.tags = newTags" />
			</div>	
		</div>
		<div class="field is-horizontal">
			<div class="field-label is-normal">
				<label class="label">Prioridad</label>
			</div>
			<div class="field-body">
				<div class="field has-text-left">
					<p class="control select">
						<select name="priority" id="priority" v-model="mform.priority">
							<option value="1">1 - Prioridad maxima</option>
							<option value="2">2</option>
							<option value="3">3 - Prioridad alta</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6 - Prioridad media</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9 - Prioridad baja</option>
							<option value="10">10 - Por defecto</option>
						</select>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FormFlyer',
		props: ['formdata'],
		data() {
			return {
				mform: {
					title: "",
					link: "",
					tags: [],
					priority: 10
				},
				tag:''
			}
		},
		created() {
			this.setInfo()
			this.emitForm()
		},
		watch: {
			formdata() {
				this.setInfo()
			},
			mform: {
				handler: function () {
					this.emitForm()
				},
				deep: true
			}
		},
		methods: {
			emitForm() {
				this.$emit('settingForm', this.mform)
			},
			setInfo() {
				if (typeof this.formdata != 'undefined') {
					if (typeof this.formdata.title != 'undefined') {
						this.mform.title = this.formdata.title
					}
					if (typeof this.formdata.link != 'undefined') {
						this.mform.link = this.formdata.link
					}
					if (typeof this.formdata.priority != 'undefined') {
						this.mform.priority = this.formdata.priority
					}
					if (typeof this.formdata.pre_tags != 'undefined') {
						if (this.formdata.pre_tags.length > 0 ) {
							for (var x in this.formdata.pre_tags) {
								this.mform.tags.push({text:this.formdata.pre_tags[x]})
							}
						}
					}
				}		
			}
		}
	}
</script>