var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.postulates != undefined)?_c('div',{staticClass:"tile is-parent is-vertical"},[(_vm.postuParams.phase == 2 || _vm.userType == 1)?_c('div',{staticClass:"tile is-vertical"},[_c('div',{staticClass:"tile is-child"},[_c('h2',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.title))])]),_vm._m(0),_c('hr'),(_vm.postulates.length > 0)?_c('div',{staticClass:"tile is-child"},[_c('table',{staticClass:"table h-fullwidth"},[_vm._m(1),_c('transition-group',{attrs:{"name":"list","tag":"tbody"}},_vm._l((_vm.postulates),function(i){return _c('tr',{key:i.plate_id,class:{
						'is-unselectable':true,
						'has-background-link has-text-white':i.plate_id == _vm.postulateSelected.id,
					},on:{"click":function($event){return _vm.rowSelection(i)}}},[_c('td',[_vm._v(_vm._s(i.plate_id))]),_c('td',[_vm._v(_vm._s(i.plate.aka))]),_c('td',[_vm._v(_vm._s(i.plate.city))]),_c('td',[_vm._v(_vm._s(i.plate.country))])])}),0)],1)]):_c('div',{staticClass:"tile is-vertical"},[_vm._m(2)])]):_c('div',{staticClass:"tile is-vertical"},[_vm._m(3)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tile is-child is-vertical"},[_c('p',[_c('b',[_vm._v("Info:")]),_vm._v(" los seleccionados son los postulantes que consiguieron los votos de todos los jueces, estos pasáran a la ronda final de votación del público")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("N°")]),_c('th',[_vm._v("AKA")]),_c('th',[_vm._v("Ciudad")]),_c('th',[_vm._v("Pais")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-5 has-text-grey"},[_vm._v("Aun no hay postulados seleccionados aun para este evento.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_c('h4',{staticClass:"title is-5 has-text-grey"},[_vm._v("El evento de postulaciones aún no se encuentra en fase de selección.")])])])
}]

export { render, staticRenderFns }