<template>
	<figure>
		<img src="/img/logo5-min.png" :width="width" alt="Habeatat Logo" title="Habeatat Logo">
		<slot></slot>
	</figure>
</template>

<script>
	export default{
		props:['width',],
	};
</script>

<style scoped>
figure {
	display:flex;
	align-content: center;
	justify-content: center;
}
</style>