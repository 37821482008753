<template>
	<transition name="fade">
		<div :class="{'modal':true, 'is-active':show}">
			<div class="modal-background"></div>
			<div class="modal-content">
				<div class="box">
					<p class="is-size-4 has-text-centered">{{message}}</p>
					<input class="input" name="code" id="code" v-model="code" v-validate="'required'"/>
						<transition name="fade">
							<span class="tag is-rounded" v-show="errors.has('code')">
								<i class="fas fa-asterisk"></i> Debe ingresar el codigo para continuar.
							</span>
						</transition>
					<br><br>
					<button :class="{'button is-success is-uppercase':true, 'is-loading':loading}" @click="submitCode()">Verificar</button>
				</div>
			</div>
		</div>	
	</transition>	
</template>

<script>
	export default {
		name: 'verifyCode',
		props: ['show', 'message', 'loading'],
		data() {
			return {
				code: ''
			}
		},
		methods: {
			submitCode() {
				this.$validator.validateAll().then(result => {
					if (result) {
						this.$emit('submit-code', this.code)
					}
				})
			}
		}
	}
</script>